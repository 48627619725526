import * as React from 'react';

import usePathAttributes from './usePathAttributes';

const toDataAttributes = (attributes: Record<string, string> | undefined, label: string) => {
  if (attributes) {
    return Object.entries(attributes)
      .map(([key, value]) => `${key}:${value}`)
      .concat(`buttonText:${label}`)
      .join(',');
  }
  return undefined;
};

const toButtonText = (children: React.ReactNode, defaultLabel: string | undefined): string => {
  if (typeof children === 'string') {
    return children;
  }

  return defaultLabel ?? 'N/A';
};

const useAnalyticClickProps = (children: React.ReactNode, defaultLabel: string | undefined) => {
  const pathAttributes = usePathAttributes();

  const dataAttribute = React.useMemo(
    () => toDataAttributes(pathAttributes, toButtonText(children, defaultLabel)),
    [pathAttributes, children, defaultLabel],
  );

  return {
    'data-amplify-analytics-on': 'click',
    'data-amplify-analytics-name': 'BUTTON_CLICK',
    'data-amplify-analytics-attrs': dataAttribute,
  };
};

export default useAnalyticClickProps;
