import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { Link, useNavigate, useParams } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { venueGetOfferApi } from '../../api/offers/venueGetOfferApi';
import Button from '../../components/Button/Button';
import LoadingButton from '../../components/Button/LoadingButton';
import { useConfirm } from '../../components/Dialogs/Confirm/context/ConfirmDialog.context';
import GoBack from '../../components/GoBack/GoBack';
import LabelDetail from '../../components/LabelDetail/LabelDetail';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import TabPanelContainer, { Panel } from '../../components/TabPanelContainer/TabPanelContainer';
import ReservationsTable from '../../components/Tables/Reservation/Reservation';
import { Offer, OfferCategory, OfferStatus } from '../../graphql/API';
import { useUpdateOfferQuery } from '../../hooks/reactQuery/useOfferQuery';
import { useAppSelector } from '../../redux/hooks';
import { datetimeWithTimezone, getTimeInTimezone } from '../../utils/dateV2';
import OfferDetailSkeleton from './OfferDetailSkeleton';
import Access from './components/Access';
import BorderBox from './components/BorderBox';
import LabelDay from './components/LabelDay';
import Requirement from './components/Requirement';
import './style.css';

const waitTimeOptions = [
  { value: 'Everyday', key: '0' },
  { value: '3 times per week', key: '2' },
  { value: '1 time per week', key: '6' },
  { value: '1 time every two weeks', key: '13' },
  { value: '1 time per month', key: '30' },
  { value: '1 time every 2 months', key: '60' },
  { value: '1 time per year', key: '364' },
  { value: '1 time only', key: '10000' },
];

const capitalizeWords = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const OfferDetail: React.FC = () => {
  const venueId = useAppSelector((state) => state.venue.value?.id);
  const nagivate = useNavigate();

  const { confirm } = useConfirm();

  const updateMutation = useUpdateOfferQuery(venueId);

  const { offerId } = useParams<{ offerId: string }>();

  const { data, isLoading } = useQuery<Offer>({
    queryKey: ['offer-detail', offerId],
    queryFn: async ({ queryKey }) => {
      const [, offerID] = queryKey as [string, string];

      return venueGetOfferApi(offerID);
    },
    placeholderData: keepPreviousData,
  });

  const frequency = React.useMemo(
    () =>
      data
        ? waitTimeOptions.find((option) => option.key === String(data.waitTime))?.value
        : undefined,
    [data],
  );

  const panels = React.useMemo<Panel[]>(
    () => [
      {
        label: 'Reservations',
        children: <ReservationsTable height='70vh' filters={{ offerID: offerId }} />,
      },
    ],
    [offerId],
  );

  const handleOfferDelete = React.useCallback(async () => {
    if (offerId) {
      const isConfirm = await confirm({
        title: 'Delete Offer',
        confirmationText: 'Delete offer',
        description: <>Are you sure you want to delete this offer?</>,
      });

      if (isConfirm) {
        await updateMutation.mutateAsync({ id: offerId, status: OfferStatus.deleted });
        nagivate('/offers');
      }
    }
  }, [offerId, updateMutation, nagivate, confirm]);

  const handleOfferReactive = React.useCallback(async () => {
    if (offerId) {
      const isConfirm = await confirm({
        title: 'Reactivate Offer',
        confirmationText: 'Reactivate',
        description: <>Are you sure you want to activate this offer?</>,
      });

      if (isConfirm) {
        await updateMutation.mutateAsync({ id: offerId, status: OfferStatus.active });
        nagivate('/offers');
      }
    }
  }, [offerId, updateMutation, nagivate, confirm]);

  const renderButton = () => {
    if (!data) {
      return null;
    }

    if ([OfferStatus.cancelled, OfferStatus.deleted].includes(data.status)) {
      return (
        <>
          <Button
            component={Link}
            to={`/offers/${data.id}/edit`}
            id='btn-edit'
            variant='outlined'
            size='small'
            color='primary'
          >
            Edit
          </Button>
          <LoadingButton
            id='btn-delete'
            size='small'
            variant='contained'
            color='primary'
            onClick={handleOfferReactive}
            loading={updateMutation.isPending}
            fullWidth
          >
            Reactivate
          </LoadingButton>
        </>
      );
    }

    return (
      <>
        <LoadingButton
          id='btn-delete'
          variant='outlined'
          size='small'
          color='primary'
          onClick={handleOfferDelete}
          loading={updateMutation.isPending}
          sx={{ minWidth: 80 }}
        >
          Delete
        </LoadingButton>
        <Button
          component={Link}
          to={`/offers/${data.id}/edit`}
          id='btn-edit'
          startIcon={<EditIcon />}
          variant='contained'
          size='small'
          color='primary'
          fullWidth
        >
          Edit
        </Button>
      </>
    );
  };

  const { startDatetime, endDatetime } = React.useMemo(
    () => ({
      startDatetime: datetimeWithTimezone({
        date: data?.startDate,
        time: data?.startTime,
        timezoneId: data?.timeZoneId,
      }).tz(),
      endDatetime: datetimeWithTimezone({
        date: data?.endDate ?? data?.startDate,
        time: data?.endTime,
        timezoneId: data?.timeZoneId,
      }).tz(),
    }),
    [data],
  );

  return (
    <Box
      px={4}
      py={2}
      sx={{ backgroundColor: 'white', flex: 1, overflowY: 'hidden', height: '100vh' }}
    >
      <Grid container spacing={6} sx={{ height: '100%' }}>
        <Grid
          item
          xs={3}
          sx={{ height: '100%', overflowY: 'auto' }}
          className='offer-detail-container'
        >
          {!isLoading && data ? (
            <>
              <Box component={AspectRatio}>
                <Avatar
                  variant='rounded'
                  alt={String(data.title)}
                  src={String(data.picture?.medium)}
                />
              </Box>

              <Box display='flex' mt={2} justifyContent='space-between'>
                <GoBack to='/offers' />

                <Stack direction='row' width='75%' spacing={2}>
                  {renderButton()}
                </Stack>
              </Box>

              <Stack spacing={2} sx={{ mt: 5 }}>
                <BorderBox title='Offer details'>
                  <LabelDetail label='Name' description={data.title} />
                  <LabelDetail label='Description' description={data.description} />
                  <LabelDetail
                    label='Status'
                    description={<StatusLabel type='offer' status={data.status} emptyBackground />}
                  />
                  {!data.private && (
                    <>
                      <LabelDetail label='Spots' description={String(data.spots)} />
                      {data.category !== OfferCategory.event && (
                        <LabelDetail label='Frequency' description={frequency} />
                      )}
                      <LabelDetail
                        label={data.category === OfferCategory.event ? 'Event date' : 'Offer Start'}
                        description={
                          data.category === OfferCategory.event
                            ? `${data.availableDays
                                ?.map((day) => capitalizeWords(day || ''))
                                .join('')} ${startDatetime.format('ll')}`
                            : startDatetime.format('ll')
                        }
                      />
                      {data.category !== OfferCategory.event && (
                        <LabelDetail
                          label='Offer End'
                          description={data.endDate ? endDatetime.format('ll') : '-'}
                        />
                      )}
                    </>
                  )}
                  {data.private ? null : (
                    <>
                      <LabelDetail
                        label='Start time'
                        description={getTimeInTimezone(data?.startDate, data?.startTime, data?.timeZoneId)}
                      />
                      <LabelDetail
                        label='End time'
                        description={getTimeInTimezone(data?.startDate, data?.endTime, data?.timeZoneId)}
                      />
                    </>
                  )}
                  {!data.private && data.category !== OfferCategory.event && (
                    <Box display='flex' flexDirection='column'>
                      <Typography variant='caption' color='text.secondary'>
                        Available Days
                      </Typography>

                      <Grid container spacing={0.5} mt={'12px'}>
                        {data.availableDays?.map((x, i) => (
                          <Grid key={i} item>
                            <LabelDay day={String(x)} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </BorderBox>

                {!data.private && (
                  <div style={{ marginTop: '42px' }}>
                    <BorderBox title='Access'>
                      <Access data={data} />
                    </BorderBox>
                  </div>
                )}

                <div style={{ marginTop: '42px' }}>
                  <BorderBox title='Requirements'>
                    <Requirement data={data} />
                  </BorderBox>
                </div>
              </Stack>
            </>
          ) : (
            <OfferDetailSkeleton />
          )}
        </Grid>

        <Grid item xs={9} sx={{ height: '100%', overflowY: 'auto' }}>
          <TabPanelContainer panels={panels} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferDetail;
