import * as React from 'react';

import { Box } from '@mui/material';

import { useModal } from 'mui-modal-provider';

import ManageContentTipsDialog from '../../components/Dialogs/ManageContentTips/ManageContentTipsDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import ListContent from './components/ListContent';

const ReservationContent: React.FC = () => {
  const { showModal } = useModal();

  const handleClickDesc = React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();

      showModal(ManageContentTipsDialog);
    },
    [showModal],
  );

  return (
    <Box sx={{ backgroundColor: 'white', flex: 1, p: 4 }}>
      <PageTitle
        title='Content'
        sideComponent={
          <Box
            component='a'
            href='#'
            onClick={handleClickDesc}
            sx={{ fontSize: '0.8rem', color: 'black' }}
          >
            What is content?
          </Box>
        }
      />

      <Box mt={2}>
        <ListContent />
      </Box>
    </Box>
  );
};

export default ReservationContent;
