import * as React from 'react';

import { FormControl, FormControlLabel, FormGroup, FormHelperText, Typography } from '@mui/material';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

import { useField } from 'formik';

export type OptionItem = {
  label: string;
  value: string | number;
};

type CheckboxProps = {
  options: OptionItem[];
} & MuiCheckboxProps;

const CheckboxGroup: React.FC<CheckboxProps> = ({ name, id, options }) => {
  const [field, meta, helper] = useField<string[]>(name || '');

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const values = field.value.reduce<Record<string, boolean>>(
        (prev, crr) => ({
          ...prev,
          [crr]: true,
        }),
        {},
      );

      const newValues = { ...values, [event.target.name]: event.target.checked };

      const vv = Object.entries(newValues)
        .map(([k, v]) => (v ? k : undefined))
        .filter(Boolean) as string[];

      helper.setValue(vv);
    },
    [field.value, helper],
  );

  return (
    <FormControl
      id={id}
      component='fieldset'
      variant='standard'
      error={meta.touched && Boolean(meta.error)}
    >
      <FormGroup row>
        {options.map((x, i) => (
          <FormControlLabel
            key={i}
            control={
              <MuiCheckbox
                checked={field.value?.includes(String(x.value))}
                value={x.value}
                name={String(x.value)}
                onChange={handleChange}
              />
            }
            label= {<Typography sx={{fontWeight:"medium"}}>{x.label}</Typography>}
          />
        ))}
      </FormGroup>
      {meta.touched && <FormHelperText>Please select at least one day</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxGroup;
