import { ConversationItem, VenueGetConversationWithVenueFilter } from '../../graphql/API';
import { venueGetConversationWithUser } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetConversationWithUserApi = async (
  filter: VenueGetConversationWithVenueFilter,
): Promise<ConversationItem> => {
  const result = await executeGql<ConversationItem>(venueGetConversationWithUser, { filter });
  return processGqlResult<ConversationItem>('venueGetConversationWithUser', result);
};
