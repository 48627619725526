import { useCallback, useRef } from 'react';

type Props = { isFetching: boolean; hasMore: boolean; cb: () => void };

export const useInfiniteScroll = ({ isFetching, hasMore, cb }: Props) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          cb();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore, cb],
  );

  return {
    observer,
    lastElementRef,
  };
};
