import * as React from 'react';

import { DialogProps } from '@mui/material/Dialog';

import OfferPreviewCalendar from '../../Calendar/OfferPreviewCalendar/OfferPreviewCalendar';
import BaseDialog from '../Base';

const CalendarDialog: React.FC<DialogProps> = ({ ...props }) => {
  return (
    <BaseDialog height='85%' width='70%' {...props}>
      <OfferPreviewCalendar
        onClose={() => {
          if (props?.onClose) {
            props.onClose({}, 'backdropClick');
          }
        }}
      />
    </BaseDialog>
  );
};

export default CalendarDialog;
