import React from 'react';

import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';

import { ChatMessageItem } from '../../graphql/API';
import { onVenueReceiveChatMessageFromAdmin } from '../../graphql/subscriptions';
import { processGqlResult } from '../../utils/gql-utils';

type Props = {
  userId?: string;
};

export const useChatMessageListenerQuery = ({ userId }: Props) => {
  const [message, setMessage] = React.useState<Nullable<ChatMessageItem>>(null);

  React.useEffect(() => {
    const createSub = API.graphql<GraphQLSubscription<ChatMessageItem>>({
      query: onVenueReceiveChatMessageFromAdmin,
      variables: { userType: 'venue', toUserId: userId },
    }).subscribe({
      next: ({ value }) => {
        const _message = processGqlResult<ChatMessageItem>(
          'onVenueReceiveChatMessageFromAdmin',
          value,
        );
        setMessage(_message as ChatMessageItem);
      },
      error: (error) => {
        console.warn(error);
      },
    });

    return () => {
      createSub.unsubscribe();
    };
  }, [setMessage, userId]);

  return {
    message,
  };
};
