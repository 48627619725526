import React from 'react';

import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export const IGCard: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 0px 25px 0px #0000000D',
        border: '1px solid #F7F7F7',
        borderRadius: '6px',
      }}
    >
      {children}
    </Box>
  );
};
