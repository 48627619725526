import React from 'react';

import { Cell, Pie, PieChart as ReChartPie, Tooltip } from 'recharts';

// const data = [
//   { name: 'Male', value: 2 },
//   { name: 'Female', value: 10 },
// ] as const;

type Chart = {
  name: string;
  value: number;
};

type Props = {
  data: Chart[];
};

const COLORS = ['#4A98FF', '#FF4791'];

export const PieChart: React.FC<Props> = ({ data }) => {
  return (
    <ReChartPie width={224} height={224}>
      <Pie
        data={data}
        innerRadius={60}
        outerRadius={90}
        fill='#4A98FF'
        paddingAngle={3}
        dataKey='value'
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </ReChartPie>
  );
};
