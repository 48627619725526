import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { useOfferDetailQuery, useUpdateOfferQuery } from '../../hooks/reactQuery/useOfferQuery';
import { useAppSelector } from '../../redux/hooks';
import OfferForm from './OfferForm';
import { mapToOfferForm, mapToUpdateOfferInput, schema } from './components/schema';

const EditOffer: React.FC = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const venueState = useAppSelector((state) => state.venue.value);

  const { data } = useOfferDetailQuery(offerId);

  const mutation = useUpdateOfferQuery(venueState?.id);

  const initialValues = React.useMemo(() => data && mapToOfferForm(data), [data]);
  if (!initialValues) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <OfferForm
      method='update'
      formikConfig={{
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
          if (data?.id) {
            const input = mapToUpdateOfferInput(data.id, values, data.timeZoneId);

            await mutation.mutateAsync(input);
          }
        },
      }}
    />
  );
};

export default EditOffer;
