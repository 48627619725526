import { VenueSaveUserInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueSaveUserMutation = /* GraphQL */ `
  mutation VenueSaveUser($input: VenueSaveUserInput!) {
    venueSaveUser(input: $input)
  }
`;

export const venueSaveUserApi = async (input: VenueSaveUserInput): Promise<void> => {
  await executeGql<void>(venueSaveUserMutation, { input });
};
