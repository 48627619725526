import * as yup from 'yup';

import { ComplaintItemInput } from '../../../../graphql/API';

export type Form = {
  cause: ReportCause | '';
  description: string;
};

export const initialValues: Form = {
  cause: '',
  description: '',
};

export enum ReportCause {
  userDidNotPost = 'User did not post',
  blockThisMember = 'Block this member',
  incompleteRequirements = 'Incomplete requirements',
  unsuitablePhoto = 'Unsuitable photo',
  other = 'Other',
}

export const schema = yup.object({
  cause: yup.mixed<ReportCause>().oneOf(Object.values(ReportCause)).required(),
  description: yup.string().required(),
});

export type ExtraPayload = {
  reservationId: string;
  userId: string;
  name: string;
  venueId: string;
  plan: string;
  venueName: string;
  venueLink: string;
  email: string;
  type: string;
};

export type ExtraChatPayload = {
  venueId: string;
  plan: string;
  venueName: string;
  venueLink: string;
  email: string;
  type: string;
};

export const mapToApiPayload = (form: Form, extra: ExtraPayload): ComplaintItemInput[] => [
  {
    name: 'entityType',
    value: 'Venue',
    title: 'User type',
  },
  {
    name: 'plan',
    value: extra.plan,
    title: 'Plan',
  },
  {
    name: 'name',
    value: extra.venueName,
    title: 'Venue name',
  },
  {
    name: 'venueLink',
    value: extra.venueLink,
    title: 'Venue url',
  },
  {
    name: 'email',
    value: extra.email,
    title: 'Venue email',
  },
  {
    name: 'type',
    value: extra.type,
    title: 'Type',
  },
  {
    name: 'appVersion',
    value: 'Desktop',
    title: 'App version',
  },
  {
    name: 'subtype',
    value: form.cause,
    title: 'Report cause',
  },
  {
    name: 'reservationLink',
    value: extra.reservationId,
    title: 'Reservation url',
  },
  {
    name: 'userLink',
    value: extra.userId,
    title: 'Model url',
  },
  {
    name: 'userName',
    value: extra.name,
    title: 'Model name',
  },
  ...(form.description
    ? [
        {
          name: 'reportDescription',
          value: form.description,
          title: 'Description',
        },
      ]
    : []),
];

export const mapToApiChatPayload = (form: Form, extra: ExtraChatPayload): ComplaintItemInput[] => [
  {
    name: 'entityType',
    value: 'Venue',
    title: 'User type',
  },
  {
    name: 'plan',
    value: extra.plan,
    title: 'Plan',
  },
  {
    name: 'name',
    value: extra.venueName,
    title: 'Venue name',
  },
  {
    name: 'venueLink',
    value: extra.venueLink,
    title: 'Venue url',
  },
  {
    name: 'email',
    value: extra.email,
    title: 'Venue email',
  },
  {
    name: 'type',
    value: extra.type,
    title: 'Type',
  },
  {
    name: 'appVersion',
    value: 'Desktop',
    title: 'App version',
  },
  {
    name: 'subtype',
    value: form.cause,
    title: 'Report cause',
  },
  ...(form.description
    ? [
        {
          name: 'reportDescription',
          value: form.description,
          title: 'Description',
        },
      ]
    : []),
];
