import React from 'react';

import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';

import { ConversationItem, ConversationList, VenueListConversationsFilter } from './../../graphql/API';

export type ConversationsContextProps = {
  data: (ConversationItem | null | undefined)[];
  isLoading: boolean;
  isFetching: boolean;
  hasNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<ConversationList, unknown>, Error>>;
  revalidateConversations: () => void;
  setVenueConversationFilter: React.Dispatch<
    React.SetStateAction<Partial<VenueListConversationsFilter>>
  >;
};

export const ConversationsContext = React.createContext<ConversationsContextProps | undefined>(
  undefined,
);

export const useConversationsContext = () => React.useContext(ConversationsContext);
