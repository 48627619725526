import getSymbolFromCurrency from 'currency-symbol-map';
import { last, sortBy } from 'lodash';

import { StripePriceWithMetadata } from '../../../redux/features/subscription/types';
import { BillingCycle, Plan } from '../types';
import { OfferCategory } from '../../../graphql/API';

export const extractSubscriptionPlan = (s: string) => s.split('_') as [Plan, string, BillingCycle];

const mapLabel: Record<string, string> = {
  venue_1: '1 Month',
  venue_6: '6 Months',
  venue_12: '12 Month',
};

const brandMapLabel: Record<string, string> = {
  brand_1: '1 Month',
  brand_6: '6 Months',
  brand_12: '12 Month',
};

export type CycleOptions = {
  label: string;
  discount?: number;
  value: string;
};

export type PlanPrice = Record<Plan, StripePriceWithMetadata>;

export const toDecimal = (amount: string | undefined) => Number(amount ?? '0') / 100;

export const toCurrencyAmount = (amount: number, currency: string) => {
  const amountDecimal = amount.toFixed(2);
  const symb = getSymbolFromCurrency(currency);

  return `${symb}${amountDecimal}`;
};

export const getCycleOptions = (plan: Plan, prices: StripePriceWithMetadata[]): CycleOptions[] => {
  if (plan === 'basic') {
    return [
      { label: '1 Month', value: '1' },
      { label: '6 Months', value: '6', discount: 25 },
      { label: '12 Month', value: '12', discount: 45 },
    ];
  }

  const planPrices = prices.filter((x) => x.metadata.ProductGroupName.includes(`${plan}_venue`));
  const options = planPrices.map((x) => {
    const productSkus = x.metadata.ProductSku.split('_').slice(0, -1);
    const value = Number(last(productSkus));
    const productSku = productSkus.slice(1).join('_');

    const label = mapLabel[productSku];
    return {
      value,
      label,
      metadata: x.metadata,
    };
  });

  return sortBy(options, 'value').map<CycleOptions>((x) => ({
    label: x.label,
    discount: x.metadata.Discount !== '0' ? Number(x.metadata.Discount) : undefined,
    value: String(x.value),
  }));
};

export const getBrandCycleOptions = (
  plan: Plan,
  type: OfferCategory,
  prices: StripePriceWithMetadata[],
): CycleOptions[] => {
  const planPrices = prices.filter((x) => x.metadata.ProductGroupName.includes(`${plan}_${type}`));
  const options = planPrices.map((x) => {
    const productSkus = x.metadata.ProductSku.split('_').slice(0, -1);
    const value = Number(last(productSkus));
    const productSku = productSkus.slice(1).join('_');

    const label = brandMapLabel[productSku];
    return {
      value,
      label,
      metadata: x.metadata,
    };
  });

  return sortBy(options, 'value').map<CycleOptions>((x) => ({
    label: x.label,
    discount: x.metadata.Discount !== '0' ? Number(x.metadata.Discount) : undefined,
    value: String(x.value),
  }));
};

export const getPlanPriceByCycle = (cycle: string, prices: StripePriceWithMetadata[]) => {
  const cyclePrices = prices.filter((x) => x.metadata.ProductSku.split('_')[2] === cycle);

  return cyclePrices.reduce<PlanPrice>(
    (acc, x) => {
      const productSkus = x.metadata.ProductSku.split('_').slice(0, -1);
      const [plan] = productSkus as [Plan];

      return {
        ...acc,
        [plan]: x,
      };
    },
    {
      basic: {
        currency: cyclePrices[0].currency,
        metadata: {},
      },
    } as PlanPrice,
  );
};

export const getBrandPlanPriceByCycle = (name: string, prices: StripePriceWithMetadata[]) => {
  const cyclePrices = prices.filter((x) => x.metadata.ProductSku.includes(name));

  return cyclePrices.reduce<PlanPrice>((acc, x) => {
    const productSkus = x.metadata.ProductSku.split('_').slice(0, -1);
    const [plan] = productSkus as [Plan];

    return {
      ...acc,
      [plan]: x,
    };
  }, {} as PlanPrice);
};

export const getPlanPriceBySku = (sku: string, prices: StripePriceWithMetadata[]) =>
  prices.find((x) => x.metadata.ProductSku.startsWith(sku));
