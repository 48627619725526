import * as React from 'react';

import { Skeleton } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export type LazyImageProps = BoxProps<'img'>;

const LazyImage: React.FC<LazyImageProps> = ({ src, alt, sx, ...restProps }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const [srcImg, setSrcImg] = React.useState(src);

  React.useEffect(() => {
    setIsLoading(true);
    setSrcImg(src);
  }, [src]);

  return (
    <>
      {isLoading && (
        <Skeleton width='100%' height='100%' variant='rectangular' sx={{ transform: 'unset' }} />
      )}
      <Box
        component='img'
        alt={alt}
        src={srcImg}
        sx={{
          transition: 'opacity 400ms ease 0ms',
          ...(isLoading ? { display: 'none' } : undefined),
          ...sx,
        }}
        onLoad={() => setIsLoading(false)}
        {...restProps}
      />
    </>
  );
};

export default LazyImage;
