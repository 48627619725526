import * as React from 'react';

import { CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

import BaseButton from './Button';

export type LoadingButtonProps = {
  loading?: boolean;
} & ButtonProps;

const LoadingButton: React.FC<React.PropsWithChildren<LoadingButtonProps>> = React.forwardRef(
  ({ children, loading, ...restProps }, ref) => {
    const props = React.useMemo<ButtonProps | undefined>(
      () =>
        loading
          ? {
              startIcon: <CircularProgress size='1rem' sx={{ color: 'grey' }} />,
              disabled: true,
            }
          : undefined,
      [loading],
    );

    return (
      <BaseButton {...restProps} {...props} ref={ref}>
        {children}
      </BaseButton>
    );
  },
);

export default LoadingButton;
