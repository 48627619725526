import * as React from 'react';

import { Box } from '@mui/material';

import PageTitle from '../../components/PageTitle/PageTitle';
import ReservationsTable from '../../components/Tables/Reservation/Reservation';

const Reservation: React.FC = () => {
  const [tableHeight, setTableHeight] = React.useState(245);

  const handleTableHeight = (val: number) => {
    setTableHeight(val);
  };

  return (
    <Box sx={{ backgroundColor: 'white', flex: 1, p: 4 }}>
      <PageTitle title='Reservations' />

      <Box mt={2}>
        <ReservationsTable
          height={`calc(100vh - ${tableHeight}px)`}
          detail={true}
          filters={{
            sortBy: 'createdAt',
            sortDirection: 'desc',
          }}
          handleTableHeight={handleTableHeight}
        />
      </Box>
    </Box>
  );
};

export default Reservation;
