import * as React from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';

import useAnalyticClickProps from '../../contexts/AnalyticsContext/useAnalyticClickProps';

// Define your custom props using TypeScript generics
interface ExtendedButtonProps<T extends React.ElementType> {
  component?: T;
}

// Combine the original ButtonProps with your custom props
type Props<T extends React.ElementType> = ButtonProps<T> & ExtendedButtonProps<T>;

const Button = React.forwardRef(
  <T extends React.ElementType>(props: Props<T>, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, ...restProps } = props;

    const analyticProps = useAnalyticClickProps(children, restProps['aria-label']);

    return (
      <MuiButton  {...analyticProps} {...restProps} ref={ref}>
        {children}
      </MuiButton>
    );
  },
);

export default Button;
