import { VenueActionAccessRequest } from '../../graphql/API';
import { venueActionAccessRequest } from '../../graphql/mutations';
import { executeGql } from '../../utils/gql-utils';

export const venueActionAccessRequestApi = async (
  input: VenueActionAccessRequest,
): Promise<void> => {
  await executeGql(venueActionAccessRequest, {
    input,
  });
};
