import { VenueCreateOfferInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueCreateOfferMutation = /* GraphQL */ `
  mutation VenueCreateOffer($input: VenueCreateOfferInput!) {
    venueCreateOffer(input: $input)
  }
`;

export const venueCreateOfferApi = async (input: VenueCreateOfferInput): Promise<void> => {
  await executeGql<void>(venueCreateOfferMutation, { input });
};
