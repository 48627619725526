import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Box, Typography } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { environment } from '../../../config';
import { AccountTransaction } from '../../../graphql/API';
import { useOfferDetailQuery } from '../../../hooks/reactQuery/useOfferQuery';
import Icons from '../../../themes/icons';
import { centsToDollars, formatNumberWithCommas } from '../../../utils/formatter';
import BaseDialog, { BaseDialogProps } from '../Base';
import { DetailItem } from './DetailItem';

type Props = {
  transaction?: AccountTransaction;
  onLicense?: () => void;
} & BaseDialogProps;

const baseUrl = (() => {
  const envUrl = environment === 'production' ? '' : `-${environment}`;
  return `https://assets${envUrl}.beautypass.app`;
})();

export const TransactionDetailsDialog: React.FC<Props> = ({ transaction, onLicense, ...rest }) => {
  const metaData = transaction?.metadata ? JSON.parse(transaction?.metadata || '') : '';
  const username =
    transaction?.userFirstname && transaction.userLastname
      ? `${transaction?.userFirstname} ${transaction.userLastname}`
      : '';

  const offerId = metaData?.offerID || '';
  const { data: offer } = useOfferDetailQuery(offerId);

  return (
    <BaseDialog
      width='469px'
      minHeight={metaData?.type === 'contentDownloaded' ? '800px' : '580px'}
      height={metaData?.type === 'contentDownloaded' ? '800px' : '580px'}
      titleComponent={
        <ModalTitle>
          {transaction?.title?.toLowerCase()?.includes('collab')
            ? `Collab details`
            : 'Transaction details'}
        </ModalTitle>
      }
      padding='6px 14px'
      {...rest}
    >
      {metaData?.type === 'contentDownloaded' && (
        <Box sx={{ my: 3 }}>
          <Box
            component={'img'}
            src={`${baseUrl}/${metaData.content}`}
            sx={{ width: '200px', height: 'auto', objectFit: 'contain' }}
          />
          <Box display={'flex'} alignItems={'center'} gap={1} my={1}>
            <Icons.InfoIcon />
            <Typography
              sx={{ textDecoration: 'underline', fontSize: '15px', cursor: 'pointer' }}
              onClick={onLicense}
            >
              License
            </Typography>
          </Box>
        </Box>
      )}
      <DetailItem
        title='Type'
        description={
          <Typography sx={{ fontSize: '15px', fontWeight: 500, textTransform: 'capitalize' }}>
            {transaction?.title}
          </Typography>
        }
      />
      {metaData?.description && (
        <DetailItem
          title='Description'
          description={
            <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>
              {metaData?.description}
            </Typography>
          }
        />
      )}
      <DetailItem
        title='Status'
        description={
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: 500,
              color: transaction?.status === 'complete' ? '#37BB90' : '#DBAF3E',
              textTransform: 'capitalize',
            }}
          >
            {transaction?.status === 'complete' ? 'Completed' : transaction?.status}
          </Typography>
        }
      />
      {username && (
        <DetailItem
          title='Account'
          description={
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Avatar
                src={transaction?.image?.medium || ''}
                alt={username}
                sx={{ width: '24px', height: '24px', objectFit: 'contain' }}
              />
              <Typography
                sx={{ fontSize: '15px', color: '#111' }}
                component={Link}
                to={`/users/${transaction?.userID}`}
              >
                {username}
              </Typography>
            </Box>
          }
        />
      )}
      {offer && (
        <DetailItem
          title='Offer'
          description={
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Avatar
                src={offer?.picture?.thumbnail || ''}
                alt={offer.title || ''}
                sx={{ width: '24px', height: '24px', objectFit: 'contain' }}
              />
              <Typography
                sx={{ fontSize: '15px', color: '#111' }}
                component={Link}
                to={`/offers/${offer?.id}`}
              >
                {offer.title}
              </Typography>
            </Box>
          }
        />
      )}
      <DetailItem
        title={
          transaction?.title?.toLowerCase()?.includes('fee')
            ? 'Transaction date'
            : transaction?.title?.toLowerCase()?.includes('collab')
              ? 'Date Sent'
              : 'Purchased date'
        }
        description={
          <Typography sx={{ fontSize: '15px', fontWeight: 400 }}>
            {dayjs(transaction?.createdAt).format('ll')}
          </Typography>
        }
      />

      {metaData?.type === 'contentDownloaded' && (
        <DetailItem
          title='License expiration date'
          description={
            <Typography sx={{ fontSize: '15px', fontWeight: 400 }}>
              {dayjs(transaction?.createdAt)
                .add(3, 'month')
                .format('ll')}
            </Typography>
          }
        />
      )}

      <DetailItem
        showBorder={false}
        title='Amount'
        description={
          <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>
            {transaction?.amount && transaction.amount < 0 ? (
              <span>
                -$
                {formatNumberWithCommas(
                  +centsToDollars(transaction.amount || 0)
                    .toString()
                    .replace('-', ''),
                )}
              </span>
            ) : (
              <span
                style={{
                  color: '#37BB90',
                }}
              >
                +${formatNumberWithCommas(+centsToDollars(transaction?.amount || 0))}
              </span>
            )}
          </Typography>
        }
      />
    </BaseDialog>
  );
};

const ModalTitle = styled.h1`
  color: #111;
  font-size: 24px;
  font-weight: 500;
  text-align: center;

  padding: 0;
  margin: 0;
`;
