import React from 'react';
import { Link } from 'react-router-dom';

import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

import styled from '@emotion/styled';

import { StarterIcon } from '../Dialogs/CollabsFilters/ToolTip';
import SvgIcon from '../Icons';

type Props = {
  children: JSX.Element;
  showStarterIcon?: boolean;
  plan?: string;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    minWidth: '340px',
  },
}));

const COLORS: Record<string, Record<string, string>> = {
  starter: {
    colorFrom: '#02BA7B',
    colorTo: '#A0F0B8',
  },
  plus: {
    colorFrom: '#b26ff3',
    colorTo: '#c991ff',
  },
};

export const PremiumTooltip: React.FC<Props> = ({
  children,
  showStarterIcon = false,
  plan = 'starter',
}) => {
  return (
    <HtmlTooltip
      arrow
      title={
        <React.Fragment>
          <PremiumBox>
            <PremiumBoxSidebar
              colorFrom={COLORS[plan]?.colorFrom}
              colorTo={COLORS[plan]?.colorTo}
            ></PremiumBoxSidebar>
            <TitleBox>
              To unlock this feature, upgrade your plan to
              {showStarterIcon && (
                <>
                  <StarterIcon
                    sx={{
                      padding: '6px 0 0 0',
                      margin: '0px 6px',
                      fontSize: '1.2rem',
                      fill: '#03BD7E',
                    }}
                  />
                  <Link
                    style={{
                      fontSize: '15px',
                      color: '#03BD7E',
                      fontWeight: 600,
                      textDecoration: 'underline',
                    }}
                    to={'/plans'}
                  >
                    Starter
                  </Link>{' '}
                  or
                </>
              )}
              <SvgIcon
                name='Plus'
                sx={{
                  fill: 'url(#linear-gradient)',
                  padding: '6px 0 0 0',
                  margin: '0px 6px',
                }}
                fontSize='small'
              />
              <Link
                style={{
                  fontSize: '15px',
                  color: '#E28DDE',
                  fontWeight: 600,
                  textDecoration: 'underline',
                }}
                to={'/plans'}
              >
                Plus
              </Link>
            </TitleBox>
          </PremiumBox>
        </React.Fragment>
      }
    >
      <span>{children}</span>
    </HtmlTooltip>
  );
};

const PremiumBox = styled.div`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
`;

const PremiumBoxSidebar = styled.div<{ colorFrom?: string; colorTo?: string }>`
  height: inherit;
  width: 12px;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom right,
    ${(props) => props.colorFrom},
    ${(props) => props.colorTo}
  );
`;

const TitleBox = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding: 0px 20px;
`;
