import { OfferAccessType, OfferCategory } from '../graphql/API';

const mapAccess: Record<OfferAccessType, string> = {
  acceptAll: 'Accept All',
  permissionRequired: 'Permission Required',
  rejectAll: 'Reject All',
};

export const memberAccessOptions = Object.entries(mapAccess).map(([value, label]) => ({
  value,
  label,
}));

export const getOfferAccessValue = (key: OfferAccessType | undefined | null): string | null =>
  key ? mapAccess[key] : null;

export const OfferCategoriesCallToConfirm = [
  OfferCategory.beauty,
  OfferCategory.boutique,
  OfferCategory.experience,
  OfferCategory.fitness,
];
