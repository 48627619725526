import * as React from 'react';

import Chip from '@mui/material/Chip';

import { upperFirst } from 'lodash';

type LabelDayProps = { day: string };

const LabelDay: React.FC<LabelDayProps> = ({ day }) => {
  return (
    <Chip
      label={upperFirst(day)}
      color='default'
      variant='filled'
      sx={{
        borderRadius: 0.6,
        fontWeight: 500,
      }}
    />
  );
};

export default LabelDay;
