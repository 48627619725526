import axios from 'axios';

import { venueGetPreSignedUrlApi } from '../venue/venueGetPreSignedUrlApi';

export const getFileExtension = (filename: string) => filename.split('.').pop();

const stringAfterChar = (str: string, char: string) => str.slice(str.indexOf(char));

export const uploadFileToS3 = async (file: File): Promise<string> => {
  const ext = getFileExtension(file.name);

  if (!ext) {
    throw new Error('Invalid file');
  }

  const preSignedUrl = await venueGetPreSignedUrlApi(ext);

  const [url] = preSignedUrl.split('?');

  const options = {
    headers: {
      'Content-Type': file.type,
    },
  };

  await axios.put(preSignedUrl, file, options);
  return stringAfterChar(url, 'tmp');
};
