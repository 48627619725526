import { useMemo } from 'react';

import { VenueListAccessRequetsFilter } from '../../../../graphql/API';
import useAccessRequestQuery from '../../../../hooks/reactQuery/useAccessRequestQuery';
import useListBlockedUserQuery from '../../../../hooks/reactQuery/useListBlockedUserQuery';

type Query = 'access-request' | 'blocked-user';

const useQuery = (filters: Partial<VenueListAccessRequetsFilter>) => {
  const query = useMemo<Query>(
    () => ((filters.status as string) === 'blocked' ? 'blocked-user' : 'access-request'),
    [filters.status],
  );

  const r1 = useAccessRequestQuery(filters, query !== 'access-request');

  const r2 = useListBlockedUserQuery(undefined, query !== 'blocked-user');

  return query === 'access-request' ? r1 : r2;
};

export default useQuery;
