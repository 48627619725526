import React from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import dayjs from 'dayjs';

import { ConversationItem } from '../../../graphql/API';

type Props = {
  active?: boolean;
  onConversation: () => void;
} & ConversationItem;

export const Thread: React.FC<Props> = ({
  active = false,
  firstname,
  lastname,
  fromUserMessageCount,
  images,
  messageTime,
  onConversation,
  offerTitle,
}) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: active ? '#F8F9FB' : '',
        paddingY: '18px',
        paddingX: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        borderBottom: !active ? '1px solid #F0F2F4' : '',
        marginBottom: '6px',
      }}
      onClick={onConversation}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
        <Avatar src={images?.thumbnail || ''} alt={firstname || 'user-picture'} />
        <Box>
          <Typography sx={{ fontSize: '15px', fontWeight: 300 }}>
            {firstname} {lastname}
          </Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#949BA5', mt: '3px' }}>
            {offerTitle}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-end' }}>
        {fromUserMessageCount && fromUserMessageCount > 0 ? (
          <Typography sx={{ fontSize: '13px', fontWeight: 500, color: '#FF0126' }}>
            {fromUserMessageCount} {fromUserMessageCount > 1 ? `Messages` : 'Message'}
          </Typography>
        ) : null}
        <Typography sx={{ fontSize: '10px', fontWeight: 400 }}>
          {messageTime ? dayjs(messageTime).format('LT') : null}
        </Typography>
      </Box>
    </Box>
  );
};
