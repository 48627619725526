/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onAdminReceiveChatMessageFromVenueOrUser = /* GraphQL */ `subscription OnAdminReceiveChatMessageFromVenueOrUser {
  onAdminReceiveChatMessageFromVenueOrUser {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnAdminReceiveChatMessageFromVenueOrUserSubscriptionVariables,
  APITypes.OnAdminReceiveChatMessageFromVenueOrUserSubscription
>;
export const onVenueReceiveChatMessageFromAdmin = /* GraphQL */ `subscription OnVenueReceiveChatMessageFromAdmin(
  $userType: String!
  $toUserId: String!
) {
  onVenueReceiveChatMessageFromAdmin(userType: $userType, toUserId: $toUserId) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnVenueReceiveChatMessageFromAdminSubscriptionVariables,
  APITypes.OnVenueReceiveChatMessageFromAdminSubscription
>;
export const onUserReceiveChatMessageFromAdmin = /* GraphQL */ `subscription OnUserReceiveChatMessageFromAdmin(
  $userType: String!
  $toUserId: String!
) {
  onUserReceiveChatMessageFromAdmin(userType: $userType, toUserId: $toUserId) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUserReceiveChatMessageFromAdminSubscriptionVariables,
  APITypes.OnUserReceiveChatMessageFromAdminSubscription
>;
export const onVenueReceiveChatMessageFromUser = /* GraphQL */ `subscription OnVenueReceiveChatMessageFromUser(
  $userType: String!
  $toUserId: String!
  $fromUserId: String!
) {
  onVenueReceiveChatMessageFromUser(
    userType: $userType
    toUserId: $toUserId
    fromUserId: $fromUserId
  ) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnVenueReceiveChatMessageFromUserSubscriptionVariables,
  APITypes.OnVenueReceiveChatMessageFromUserSubscription
>;
export const onUserReceiveChatMessageFromVenue = /* GraphQL */ `subscription OnUserReceiveChatMessageFromVenue(
  $userType: String!
  $toUserId: String!
  $fromUserId: String!
) {
  onUserReceiveChatMessageFromVenue(
    userType: $userType
    toUserId: $toUserId
    fromUserId: $fromUserId
  ) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUserReceiveChatMessageFromVenueSubscriptionVariables,
  APITypes.OnUserReceiveChatMessageFromVenueSubscription
>;
export const onMessagingBtwVenueAndUser = /* GraphQL */ `subscription OnMessagingBtwVenueAndUser(
  $userType: String!
  $toUserId: String!
) {
  onMessagingBtwVenueAndUser(userType: $userType, toUserId: $toUserId) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMessagingBtwVenueAndUserSubscriptionVariables,
  APITypes.OnMessagingBtwVenueAndUserSubscription
>;
export const onAdminReceiveChatMessageFromAdmin = /* GraphQL */ `subscription OnAdminReceiveChatMessageFromAdmin($conversationId: String!) {
  onAdminReceiveChatMessageFromAdmin(conversationId: $conversationId) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnAdminReceiveChatMessageFromAdminSubscriptionVariables,
  APITypes.OnAdminReceiveChatMessageFromAdminSubscription
>;
export const onMessageUpdated = /* GraphQL */ `subscription OnMessageUpdated($conversationId: String!) {
  onMessageUpdated(conversationId: $conversationId) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMessageUpdatedSubscriptionVariables,
  APITypes.OnMessageUpdatedSubscription
>;
export const onMessageRejected = /* GraphQL */ `subscription OnMessageRejected(
  $conversationId: String!
  $messageTime: String!
) {
  onMessageRejected(
    conversationId: $conversationId
    messageTime: $messageTime
  ) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMessageRejectedSubscriptionVariables,
  APITypes.OnMessageRejectedSubscription
>;
export const onChatClosed = /* GraphQL */ `subscription OnChatClosed($userType: String!, $toUserId: String!) {
  onChatClosed(userType: $userType, toUserId: $toUserId) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnChatClosedSubscriptionVariables,
  APITypes.OnChatClosedSubscription
>;
export const onAdminUpdateChatMessage = /* GraphQL */ `subscription OnAdminUpdateChatMessage(
  $messageTime: String!
  $conversationId: String!
) {
  onAdminUpdateChatMessage(
    messageTime: $messageTime
    conversationId: $conversationId
  ) {
    fromUserId
    toUserId
    conversationId
    userType
    messageTime
    message
    messageType
    received
    seen
    rejected
    url
    title
    thumbnail
    complaintID
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnAdminUpdateChatMessageSubscriptionVariables,
  APITypes.OnAdminUpdateChatMessageSubscription
>;
export const onAdminDeleteChatMessage = /* GraphQL */ `subscription OnAdminDeleteChatMessage(
  $messageTime: String!
  $conversationId: String!
) {
  onAdminDeleteChatMessage(
    messageTime: $messageTime
    conversationId: $conversationId
  ) {
    conversationId
    messageTime
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnAdminDeleteChatMessageSubscriptionVariables,
  APITypes.OnAdminDeleteChatMessageSubscription
>;
