import * as React from 'react';

import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';

type SkeletonWithChildProps = {
  loading: boolean;
} & SkeletonProps;

const SkeletonWithChild: React.FC<React.PropsWithChildren<SkeletonWithChildProps>> = ({
  children,
  loading,
  ...restProps
}) => {
  return loading ? <Skeleton {...restProps}>{children}</Skeleton> : children;
};

export default SkeletonWithChild;
