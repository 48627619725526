import { PaymentIntent, VenuePaymentIntentInput } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueGetPaymentIntentQuery = /* GraphQL */ `
  query venueGetPaymentIntent($input: VenuePaymentIntentInput!) {
    venueGetPaymentIntent(input: $input) {
      customerId
      merchantDisplayName
      customerEphemeralKeySecret
      paymentIntentClientSecret
    }
  }
`;

export const venueGetPaymentIntentApi = async (
  input: VenuePaymentIntentInput,
): Promise<PaymentIntent> => {
  const result = await executeGql<PaymentIntent>(venueGetPaymentIntentQuery, { input });
  return processGqlResult<PaymentIntent>('venueGetPaymentIntent', result);
};
