import * as React from 'react';
import { ToolbarProps as RBCToolbarProps } from 'react-big-calendar';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import dayjs from 'dayjs';

import Button from '../../Button/Button';

type ToolbarProps = { title?: string } & RBCToolbarProps;

const Toolbar: React.FC<ToolbarProps> = ({ title, date, onNavigate }) => {
  const goToBack = React.useCallback(() => {
    onNavigate('PREV');
  }, [onNavigate]);

  const goToNext = React.useCallback(() => {
    onNavigate('NEXT');
  }, [onNavigate]);

  const goToday = React.useCallback(() => {
    onNavigate('TODAY');
  }, [onNavigate]);

  const label = React.useMemo(() => dayjs(date).format('MMMM YYYY'), [date]);

  return (
    <Box pb={2} display='flex' alignItems='center' justifyContent='space-between'>
      <Typography fontWeight='500'>{title}</Typography>

      <Stack direction='row' alignItems='center'>
        <IconButton color='primary' onClick={goToBack}>
          <ChevronLeftIcon />
        </IconButton>

        <Button id='btn-cal-today' size='large' onClick={goToday}>
          {label}
        </Button>

        <IconButton color='primary' onClick={goToNext}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default Toolbar;
