import * as React from 'react';

import { Box, CircularProgress, Grid, Stack } from '@mui/material';

import { Formik } from 'formik';
import { isEqual } from 'lodash';

import { venueUpdateVenueApi } from '../../../../api/venue/venueUpdateVenueApi';
import { VenueUpdateVenueInput } from '../../../../graphql/API';
import { fetchVenue } from '../../../../redux/features/venue/venueSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Button/Button';
import TextField from '../../../Formiks/TextField/TextField';
import GooglePlaceContainer from './GooglePlaceContainer';
import './ProfileUpdate.css';
import { makeFormValue, schema } from './schema';

type ProfileUpdateProps = {
  onSaveComplete?: () => void;
};

const ProfileUpdate: React.FC<ProfileUpdateProps> = ({ onSaveComplete }) => {
  const venueState = useAppSelector((state) => state.venue.value);
  const dispatch = useAppDispatch();

  const initialValues = React.useMemo<VenueUpdateVenueInput | undefined>(
    () => makeFormValue(venueState),
    [venueState],
  );

  return initialValues ? (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        if (!isEqual(initialValues, values)) {
          await venueUpdateVenueApi(values);
          dispatch(fetchVenue());
        }

        onSaveComplete && onSaveComplete();
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            height: '100%',
            py: 2,
          }}
          component='form'
          onSubmit={handleSubmit}
        >
          <Stack spacing={3} width='100%'>
            <Grid container>
              <Grid item xs={4}>
                <Box paddingRight='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Name
                  </label>
                  <TextField id='txt-name' name='name' />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box paddingX='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Phone number
                  </label>
                  <TextField id='txt-phone' name='phone' />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box paddingLeft='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Website
                  </label>
                  <TextField id='txt-website' name='website' />
                </Box>
              </Grid>
            </Grid>

            <GooglePlaceContainer />

            <Grid container rowGap={1}>
              <Grid xs={4}>
                <Box paddingRight='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Address 1
                  </label>
                  <TextField id='txt-address-1' name='address1' />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box paddingX='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    City
                  </label>
                  <TextField id='txt-city' name='city' />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box paddingLeft='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    State
                  </label>
                  <TextField id='txt-state' name='stateProvince' />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Address 2
                  </label>
                  <TextField id='txt-address-2' name='address2' />
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={4}>
                <Box paddingRight='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Zip Code
                  </label>
                  <TextField id='txt-postal-code' name='postalCode' />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box paddingLeft='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Country
                  </label>
                  <TextField id='txt-country' name='country' />
                </Box>
              </Grid>
            </Grid>

            <Box>
              <label htmlFor='' className='update-profile-modal-label'>
                Description
              </label>
              <TextField
                id='txt-desc'
                placeholder='Add a Description (optional)'
                name='description'
                multiline
                rows={3}
              />
            </Box>

            <Grid container>
              <Grid xs={4}>
                <Box paddingRight='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Contact first name
                  </label>
                  <TextField id='txt-firstname' name='contactFirstname' />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box paddingX='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Contact last name
                  </label>
                  <TextField id='txt-lastname' name='contactLastname' />
                </Box>
              </Grid>
              <Grid xs={4}>
                <Box paddingLeft='10px'>
                  <label htmlFor='' className='update-profile-modal-label'>
                    Position in company
                  </label>
                  <TextField id='txt-position' name='contactPosition' />
                </Box>
              </Grid>
            </Grid>
          </Stack>

          <Button
            id='btn-save'
            variant='contained'
            size='large'
            type='submit'
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size='1rem' sx={{ color: 'inherit' }} />}
            sx={{ mt: 4, px: 20 }}
          >
            Save
          </Button>
        </Box>
      )}
    </Formik>
  ) : null;
};

export default ProfileUpdate;
