import { BsInfoCircleFill as InfoCircleFillIcon } from 'react-icons/bs';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockIcon from '@mui/icons-material/Lock';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';

import { ReactComponent as AttentionIcon } from '../assets/icons/attention-icon.svg';
import { ReactComponent as BalanceRedIcon } from '../assets/icons/balance-red.svg';
import { ReactComponent as BalanceIcon } from '../assets/icons/balance.svg';
import { ReactComponent as BeautyPassIcon } from '../assets/icons/beautypass-icon.svg';
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
import { ReactComponent as CollabMessageIcon } from '../assets/icons/collab-message.svg';
import { ReactComponent as CollabOfferIcon } from '../assets/icons/collabOffer.svg';
import { ReactComponent as CountryIcon } from '../assets/icons/country.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as DollarSignIcon } from '../assets/icons/dollar-sign.svg';
import { ReactComponent as EventOfferIcon } from '../assets/icons/event-offer.svg';
import { ReactComponent as GridIcon } from '../assets/icons/grid.svg';
import { ReactComponent as HeartIcon } from '../assets/icons/heart.svg';
import { ReactComponent as InfoLightIcon } from '../assets/icons/info-light.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg';
import { ReactComponent as NoChatIcon } from '../assets/icons/no-chat.svg';
import { ReactComponent as NoRequirementIcon } from '../assets/icons/no-requirement.svg';
import { ReactComponent as ReceivePostIcon } from '../assets/icons/receive-post.svg';
import { ReactComponent as ReceiveUserIcon } from '../assets/icons/receive-user.svg';
import { ReactComponent as RecruitOfferIcon } from '../assets/icons/recuritingOffer.svg';
import { ReactComponent as SpecialOfferIcon } from '../assets/icons/specialOffer.svg';
import { ReactComponent as StatsIcon } from '../assets/icons/stats.svg';
import { ReactComponent as StripeIcon } from '../assets/icons/stripe-icon.svg';
import { ReactComponent as TwoWayArrowIcon } from '../assets/icons/two-way-arrow-icon.svg';
import { ReactComponent as UsageBadgeIcon } from '../assets/icons/usage-badge.svg';
import { ReactComponent as VideoIcon } from '../assets/icons/video.svg';

export default {
  ChatIcon,
  DoneAllIcon,
  CollabOfferIcon,
  RecruitOfferIcon,
  SpecialOfferIcon,
  PlaceRoundedIcon,
  AddIcon,
  PlayArrowIcon,
  AttentionIcon,
  StatsIcon,
  InfoIcon,
  VideoIcon,
  MessageIcon,
  HeartIcon,
  CountryIcon,
  EventOfferIcon,
  BalanceIcon,
  CloseIcon,
  UsageBadgeIcon,
  InfoCircleFillIcon,
  CollabMessageIcon,
  ReceivePostIcon,
  ReceiveUserIcon,
  DollarSignIcon,
  StripeIcon,
  BeautyPassIcon,
  TwoWayArrowIcon,
  LockIcon,
  InfoLightIcon,
  SendIcon,
  BalanceRedIcon,
  CalendarIcon,
  DeleteIcon,
  NoChatIcon,
  NoRequirementIcon,
  GridIcon,
  ListIcon,
  StarIcon,
};
