import { useMemo } from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListRatedUserApi } from '../../api/users/venueListRatedUserApi';
import { VenueListRatedUsersFilter, VenueRatedUser } from '../../graphql/API';
import { useAppSelector } from '../../redux/hooks';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

const useListRatedUserQuery = (
  filters?: Partial<VenueListRatedUsersFilter>,
): QueryResult<VenueRatedUser[]> => {
  const venueId = useAppSelector((state) => state.venue.value?.id);

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<
    VenueRatedUser[]
  >({
    initialPageParam: 0,
    queryKey: ['list-rated-user', venueId, filters],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID, queryFilters] = queryKey as [
        string,
        string,
        Partial<VenueListRatedUsersFilter>,
      ];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListRatedUserApi({
        ...queryFilters,
        limit: PAGE_LIMIT,
        offset,
        venueID: Number(venueID),
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueId,
    refetchOnWindowFocus: false,
  });

  const flatData = useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useListRatedUserQuery;
