import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import ManageAccessTips from './ManageAccessTips';

const ManageAccessTipsDialog: React.FC<BaseDialogProps> = (props) => {
  return (
    <BaseDialog {...props} width='800px' height='unset'>
      <ManageAccessTips />
    </BaseDialog>
  );
};

export default ManageAccessTipsDialog;
