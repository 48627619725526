import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: '365px',
    maxWidth: '365px',
    fontSize: '15px',
    boxShadow: '0px -2px 20px 0px #00000014',
  },
}));
