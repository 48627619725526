import React, { useMemo } from 'react';
import { Navigate, isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Typography } from '@mui/material';

const Error: React.FC = () => {
  const error = useRouteError();
  const json = useMemo(() => JSON.stringify(error, Object.getOwnPropertyNames(error), 2), [error]);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Navigate to='/' replace />;
    }
  }

  console.error({ error });

  return (
    <Typography component='div'>
      <pre>{json}</pre>
    </Typography>
  );

  return <>Error 123</>;
};

export default Error;
