import React, { useRef, useState } from 'react';
import { Gallery } from 'react-photoswipe-gallery';

import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import { updateMessageStatusApi } from '../../api/chat/updateMessageStatusApi';
import { venueSendAdminChatMessageApi } from '../../api/chat/venueSendAdminChatMessageApi';
import { uploadFileToS3 } from '../../api/files/uploadFileToS3';
import { venueCreateComplaintApi } from '../../api/reservations/venueCreateComplaintApi';
import { venueListVenueComplaintCountApi } from '../../api/support/venueListVenueComplaintCountApi';
import { useChatContext } from '../../contexts/ChatContext/useChatContext';
import { AdminListUserComplaintFilter, ChatMessageItem, ConversationItem } from '../../graphql/API';
import { useAdminCloseChatListener } from '../../hooks/reactQuery/useAdminCloseChatListener';
import { useChatListQuery } from '../../hooks/reactQuery/useChatListQuery';
import { useChatMessageListenerQuery } from '../../hooks/reactQuery/useChatMessageListenerQuery';
import { useMessageSeenListenerQuery } from '../../hooks/reactQuery/useMessageSeenListenerQuery';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { useAppSelector } from '../../redux/hooks';
import icons from '../../themes/icons';
import { ReportCause, mapToApiChatPayload } from '../Dialogs/ReportReservations/components/schema';
import { AttachmentInput, FileType } from './AttachmentInput/AttachmentInput';
import './ChatSupport.css';
import { Message } from './Message/Message';

interface ChatButtonProps {
  userId?: string;
  conversation?: ConversationItem;
  onClose: () => void;
}

export const ChatSupport: React.FC<ChatButtonProps> = ({ userId, conversation, onClose }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const venue = useAppSelector((state) => state.venue.value);
  const { offerId, setOfferId } = useChatContext() || {};
  const outSideElementRef = useClickOutside({ onClickOutside: onClose });
  const scrollingView = useRef<HTMLDivElement | null>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const firstTimeRender = useRef<number>(0);
  const [shouldCreateTicket, setShouldCreateTicket] = useState(true);
  const [shouldIncludeTheBotMessage, setShouldIncludeTheBotMessage] = useState(true);

  const {
    data: supportTicketCount,
    isLoading: supportTicketIsLoading,
    refetch: supportTicketQueryRefetch,
  } = useQuery({
    queryKey: ['venue-complaint-count', { venueID: userId }],
    queryFn: async ({ queryKey }) => {
      const [, filter] = queryKey as [string, AdminListUserComplaintFilter];
      const data = await venueListVenueComplaintCountApi({ ...filter });
      return data;
    },
  });

  const preDefinedMessage = React.useMemo(
    () =>
      ({
        complaintID: null,
        conversationId: conversation?.conversationId || '',
        fromUserId: conversation?.fromUserId || '',
        message: `Hi ${venue?.name}! How can we assist you today?`,
        messageTime: dayjs.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        messageType: 'text',
        received: true,
        rejected: false,
        seen: true,
        toUserId: '97',
        userType: 'venue',
        __typename: 'ChatMessageItem',
      }) as ChatMessageItem,
    [conversation?.conversationId, conversation?.fromUserId, venue?.name],
  );

  const {
    isFetching: isFetchingMessages,
    isLoading: isLoadingMessages,
    data,
    hasNextPage,
    fetchNextPage,
  } = useChatListQuery({ conversationId: conversation?.conversationId || '' });
  const [isLocalMessageStart, setIsLocalMessageStart] = useState(false);

  const { message } = useChatMessageListenerQuery({ userId });
  const { closeMessage } = useAdminCloseChatListener({ userId });
  const { updatedMessage } = useMessageSeenListenerQuery({
    conversationId: conversation?.conversationId || '',
  });
  const { lastElementRef } = useInfiniteScroll({
    isFetching: isFetchingMessages,
    hasMore: hasNextPage,
    cb: () => {
      setIsLocalMessageStart(false);
      fetchNextPage();
    },
  });
  const [messages, setMessages] = React.useState<Nullable<ChatMessageItem | undefined>[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);

  const postMessageMutation = useMutation({
    mutationFn: venueSendAdminChatMessageApi,
    onSuccess: () => {
      console.log('sended');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['chat-list'] });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong while sending the message', { variant: 'error' });
    },
  });

  const updateMessageStatusMutation = useMutation({
    mutationFn: updateMessageStatusApi,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['venue-conversation'] });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong while updating the message status.', {
        variant: 'error',
      });
    },
  });

  const focusInput = React.useCallback(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);

  const handleMessageUpdate = async (message: ChatMessageItem) => {
    await updateMessageStatusMutation.mutate({
      messageTime: message.messageTime,
      conversationId: message.conversationId,
      seen: true,
      received: true,
    });
  };

  React.useEffect(() => {
    if (offerId && setOfferId) {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['chat-list'] });
        setOfferId('');
      }, 5000);
    }
  }, [offerId, queryClient, setOfferId]);

  React.useEffect(() => {
    console.log(!supportTicketIsLoading && supportTicketCount && supportTicketCount?.open >= 1)
    if (
      !supportTicketIsLoading &&
      supportTicketCount &&
      supportTicketCount?.open === 0 &&
      shouldIncludeTheBotMessage
    ) {
      const _messages = [
        ...data.slice(0, firstTimeRender.current),
        preDefinedMessage,
        ...data.slice(firstTimeRender.current),
      ];
      setMessages(_messages);
    } else if (!supportTicketIsLoading && supportTicketCount && supportTicketCount?.open >= 1) {
      setMessages(data);
    }
  }, [
    data,
    preDefinedMessage,
    supportTicketIsLoading,
    supportTicketCount,
    shouldIncludeTheBotMessage,
  ]);

  React.useEffect(() => {
    if (!supportTicketIsLoading && supportTicketCount && supportTicketCount?.open === 0) {
      setShouldCreateTicket(true);
    } else {
      setShouldCreateTicket(false);
    }
  }, [supportTicketCount, supportTicketIsLoading]);

  React.useEffect(() => {
    if (message) {
      firstTimeRender.current = firstTimeRender.current + 1;
      setMessages((prev) => [message, ...prev]);
    }
  }, [message, setMessages]);

  React.useEffect(() => {
    focusInput();
  }, [focusInput]);

  React.useEffect(() => {
    if (updatedMessage) {
      queryClient.invalidateQueries({ queryKey: ['chat-list'] });
    }
  }, [updatedMessage, queryClient]);

  React.useEffect(() => {
    if (closeMessage) {
      setShouldCreateTicket(true);
      setShouldIncludeTheBotMessage(false);
      supportTicketQueryRefetch();
      setMessages((prev) => [closeMessage, ...prev]);
    }
  }, [closeMessage, supportTicketQueryRefetch]);

  const handleFileUpload = React.useCallback(
    async (file: File, fileType: FileType) => {
      const messageTime = dayjs.utc().format();
      const dataUrl = URL.createObjectURL(file);
      setIsLocalMessageStart(true);

      if (file && conversation) {
        const newMessages = [
          {
            __typename: 'ChatMessageItem',
            fromUserId: userId,
            toUserId: conversation.fromUserId, // adminID
            conversationId: conversation.conversationId,
            userType: 'venue',
            messageTime: messageTime,
            message: dataUrl,
            messageType: fileType,
          },
          ...messages,
        ];
        setMessages(newMessages as ChatMessageItem[]);

        firstTimeRender.current = firstTimeRender.current + 1;
        setIsFileUploading(true);
        const url = await uploadFileToS3(file);
        setIsFileUploading(false);

        if (userId && conversation) {
          await postMessageMutation.mutateAsync({
            conversationId: conversation.conversationId,
            message: url,
            userChatId: userId,
            messageType: fileType,
          });
        }
      }
    },
    [conversation, messages, postMessageMutation, userId],
  );

  const onMessageSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newMessage.trim() !== '' && conversation) {
      const newMessages = [
        {
          __typename: 'ChatMessageItem',
          fromUserId: userId,
          toUserId: conversation.fromUserId, // adminID
          conversationId: conversation.conversationId,
          userType: 'venue',
          messageTime: dayjs.utc().format(),
          message: newMessage,
        },
        ...messages,
      ];
      setMessages(newMessages as ChatMessageItem[]);
      setNewMessage('');
      setIsLocalMessageStart(true);
      scrollingView.current?.scrollIntoView({ block: 'end', behavior: 'auto' });
      if (userId) {
        if (shouldCreateTicket) {
          const apiPayload = mapToApiChatPayload(
            { cause: ReportCause.other, description: newMessage },
            {
              venueId: venue?.id || '',
              plan: venue?.premium ? 'PREMIUM' : 'BASIC',
              venueName: venue?.name || '',
              email: venue?.email || '',
              venueLink: venue?.id || '',
              type: 'Reservation report',
            },
          );
          await venueCreateComplaintApi(apiPayload);
          await postMessageMutation.mutateAsync({
            conversationId: conversation.conversationId,
            message: newMessage,
            userChatId: userId,
          });
          firstTimeRender.current = firstTimeRender.current + 1;
        } else {
          await postMessageMutation.mutateAsync({
            conversationId: conversation.conversationId,
            message: newMessage,
            userChatId: userId,
          });
          firstTimeRender.current = firstTimeRender.current + 1;
        }
        setShouldCreateTicket(false);
      }
    }
  };

  return (
    <Box className='chat-container' ref={outSideElementRef}>
      <div className='root'>
        <Paper className='paper' elevation={2}>
          <div className='chat-header'>
            <Box display='flex' alignItems='center' gap='14px'>
              <icons.ChatIcon color='#3f51b5' style={{ width: 20, height: 20 }} />
              <Typography className='chat-name'>Support chat</Typography>
            </Box>
            <ClearButton onClick={onClose}>
              <ClearIcon />
            </ClearButton>
          </div>
          {!isLocalMessageStart && (isLoadingMessages || isFetchingMessages) && (
            <Box textAlign={'center'} marginY={1}>
              <CircularProgress style={{ width: 20, height: 20 }} />
              <div>
                <em>Loading messages...</em>
              </div>
            </Box>
          )}
          <Gallery>
            <div className='message-container'>
              <div ref={scrollingView} />
              {messages.map((msg, index) => (
                <div
                  {...(messages.length === index + 1 && {
                    ref: lastElementRef,
                  })}
                  className={
                    userId === msg?.fromUserId
                      ? 'message-content-sender'
                      : msg?.messageType === 'endchat'
                        ? 'message-content-receiver-endchat'
                        : 'message-content-receiver'
                  }
                  key={`${index}-${msg?.messageTime}-${conversation?.conversationId}`}
                >
                  {userId && msg && (
                    <Message userId={userId} msg={msg} handleMessageUpdate={handleMessageUpdate} />
                  )}
                </div>
              ))}
            </div>
          </Gallery>
          <form className='message-form-container' onSubmit={onMessageSubmitHandler}>
            <AttachmentInput handleFileUpload={handleFileUpload} disabled={isFileUploading} />
            <Box position='relative' width='100%'>
              <input
                placeholder='Type your message'
                className='message-send-input'
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                ref={messageInputRef}
              />
              <button className='send-button' type='submit'>
                <SendIcon />
              </button>
            </Box>
          </form>
        </Paper>
      </div>
    </Box>
  );
};

const ClearButton = styled.button`
  border: none !important;
  background-color: transparent !important;
  cursor: pointer !important;
  padding: 0 !important;
  display: flex;
  text-align: center;
`;
