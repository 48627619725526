import { Box, Typography } from '@mui/material';

import { orderBy } from 'lodash';

import { Offer } from '../../../graphql/API';
import { ListItem } from '../Base/types';

const mapToItem = (data: Offer): ListItem => ({
  avatar: data.picture?.thumbnail || '',
  to: `/offers/${data.id}`,
  primaryText: data.title || '',
  secondaryText: <></>,
  actionSlot: (
    <Typography component='div'>
      <Box component='span' mr={1} sx={{ color: '#37BB90' }}>
        {data.totalCheckins}
      </Box>
      Total checkins
    </Typography>
  ),
});

export const mapToListItem = (data: Offer[]) =>
  orderBy(data, 'totalCheckins', 'desc').map(mapToItem);
