import * as React from 'react';

import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export type InstagramTagProps = {
  username?: string | null;
} & BoxProps;

const InstaLink = styled(Box)<{ target?: string; href?: string }>({
  color: '#0C66E4',
  fontSize: '15px',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const InstagramTag: React.FC<InstagramTagProps> = ({ username, ...restProps }) => {
  return (
    username && (
      <InstaLink
        component='a'
        target='_blank'
        href={`https://instagram.com/${username}`}
        {...restProps}
      >
        @{username}
      </InstaLink>
    )
  );
};

export default InstagramTag;
