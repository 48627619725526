import { executeGql } from '../../utils/gql-utils';

const venueDeletePortfolioContentMutation = /* GraphQL */ `
  mutation venueDeletePortfolioContent($input: VenueDeletePortfolioInput!) {
    venueDeletePortfolioContent(input: $input)
  }
`;

export const venueDeletePortfolioContentApi = async (id: string): Promise<void> => {
  await executeGql<void>(venueDeletePortfolioContentMutation, { input: { id } });
};
