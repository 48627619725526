import React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

type Props = {
  content: Array<{ title: string; description: string[] }>;
  updateDate: string;
  isTermsRead: boolean;
  onTermsRead: () => void;
};

export const LegalContent: React.FC<Props> = ({
  content,
  updateDate,
  isTermsRead,
  onTermsRead,
}) => {
  const observerTarget = React.useRef(null);

  React.useEffect(() => {
    const target = observerTarget.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isTermsRead) {
          onTermsRead();
        }
      },
      { rootMargin: '-10px' },
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [observerTarget, onTermsRead]);

  return (
    <Box mx={'auto'}>
      {content.map((paragraph, i) => (
        <React.Fragment key={i}>
          <Heading>{paragraph.title}</Heading>
          {paragraph.description.map((text, j) => (
            <Description key={j}>{text}</Description>
          ))}
        </React.Fragment>
      ))}
      <Box textAlign={'right'} sx={{ color: '#ccc' }}>
        <i>Effective on: {updateDate}</i>
      </Box>
      <div ref={observerTarget}>
        <span>&nbsp;</span>
      </div>
    </Box>
  );
};

const Heading = styled.h1`
  color: #111827;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 24px 0;
  padding: 0;
`;

const Description = styled.p`
  color: #111;
  font-size: 15px;
  font-weight: 300;
  padding: 0;
  margin: 0 0 24px 0;
`;
