import { UserReservationContent, VenueListUserReservationContentFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListUserReservationContentQuery = /* GraphQL */ `
  query VenueListUserReservationContent($filter: VenueListUserReservationContentFilter!) {
    venueListUserReservationContent(filter: $filter) {
      items {
        id
        seen
        downloaded
        createdAt
        updatedAt
        userID
        images {
          large
          medium
          small
          thumbnail
          video
        }
      }
    }
  }
`;

export const venueListUserReservationContentApi = async (
  filter: VenueListUserReservationContentFilter,
): Promise<UserReservationContent[]> => {
  const result = await executeGql<UserReservationContent[]>(venueListUserReservationContentQuery, {
    filter,
  });
  return processGqlResult<UserReservationContent[]>(
    'venueListUserReservationContent.items',
    result,
  );
};
