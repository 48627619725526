import React from 'react';

import { Box, Button } from '@mui/material';

import styled from '@emotion/styled';

import Icons from '../../../themes/icons';
import BaseDialog, { BaseDialogProps } from '../Base';

type Props = { onVerify?: () => void } & BaseDialogProps;

const StripeAccountVerifyDialog: React.FC<Props> = ({ onVerify, ...rest }) => {
  return (
    <BaseDialog width='550px' height='440px' padding='14px 34px' {...rest}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '224px',
            justifyContent: 'space-between',
            marginY: '40px',
          }}
        >
          <Icons.BeautyPassIcon />
          <Icons.TwoWayArrowIcon />
          <Icons.StripeIcon />
        </Box>
      </Box>
      <Heading>Verify your account</Heading>
      <Description>To add funds, you must first verify your account on Stripe</Description>

      <Button
        variant='contained'
        fullWidth
        onClick={() => {
          if (rest?.onClose) {
            rest.onClose({}, 'backdropClick');
          }
          if (onVerify) onVerify();
        }}
        sx={{ paddingY: '15px', fontSize: '18px', marginTop: '54px' }}
      >
        Connect with Stripe
      </Button>
    </BaseDialog>
  );
};

const Heading = styled.h2`
  padding: 0;
  margin: 0;

  text-align: center;
  color: #111;
  font-size: 24px;
  font-weight: 500;
`;

const Description = styled.p`
  color: #848484;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
`;

export default StripeAccountVerifyDialog;
