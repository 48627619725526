import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import { reset as resetInvite } from './features/invite/inviteSlice';
import { reset as resetOffer } from './features/offer/offerSlice';
import { reset as resetReservation } from './features/reservation/reservationSlice';
import { reset as resetSubscription } from './features/subscription/subscriptionSlice';
import { reset as resetVenue } from './features/venue/venueSlice';
import type { AppDispatch, RootState } from './store';

const resets = [resetInvite, resetOffer, resetReservation, resetVenue, resetSubscription];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppReset = () => {
  const dispatch = useAppDispatch();

  const reset = useCallback(() => {
    resets.forEach((x) => dispatch(x()));
  }, [dispatch]);

  return reset;
};
