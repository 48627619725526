import React, { useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import { venueGetConversationApi } from '../../api/chat/venueGetConversationApi';
import { useChatContext } from '../../contexts/ChatContext/useChatContext';
import { ChatMessageItem, VenueGetConversationFilter } from '../../graphql/API';
import { useChatMessageListenerQuery } from '../../hooks/reactQuery/useChatMessageListenerQuery';
import { useHasFocus } from '../../hooks/useFocus';
import { useAppSelector } from '../../redux/hooks';
import ChatButton from './ChatButton';
import { ChatSupport } from './ChatSupport';

export const Chat = () => {
  const hasFocus = useHasFocus();
  const venue = useAppSelector((state) => state.venue.value);
  const [showChat, setShowChat] = React.useState(false);
  const { shouldChatOpen, setShouldChatOpen } = useChatContext() || {};

  const { data: conversation, refetch } = useQuery({
    queryKey: ['venue-conversation', { venueID: venue?.id || '' }],
    queryFn: async ({ queryKey }) => {
      const [, filters] = queryKey as [string, VenueGetConversationFilter];
      const data = await venueGetConversationApi({
        ...filters,
      });
      if (shouldChatOpen && setShouldChatOpen) setShouldChatOpen(false);
      return data;
    },
  });
  const { message } = useChatMessageListenerQuery({ userId: venue?.id || '' });
  const renderCount = useRef<number>(0);
  const [localMessage, setLocalMessage] = React.useState<Nullable<ChatMessageItem>>(null);

  const handleChat = React.useCallback(() => {
    setShowChat((pre) => !pre);
    renderCount.current = renderCount.current + 1;
  }, []);

  React.useEffect(() => {
    if (message) {
      setLocalMessage(message);
      refetch();
    }
  }, [message, refetch]);

  React.useEffect(() => {
    if (
      (localMessage !== null && renderCount.current === 0 && hasFocus) ||
      (!isEqual(localMessage, message) && !showChat && hasFocus)
    ) {
      handleChat();
    }
  }, [handleChat, localMessage, message, showChat, hasFocus]);

  React.useEffect(() => {
    if (shouldChatOpen) {
      setShowChat(shouldChatOpen);
    }
  }, [shouldChatOpen]);

  return (
    <>
      <ChatButton
        onClick={handleChat}
        disabled={showChat}
        unreadCount={conversation?.toUserMessageCount || 0}
      />
      {showChat && venue?.id ? (
        <ChatSupport userId={venue?.id || ''} onClose={handleChat} conversation={conversation} />
      ) : null}
    </>
  );
};
