import { AppConfig, Environment, environments } from './types';

const getEnvironment = (value: string | undefined): Environment => {
  const env = (value || 'dev') as Environment;

  if (environments.includes(env)) {
    return env;
  }

  throw new Error('Invalid environment');
};

export const environment = getEnvironment(process.env.REACT_APP_ENV);

type ConfigEnv = {
  [key in Environment]: AppConfig;
};

const GOOGLE_MAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAP_API ?? '';

const dev: AppConfig = {
  googleMapApiKey: GOOGLE_MAPS_APIKEY,
  stripKey:
    'pk_test_51KbRR4JwGfgtrYUhVvYLgZUd5vHLDMZztO9xNTLazHWgysoTXMJeTvfa6zMEnMWT2zj8ZAtFBOeTkJv1OQART11o00Zog2Mr42',
};

const config: ConfigEnv = {
  local: { ...dev },
  dev,
  production: {
    googleMapApiKey: GOOGLE_MAPS_APIKEY,
    stripKey:
      'pk_live_51KbRR4JwGfgtrYUhrVogjEFDiSTnOElaB5zpVZIJ9xlNEKhD9XurvaMiqLP1wwCIIe3UiVm1vZ4OFCjfIFsMsWf200ifWPaQIz',
  },
};

export default config[environment];
