import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { StripePriceWithMetadata } from '../../../../redux/features/subscription/types';
import { toCurrencyAmount, toDecimal } from '../../common/helper';

export type PlanPriceProps = {
  price: StripePriceWithMetadata | undefined;
  highlightPrice?: string;
  showBasePrice?: boolean;
  boldBilledPrice?: boolean;
};

const cycleValue = (p: StripePriceWithMetadata | undefined) => {
  if (p?.unit_amount) {
    const periodPrice = toCurrencyAmount(toDecimal(String(p.unit_amount)), p.currency);

    const month = Number(p.metadata.ProductSku.split('_')[2]);

    if (month === 1) {
      return 'Billed monthly';
    }

    return `${periodPrice} Billed every ${month} months`;
  }

  return 'No billing';
};

const PlanPrice: React.FC<PlanPriceProps> = ({
  price,
  highlightPrice,
  showBasePrice = true,
  boldBilledPrice = true,
}) => {
  const showOriginalrice = React.useMemo(() => {
    if (price?.metadata.BasePrice) {
      return !(price?.metadata.BasePrice == String(price?.unit_amount));
    }

    return false;
  }, [price?.metadata.BasePrice, price?.unit_amount]);

  return (
    <Typography
      component='div'
      variant='subtitle1'
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      {showBasePrice && (
        <Box
          sx={{
            lineHeight: 1,
            ...(showOriginalrice
              ? { textDecoration: 'line-through', color: '#111' }
              : { color: 'white' }),
          }}
        >
          {toCurrencyAmount(toDecimal(price?.metadata.BasePrice), price?.currency ?? '')}
        </Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ fontSize: '34px', fontWeight: '600', marginRight: 1, color: highlightPrice }}>
          {toCurrencyAmount(toDecimal(price?.metadata.CostPerMonth), price?.currency ?? '')}
        </Box>
        <Box sx={{ color: '#5F5F60', fontSize:'15px', fontWeight:'500' }}>/month</Box>
      </Box>

      <Box sx={{ fontSize: '15px', fontWeight: boldBilledPrice ? '400' : undefined }}>
        {cycleValue(price)}
      </Box>
    </Typography>
  );
};

export default PlanPrice;
