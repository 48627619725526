import { ConversationList, VenueListConversationsFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListConversationsApi = async (
  filter: VenueListConversationsFilter,
): Promise<ConversationList> => {
  const result = await executeGql(venueListConversations, { filter });
  return processGqlResult<ConversationList>('venueListConversations', result);
};

export const venueListConversations = /* GraphQL */ `
  query VenueListConversations($filter: VenueListConversationsFilter!) {
    venueListConversations(filter: $filter) {
      nextPageKey
      items {
        fromUserId
        toUserId
        conversationId
        userType
        messageTime
        fromUserMessageCount
        toUserMessageCount
        firstname
        lastname
        instagramHandle
        instagramFollowers
        premium
        plan
        offerTitle
        reservationID
        isChatClosed
        images {
          thumbnail
        }
        __typename
      }
      __typename
    }
  }
`;
