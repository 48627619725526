import { useCallback } from 'react';

import { download, generateCsv, mkConfig } from 'export-to-csv';

type DefaultType = {
  [k: string]: unknown;
  [k: number]: unknown;
};

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const useExportCsv = <T extends DefaultType>() => {
  const doExportData = useCallback((data: T[]) => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }, []);

  return { doExportData };
};

export default useExportCsv;
