import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ShowFnOutput } from 'mui-modal-provider';

import { venueUpdateSubscriptionApi } from '../../../../api/stripe/venueUpdateSubscriptionApi';
import { VenueSubscriptionItem } from '../../../../graphql/API';
import { extractSubscriptionPlan } from '../../../../pages/SubscriptionPlans/common/helper';
import PlanPrice from '../../../../pages/SubscriptionPlans/components/Plans/PlanPrice';
import { SubscriptionConditionKind } from '../../../../pages/SubscriptionPlans/types';
import { StripePriceWithMetadata } from '../../../../redux/features/subscription/types';
import Button from '../../../Button/Button';
import LoadingButton from '../../../Button/LoadingButton';
import { BaseDialogProps } from '../../Base';

export type CycleChangeProps = {
  price: StripePriceWithMetadata | undefined;
  subscription: VenueSubscriptionItem | undefined;
  subscriptionKind: SubscriptionConditionKind;
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
};

const map: Partial<
  Record<SubscriptionConditionKind, { descriptionPrice: string; buttonLabel: string }>
> = {
  'cycle-change-increase': {
    buttonLabel: 'Increase period',
    descriptionPrice:
      'By increase the billing period of your plan from _CURRENT_CYCLE_ to _NEW_CYCLE_, the new monthly cost will be:',
  },
  'cycle-change-decrease': {
    buttonLabel: 'Decrease period',
    descriptionPrice:
      'By reducing the billing period from _CURRENT_CYCLE_ to _NEW_CYCLE_, you will increase the average monthly cost to:',
  },
};

const CycleChange: React.FC<CycleChangeProps> = ({
  price,
  subscription,
  subscriptionKind,
  modalRef,
}) => {
  const mutation = useMutation({
    mutationFn: venueUpdateSubscriptionApi,
    onSuccess: () => {
      window.location.href = `/plans?message_type=${subscriptionKind}`;
    },
  });

  const data = React.useMemo(() => {
    if (!(price?.metadata.ProductSku && subscription?.sku)) {
      return;
    }

    const [, , currentCycle] = extractSubscriptionPlan(subscription.sku);
    const [, , newCycle] = extractSubscriptionPlan(price?.metadata.ProductSku);

    const mapped = map[subscriptionKind];

    return {
      ...mapped,
      expireDate: dayjs(subscription.expirationDate).format('DD/MM/YYYY'),
      descriptionPrice: mapped?.descriptionPrice
        .replace('_CURRENT_CYCLE_', currentCycle)
        .replace('_NEW_CYCLE_', newCycle),
    };
  }, [subscriptionKind, subscription, price?.metadata.ProductSku]);

  return (
    <Box>
      <Typography>{data?.descriptionPrice}</Typography>

      <Box sx={{ marginY: 3, p: 2, border: '1px solid #F5F5F5', borderRadius: 1 }}>
        <PlanPrice price={price} highlightPrice='#37BB90' />
      </Box>

      <Stack spacing={2} direction='row' sx={{ mt: 6, width: '100%' }}>
        <Button
          size='large'
          variant='outlined'
          sx={{ px: 10 }}
          onClick={() => modalRef.current?.destroy()}
        >
          Cancel
        </Button>
        <LoadingButton
          size='large'
          variant='contained'
          loading={mutation.isPending}
          fullWidth
          onClick={() => {
            mutation.mutate({
              newPriceId: price?.id,
              subscriptionId: subscription?.subscriptionId,
            });
          }}
        >
          {data?.buttonLabel}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CycleChange;
