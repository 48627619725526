import * as React from 'react';

import { Box, CircularProgress, Container, Typography } from '@mui/material';

import PageTitle from '../../components/PageTitle/PageTitle';
import ToggleButtonContainer from '../../components/ToggleButton/ToggleButtonContainer';
import { useAppSelector } from '../../redux/hooks';
import { getCycleOptions } from './common/helper';
import AlertMessage from './components/AlertMessage/AlertMessage';
import Plans from './components/Plans/Plans';
import SubscriptionDetail from './components/SubscriptionDetail/SubscriptionDetail';
import { useSubscription } from './context/Subscription.context';
import SubscriptionPrivider from './context/Subscription.provider';
import { BillingCycle } from './types';

const PlanWithDiscount: React.FC<{ label: string; discount?: number }> = ({ label, discount }) => (
  <Box display='flex' alignItems='baseline'>
    {label}
    {discount && (
      <Typography variant='caption' sx={{ marginLeft: 1, color: 'red', fontWeight: 500 }}>
        -{discount}% off
      </Typography>
    )}
  </Box>
);

const SubscriptionPlans: React.FC = () => {
  const { isLoading, pricing, subscription } = useAppSelector((state) => state.subscription.value);
  const { type } = useAppSelector((state) => state.venue.value) || {};

  const { state, dispatch } = useSubscription();

  const cycleOptions = React.useMemo(() => {
    if (!pricing) {
      return [];
    }

    const options = getCycleOptions(state.plan, pricing);

    return options.map((x) => ({
      label: <PlanWithDiscount label={x.label} discount={x.discount} />,
      value: x.value,
    }));
  }, [state.plan, pricing]);

  const toggleChangeHandler = React.useCallback(
    (v: string) => {
      dispatch({ type: 'update-state', payload: { billingCycle: v as BillingCycle } });
    },
    [dispatch],
  );

  return (
    <Box
      sx={{
        flex: 1,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FEFEFE',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth='lg'>
          <PageTitle
            titleComponent={
              <Typography fontSize={'28px'} fontWeight={500}>
                Plans
              </Typography>
            }
            rightComponent={
              <>
                {type !== 'brand' && (
                  <ToggleButtonContainer
                    borderRadius={4}
                    buttonColour='black'
                    buttons={cycleOptions}
                    value={state.billingCycle}
                    onChange={toggleChangeHandler}
                  />
                )}
              </>
            }
            sx={{
              fontSize: '32px',
            }}
          />
          <AlertMessage />
          <Box sx={{ marginTop: 6 }}>
            <Plans />
          </Box>
          {subscription && (
            <Box sx={{ marginTop: 4 }}>
              <PageTitle title='Manage your plan' />
              <Box marginTop={2}>
                <SubscriptionDetail subscription={subscription} pricing={pricing ?? []} />
              </Box>
            </Box>
          )}
        </Container>
      )}
    </Box>
  );
};

const SubscriptionPlansContainer: React.FC = () => (
  <SubscriptionPrivider>
    <SubscriptionPlans />
  </SubscriptionPrivider>
);

export default SubscriptionPlansContainer;
