import * as React from 'react';

import { Box, LinearProgress, Typography } from '@mui/material';

type StepProps = {
  active?: boolean;
  title: string;
};

const Step: React.FC<StepProps> = ({ title, active }) => {
  const color = React.useMemo(() => (active ? 'primary' : 'secondary'), [active]);

  return (
    <Box flex={1}>
      <LinearProgress
        variant='determinate'
        value={100}
        color={color}
        sx={{
          borderRadius: 3,
          height: 6,
        }}
      />
      <Typography variant='caption' fontWeight='bold' color={`text.${color}`}>
        {title}
      </Typography>
    </Box>
  );
};

export default Step;
