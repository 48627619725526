import React from 'react';

import { Box } from '@mui/material';

import { User } from '../../../graphql/API';
import { FavouriteItem } from './FavouriteItem';

type Props = {
  users: User[];
  onInviteHandle: (user: User) => void;
};

export const ListFavourites: React.FC<Props> = ({ users, onInviteHandle }) => {
  const handleInvite = React.useCallback((user: User) => {
    onInviteHandle(user);
  }, [onInviteHandle]);

  return (
    <Box flex={1}>
      {users.map((user) => (
        <FavouriteItem key={user.id} user={user} onInvite={() => handleInvite(user)} />
      ))}
    </Box>
  );
};
