import * as React from 'react';

import { Box } from '@mui/material';

import { useModal } from 'mui-modal-provider';

import AccessRequestDescDialog from '../../components/Dialogs/AccessRequestDesc/AccessRequestDescDialog';
import ListAccessRequestDialog from '../../components/Dialogs/ListAccessRequest/ListAccessRequestDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import AccessRequestTable from '../../components/Tables/AccessRequest/AccessRequest';
import Icons from '../../themes/icons';

export type AccessRequestPageView = 'grid' | 'list';

const ViewItem: React.FC<{
  title: string;
  icon: React.ReactNode;
  view: AccessRequestPageView;
  activeView: AccessRequestPageView;
  handleView: (val: AccessRequestPageView) => void;
}> = ({ title, icon, view, activeView, handleView }) => (
  <Box
    display={'flex'}
    alignItems={'center'}
    gap={'8px'}
    color={view === activeView ? '#765BC6' : '#757575'}
    sx={{ cursor: 'pointer' }}
    onClick={() => {
      handleView('list');
    }}
  >
    {icon}
    <span>{title}</span>
  </Box>
);

const AccessRequest: React.FC = () => {
  const { showModal } = useModal();

  const [view, setView] = React.useState<AccessRequestPageView>('grid');

  const handleClickDesc = React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();

      showModal(AccessRequestDescDialog, { width: '600px', height: 'unset' });
    },
    [showModal],
  );

  const handleClickList = React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      e.preventDefault();

      showModal(ListAccessRequestDialog, {
        titleComponent: <Box textAlign='center'>Access list</Box>,
        width: '900px',
      });
    },
    [showModal],
  );

  const handleView = (val: AccessRequestPageView) => {
    setView(val);
  };

  return (
    <Box sx={{ backgroundColor: 'white', flex: 1, p: 4 }}>
      <PageTitle
        title='Access Request'
        sideComponent={
          <Box
            component='a'
            href='#'
            onClick={handleClickDesc}
            sx={{ fontSize: '0.8rem', color: 'black' }}
          >
            What is access request?
          </Box>
        }
        rightComponent={
          <Box display={'flex'} alignItems={'center'} gap={'24px'}>
            <ViewItem
              title='Grid'
              view={'grid'}
              activeView={view}
              handleView={() => {
                handleView('grid');
              }}
              icon={<Icons.GridIcon />}
            />
            <ViewItem
              title='List'
              view={'list'}
              activeView={view}
              handleView={() => {
                handleView('list');
              }}
              icon={<Icons.ListIcon />}
            />
          </Box>
        }
      />

      <Box mt={2}>
        <AccessRequestTable view={view} height='calc(100vh - 266px)' handleClickList={handleClickList} />
      </Box>
    </Box>
  );
};

export default AccessRequest;
