import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import Lottie from 'lottie-react';

import plan from '../../animations/plan.json';
import LoadingButton from '../../components/Button/LoadingButton';
import { useFrom } from '../../hooks/useFrom';
import './styles.css';

const EmailSend = () => {
  const lastUsedEmail = localStorage.getItem('lastUsedEmail');
  const navigate = useNavigate();
  const from = useFrom();
  const { authStatus } = useAuthenticator();

  useEffect(() => {
    if (authStatus === 'authenticated') navigate(from, { replace: true });
  }, [authStatus, from, navigate]);

  return (
    <div className='sent-email-container'>
      <div className='back-container'></div>
      <div className='plan-container'>
        <Lottie animationData={plan} loop={true} />
      </div>
      <div className='form-container'>
        <p className='heading'>We have sent you an email</p>
        <p className='detail'>We emailed you instructions on how to reset you password</p>
        <div className='auth-footer'>
          <LoadingButton
            color='primary'
            fullWidth
            variant='contained'
            size='large'
            onClick={() => {
              window.open(`mailto:${lastUsedEmail}`, '_blank');
            }}
          >
            Go to Email
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default EmailSend;
