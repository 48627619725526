import * as React from 'react';

import Box from '@mui/material/Box';
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MuiRadioGroup, { RadioGroupProps, useRadioGroup } from '@mui/material/RadioGroup';

import styled from '@emotion/styled';
import { useField } from 'formik';

import SvgIcon from '../../../../../components/Icons';
import { Requirement } from '../../schema';
import RadioContent from './RadioContent';

const FormControlLabel: React.FC<FormControlLabelProps> = (props) => {
  const radioGroup = useRadioGroup();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (radioGroup) {
      setChecked(radioGroup.value === props.value);
    }
  }, [radioGroup, props.value]);

  return (
    <MuiFormControlLabel
      checked={checked}
      {...props}
      sx={{ margin: 0, width: '100%', '.MuiButtonBase-root': { p: 0 } }}
    />
  );
};

const OfferRequirement: React.FC<RadioGroupProps> = ({ id }) => {
  const [field, , helper] = useField<Requirement>('requirements');

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const v = e.currentTarget.value as Requirement;
      helper.setValue(v);
    },
    [helper],
  );

  return (
    <RadioWraper>
      <MuiRadioGroup id={id} value={field.value} onChange={handleChange}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControlLabel
              value='postOnInstagramStories'
              control={
                <RadioContent
                  label='Post on Story'
                  icon={<SvgIcon name='StoryBlack' />}
                  checkedIcon={<SvgIcon name='Story' />}
                />
              }
              label=''
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value='postOnInstagramStories3x'
              control={
                <RadioContent
                  label='Post 3 Stories'
                  icon={<SvgIcon name='StoryBlack' />}
                  checkedIcon={<SvgIcon name='Story' />}
                />
              }
              label=''
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value='postOnInstagramFeed'
              control={
                <RadioContent
                  label='Post on Feed'
                  icon={<SvgIcon name='FeedBlack' />}
                  checkedIcon={<SvgIcon name='Feed' />}
                />
              }
              label=''
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value='postOnInstagramReels'
              control={
                <RadioContent
                  label='Post on Reel'
                  icon={<SvgIcon name='ReelBlack' />}
                  checkedIcon={<SvgIcon name='Reel' />}
                />
              }
              label=''
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ border: '1px solid red' }}
              value='noRequirement'
              control={
                <RadioContent
                  label={<Box>No Requirements</Box>}
                  icon={<SvgIcon name='NoRequirement' />}
                />
              }
              label=''
            />
          </Grid>
        </Grid>
      </MuiRadioGroup>
    </RadioWraper>
  );
};

export default OfferRequirement;

const RadioWraper = styled.div`
  .css-1sa2158 {
    border: 1px solid #e6e6e6;
  }
`;
