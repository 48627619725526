import React from 'react';

import { Box, Typography } from '@mui/material';

export const NoPendingAccessRequest = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        component='img'
        src='/png/access-empty@2x.png'
        sx={{ marginTop: 10, marginBottom: 2, width: '100px' }}
      />
      <Typography fontWeight='medium'>No access requests yet</Typography>
      <Typography variant='caption'>
        When users request access to your offers, you’ll see them listed here
      </Typography>
    </Box>
  );
};
