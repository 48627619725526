import * as React from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Modal, { ModalProps } from '@mui/material/Modal';

import Content, { ContentProps } from './components/Content';

const ModalContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export type ManageContentDialogProps = ContentProps & Omit<ModalProps, 'children'>;

const ManageContentDialog: React.FC<ManageContentDialogProps> = ({
  open,
  onClose,
  ...restProps
}) => {
  return (
    <Modal open={open} onClose={onClose} disableAutoFocus>
      <ModalContainer>
        <Content onClose={onClose} {...restProps} />
      </ModalContainer>
    </Modal>
  );
};

export default ManageContentDialog;
