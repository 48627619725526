import React from 'react';

import { Box, Typography } from '@mui/material';

export const MemberCategoryTooltipContent = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'} padding={'12px 14px'}>
      <Box>
        <Typography color={'#37BB90'} fontSize={'13px'}>
          Lifestyle
        </Typography>
        <Typography color={'#808080'} fontSize={'13px'}>
          These members may be new to the world of fashion but they are eager to promote your
          business.
        </Typography>
      </Box>
      <Box>
        <Typography color={'#955CCC'} fontSize={'13px'}>
          Beautypass Select
        </Typography>
        <Typography color={'#808080'} fontSize={'13px'}>
          These members are represented by top model agencies or have a strong social presence.
        </Typography>
      </Box>
    </Box>
  );
};
