import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { venueGetReservationApi } from '../../api/reservations/venueGetReservationApi';
import { Reservation } from '../../graphql/API';

export const useQueryReservationById = (id: string) => {
  return useQuery<Reservation>({
    queryKey: ['reservation-detail-by-id', id],
    queryFn: async ({ queryKey }) => {
      const [, reservationID] = queryKey as [string, string];

      return venueGetReservationApi(reservationID);
    },
    placeholderData: keepPreviousData,
    enabled: !!id,
  });
};
