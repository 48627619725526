import React from 'react';

import { Box, Skeleton } from '@mui/material';

import styled from '@emotion/styled';

import { CurrencySign } from '../../../components/CurrencySign/CurrencySign';
import Icons from '../../../themes/icons';
import { formatNumberWithCommas } from '../../../utils/formatter';

type Props = {
  isLoading?: boolean;
  balance?: string;
  currency?: string;
  isInActive?: boolean;
  onAddFunds?: () => void;
  onLearnMoreAbout?: () => void;
};

export const BalanceSheetCard: React.FC<Props> = ({
  isLoading = false,
  balance,
  isInActive = false,
  onAddFunds,
  onLearnMoreAbout,
}) => {
  return (
    <Container>
      <Box>
        <IconContainer>
          <Icons.BalanceIcon style={{ width: '35px', height: '35px' }} />
        </IconContainer>
      </Box>
      <Box>
        {isLoading ? (
          <Skeleton width={'124px'} height={'36px'} />
        ) : (
          <BalanceText textColor={isInActive ? '#FF5454' : ''}>
            <CurrencySign currency={'usd'} /> {formatNumberWithCommas(+(balance || 0))}
          </BalanceText>
        )}
        <BalanceDescription>Use balance to collaborate with models</BalanceDescription>
        <Box sx={{ marginTop: '36px', display: 'flex', alignItems: 'center', gap: '30px' }}>
          <AddFundButton onClick={onAddFunds} disabled={isLoading}>
            + Add Funds
          </AddFundButton>
          <LearnMoreAbout onClick={onLearnMoreAbout}>Learn more about Collabs</LearnMoreAbout>
        </Box>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 9px;
  background-color: #fff;
  padding: 34px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  border: 1px solid #f1f1f1;

  display: flex;
  align-items: flex-start;

  gap: 30px;
`;

const BalanceText = styled.span<{ textColor?: string }>`
  display: block;
  color: ${(props) => props.textColor || '#111'};
  font-size: 24px;
  font-weight: 600;
`;

const BalanceDescription = styled.p`
  color: rgba(17, 17, 17, 0.5);
  font-size: 18px;
  font-weight: 400;

  margin: 8px 0 0 0;
  padding: 0;
`;

const AddFundButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #000;

  padding: 15px 36px;

  outline: none;
  border: none;

  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 500;

  cursor: pointer;

  &:disabled {
    background: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;

const LearnMoreAbout = styled.p`
  color: #111;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  border-radius: 9px;
  background-color: #f8f8f8;
  padding: 14px 22px;
`;
