import styled from '@emotion/styled';

export const FigureHeading = styled.h1<{ color?: string }>`
  font-size: 28px;
  font-weight: 600;
  color: ${(props) => (props.color ? props.color : '#111')};
  margin: 0;
`;

export const FigureDescription = styled.p<{
  textAlign?: string;
  fontWeight?: number;
  color?: string;
  maxWidth?: string;
}>`
  font-size: 15px;
  color: #111;
  margin: 0;
  font-weight: 400;
  text-align: ${(props) => props.textAlign};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  color: ${(props) => props.color};
  max-width: ${(props) => props.maxWidth};
`;
