import { useMutation } from '@tanstack/react-query';

import { venueUpdateReservationApi } from '../../../api/reservations/venueUpdateReservation';

type Props = {
  onSuccess?: () => void;
};

export const useUpdateReservationMutation = ({ onSuccess }: Props) => {
  const cancelReservationMutation = useMutation({
    mutationFn: venueUpdateReservationApi,
    onSuccess,
  });

  return cancelReservationMutation;
};
