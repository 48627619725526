import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  listHeight?: number;
};

export const ListRatedUserEmptyPlaceholder: React.FC<Props> = ({ listHeight }) => {
  return (
    <Box
      sx={{
        height: listHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box display={'flex'} justifyContent={'center'} alignItems={'start'} gap={'42px'}>
        <Box>
          <img src='/png/rating.png' alt='top rating' />
        </Box>
        <Box>
          <Typography fontSize={'18px'} fontWeight={'600'}>
            Discover your top creators
          </Typography>
          <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>
            Rate each reservation. Your top rated creators will be listed here.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
