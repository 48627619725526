import { useQuery } from '@tanstack/react-query';

import { venueListAccessRequestsCountApi } from '../../../api/accessRequest/venueListAccessRequestsCountApi';
import { VenueListAccessRequetsFilter } from '../../../graphql/API';
import { useAppSelector } from '../../../redux/hooks';

export const useVenueListAccessRequestsCountQuery = (
  filter: Partial<VenueListAccessRequetsFilter> | undefined,
  enabled = true,
) => {
  const venueState = useAppSelector((state) => state.venue.value);

  const { data, isFetching, isError, isLoading } = useQuery({
    queryKey: ['venueListAccessRequestsCountAPI', venueState?.id, { ...filter }],
    queryFn: async ({ queryKey }) => {
      const [, venueID, filters] = queryKey as [string, number, VenueListAccessRequetsFilter];

      return venueListAccessRequestsCountApi({ ...filters, venueID });
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(venueState?.id) && enabled,
  });
  return { data, isFetching, isError, isLoading };
};
