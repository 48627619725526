import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import Button from '../Button/Button';
import { PlusIcon } from '../Dialogs/CollabsFilters/ToolTip';

export const UpgradePlanOverlay = () => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.90) 71.9%, #FFF 100%)',
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        minHeight: '232px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid #DC4397',
          backgroundColor: '#FFF',
          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
          minWidth: '800px',
          display: 'flex',
          alignItems: 'center',
          padding: '24px 28px',
          justifyContent: 'space-between',
          gap: '70px',
        }}
      >
        <Typography
          component={'span'}
          sx={{ color: '#222', fontSize: '18px', display: 'inline-block', fontWeight: 500 }}
        >
          Unlock access to 30,000+ models for exclusive collaborations
        </Typography>
        <Button
          component={Link}
          variant='contained'
          color='primary'
          sx={{ fontSize: '18px', fontWeight: 500, px: 2, py: 1.3 }}
          startIcon={<PlusIcon sx={{ height: '20px', width: '20px' }} />}
          to='/plans'
        >
          Upgrade plan
        </Button>
      </Box>
    </Box>
  );
};
