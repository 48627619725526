import * as React from 'react';

import { Box, styled } from '@mui/material';

const ContainerRoot = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '64px',
  width: '64px',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)',
});

const PlayIcon: React.FC = () => (
  <ContainerRoot>
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: '16px 0px 16px 26px',
        borderColor: 'transparent transparent transparent white',
        marginLeft: '8px',
      }}
    />
  </ContainerRoot>
);

export default PlayIcon;
