import * as React from 'react';

import InstagramIcon from '@mui/icons-material/Instagram';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { User } from '../../../graphql/API';
import { formatNumber } from '../../../utils/formatter';
import LinkProfile from './LinkProfile';
import ProfileCheckBox from './ProfileCheckBox';
import { OnChange } from './types';

type ProfileCardProps = {
  data: User;
  onChange: OnChange;
  fullProfile?: boolean;
  checked?: boolean;
};

const ProfileCard: React.FC<ProfileCardProps> = ({ data, fullProfile, onChange, checked }) => {
  return (
    <Box display='flex' flexDirection='column'>
      <ProfileCheckBox
        placeholderSrc={String(data.picture?.thumbnail)}
        avatarSrc={String(data.picture?.medium)}
        instagramVerified={Boolean(data.agencyProfileUrl)}
        lifeStyle={Boolean(data.lifestyle)}
        value={data.id}
        onChange={onChange}
        checked={checked}
      />
      <Typography
        component='div'
        variant='body2'
        fontWeight='bold'
        sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}
      >
        <LinkProfile
          url={`/users/${data.id}`}
          label={`${data.firstname} ${data.lastname}`}
          hoverTextDecoration='underline'
        />

        <Box
          component='div'
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            fontSize: '15px',
            fontWeight: 'normal',
            marginTop: '5px',
          }}
        >
          <InstagramIcon fontSize='medium' sx={{ mr: 1 }} />
          {fullProfile && (
            <Box sx={{ mr: 1 }}>
              <LinkProfile
                url={`https:/instagram.com/${data.instagramHandle}`}
                label={`@${data.instagramHandle}`}
                target='_blank'
                fontSize='15px'
              />
            </Box>
          )}
          <Box component='span'>{formatNumber(data.instagramFollowers ?? 0)} Followers</Box>
          <Typography
            component='div'
            sx={{ display: 'flex', alignItems: 'center', mt: 1, fontSize: '15px' }}
          >
            <StarIcon sx={{ fontSize: '1rem', mr: 1 }} />
            <Box component='span' mr={0.5} fontWeight='bold'>
              {data.rating?.toFixed(1)}
            </Box>
            Average rating ({data.totalRatings} ratings)
          </Typography>
        </Box>
      </Typography>
    </Box>
  );
};

export default ProfileCard;
