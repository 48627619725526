import { User, VenueListUsersFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListUsersQuery = /* GraphQL */ `
  query venueListUsers($filter: VenueListUsersFilter!) {
    venueListUsers(filter: $filter) {
      items {
        id
        email
        firstname
        lastname
        agency
        agencyProfileUrl
        birthdate
        picture {
          large
          medium
          small
          thumbnail
        }
        instagramHandle
        instagramFollowers
        marketID
        marketName
        createdAt
        updatedAt
        approvalDate
        status
        lastSeen
        totalCheckins
        totalCheckinLastMonth
        totalOfferValueRedeemed
        instagramVerificationRequested
        instagramDuplicates
        birthdateDuplicates
        tags
        totalPortfolioContents
        city
        noshowCredits
        premium
        points
        instagramVerified
        inviteCredits
        lifestyle
        totalRatings
        rating
      }
    }
  }
`;

export const venueListUsersApi = async (filter: VenueListUsersFilter): Promise<User[]> => {
  const result = await executeGql<User[]>(venueListUsersQuery, { filter });
  return processGqlResult<User[]>('venueListUsers.items', result);
};
