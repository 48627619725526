import React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

type Props = {
  icon: React.ReactNode;
  title: string;
  description: string;
  showLine?: boolean;
};

export const CollabItem: React.FC<Props> = ({ icon, title, description, showLine = false }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '35px' }}>
      <IconBox>
        {icon}
        {showLine && <Line />}
      </IconBox>
      <Box>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Box>
    </Box>
  );
};

const IconBox = styled.div`
  width: 86px;
  height: 86px;
  border: 3px solid #66c48e;
  border-radius: 50%;

  padding: 29px 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const Title = styled.h3`
  color: #111;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  padding: 0;
  margin: 0 0 8px 0;
`;

const Description = styled.p`
  color: #848484;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  padding: 0;
  margin: 0;
`;

const Line = styled.div`
  height: 68px;
  border: 2px solid #66c48e;

  position: absolute;
  left: 48%;
  bottom: -87%;
  border-radius: 2px;
`;
