import React from 'react';

import styled from '@emotion/styled';

const INVITE_STATUS: Record<string, Record<string, string>> = {
  accepted: { colorCode: '#37BB90', text: 'Accepted' },
  declined: { colorCode: '#DC5564', text: 'Declined' },
  expired: { colorCode: '#DC5564', text: 'Expired' },
  pending: { colorCode: '#707070', text: 'Pending' },
  available: { colorCode: '#707070', text: 'Pending' },
};

type Props = {
  status: string;
};

export const InviteStatus: React.FC<Props> = ({ status }) => {
  return (
    <StatusLabel statusColor={INVITE_STATUS[status].colorCode}>
      {INVITE_STATUS[status].text}
    </StatusLabel>
  );
};

const StatusLabel = styled.span<{ statusColor?: string }>`
  display: inline-block;
  color: ${(props) => props.statusColor || '#37bb90'};
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
`;
