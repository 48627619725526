import * as React from 'react';

import { UserPortfolioItem } from '../../../graphql/API';
import { useQueryUserPortfolio } from '../../../hooks/reactQuery/useQueryUser';
import MediaContainer, { MediaItem } from './MediaContainer';

type PanelUserPortfolioProps = {
  userId?: string;
};

const toImageItems = (data: UserPortfolioItem[]): MediaItem[] =>
  data.map((x) => ({
    id: String(x.id),
    label: String(x.portfolio),
    src: String(x.asset?.medium),
    video: x.asset?.video ?? undefined,
    placeholderSrc: String(x.asset?.thumbnail),
  }));

const PanelUserPortfolio: React.FC<PanelUserPortfolioProps> = ({ userId }) => {
  const { data, isLoading } = useQueryUserPortfolio(userId);

  const items = React.useMemo(() => toImageItems(data ?? []), [data]);

  return <MediaContainer isLoading={isLoading} items={items} contentType='portfolio' />;
};

export default PanelUserPortfolio;
