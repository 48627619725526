import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import TimePicker from '../../../TimePicker/TimePicker';

type TimeRangePickerProps = {
  startTime?: Dayjs;
  endTime?: Dayjs;
  onChange: (props: { startTime: Dayjs; endTime: Dayjs }) => void;
  shouldDisaledThePreviousTime?: boolean;
  isStartTimeMustLeastAfterOneHour?: boolean;
  isEndTimeMustLeastAfterTwoHour?: boolean;
  isEndTimeValid?: boolean;
};

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  startTime,
  endTime,
  onChange,
  shouldDisaledThePreviousTime = false,
  isStartTimeMustLeastAfterOneHour = false,
  isEndTimeMustLeastAfterTwoHour = false,
  isEndTimeValid = false,
}) => {
  return (
    <Stack direction='row' spacing={4}>
      <Box>
        <Typography fontWeight='medium' marginBottom={1}>
          Start time
        </Typography>
        <TimePicker
          name='startTime'
          defaultValue={startTime || dayjs()}
          onSelectChange={(newValue) => {
            onChange({ startTime: newValue, endTime: endTime ?? dayjs() });
          }}
          shouldDisaledThePreviousTime={shouldDisaledThePreviousTime}
          error={isStartTimeMustLeastAfterOneHour}
          helperText={
            isStartTimeMustLeastAfterOneHour
              ? 'Start time must be at least one hour later from now'
              : ''
          }
        />
      </Box>
      <Box>
        <Typography fontWeight='medium' marginBottom={1}>
          End time
        </Typography>
        <TimePicker
          name='endTime'
          defaultValue={endTime || dayjs()}
          onSelectChange={(newValue) => {
            onChange({ startTime: startTime ?? dayjs(), endTime: newValue });
          }}
          shouldDisaledThePreviousTime={shouldDisaledThePreviousTime}
          error={isEndTimeMustLeastAfterTwoHour || isEndTimeValid}
          helperText={
            isEndTimeMustLeastAfterTwoHour
              ? 'End time must be at least 2 hours later from now'
              : isEndTimeValid
                ? 'End time must be later than start time'
                : ''
          }
        />
      </Box>
    </Stack>
  );
};

export default TimeRangePicker;
