import * as React from 'react';

import { ShowFnOutput } from 'mui-modal-provider';

import BaseDialog, { BaseDialogProps } from '../Base';
import ProfileUpdate from './components/ProfileUpdate';

export type ProfileUpdateDialogProps = {
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
} & BaseDialogProps;

const ProfileUpdateDialog: React.FC<ProfileUpdateDialogProps> = ({ modalRef, ...restProps }) => {
  return (
    <BaseDialog {...restProps} height='unset'>
      <ProfileUpdate onSaveComplete={() => modalRef.current?.destroy()} />
    </BaseDialog>
  );
};

export default ProfileUpdateDialog;
