import { useMutation } from '@tanstack/react-query';

import { venueRateUserApi } from '../../../api/reservations/venueRateUserApi';
import { VenueUserRateInput } from '../../../graphql/API';

type Props = {
  onMutate?: () => void;
  onSuccess?: () => void;
  onSettled?: () => void;
};

export const useVenueRateUserMutation = ({ onMutate, onSuccess, onSettled }: Props) => {
  const useVenueRateUser = useMutation({
    mutationFn: async (input: VenueUserRateInput) => {
      const venueRate = await venueRateUserApi(input);
      return venueRate;
    },
    onMutate: () => {
      if (onMutate) {
        onMutate();
      }
    },
    onSuccess: (pi) => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onSettled: () => {
      if (onSettled) {
        onSettled();
      }
    },
  });

  return useVenueRateUser;
};
