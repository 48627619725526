import {
  BlockedUser,
  VennueAccessRequestItem,
  VenueActionAccessRequest,
  VenueBlockUserInput,
} from '../../../../graphql/API';
import { isVenueActionAccessInput } from '../../../List/ListAccess/types';
import { TabOption } from './types';

const extractUser = (
  data: VennueAccessRequestItem[] | BlockedUser[],
  input: VenueActionAccessRequest | VenueBlockUserInput,
) => {
  if (isVenueActionAccessInput(input)) {
    const d = data as VennueAccessRequestItem[];
    const found = d.find((x) => x.offerID === input.offerID && x.userID === input.userID);

    return {
      name: `${found?.user?.firstname} ${found?.user?.lastname}`,
      gender: found?.user?.gender === 'female' ? 'her' : 'his',
    };
  }

  const d = data as BlockedUser[];
  const found = d.find((x) => x.id === String(input.userID));

  return {
    name: `${found?.firstname} ${found?.lastname}`,
  };
};

export const getSuccessMessage = (
  option: TabOption,
  data: VennueAccessRequestItem[] | BlockedUser[],
  input: VenueActionAccessRequest | VenueBlockUserInput,
) => {
  const user = extractUser(data, input);

  switch (option) {
    case TabOption.accepted: {
      return (
        <>
          You have removed <b>{user.name}</b>'s access. You can find {user.gender} in your Rejected
          List
        </>
      );
    }
    case TabOption.rejected: {
      return (
        <>
          You have given access to <b>{user.name}</b>'s access. You can find {user.gender} in your
          Accepted List
        </>
      );
    }
    case TabOption.blocked: {
      return (
        <>
          You have unblocked <b>{user.name}</b>
        </>
      );
    }
    default:
      break;
  }

  return undefined;
};
