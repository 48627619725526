import * as React from 'react';

import Box from '@mui/material/Box';
import MuiRadio, { RadioProps } from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

type RadioContentProps = {
  label: React.ReactNode;
  icon: React.ReactNode;
} & RadioProps;

const RadioContent: React.FC<RadioContentProps> = ({ label, icon, checkedIcon, ...restProps }) => {
  const { checked } = restProps;

  const style = React.useMemo(
    () => (checked ? { borderWidth: '2px', borderColor: '#111111' } : undefined),
    [checked],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        border: '1px solid #B2B2B2',
        borderRadius: 1,
        p: 2.7,
        ...style,
      }}
    >
      <MuiRadio icon={<></>} checkedIcon={<></>} {...restProps} />
      <Typography
        component='div'
        variant='subtitle1'
        fontWeight={checked ? 'bold' : undefined}
        sx={{ display: 'flex' }}
      >
        <Box display='flex' alignItems='center' sx={{ mr: 2 }}>
          {checked ? checkedIcon ?? icon : icon}
        </Box>
        {label}
      </Typography>
    </Box>
  );
};

export default RadioContent;
