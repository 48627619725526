import React from 'react';

import { Box, Stack } from '@mui/material';

type Props = {
  count: number;
};

export const TitleComponent: React.FC<Props> = ({ count }) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent={'center'} spacing={1}>
      <Box component='span'>Favorites ({count})</Box>
    </Stack>
  );
};
