import React from 'react';

import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';

import { ChatMessageItem } from '../../graphql/API';
import { onChatClosed } from '../../graphql/subscriptions';
import { processGqlResult } from '../../utils/gql-utils';

type Props = {
  userId?: string;
};

export const useAdminCloseChatListener = ({ userId }: Props) => {
  const [closeMessage, setCloseMessage] = React.useState<Nullable<ChatMessageItem>>(null);

  React.useEffect(() => {
    const createSub = API.graphql<GraphQLSubscription<ChatMessageItem>>({
      query: onChatClosed,
      variables: { userType: 'venue', toUserId: userId },
    }).subscribe({
      next: ({ value }) => {
        const _message = processGqlResult<ChatMessageItem>(
          'onChatClosed',
          value,
        );
        setCloseMessage(_message as ChatMessageItem);
      },
      error: (error) => {
        console.warn(error);
      },
    });

    return () => {
      createSub.unsubscribe();
    };
  }, [setCloseMessage, userId]);

  return {
    closeMessage,
  };
};
