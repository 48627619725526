import * as Yup from 'yup';

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      'Password must be 8+ characters containing uppercase, lower case, and number',
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required'),
});
