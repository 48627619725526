import * as React from 'react';

import MuiPaper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { Theme, styled } from '@mui/material/styles';

const backgroundColor = '#F5F5F5';

const Paper = styled(MuiPaper)({
  display: 'inline-flex',
  flexWrap: 'wrap',
  backgroundColor,
});

const calcBorderRadius = (theme: Theme, borderRadius: number | undefined) =>
  theme.shape.borderRadius * (borderRadius ?? 1);

const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (propName) => !['borderRadius', 'buttonColour'].includes(propName as string),
})<{ borderRadius?: number; buttonColour?: string }>(({ theme, borderRadius, buttonColour }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    backgroundColor,
    color: 'black',
    margin: theme.spacing(0.5),
    border: 0,
    '&.MuiToggleButton-root': {
      paddingInline: theme.spacing(4),
      paddingBlock: theme.spacing(0.5),
    },
    '&.Mui-selected': {
      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.87)' },
      backgroundColor: 'white',
      ...(buttonColour
        ? {
            color: 'white',
            backgroundColor: 'black',
            '&:hover': { backgroundColor: 'black' },
          }
        : undefined),
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: calcBorderRadius(theme, borderRadius),
    },
    '&:first-of-type': {
      borderRadius: calcBorderRadius(theme, borderRadius),
    },
  },
}));

type ToggleOnChange = Exclude<ToggleButtonGroupProps['onChange'], undefined>;

export type ToggleButtonItem<T = string> = {
  label: React.ReactNode;
  value: T;
};

type ToggleButtonProps<T = string> = {
  buttons: ToggleButtonItem<T>[];
  value: string;
  onChange: (value: T) => void;
  fullWidth?: boolean;
  borderRadius?: number;
  buttonColour?: string;
};

const ToggleButtonContainer: React.FC<ToggleButtonProps> = ({
  fullWidth,
  value,
  onChange,
  buttons,
  borderRadius,
  buttonColour,
}) => {
  const handleChange = React.useCallback<ToggleOnChange>(
    (_, newValue) => {
      if (newValue) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  return (
    <Paper
      sx={(theme) => ({
        borderRadius: borderRadius ? calcBorderRadius(theme, borderRadius) : undefined,
        boxShadow: 'none',
        background: '#F5F5F5',
        ...(fullWidth ? { width: '100%' } : undefined),
      })}
    >
      <StyledToggleButtonGroup
        borderRadius={borderRadius}
        buttonColour={buttonColour}
        size='small'
        value={value}
        exclusive
        fullWidth={fullWidth}
        onChange={handleChange}
        aria-label='credits toggle'
      >
        {buttons.map((x, i) => (
          <ToggleButton
            key={i}
            value={x.value}
            fullWidth={fullWidth}
            aria-label={`${x.label} aligned`}
            sx={{ backgroundColor: '#111111', fontSize: '15px', fontWeight: '600' }}
          >
            {x.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Paper>
  );
};

export default ToggleButtonContainer;
