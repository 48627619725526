import React from 'react';

import { Box, Typography } from '@mui/material';

import Icons from '../../themes/icons';

type Props = {
  title?: string;
};

export const NoChatFound: React.FC<Props> = ({ title = 'There are no active chats' }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      gap={'48px'}
      my={5}
    >
      <Icons.NoChatIcon />
      <Box textAlign={'center'} maxWidth={'75%'}>
        <Typography
          sx={{
            color: '#848484',
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            mt: '8px',
            color: '#848484',
            fontSize: '15px',
            fontWeight: 400,
          }}
        >
          You can chat with users you are collaborating with
        </Typography>
      </Box>
    </Box>
  );
};
