import * as React from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import { Box } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useField } from 'formik';

type Props = TextFieldProps & {
  handleShowPassword?: () => void;
};

const FormikTextField: React.FC<Props> = ({
  name,
  id,
  className = '',
  handleShowPassword,
  ...props
}) => {
  const [field, meta] = useField(name || '');

  return (
    <Box position='relative'>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        id={id}
        name={field.name}
        value={field.value}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched ? meta.error : ''}
        onChange={field.onChange}
        className={className}
        {...props}
      />
      {handleShowPassword && (
        <span className='auth-input-password-field-eye-icon' onClick={handleShowPassword}>
          {props.type === 'password' ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </span>
      )}
    </Box>
  );
};

export default FormikTextField;
