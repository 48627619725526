import React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListAccountTransactionsApi } from '../../../api/balance/venueListAccountTransactionsApi';
import { TransactionListFilter } from '../../../graphql/API';

export const PAGE_LIMIT = 50;

export const useVenueListAccountTransactionsQuery = (filter: TransactionListFilter) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['venueListAccountTransactions', filter],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, filters] = queryKey as [string, TransactionListFilter];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListAccountTransactionsApi({ limit: PAGE_LIMIT, offset, ...filters });
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(filter.entityID) && Boolean(filter.entityType),
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
  });
  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage, refetch };
};
