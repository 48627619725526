import { Shadows, ThemeOptions } from '@mui/material';
import shadows from '@mui/material/styles/shadows';

export const colors = {
  brandDarkGrey: '#000000de',
  brandMediumGrey: '#7e818b',
  brandGrey: '#a7a9b3',
  brandLightGrey: '#e1e4eb',
  brandBlue: '#375fda',
  brandPink: '#cb87eb',
  brandCyan: '#06b496',
  brandGreen: '#32c423',
  brandRed: '#ff3d3d',
  brandPurple: '#765BC6',
  brandOrange: '#ea9f1a',
  lightGrey1: '#f8f9fb',
  white: '#ffffff',
  black: '#000000',
  grey300: '#e0e0e0',
  grey400: '#bdbdbd',
  grey500: '#9e9e9e',
  skeleton: '#f4f7f9',
  lightGreen: '#B5EEDB',
  contrastText: '#37BB90',

};

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      colors: typeof colors;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: {
      colors: typeof colors;
    };
  }
}

const newShadows = [
  'none',
  'rgba(0, 0, 0, 0.02) 0px 1px 6px 0px',
  '0px 0px 2px #0000000D',
  '0px 0px 20px #00000014',
  'rgba(0, 0, 0, 0.1) 0px 0px 99px',
  ...shadows.slice(5),
] as Shadows;

export const themeOptions: ThemeOptions = {
  components: {
    MuiCard: {
      styleOverrides: {
        root: { boxShadow: newShadows[3] },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#000',
      // light: '#0000',
    },
    secondary: {
      main: '#F6F6F6',
      contrastText: '#848484',
    },
    background: {
      default: '#f8f9fb',
    },
    // success: {
    //   main: '#B5EEDB',
    //   contrastText: '#37BB90',
    // },
    // error: {
    //   main: '#FFE1E5',
    //   contrastText: '#DC5564',
    // },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    subtitle1: {
      fontSize: '18px',
    },
  },
  custom: { colors },
};

export type ThemeColor = keyof typeof colors;
