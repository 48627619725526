import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import { getServiceSendForgotPassword } from '../../api/forgotPassword/serviceSendForgotPasswordApi';
import Header from '../../components/Authenticator/Header';
import LoadingButton from '../../components/Button/LoadingButton';
import FormikTextField from '../../components/Formiks/TextField/TextField';
import { useFrom } from '../../hooks/useFrom';
import { forgotPasswordSchema } from './schema';
import './styles.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const from = useFrom();
  const { authStatus } = useAuthenticator();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authStatus === 'authenticated') navigate(from, { replace: true });
  }, [authStatus, from, navigate]);

  const onSubmit = async ({ email }: { email: string }) => {
    localStorage.setItem('lastUsedEmail', email);
    try {
      setIsLoading(true);
      await getServiceSendForgotPassword(email, true);
      navigate('/email-send', { replace: true });
      setIsLoading(false);
    } catch (error) {
      const _err = error as Error;
      enqueueSnackbar({ message: _err.message, variant: 'error' });
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className='auth-form'>
      <Header />
      <div className='auth-form-container'>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur }) => (
            <Form>
              <Box display='flex' flexDirection='column' gap='0.5rem'>
                <label htmlFor='email' className='auth-input-label'>
                  Email
                </label>
                <FormikTextField
                  id='email'
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  className='auth-input-field'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <div className='auth-footer'>
                <LoadingButton
                  type='submit'
                  loading={isLoading}
                  color='primary'
                  fullWidth
                  variant='contained'
                  size='large'
                >
                  {isLoading ? 'Sending reset link' : 'Send reset link'}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
