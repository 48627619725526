import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput, Stack } from '@mui/material';

import { debounce } from 'lodash';
import { MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { venueListReservationsApi } from '../../../api/reservations/venueListReservationsApi';
import { useNotificationsContext } from '../../../contexts/NotificationsContext';
import {
  OfferCategory,
  ReservationStatus,
  VenueListReservationsFilter,
} from '../../../graphql/API';
import { useVenueListReservationsCountQuery } from '../../../hooks/reactQuery/reservations/useVenueListReservationsCountQuery';
import useReservationQuery from '../../../hooks/reactQuery/useReservationQuery';
import useExportCsv from '../../../hooks/useExportCsv';
import useVenueId from '../../../hooks/useVenueId';
import { extractSubscriptionPlan } from '../../../pages/SubscriptionPlans/common/helper';
import { useAppSelector } from '../../../redux/hooks';
import { brandPromotionLimit } from '../../../utils/reservations';
import { BrandPromotionLimit } from '../../BrandPromotionLimit/BrandPromotionLimit';
import LoadingButton from '../../Button/LoadingButton';
import ChipOptions from '../../ChipOptions/ChipOptions';
import useColumn from './Column';
import mapToCsv from './mapToCsv';
import './resrvation.css';

type ReservationsTableProps = {
  height?: string;
  detail?: boolean;
  filters?: Partial<VenueListReservationsFilter>;
  handleTableHeight?: (val: number) => void;
};

const ReservationsTable: React.FC<ReservationsTableProps> = ({
  height,
  detail,
  filters: defaultFilters,
  handleTableHeight,
}) => {
  const venueID = useVenueId();
  const navigate = useNavigate();

  const venueState = useAppSelector((state) => state.venue.value);
  const { subscription } = useAppSelector((state) => state.subscription.value);
  const [exportLoading, setExportLoading] = React.useState<boolean>();
  const [filters, setFilters] = React.useState<Partial<VenueListReservationsFilter> | undefined>(
    defaultFilters,
  );
  const columns = useColumn({ detail });
  const [sorting, setSorting] = React.useState<MRT_SortingState>([
    { id: defaultFilters?.sortBy ?? 'createdAt', desc: defaultFilters?.sortDirection === 'desc' },
  ]);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useReservationQuery(
    filters,
    venueID,
  );

  const { data: reservationsCount } = useVenueListReservationsCountQuery(
    {
      venueID: venueID || '',
      subscriptionDates: {
        start: subscription?.updatedAt || '',
        end: subscription?.expirationDate || '',
      },
      privateOffer: false,
    },
    venueState?.type === OfferCategory.brand,
  );

  const { data: allReservationsCount } = useVenueListReservationsCountQuery({
    venueID: venueID || '',
    search: filters?.searchText,
    offerID: filters?.offerID,
  });

  const { handleUpdateReservation } = useNotificationsContext() || {};

  const { doExportData } = useExportCsv();

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && hasNextPage) {
          fetchNextPage();
        }
      }
    },
    [isFetching, fetchNextPage, hasNextPage],
  );

  React.useEffect(() => {
    if (data.length > 0 && handleUpdateReservation) {
      handleUpdateReservation(data[0].createdAt || '');
    }
  }, [data]);

  React.useEffect(() => {
    const [sort] = sorting;
    setFilters((x) => ({ ...x, sortBy: sort?.id, sortDirection: sort?.desc ? 'desc' : 'asc' }));
  }, [sorting]);

  const handleExportData = React.useCallback(async () => {
    setExportLoading(true);
    const items = await venueListReservationsApi({
      ...filters,
      limit: 9999,
      offset: 0,
      venueID,
    });

    doExportData(items.map(mapToCsv));
    setExportLoading(false);
  }, [doExportData, venueID, filters]);

  const table = useMaterialReactTable({
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: true,
    manualSorting: true,
    onSortingChange: setSorting,
    muiTableHeadProps: { sx: { opacity: 1 } },
    muiTableHeadRowProps: {
      sx: { backgroundColor: 'white', boxShadow: 'none' },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: { backgroundColor: 'inherit' },
      onClick: () => {
        navigate(`/reservations/${row.original.id}`);
      },
    }),
    muiTableBodyCellProps: {
      sx: ({ custom }) => ({ borderColor: custom.colors.brandLightGrey }),
    },
    muiTableHeadCellProps: {
      sx: ({ custom }) => ({
        color: 'text.secondary',
        borderColor: custom.colors.brandLightGrey,
      }),
    },
    muiTablePaperProps: { sx: { boxShadow: 'none' } },
    muiTableContainerProps: {
      sx: { height, maxHeight: height, backgroundColor: 'white' },
      onScroll: (event) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    columns,
    data,
    rowVirtualizerOptions: { overscan: 5 },
    state: { sorting, isLoading: isLoading || isFetching },
  });

  const handleSearch = debounce((value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchText: value,
    }));
  }, 500);

  const handleChange = React.useCallback(
    (value?: string) => {
      handleSearch(value);
    },
    [handleSearch],
  );

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  const totalReservations = React.useMemo(
    () => (reservationsCount?.totalReserved || 0) + (reservationsCount?.totalCheckedIn || 0),
    [reservationsCount],
  );

  const isPromotionLimitHit = React.useMemo(
    () =>
      venueState?.type === OfferCategory.brand &&
      (subscriptionPlan === 'basic' || subscriptionPlan === 'starter') &&
      totalReservations === brandPromotionLimit[subscriptionPlan],
    [subscriptionPlan, totalReservations, venueState?.type],
  );

  React.useEffect(() => {
    if (isPromotionLimitHit && handleTableHeight) {
      handleTableHeight(345);
    } else if (handleTableHeight) {
      handleTableHeight(245);
    }
  }, [isPromotionLimitHit]);

  const totalAllReservations =
    (allReservationsCount?.totalReserved || 0) +
    (allReservationsCount?.totalCheckedIn || 0) +
    (allReservationsCount?.totalCancelled || 0) +
    (allReservationsCount?.totalNoShow || 0) +
    (allReservationsCount?.totalNoShowProcessed || 0);

  return (
    <Stack spacing={4}>
      <Box display='flex' justifyContent='space-between'>
        <ChipOptions
          name='reservationStatus'
          options={[
            { label: `All (${totalAllReservations})`, value: '' },
            {
              label: `Reserved (${allReservationsCount?.totalReserved || 0})`,
              value: ReservationStatus.reserved,
            },
            {
              label: `Checked in (${allReservationsCount?.totalCheckedIn || 0})`,
              value: ReservationStatus.checkedin,
            },
            {
              label: `Cancelled (${allReservationsCount?.totalCancelled || 0})`,
              value: ReservationStatus.cancelled,
            },
            {
              label: `No show (${
                (allReservationsCount?.totalNoShow || 0) +
                (allReservationsCount?.totalNoShowProcessed || 0)
              })`,
              value: ReservationStatus.noshow,
            },
          ]}
          onChange={({ value: v }) =>
            setFilters((pre) => ({
              ...pre,
              status: v
                ? v === ReservationStatus.noshow
                  ? [ReservationStatus.noshow, ReservationStatus.noshowprocessed]
                  : [v as ReservationStatus]
                : undefined,
            }))
          }
        />

        <Stack direction='row' spacing={1}>
          <OutlinedInput
            id='outlined-adornment-amount'
            placeholder='Search a member...'
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            size='small'
          />
          <LoadingButton
            variant='outlined'
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            loading={exportLoading}
          >
            Export Data
          </LoadingButton>
        </Stack>
      </Box>

      {venueState?.type === OfferCategory.brand &&
        (subscriptionPlan === 'basic' || subscriptionPlan === 'starter') &&
        totalReservations === brandPromotionLimit[subscriptionPlan] && (
          <Box sx={{ py: 0.5 }}>
            <BrandPromotionLimit
              totalReservations={totalReservations}
              outOfReservations={brandPromotionLimit[subscriptionPlan] || 0}
            />
          </Box>
        )}

      <MaterialReactTable table={table} />
    </Stack>
  );
};

export default ReservationsTable;
