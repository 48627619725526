import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SkeletonLoading: React.FC = () => {
  return (
    <Box height='100%' display='flex' p={4} flexDirection='column'>
      <Box display='flex'>
        <Skeleton variant='rounded' width={120} height={120} />

        <Stack>
          <Skeleton variant='text' width={300} height={50} sx={{ ml: 3, fontSize: '1rem' }} />
          <Skeleton variant='text' width={100} height={30} sx={{ ml: 3, fontSize: '1rem' }} />
        </Stack>
      </Box>

      <Grid sx={{ mt: 3 }} container spacing={2}>
        {[...Array(5)].map((x, i) => (
          <Grid key={i} item xs={2}>
            <Skeleton variant='rounded' height={100} />
          </Grid>
        ))}
      </Grid>

      <Grid sx={{ py: 2, height: '100%' }} container spacing={2}>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%' />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2} height='100%'>
            <Skeleton variant='rounded' width='100%' height='100%' />
            <Skeleton variant='rounded' width='100%' height='100%' />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SkeletonLoading;
