import { UserEstimatedPrice, VenueListUserPriceEstimatedFilter } from '../../graphql/API';
import { venueListUserEstimatedPrice } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListUserEstimatedPriceApi = async (
  filter: VenueListUserPriceEstimatedFilter,
): Promise<UserEstimatedPrice[]> => {
  const result = await executeGql(venueListUserEstimatedPrice, { filter });
  return processGqlResult<UserEstimatedPrice[]>('venueListUserEstimatedPrice.items', result);
};
