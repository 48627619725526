import * as React from 'react';

import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import { UserReservationContent } from '../../../graphql/API';
import { useQueryUserContent } from '../../../hooks/reactQuery/useQueryUser';
import MediaContainer, { MediaItem } from './MediaContainer';

type PanelUserContentProps = {
  userId?: string;
};

const toImageItems = (data: UserReservationContent[]): MediaItem[] =>
  data.map((x) => ({
    id: String(x.id),
    label: String(x.id),
    src: String(x.images?.medium),
    video: x.images?.video ?? undefined,
    placeholderSrc: String(x.images?.thumbnail),
  }));

const PanelUserContent: React.FC<PanelUserContentProps> = ({ userId }) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useQueryUserContent(userId);

  const items = React.useMemo(() => toImageItems(data), [data]);

  return (
    <InfiniteScroll
      isLoading={isLoading}
      isFetching={!isLoading && items.length > 0 && isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    >
      <MediaContainer isLoading={isLoading} items={items} contentType='content' />
    </InfiniteScroll>
  );
};

export default PanelUserContent;
