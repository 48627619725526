import * as React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

type BorderBoxProps = {
  title: string;
};

const BorderBox: React.FC<React.PropsWithChildren<BorderBoxProps>> = ({ children, title }) => (
  <Box>
    <Title>{title}</Title>
    {children}
  </Box>
);

export default BorderBox;

const Title = styled.h4`
  margin: 0 0 15px 0;
  color: #111;
  font-weight: 600;
`;
