import * as React from 'react';

import { Box, Button, Typography } from '@mui/material';

import BaseDialog, { BaseDialogProps } from '../Base';

type Props = {
  title: string;
  subTitle?: string;
  onConfirm: () => void;
} & BaseDialogProps;

export const CheckedInConfirmDialog: React.FC<Props> = ({
  title,
  subTitle,
  onConfirm,
  ...restProps
}) => {
  return (
    <BaseDialog height='280px' width='388px' {...restProps}>
      <Typography fontSize='18px' fontWeight={500} mt={1}>
        {title}
      </Typography>
      {subTitle && (
        <Typography sx={{ mt: '16px', fontSize: '15px', fontWeight: 400 }}>{subTitle}</Typography>
      )}

      <Box sx={{ mt: '70px', display: 'flex', alignItems: 'stretch', gap: '12px' }}>
        <Button
          variant='outlined'
          color='primary'
          fullWidth
          sx={{ paddingY: '10px' }}
          onClick={() => {
            if (restProps?.onClose) restProps?.onClose({}, 'backdropClick');
          }}
        >
          Cancel
        </Button>

        <Button
          fullWidth
          variant='contained'
          onClick={() => {
            onConfirm();
            if (restProps?.onClose) restProps?.onClose({}, 'backdropClick');
          }}
        >
          Check in
        </Button>
      </Box>
    </BaseDialog>
  );
};
