import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { AvatarContainer } from './Content';

const ContentSkeleton: React.FC = () => {
  return (
    <Box display='flex' flexDirection='column' position='relative'>
      <AspectRatio>
        <Skeleton variant='rounded' />
      </AspectRatio>

      <AvatarContainer sx={{ width: '100%' }}>
        <Skeleton variant='circular' sx={{ width: '2rem', height: '2rem', mr: 1 }} />
        <Skeleton variant='text' width='50%' />
      </AvatarContainer>
    </Box>
  );
};

export default ContentSkeleton;
