import React from 'react';

import { Box, Link } from '@mui/material';

import styled from '@emotion/styled';

import { useChatContext } from '../../../../contexts/ChatContext/useChatContext';

export const NoPriceBuyContent: React.FC = () => {
  const { setShouldChatOpen } = useChatContext() || {};
  return (
    <Container>
      <Box>
        <Box display={'flex'} alignItems='center' justifyContent={'space-between'}>
          <BuyContentHeading>Buy content</BuyContentHeading>
        </Box>
        <UsageDescription>
          This content does not have a set price. If you're interested in purchasing it, please
          contact us through{' '}
          <Link
            sx={{ fontSize: '15px' }}
            onClick={() => {
              if (setShouldChatOpen) setShouldChatOpen(true);
            }}
            component={'button'}
          >
            Support chat
          </Link>
        </UsageDescription>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #ebe8e8;
  background-color: #f7f7f7;

  padding: 22px 30px;

  display: flex;
  flex-direction: column;

  gap: 68px;
`;

const BuyContentHeading = styled.h6`
  padding: 0;
  margin: 0;

  color: #111;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`;

const UsageDescription = styled.p`
  margin: 0;
  padding: 0;

  color: #5f5f60;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 6px;
`;
