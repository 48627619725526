export const environments = ['local', 'dev', 'production'] as const;

export type Environment = (typeof environments)[number];

export type AwsConfig = {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_mobile_analytics_app_id: string;
  aws_mobile_analytics_app_region: string;
  Analytics: {
    /** MARK: otherwise it raises an error during local development */
    disabled: boolean;
  };
};

export type AppConfig = {
  stripKey: string;
  googleMapApiKey: string;
};

export interface AwsConfigV2 {
  awsConfig: AwsConfigMain;
  keys: Keys;
  urls: Urls;
  copy: Copy;
  waitTimeOptions: WaitTimeOption[];
  generic: Generic;
  socials: Socials;
  stripe: Stripe;
  app: App;
  appAssets: AppAssets;
  marketReleases: MarketReleases;
  sentrySettings: SentrySettings;
  marketplace: Marketplace;
  collabPlatformFee: CollabPlatformFee;
  collabRequirementPrices: CollabRequirementPrices;
}

interface CollabRequirementPrices {
  story: number;
  stories3x: number;
  feed: number;
  reel: number;
}

interface CollabPlatformFee {
  platformFee: number;
}

interface Marketplace {
  minimumCostPercentage: number;
}

interface SentrySettings {
  timeout: number;
}

interface MarketReleases {
  collabs: number[];
}

interface AppAssets {
  public: string;
}

interface App {
  minAppVersion: string;
  newestAppVersion: string;
}

interface Stripe {
  pub: string;
  credits: Credits;
}

interface Credits {
  '1': _1;
  '3': _1;
  '10': _1;
}

interface _1 {
  price: number;
  percentOff: number;
  mostPopular: boolean;
}

interface Socials {
  fb: Oauth;
}

interface Generic {
  checkInRadius: number;
  showExtraContent: boolean;
}

interface WaitTimeOption {
  label: string;
  value: string;
}

interface Copy {
  resetPasswordText: string;
  resetPasswordConfirm: string;
}

interface Urls {
  sentry: string;
}

interface Keys {
  googleMaps: string;
}

interface AwsConfigMain {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: Oauth;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_mobile_analytics_app_id: string;
  aws_mobile_analytics_app_region: string;
  Analytics: Analytics;
  PushNotification: PushNotification;
  Notifications: Notifications;
}

interface Notifications {
  Push: Push;
}

interface Push {
  AWSPinpoint: AWSPinpoint;
}

interface PushNotification {
  appId: string;
  requestIOSPermissions: boolean;
}

interface Analytics {
  disabled: boolean;
  autoSessionRecord: boolean;
  AWSPinpoint: AWSPinpoint;
  bufferSize: number;
  flushInterval: number;
  flushSize: number;
  resendLimit: number;
}

interface AWSPinpoint {
  appId: string;
  region: string;
}

interface Oauth {}
