import { VenueUpdateReservationInput } from '../../graphql/API';
import { venueUpdateReservation } from '../../graphql/mutations';
import { executeGql } from '../../utils/gql-utils';

export const venueUpdateReservationApi = async (
  input: VenueUpdateReservationInput,
): Promise<void> => {
  await executeGql(venueUpdateReservation, {
    input,
  });
};
