import { VenuePortfolioItem } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListUsersQuery = /* GraphQL */ `
  query venueListPortfolioContent($id: String!) {
    venueListPortfolioContent(id: $id) {
      items {
        id
        venueID
        portfolio
        createdAt
        updatedAt
        asset {
          large
          medium
          small
          thumbnail
        }
      }
    }
  }
`;

export const venueListPortfolioContentApi = async (id: string): Promise<VenuePortfolioItem[]> => {
  const result = await executeGql<VenuePortfolioItem[]>(venueListUsersQuery, { id });
  return processGqlResult<VenuePortfolioItem[]>('venueListPortfolioContent.items', result);
};
