import * as React from 'react';

import dayjs from 'dayjs';
import { useField } from 'formik';

import CustomTimePicker, {
  TimePickerProps as CustomTimePickerProps,
} from '../../TimePicker/TimePicker';

type OnChange = Exclude<CustomTimePickerProps['onSelectChange'], undefined>;

const TimePicker: React.FC<CustomTimePickerProps> = ({ name, label, ...restProps }) => {
  const [field, meta, helper] = useField<Date | null>(name || '');

  const handleChange = React.useCallback<OnChange>(
    (v) => {
      const vv = v ? v.toDate() : null;

      helper.setValue(vv);
    },
    [helper],
  );

  return (
    <CustomTimePicker
      label={label}
      name={name}
      fullWidth
      defaultValue={field.value ? dayjs(field.value) ?? undefined : undefined}
      onSelectChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched ? meta.error : ''}
      {...restProps}
    />
  );
};

export default TimePicker;
