import { useContext } from 'react';

import { ChatContext, ChatContextProps } from './Chat';

export const useChatContext = (): ChatContextProps | null => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }

  return context;
};
