import * as React from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

type TabPanelProps = {
  index: number;
  value: number;
  sx?: SxProps<Theme>;
};

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  children,
  index,
  value,
  sx,
}) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ py: 3, flex: 1, ...sx }}
    >
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
