import * as React from 'react';

import { ButtonProps, CircularProgress } from '@mui/material';

import Button from '../../../components/Button/Button';

type UploadButtonProps = {
  loading?: boolean;
  onFileUpload: React.ChangeEventHandler<HTMLInputElement>;
  enableImagePicker?: boolean;
} & ButtonProps;

const UploadButton: React.FC<React.PropsWithChildren<UploadButtonProps>> = ({
  children,
  loading,
  disabled,
  enableImagePicker = true,
  onFileUpload,
  ...restProps
}) => {
  return (
    <Button
      size='small'
      component='label'
      disabled={disabled || loading}
      startIcon={loading && <CircularProgress size='1rem' sx={{ color: 'inherit' }} />}
      {...restProps}
    >
      {children}
      {enableImagePicker ? (
        <input hidden accept='image/*' type='file' onChange={onFileUpload} />
      ) : null}
    </Button>
  );
};

export default UploadButton;
