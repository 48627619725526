import React, { useEffect, useRef } from 'react';

type UseClickOutsideProps = {
  onClickOutside: () => void;
};

export const useClickOutside = ({ onClickOutside }: UseClickOutsideProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside();
    }
  }, [onClickOutside]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, onClickOutside]);

  return ref;
};
