import { VenueUpdateOfferInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueUpdateOfferMutation = /* GraphQL */ `
  mutation VenueUpdateOffer($input: VenueUpdateOfferInput!) {
    venueUpdateOffer(input: $input)
  }
`;

export const venueUpdateOfferApi = async (input: VenueUpdateOfferInput): Promise<void> => {
  await executeGql<void>(venueUpdateOfferMutation, { input });
};
