import { Avatar, Box, CardHeader, Skeleton, createStyles, makeStyles } from '@mui/material';

interface MediaProps {
  loading?: boolean;
}

function Media(props: MediaProps) {
  const { loading = false } = props;

  return (
    <Box
      sx={{
        maxWidth: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <CardHeader
        avatar={
          loading ? (
            <Skeleton animation='wave' variant='circular' width={44} height={44} />
          ) : (
            <Avatar
              alt='Ted talk'
              src='https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg'
            />
          )
        }
        title={
          loading ? (
            <Skeleton animation='wave' height={15} width='80%' style={{ marginBottom: 6 }} />
          ) : (
            'Ted'
          )
        }
        subheader={loading ? <Skeleton animation='wave' height={15} width='40%' /> : '5 hours ago'}
      />
    </Box>
  );
}

export default function ChatThreadSkeleton() {
  return (
    <div>
      <Media loading />
    </div>
  );
}
