import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Formik, FormikConfig } from 'formik';
import { ShowFnOutput } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';

import { venueCreateComplaintApi } from '../../../../api/reservations/venueCreateComplaintApi';
import { venueBlockUserApi } from '../../../../api/users/venueBlockUserApi';
import { useChatContext } from '../../../../contexts/ChatContext/useChatContext';
import { getOptionsFromEnum } from '../../../../utils/options';
import LoadingButton from '../../../Button/LoadingButton';
import SelectOption from '../../../Formiks/SelectOption/SelectOption';
import TextField from '../../../Formiks/TextField/TextField';
import { BaseDialogProps } from '../../Base';
import { ExtraPayload, Form, ReportCause, initialValues, mapToApiPayload, schema } from './schema';

export type ReportReservationsProps = {
  payload: ExtraPayload;
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
  refreshHandle: () => void;
  offerId?: string;
};

const ReportReservations: React.FC<ReportReservationsProps> = ({
  payload,
  modalRef,
  refreshHandle,
  offerId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const chatContext = useChatContext();

  const handleSubmit = React.useCallback<FormikConfig<Form>['onSubmit']>(
    async (values) => {
      if (values.cause === ReportCause.blockThisMember) {
        await venueBlockUserApi({
          userID: Number(payload.userId),
          venueID: Number(payload.venueId),
        });
      }

      const apiPayload = mapToApiPayload(values, payload);

      await venueCreateComplaintApi(apiPayload);

      const message =
        values.cause === ReportCause.blockThisMember
          ? 'You have successfully blocked the member'
          : 'You have successfully sent the reservation report. Our help center will look into this right away';

      enqueueSnackbar(message, { variant: 'success' });

      refreshHandle();
      if (chatContext && offerId) {
        chatContext.setShouldChatOpen(true);
        chatContext.setOfferId(offerId);
      }
      modalRef.current?.destroy();
    },
    [payload, enqueueSnackbar, refreshHandle, chatContext, offerId, modalRef],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            pt: 1,
          }}
        >
          <Stack spacing={4}>
            <SelectOption
              id='slt-report-cause'
              name='cause'
              label='Select a cause'
              placeholder='Select a cause'
              options={getOptionsFromEnum(ReportCause)}
              fullWidth
            />

            <TextField
              id='txt-desc'
              label='Issue Detail (optional)'
              name='description'
              placeholder='Please describe the issue in detail'
              multiline
              fullWidth
              rows={5}
            />
          </Stack>

          <LoadingButton fullWidth loading={isSubmitting} variant='contained' type='submit'>
            Submit
          </LoadingButton>
        </Box>
      )}
    </Formik>
  );
};

export default ReportReservations;
