import * as React from 'react';

import Box from '@mui/material/Box';

import styled from '@emotion/styled';
import { useField } from 'formik';

import { OfferCategory } from '../../../graphql/API';
import { useAppSelector } from '../../../redux/hooks';
import { Method } from '../OfferForm';
import { AttentionError } from './AttentionError';
import NumberFieldWithCheckbox from './NumberFieldWithCheckbox';
import SectionTitle from './SectionTitle';
import SideBySide from './SideBySide';
import TextFieldWithCheckbox from './TextFieldWithCheckbox';
import './formiks.css';
import OfferCallToConfirm from './formiks/OfferCallToConfirm/OfferCallToConfirm';
import ReqRadioGroup from './formiks/OfferRequirement/OfferRequirement';
import { FormValue, OfferType, Requirement, isAllowCallToConfirm } from './schema';

type Props = {
  method: Method;
  initialValues: FormValue;
};

const RequirementsSection: React.FC<Props> = ({ initialValues, method }) => {
  const [typeField] = useField<OfferType>('type');
  const [catField] = useField<OfferCategory>('category');
  const [regField] = useField<Requirement>('requirements');
  const [confirmWithCallField] = useField('confirmWithCall');
  const [suggestedTipField] = useField('suggestedTip');
  const venueState = useAppSelector((state) => state.venue.value);

  const isOnReg = React.useMemo(() => regField.value !== 'noRequirement', [regField.value]);

  const isAllowCall = React.useMemo(
    () => isAllowCallToConfirm(isOnReg, catField.value),
    [isOnReg, catField.value],
  );

  return (
    <Box width='100%'>
      <SectionTitle title='Requirements' />
      
      <ReqRadioGroup
        aria-labelledby='radio-group-requirement-label'
        name='radio-group-requirement'
      />

      {method === 'update' && regField.value !== initialValues.requirements && <AttentionError />}

      <Box mt={5}>
        {isAllowCall && <OfferCallToConfirm />}

        {method === 'update' &&
          Boolean(initialValues.confirmWithCall) !== Boolean(confirmWithCallField.value) && (
            <AttentionError />
          )}

        {isOnReg && (
          <SideBySide ratio={7}>
            <CustomBox>
              <label htmlFor='txt-dresscode' className='offer-form-label'>
                Dresscode
              </label>
              <TextFieldWithCheckbox
                className='text-field-with-checkbox'
                id='txt-dresscode'
                name='dressCode'
                placeholder='Ex. casual, elegant, sportive, etc.'
              />
            </CustomBox>
            {typeField.value === 'public' && catField.value !== 'event' && (
              <CustomBoxTrip>
                <label htmlFor='txt-tip' className='offer-form-label'>
                  Suggested tip
                </label>
                <NumberFieldWithCheckbox
                  className='text-field-with-checkbox'
                  id='txt-tip'
                  name='suggestedTip'
                  type='number'
                  placeholder='0'
                  currencySign={venueState?.currency}
                />

                {method === 'update' && Number(suggestedTipField.value) !== Number(initialValues.suggestedTip) && (
                  <AttentionError />
                )}
              </CustomBoxTrip>
            )}
          </SideBySide>
        )}
      </Box>
    </Box>
  );
};

export default RequirementsSection;

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;

const CustomBoxTrip = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;
