import * as React from 'react';

import Chip, { ChipProps } from '@mui/material/Chip';

import { UserCategory, mapUserCategory } from '../../../utils/user';

type UserCategoryChipProps = {
  userCategory: UserCategory;
} & ChipProps;

const mapUserCategoryProp: Record<
  UserCategory,
  { label: string; backgroundColor: string; color: string }
> = {
  [UserCategory.lifestyle]: {
    label: mapUserCategory[UserCategory.lifestyle],
    backgroundColor: '#37BB901A',
    color: '#37BB90',
  },
  [UserCategory.select]: {
    label: mapUserCategory[UserCategory.select],
    backgroundColor: '#955CCC1A',
    color: '#955CCC',
  },
};

const UserCategoryChip: React.FC<UserCategoryChipProps> = ({ userCategory, sx }) => {
  const map = React.useMemo(() => mapUserCategoryProp[userCategory], [userCategory]);

  return (
    <Chip
      size='small'
      label={map.label}
      variant='outlined'
      sx={{
        fontWeight: 'medium',
        borderColor: map.color,
        backgroundColor: map.backgroundColor,
        color: map.color,
        ...sx,
      }}
    />
  );
};

export default UserCategoryChip;
