import * as React from 'react';

import { ButtonProps } from '@mui/material/Button';

import useMutationSaveUser, { OnSuccess } from '../../hooks/reactQuery/useMutationSaveUser';
import SavedButton from './SavedButton';

type SaveUserButtonProps = {
  saved?: boolean;
  userId?: number;
  onSuccess?: OnSuccess;
} & ButtonProps;

const SaveUserButton: React.FC<SaveUserButtonProps> = ({
  children,
  saved,
  userId,
  onSuccess,
  ...restProps
}) => {
  const [active, setActive] = React.useState(Boolean(saved));

  const { saveUserMutation, unsaveUserMutation } = useMutationSaveUser({ onSuccess });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(() => {
    setActive((x) => {
      const vv = !x;

      if (userId) {
        const mutation = vv ? saveUserMutation : unsaveUserMutation;
        mutation.mutate(userId);
      }

      return vv;
    });
  }, [userId, setActive, saveUserMutation, unsaveUserMutation]);

  React.useEffect(() => {
    setActive(Boolean(saved));
  }, [saved]);

  const isLoading = React.useMemo(
    () => saveUserMutation.isPending || unsaveUserMutation.isPending,
    [saveUserMutation.isPending, unsaveUserMutation.isPending],
  );

  return (
    <SavedButton
      {...restProps}
      id='btn-saved'
      active={active}
      loading={isLoading}
      onClick={handleClick}
    >
      {children}
    </SavedButton>
  );
};

export default SaveUserButton;
