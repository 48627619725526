import { useQuery } from '@tanstack/react-query';

import { venueListUserEstimatedPriceApi } from '../../../api/balance/venueListUserEstimatedPriceApi';
import { VenueListUserPriceEstimatedFilter } from '../../../graphql/API';

export const useVenueListUserEstimatedPriceQuery = (filter: VenueListUserPriceEstimatedFilter) => {
  const { data, isFetching, isError, isLoading } = useQuery({
    queryKey: ['venueListUserEstimatedPrice', filter],
    queryFn: async ({ queryKey }) => {
      const [, filters] = queryKey as [string, VenueListUserPriceEstimatedFilter];

      return venueListUserEstimatedPriceApi(filters);
    },
    refetchOnWindowFocus: false,
    enabled: filter.userIDs.length > 0,
  });

  return { data, isFetching, isError, isLoading };
};
