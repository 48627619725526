import { VenueDownloadReservationContentInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';
import { venueDownloadReservationContentV2 } from './../../graphql/mutations';

export const venueDownloadReservationContentV2Api = async (
  input: VenueDownloadReservationContentInput,
): Promise<void> => {
  await executeGql(venueDownloadReservationContentV2, {
    input,
  });
};
