import * as React from 'react';

import { BlockedUser, VennueAccessRequestItem } from '../../../graphql/API';
import { QueryResult } from '../../../hooks/reactQuery/types';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import List from '../Base/List';
import ListSkeleton from '../Base/ListSkeleton';
import { mapToListItem } from './mapper';
import { MapProps } from './types';

export type ListAccessProps = {
  queryResult: QueryResult<VennueAccessRequestItem[]> | QueryResult<BlockedUser[]>;
} & MapProps;

const ListAccess: React.FC<ListAccessProps> = ({ queryResult, ...restProps }) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = queryResult;

  return (
    <InfiniteScroll
      isLoading={isLoading}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    >
      {isLoading ? (
        <ListSkeleton avatarVariant='circular' />
      ) : (
        <List items={mapToListItem(data, restProps)} />
      )}
    </InfiniteScroll>
  );
};

export default ListAccess;
