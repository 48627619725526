import React from 'react';
import { Navigate } from 'react-router-dom';

import { OfferCategory } from '../../graphql/API';
import { extractSubscriptionPlan } from '../../pages/SubscriptionPlans/common/helper';
import { useAppSelector } from '../../redux/hooks';

type Props = {
  children: React.ReactNode;
};

export const BrandRoute: React.FC<Props> = ({ children }) => {
  const { type } = useAppSelector((state) => state.venue.value) || {};
  const isLoading = useAppSelector((state) => state.venue.isLoading) || {};
  const { subscription, isLoading: subscriptionLoading } = useAppSelector(
    (state) => state.subscription.value,
  );

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  if (isLoading && subscriptionLoading) return null;

  return type && type === OfferCategory.brand && !subscriptionPlan ? (
    <Navigate to='/plans' />
  ) : (
    children
  );
};
