import React from 'react';

import { Typography } from '@mui/material';

import BaseDialog, { BaseDialogProps } from '../Base';
import {
  EditReservationDateAndTime,
  EditReservationDateAndTimeProps,
} from './components/EditReservationDateAndTime';

type EditReservationDateAndTimeDialog = EditReservationDateAndTimeProps & BaseDialogProps;

export const EditReservationDateAndTimeDialog: React.FC<EditReservationDateAndTimeDialog> = ({
  modalRef,
  reservationDetail,
  refetchReservationDetails,
  ...restProps
}) => {
  return (
    <BaseDialog
      width='490px'
      height='440px'
      titleComponent={
        <Typography fontSize={'20px'} fontWeight={'500'}>
          Edit date and time
        </Typography>
      }
      {...restProps}
    >
      <EditReservationDateAndTime
        modalRef={modalRef}
        reservationDetail={reservationDetail}
        refetchReservationDetails={refetchReservationDetails}
      />
    </BaseDialog>
  );
};
