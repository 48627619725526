import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import ListAccessRequestContent from './components/ListAccessRequestContent';

const ListAccessRequestDialog: React.FC<BaseDialogProps> = (props) => {
  return (
    <BaseDialog
      {...props}
      height='700px'
      sx={{
        '.MuiDialogContent-root': {
          p: 0,
        },
      }}
    >
      <ListAccessRequestContent />
    </BaseDialog>
  );
};

export default ListAccessRequestDialog;
