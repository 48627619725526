import * as React from 'react';

import Box, { BoxProps } from '@mui/material/Box';

const BaseBorder: React.FC<React.PropsWithChildren<BoxProps>> = ({
  children,
  sx,
  ...restProps
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        border: '1px solid #DBDBDB',
        borderRadius: 1,
        p: 2,
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default BaseBorder;
