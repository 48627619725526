import * as React from 'react';

import Box from '@mui/material/Box';

import { useField } from 'formik';
import { isEqual, sortBy, upperFirst } from 'lodash';

import CheckboxGroup, { OptionItem } from '../../../components/Formiks/CheckboxGroup/CheckboxGroup';
import { DaysOfWeek } from '../../../graphql/API';
import { Method } from '../OfferForm';
import { AttentionError } from './AttentionError';
import SectionTitle from './SectionTitle';
import { FormValue } from './schema';

const daysOfWeek: DaysOfWeek[] = [
  DaysOfWeek.monday,
  DaysOfWeek.tuesday,
  DaysOfWeek.wednesday,
  DaysOfWeek.thursday,
  DaysOfWeek.friday,
  DaysOfWeek.saturday,
  DaysOfWeek.sunday,
];

const options = daysOfWeek.map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

type Props = {
  method: Method;
  initialValues: FormValue;
};

const AvailableSection: React.FC<Props> = ({ method, initialValues }) => {
  const [availableDaysField] = useField('availableDays');
  return (
    <Box>
      <SectionTitle
        title='Available every'
        description='Select the days of the week on which you want the offer to repeat.'
      />

      <CheckboxGroup options={options} name='availableDays' />

      {method === 'update' &&
        !isEqual(sortBy(initialValues.availableDays), sortBy(availableDaysField.value)) && (
          <AttentionError />
        )}
    </Box>
  );
};

export default AvailableSection;
