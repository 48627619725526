import { VenueBlockUserInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

export const venueBlockUserMutation = /* GraphQL */ `
  mutation venueBlockUser($input: VenueBlockUserInput!) {
    venueBlockUser(input: $input)
  }
`;

export const venueBlockUserApi = async (input: VenueBlockUserInput): Promise<void> => {
  await executeGql(venueBlockUserMutation, { input });
};
