import * as React from 'react';

import { Typography } from '@mui/material';

import BaseDialog, { BaseDialogProps } from '../Base';
import { CancelReservation, CancelReservationProps } from './components/CancelReservation';

type CancelReservationsDialogProps = BaseDialogProps & CancelReservationProps;

const CancelReservationDialog: React.FC<CancelReservationsDialogProps> = ({
  modalRef,
  reservationDetail,
  refetchReservationDetails,
  ...restProps
}) => {
  return (
    <BaseDialog
      width='550px'
      height='264px'
      titleComponent={<Typography fontSize={'20px'}>Cancel reservation</Typography>}
      {...restProps}
    >
      <CancelReservation
        modalRef={modalRef}
        reservationDetail={reservationDetail}
        refetchReservationDetails={refetchReservationDetails}
      />
    </BaseDialog>
  );
};

export default CancelReservationDialog;
