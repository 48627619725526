export interface OptionEntry {
  label: string;
  value: string;
}

export const getOptionsFromEnum = (enumObject: Record<string, string>): OptionEntry[] =>
  Object.values(enumObject).map((s) => ({
    value: s,
    label: s,
  }));
