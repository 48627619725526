import React from 'react';

import { Alert, Box, Button, LinearProgress, Typography } from '@mui/material';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { formatNumberWithCommas } from '../../../utils/formatter';
import { CurrencySign } from '../../CurrencySign/CurrencySign';

type Props = {
  amount: number;
  amountWithoutFee: number;
  currency?: string;
  onSuccess?: () => void;
};

export const CheckoutFunds: React.FC<Props> = ({ amount, onSuccess }) => {
  const [isReady, setIsReady] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isProcessing, setIsProcessing] = React.useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    const { origin, pathname } = window.location;
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${origin}${pathname}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setError(error.message || '');
    } else if (paymentIntent.status === 'succeeded') {
      if (onSuccess) onSuccess();
    } else {
      console.error('unexpected error');
    }

    setIsProcessing(false);
  };

  return (
    <Box component='form' width='100%' onSubmit={handleSubmit}>
      {error && (
        <Alert variant='filled' severity='error'>
          <Typography>{error}</Typography>
        </Alert>
      )}

      {isProcessing && <LinearProgress sx={{ mt: 2 }} />}

      <PaymentElement
        options={{ layout: { type: 'accordion' } }}
        onReady={() => {
          setIsReady(true);
        }}
      />

      <Button
        fullWidth
        variant='contained'
        type='submit'
        disabled={!isReady || isProcessing}
        sx={{ marginTop: 4, py: '15px' }}
      >
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <span>Add</span>
          <span>
            <CurrencySign currency={'usd'} />
            <span>{formatNumberWithCommas(amount)}</span>
          </span>
        </Box>
      </Button>
    </Box>
  );
};
