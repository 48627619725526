import * as React from 'react';

import Box from '@mui/material/Box';

type InviteIconProps = {
  active?: boolean;
};
const InviteIcon: React.FC<InviteIconProps> = ({ active }) => {
  const src = React.useMemo(
    () => (active ? '/png/invite-purple.png' : '/png/invite-grey.png'),
    [active],
  );

  return <Box component='img' src={src} width='20px' />;
};

export default InviteIcon;
