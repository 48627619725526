import * as React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { DRAWER_WIDTH } from '../constant';
import './SideMenu.css';
import Tabs from './Tabs';

interface SideBarProps {
  drawerOpen: boolean;
  onDrawerClick?: () => void;
}

const Lock = styled(Box, { shouldForwardProp: (prop) => prop !== 'locked' })<{
  locked?: boolean;
}>(({ theme, locked }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  padding: '4px',
  borderTop: '1px solid #e1e4eb',
  color: 'black',
  cursor: 'pointer',
  [theme.breakpoints.down(1024)]: {
    display: 'none',
  },
  ...(locked && {
    svg: {
      transform: 'rotate(-180deg)',
    },
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: '#fcfcfc',
      border: '1px solid #f1f2f1',
      overflowX: 'hidden',
      boxSizing: 'border-box',
      ...(!open && {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(8),
        },
      }),
    },
  }),
);

const SideBar: React.FC<SideBarProps> = ({ drawerOpen, onDrawerClick }) => {
  return (
    <Drawer variant='permanent' open={drawerOpen} sx={{}}>
      <Tabs />

      <Lock onClick={onDrawerClick} locked={drawerOpen ? drawerOpen : undefined}>
        <IconButton onClick={onDrawerClick}>
          <ChevronRightIcon />
        </IconButton>
      </Lock>
    </Drawer>
  );
};

export default SideBar;
