import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueGetPreSignedUrlQuery = /* GraphQL */ `
  query VenueGetPreSignedUrl($ext: String) {
    venueGetPreSignedUrl(ext: $ext)
  }
`;

export const venueGetPreSignedUrlApi = async (ext: string): Promise<string> => {
  const result = await executeGql<string>(venueGetPreSignedUrlQuery, { ext });
  return processGqlResult<string>('venueGetPreSignedUrl', result);
};
