import React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { Link } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import styled from '@emotion/styled';

import { VennueAccessRequestItem, VenueAccessREnum } from '../../../graphql/API';
import LinkProfile from '../../../pages/Collabs/components/LinkProfile';
import Icons from '../../../themes/icons';
import { formatNumber } from '../../../utils/formatter';
import LazyAvatar from '../../Avatar/LazyAvatar/LazyAvatar';
import LoadingButton from '../../Button/LoadingButton';
import { OnAction } from '../../Tables/AccessRequest/useAction';
import { buttonLoadingState, mapToActionRequest } from '../../Tables/AccessRequest/utils';

type Props = {
  accessRequest: VennueAccessRequestItem;
  fullProfile: boolean;
  loadingState: Record<string, boolean>;
  onAction: OnAction;
};

export const AccessRequestUserProfileCard: React.FC<Props> = ({
  accessRequest,
  fullProfile,
  loadingState,
  onAction,
}) => {
  return (
    <Box display='flex' flexDirection='column'>
      <Box component={Link} to={`/users/${accessRequest.userID}`} position={'relative'}>
        <AspectRatio>
          <LazyAvatar
            variant='rounded'
            src={String(accessRequest?.user?.picture?.medium)}
            placeholderSrc={String(accessRequest?.user?.picture?.thumbnail)}
          />
        </AspectRatio>
        <RatingContainer rating={accessRequest?.user?.rating || 0}>
          <Icons.StarIcon />
          {!accessRequest?.user?.rating ? (
            <span>No rating yet</span>
          ) : (
            <span>{(accessRequest?.user?.rating || 0).toFixed(1)} Rating</span>
          )}
        </RatingContainer>
      </Box>

      <Typography
        component='div'
        variant='body2'
        fontWeight='bold'
        sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}
      >
        <LinkProfile
          fontSize='17px'
          fontWeight='600'
          url={`/users/${accessRequest.userID}`}
          label={`${accessRequest?.user?.firstname} ${accessRequest?.user?.lastname}`}
          hoverTextDecoration='underline'
        />

        <Box
          component='div'
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            fontSize: '15px',
            fontWeight: 'normal',
            marginTop: '5px',
          }}
        >
          {fullProfile && (
            <Box sx={{ mr: 1 }}>
              <LinkProfile
                url={`https:/instagram.com/${accessRequest?.user?.instagramHandle}`}
                label={`@${accessRequest?.user?.instagramHandle}`}
                target='_blank'
                fontSize='15px'
                fontWeight='500'
                color='#1E55A1'
              />
            </Box>
          )}
          <Box component='span' sx={{ fontSize: '15px', color: '#707070' }}>
            {formatNumber(accessRequest?.user?.instagramFollowers ?? 0)} Followers
          </Box>
        </Box>
      </Typography>
      <Box component='span' sx={{ fontSize: '15px', color: '#111', my: 0.2 }}>
        Requested access to {accessRequest.offer?.title}
      </Box>
      <Stack spacing={1} direction='row' mt={0.7}>
        <LoadingButton
          fullWidth
          variant='outlined'
          onClick={() => {
            onAction(mapToActionRequest(accessRequest ?? undefined, VenueAccessREnum.accepted));
          }}
          {...buttonLoadingState(loadingState, accessRequest, VenueAccessREnum.accepted)}
        >
          Accept
        </LoadingButton>
        <LoadingButton
          fullWidth
          variant='outlined'
          onClick={() => {
            onAction(mapToActionRequest(accessRequest ?? undefined, VenueAccessREnum.declined));
          }}
          {...buttonLoadingState(loadingState, accessRequest, VenueAccessREnum.declined)}
        >
          Reject
        </LoadingButton>
      </Stack>
    </Box>
  );
};

const RatingContainer = styled.div<{ rating: number }>`
  position: absolute;
  top: 2%;
  left: 2%;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.6);

  color: ${(props) => (props.rating > 4 || props.rating === 0 ? '#fff' : '#FF7282')};

  display: inline-flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;

  font-size: 15px;
`;
