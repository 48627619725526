import { VenueAccountBalanceRequest, VenueVerificatioURLResponse } from '../../graphql/API';
import { venueGetVerificationUrl } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetVerificationUrlApi = async (
  filter: VenueAccountBalanceRequest,
): Promise<VenueVerificatioURLResponse> => {
  const result = await executeGql(venueGetVerificationUrl, { filter });
  return processGqlResult<VenueVerificatioURLResponse>('venueGetVerificationUrl', result);
};
