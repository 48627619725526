import { Event as BigCalendarEvent } from 'react-big-calendar';

import dayjs, { Dayjs } from 'dayjs';
import { RRule, Weekday } from 'rrule';

import { DaysOfWeek, Offer } from '../../../graphql/API';
import colours from './colours';

export type Resource = {
  index: number;
  colour: string;
};

type WeekdayMap = {
  [key in DaysOfWeek]: Weekday;
};

const weekdayMap: WeekdayMap = {
  sunday: RRule.SU,
  monday: RRule.MO,
  tuesday: RRule.TU,
  wednesday: RRule.WE,
  thursday: RRule.TH,
  friday: RRule.FR,
  saturday: RRule.SA,
};

const toEvent =
  (todayDate: Dayjs, selectedDate: Dayjs) =>
  (offer: Offer, i: number): Event[] => {
    const startOfMonth = selectedDate.startOf('month');
    const endOfMonth = selectedDate.endOf('month');
    const offerStartDate = dayjs(offer.startDate);
    const offerEndDate = dayjs(offer.endDate || dayjs().add(3, 'M'));

    const displayStartDate = startOfMonth.isAfter(offerStartDate) ? startOfMonth : offerStartDate;
    const displayEndDate = endOfMonth.isBefore(offerEndDate) ? endOfMonth : offerEndDate;

    const colour = colours.getColor(i);

    const availableDays = (offer.availableDays?.map((x) => x && weekdayMap[x]).filter(Boolean) ||
      []) as Weekday[];

    const rule = new RRule({
      freq: RRule.WEEKLY,
      wkst: RRule.SU,
      byweekday: availableDays,
      dtstart: displayStartDate.toDate(),
      until: displayEndDate.toDate(),
    });

    const ddays = rule.all().map((x) => ({
      title: offer.title,
      start: x,
      end: x,
      allDay: false,
      resource: {
        index: i,
        colour: todayDate.isBefore(x) ? colour : 'grey',
      },
      status: offer.status,
    }));

    return ddays;
  };

export type Event = { resource: Resource } & BigCalendarEvent;

export const toEventResource = (e: BigCalendarEvent): Resource => e.resource;

export const mapToCalendarEvent = (data: Offer[], selectedDate: Date) => {
  const todayDate = dayjs();
  const selectedDateJS = dayjs(selectedDate);
  const events = data.map(toEvent(todayDate, selectedDateJS)).flat();

  return events;
};
