import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { useSnackbar } from 'notistack';

import { venueGetConversationWithUserApi } from '../../api/chat/venueGetConversationWithUserApi';
import { Chat } from '../../components/Chats/Chat';
import { ChatThreads } from '../../components/Chats/ChatThreads';
import { ConversationItem } from '../../graphql/API';
import useQueryParams from '../../hooks/useQueryParams';
import useVenueId from '../../hooks/useVenueId';

export const Chats = () => {
  const venueID = useVenueId();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { getQueryParam } = useQueryParams();
  const userID = getQueryParam('userID');
  const reservationID = getQueryParam('reservationID');
  const offerTitle = getQueryParam('offerTitle');

  const [selectedConversation, setSelectedConversation] = useState<ConversationItem | null>(null);

  const getConversationFromUserID = async (
    userID: string,
    reservationID: string,
    offerTitle: string,
  ) => {
    try {
      const conversation = await venueGetConversationWithUserApi({
        venueID: venueID || '',
        userID: userID || '',
        reservationID: reservationID || '',
        offerTitle: offerTitle || '',
      });
      setSelectedConversation(conversation);
    } catch (error) {
      console.error(error);

      enqueueSnackbar('Something went wrong please try again later.', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (userID && reservationID && offerTitle) {
      getConversationFromUserID(userID as string, reservationID as string, offerTitle as string);
    }
  }, [userID, reservationID, offerTitle]);

  const onConversationSelectionHandle = (conversation: ConversationItem) => {
    if (conversation.conversationId !== selectedConversation?.conversationId) {
      setSelectedConversation(conversation);
      if (userID || reservationID) {
        navigate('/chats');
      }
    }
  };

  return (
    <Box sx={{ height: '100%', bgcolor: '#fff', display: 'flex' }}>
      <Box
        sx={{
          flex: '0 1 25%',
          bgcolor: '#fff',
          borderRight: '1px solid #F1F2F1',
          height: '100%',
        }}
      >
        <ChatThreads
          selectedConversation={selectedConversation}
          onConversationSelectionHandle={onConversationSelectionHandle}
        />
      </Box>
      {selectedConversation && (
        <Box sx={{ flex: '1', height: '100%' }}>
          <Chat conversation={selectedConversation} />
        </Box>
      )}
    </Box>
  );
};
