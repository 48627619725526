import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

type Props = {
  listHeight?: number;
};

export const ListOfferEmptyPlaceholder: React.FC<Props> = ({ listHeight }) => {
  return (
    <Box
      sx={{
        height: listHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box display={'flex'} gap={'72px'} justifyContent={'center'} alignItems={'start'}>
        <Box>
          <img src='/svg/performance.svg' alt='top offers' />
        </Box>
        <Box>
          <Typography fontSize={'18px'} fontWeight={'600'}>
            See your most requested offers
          </Typography>
          <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>
            Offers with the most check-ins will be listed here.
          </Typography>
          <Button
            sx={{ marginTop: '30px' }}
            component={Link}
            to='/offers/create'
            variant='outlined'
          >
            Create an offer
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
