import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { TabOption } from './types';

type EmptyContentProps = {
  option: TabOption | null;
};

const mapContent: Record<TabOption, { src: string; text: string }> = {
  [TabOption.accepted]: {
    src: '/png/access-accepted-empty@2x.png',
    text: 'Users you granted access to will show up here',
  },
  [TabOption.rejected]: {
    src: '/png/access-rejected-empty@2x.png',
    text: 'Users you did not grant access to will be listed here',
  },
  [TabOption.blocked]: {
    src: '/png/access-blocked-empty@2x.png',
    text: "Users you've blocked will be displayed here",
  },
};

const EmptyContent: React.FC<EmptyContentProps> = ({ option }) => {
  const content = React.useMemo(() => {
    if (option) {
      return mapContent[option];
    }
    return undefined;
  }, [option]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box component='img' src={content?.src} />
      <Typography sx={{ width: '200px', textAlign: 'center', marginTop: 4 }}>
        {content?.text}
      </Typography>
    </Box>
  );
};
export default EmptyContent;
