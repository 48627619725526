import { VenueListReservationsCountFilter } from '../../graphql/API';
import { venueListReservationsCount } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListReservationsCountApi = async (
  filter: VenueListReservationsCountFilter,
): Promise<Record<string, number>> => {
  const result = await executeGql<string>(venueListReservationsCount, { filter });
  const json = JSON.parse(processGqlResult<string>('venueListReservationsCount', result));
  return json;
};
