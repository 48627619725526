import * as React from 'react';
import { Link } from 'react-router-dom';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';

import { type MRT_ColumnDef } from 'material-react-table';

import { VennueAccessRequestItem, VenueAccessREnum } from '../../../graphql/API';
import { getUserCategory } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import LoadingButton from '../../Button/LoadingButton';
import StatusLabel from '../../StatusLabel/StatusLabel';
import { OnAction } from './useAction';
import { buttonLoadingState, mapToActionRequest } from './utils';

type UseColumnProps = {
  loadingState: Record<string, boolean>;
  onAction: OnAction;
};

const useColumn: (props: UseColumnProps) => MRT_ColumnDef<VennueAccessRequestItem>[] = ({
  loadingState,
  onAction,
}) => [
  {
    accessorKey: 'user.picture.thumbnail',
    header: '',
    size: 80,
    Cell: ({ renderedCellValue, row }) =>
      renderedCellValue ? (
        <UserAvatarCategory
          src={String(renderedCellValue)}
          userCategory={getUserCategory(row.original.user?.tags ?? [])}
        />
      ) : null,
  },
  {
    accessorFn: (row) =>
      row.user?.firstname || row.user?.lastname
        ? `${row.user?.firstname} ${row.user?.lastname}`
        : undefined,
    header: 'Name',
    size: 150,
    Cell: ({ renderedCellValue, row }) => (
      <Box
        component={Link}
        to={`/users/${row.original.userID}`}
        sx={{
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
          color: '#1E55AA',
        }}
      >
        {renderedCellValue}
      </Box>
    ),
  },
  {
    accessorKey: 'offer.title',
    header: 'Request access for',
    size: 200,
  },
  {
    accessorFn: (row) => getUserCategory(row.user?.tags ?? []),
    header: 'Member Category',
    size: 150,
    Cell: ({ renderedCellValue }) =>
      renderedCellValue && (
        <StatusLabel type='userCategory' status={String(renderedCellValue)} emptyBackground />
      ),
  },
  {
    accessorKey: 'id',
    header: '',
    size: 80,
    Cell: ({ row }) => (
      <Stack spacing={2} direction='row'>
        <LoadingButton
          fullWidth
          variant='outlined'
          onClick={() => {
            onAction(mapToActionRequest(row.original ?? undefined, VenueAccessREnum.declined));
          }}
          {...buttonLoadingState(loadingState, row.original, VenueAccessREnum.declined)}
        >
          Reject
        </LoadingButton>
        <LoadingButton
          fullWidth
          variant='contained'
          onClick={() => {
            onAction(mapToActionRequest(row.original ?? undefined, VenueAccessREnum.accepted));
          }}
          {...buttonLoadingState(loadingState, row.original, VenueAccessREnum.accepted)}
        >
          Accept
        </LoadingButton>
      </Stack>
    ),
  },
];

export default useColumn;
