import * as React from 'react';

import { Alert, Box, Tab, Tabs } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import { venueActionAccessRequestApi } from '../../../../api/accessRequest/venueActionAccessRequestApi';
import {
  UserAccessRequestResultValue,
  VenueListAccessRequetsFilter,
} from '../../../../graphql/API';
import useVenueId from '../../../../hooks/useVenueId';
import { OptionEntry } from '../../../../utils/options';
import ListAccessRequest, { ListAccessProps } from '../../../List/ListAccess/ListAccess';
import { OnAction, isVenueActionAccessInput } from '../../../List/ListAccess/types';
import EmptyContent from './EmptyContent';
import { getSuccessMessage } from './helper';
import useQuery from './hooks';
import { TabOption } from './types';
import { venueUnblockUserApi } from '../../../../api/users/venueUnblockUserApi';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const tabOptions: OptionEntry[] = [
  { label: 'Accepted', value: TabOption.accepted },
  { label: 'Rejected', value: TabOption.rejected },
  { label: 'Blocked', value: TabOption.blocked },
];

const ListAccessRequestContent: React.FC = () => {
  const venueId = useVenueId();
  const [filters, setFilters] = React.useState<Partial<VenueListAccessRequetsFilter>>({
    status: UserAccessRequestResultValue.accepted,
  });
  const [loading, setLoading] = React.useState<Record<string, boolean>>({});
  const [dictSuccessText, setSuccessText] = React.useState<Record<string, React.ReactNode | null>>(
    {},
  );

  const queryResult = useQuery(filters);
  const queryClient = useQueryClient();

  const value = React.useMemo(() => {
    const i = tabOptions.findIndex((x) => x.value === filters.status);

    return i;
  }, [filters]);

  const handleChange = React.useCallback((event: React.SyntheticEvent, newValue: number) => {
    const status = tabOptions[newValue]?.value as UserAccessRequestResultValue | undefined;

    setFilters({ status });
  }, []);

  const handleAction = React.useCallback<OnAction>(
    async (input) => {
      if (!input) {
        return;
      }
      const isVenueAccessAction = isVenueActionAccessInput(input);
      const key = isVenueAccessAction
        ? `${input.userID}:${input.offerID}`
        : `${input.userID}:${input.venueID}`;

      setLoading((x) => ({ ...x, [key]: true }));

      if (isVenueAccessAction) {
        await venueActionAccessRequestApi(input);
      } else {
        await venueUnblockUserApi(input);
      }

      const queryKey = isVenueAccessAction ? 'list-access-request' : 'list-blocked-users';

      await queryClient.invalidateQueries({ queryKey: [queryKey] });

      setSuccessText({
        [filters.status ?? '']: getSuccessMessage(
          String(filters.status) as TabOption,
          queryResult.data,
          input,
        ),
      });
      setLoading((x) => ({ ...x, [key]: false }));
    },
    [queryClient, filters.status, queryResult.data],
  );

  const successText = React.useMemo(
    () => dictSuccessText[String(filters.status) as TabOption],
    [dictSuccessText, filters.status],
  );

  const renderContent = React.useCallback(
    (props: ListAccessProps) => {
      if (props.queryResult.isLoading || props.queryResult.data.length > 0) {
        return <ListAccessRequest {...props} />;
      }

      return <EmptyContent option={(filters.status ?? null) as TabOption | null} />;
    },
    [filters.status],
  );

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          {tabOptions.map((x, i) => (
            <Tab key={x.value} sx={{ width: '33%' }} label={x.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, flex: '1', overflowY: 'auto' }}>
        {renderContent({
          queryResult,
          venueId: Number(venueId),
          onAction: handleAction,
          loadingState: loading,
        })}
        {successText && <Alert severity='success'>{successText}</Alert>}
      </Box>
    </Box>
  );
};
export default ListAccessRequestContent;
