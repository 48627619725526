import * as React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { User, VenueListUserFvouritesFilter } from '../../../graphql/API';
import { QueryResult } from '../../../hooks/reactQuery/types';
import { useAppSelector } from '../../../redux/hooks';
import { venueListUserFavouritesApi } from '../../../api/users/venueListUserFavouritesApi';

const PAGE_LIMIT = 50;

const useListVenueFavouritesUsersQuery = (): QueryResult<User[]> => {
  const venueState = useAppSelector((state) => state.venue.value);

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<User[]>({
    initialPageParam: 0,
    queryKey: ['list-users-favourites', venueState?.id],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID] = queryKey as [string, string, Partial<VenueListUserFvouritesFilter>];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListUserFavouritesApi({
        limit: PAGE_LIMIT,
        offset,
        venueID: Number(venueID),
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueState?.id,
    refetchOnWindowFocus: false,
  });

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useListVenueFavouritesUsersQuery;
