import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { venueGetAccountBalanceApi } from '../../../api/balance/venueGetAccountBalanceApi';
import { AccountBalanceResponse, VenueAccountBalanceRequest } from '../../../graphql/API';

export const fetchAccountBalance = createAsyncThunk(
  'balance/fetchAccountBalance',
  async (filter: VenueAccountBalanceRequest) => {
    const data = await venueGetAccountBalanceApi(filter);
    return data;
  },
);

export interface BalanceState {
  value?: AccountBalanceResponse;
  isLoading: boolean;
}

const initialState: BalanceState = {
  value: undefined,
  isLoading: true,
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    reset: () => {},
    handleBalanceIsLoading: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountBalance.pending, (state, action) => {
      state.value = action.payload;
      state.isLoading = true;
    });
    builder.addCase(fetchAccountBalance.fulfilled, (state, action) => {
      state.value = action.payload;
      state.isLoading = false;
    });
  },
});

export const { reset, handleBalanceIsLoading } = balanceSlice.actions;

export default balanceSlice.reducer;
