import React from 'react';

import { Button } from '@mui/material';

import { useModal } from 'mui-modal-provider';

import { useVenueGetVerificationUrl } from '../hooks/reactQuery/balance/useVenueGetVerificationUrl';
import useVenueId from '../hooks/useVenueId';
import {
  fetchAccountBalance,
  handleBalanceIsLoading,
} from '../redux/features/balance/balanceSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Icons from '../themes/icons';
import { delay } from '../utils/delay';
import AddBalanceDialog from './Dialogs/AddBalance/AddBalanceDialog';
import { AddFundsStripeDialog } from './Dialogs/AddFundsStripe/AddFundsStripeDialog';
import StripeAccountVerifyDialog from './Dialogs/StripeAccountVerify/StripeAccountVerifyDialog';

export const AddFundsButton = () => {
  const venueID = useVenueId();
  const appDispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.balance.value) || {};
  const { showModal } = useModal();

  const { data: verficationData } = useVenueGetVerificationUrl(
    { venueID: venueID || '' },
    status === 'inactive',
  );

  const [successAmount, setSuccessAmount] = React.useState(0);
  const [totalAmountWithFee, setTotalAmountWithFee] = React.useState(0);

  const [stripeDialog, setStripeDialog] = React.useState(false);

  const handleStripeDialog = () => {
    setStripeDialog((prev) => !prev);
  };

  const handleAddFundsDialog = () => {
    if (status === 'inactive') {
      return showModal(StripeAccountVerifyDialog, {
        onVerify: () => {
          return window.open(verficationData?.onBoardingURL, '_self');
        },
      });
    }

    showModal(AddBalanceDialog, {
      onSubmit: (totalAmountWithFee) => {
        setTotalAmountWithFee(totalAmountWithFee as number);
        handleStripeDialog();
      },
      getOriginalPriceHandle: (amount) => {
        setSuccessAmount(amount as number);
      },
    });
  };

  return (
    <>
      <Button
        variant='contained'
        size='large'
        fullWidth
        onClick={handleAddFundsDialog}
        sx={{
          paddingY: '18px',
        }}
        startIcon={<Icons.BalanceIcon />}
      >
        +Add Funds
      </Button>
      <AddFundsStripeDialog
        open={stripeDialog}
        onClose={handleStripeDialog}
        amount={totalAmountWithFee}
        amountWithoutFee={successAmount}
        onSuccess={() => {
          appDispatch(handleBalanceIsLoading());
          delay(10000).then(() => {
            appDispatch(fetchAccountBalance({ venueID: venueID || '' }));
          });
        }}
      />
    </>
  );
};
