import React from 'react';

import { Box, Typography } from '@mui/material';

import { User } from '../../../graphql/API';
import LinkProfile from '../../../pages/Collabs/components/LinkProfile';
import { formatNumber } from '../../../utils/formatter';
import { getUserCategory } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import Button from '../../Button/Button';

type Props = {
  user: User;
  onInvite: () => void;
};

export const FavouriteItem: React.FC<Props> = ({ user, onInvite }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderBottom={'1px solid #F2F2F2'}
      paddingY={2}
    >
      <Box display={'flex'}>
        <UserAvatarCategory
          sx={{
            height: '52px',
            width: '52px',
          }}
          src={user?.picture?.thumbnail || ''}
          userCategory={getUserCategory(user?.tags ?? [])}
        />
        <Box marginLeft={2} marginTop={0.4}>
          <LinkProfile
            url={`/users/${user.id}`}
            label={`${user.firstname} ${user.lastname}`}
            hoverTextDecoration='underline'
            fontSize='15px'
            fontWeight='bold'
          />
          <Typography fontSize={'15px'} color={'#808080'}>
            {formatNumber(user.instagramFollowers ?? 0)} Followers
          </Typography>
        </Box>
      </Box>
      <Button variant='contained' size='small' sx={{ px: 4 }} onClick={onInvite}>
        Invite
      </Button>
    </Box>
  );
};
