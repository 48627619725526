import React from 'react';

import { Box, Grid } from '@mui/material';

import PageTitle from '../../components/PageTitle/PageTitle';
import { Gallery } from '../../components/Photoswipe/Photoswipe';
import PortfolioPanel from './components/PortfolioPanel';
import ProfilePanel from './components/ProfilePanel';

const Profile: React.FC = () => {
  return (
    <Box px={4} py={2} sx={{ backgroundColor: 'white', flex: 1 }}>
      <PageTitle title='Profile' />

      <Box mt={2} />

      <Gallery>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <ProfilePanel />
          </Grid>
          <Grid item xs={8}>
            <PortfolioPanel />
          </Grid>
        </Grid>
      </Gallery>
    </Box>
  );
};

export default Profile;
