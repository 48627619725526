import * as React from 'react';

import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

import styled from '@emotion/styled';

import Icons from '../../../../themes/icons';
import { formatNumberWithCommas } from '../../../../utils/formatter';
import { CurrencySign } from '../../../CurrencySign/CurrencySign';
import { useCreateInvite } from '../context/CreateInvite.context';
import { CreateInviteProviderProps } from '../context/CreateInvite.provider';

type Props = {
  modalRef?: CreateInviteProviderProps['modalRef'];
};

const SuccessBuy: React.FC<Props> = ({ modalRef }) => {
  const { state, mutation } = useCreateInvite();

  const totalOfferPrice = React.useMemo(
    () =>
      state.usersWithOfferPrice?.reduce(
        (acc, { offerPrice }) => (acc = acc + (offerPrice || 0)),
        0,
      ),
    [state.usersWithOfferPrice],
  );

  return mutation.isSuccess ? (
    <Box>
      <Box mt={4} display={'flex'} alignItems='center' justifyContent={'center'}>
        <Box component='img' src={'/png/success.png'} sx={{ height: 'auto', objectFit: 'cover' }} />
      </Box>

      <Box px={6} mt={6}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={4}>
          <Box display={'flex'} alignItems={'center'} gap={3}>
            <LightBalanceText>Starting Balance</LightBalanceText>
          </Box>
          <LightBalanceText>
            <CurrencySign currency={'usd'} />
            {formatNumberWithCommas(+(state.previousTotalBalance || 0))}
          </LightBalanceText>
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={4}>
          <LightBalanceText>Total</LightBalanceText>
          <LightBalanceText>
            <CurrencySign currency={'usd'} />
            {formatNumberWithCommas(totalOfferPrice || 0)}
          </LightBalanceText>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          my={2}
          sx={{ borderTop: '1px solid #E5E5E5', py: 3 }}
        >
          <BalanceText fontSize='19px'>Ending Balance</BalanceText>
          <BalanceText fontSize='19px'>
            <CurrencySign currency={'usd'} />
            {formatNumberWithCommas(
              Number(state.previousTotalBalance || 0) - (totalOfferPrice || 0),
            )}
          </BalanceText>
        </Box>

        <Button
          fullWidth
          variant='contained'
          sx={{ mt: 5 }}
          onClick={() => {
            modalRef?.current?.destroy();
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  ) : (
    <Box display='flex' justifyContent='center' height='100%' alignItems='center'>
      <CircularProgress />
    </Box>
  );
};

export default SuccessBuy;

const BalanceText = styled.span<{ fontSize?: string }>`
  display: inline-block;
  color: #111;
  font-size: ${(props) => props.fontSize || '15px'};
  font-weight: 500;
  line-height: 19px;
`;

const LightBalanceText = styled.span`
  display: inline-block;
  color: #848484;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
`;
