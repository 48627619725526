import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { venueListPricingApi } from '../../../api/stripe/venueListPricingApi';
import { venueListSubscriptionsApi } from '../../../api/stripe/venueListSubscriptionsApi';
import { VenueSubscriptionItem } from '../../../graphql/API';
import { Plan } from '../../../pages/SubscriptionPlans/types';
import { ACCESS, mapAccessWithPlan } from './access';
import { StripePriceWithMetadata } from './types';

export const fetchSubscription = createAsyncThunk(
  'subscription/fetchSubscription',
  venueListSubscriptionsApi,
);

export const fetchStripePricing = createAsyncThunk(
  'subscription/fetchStripePricing',
  venueListPricingApi,
);

export interface StripeState {
  value: {
    isLoading: boolean;
    premium?: boolean;
    plan?: Plan;
    subscription?: VenueSubscriptionItem;
    pricing?: StripePriceWithMetadata[];
    access?: ACCESS[];
  };
}

const initialState: StripeState = {
  value: { isLoading: true },
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: () => initialState,
    updatePremium: (state, action: PayloadAction<boolean>) => {
      state.value.premium = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      const [first] = action.payload;

      if (first) {
        const subsItem: VenueSubscriptionItem = {
          ...first,
          sku: first.sku.replace('premium', 'plus'),
          productGroup: first.productGroup.replace('premium', 'plus'),
        };

        const plan = subsItem.productGroup.split('_')[0] as Plan;
        state.value.plan = plan;
        state.value.subscription = subsItem;
        state.value.access = mapAccessWithPlan[plan];
      }

      state.value.isLoading = false;
    });
    builder.addCase(fetchStripePricing.fulfilled, (state, action) => {
      if (!state.value.premium) {
        state.value.isLoading = false;
      }

      state.value.pricing = action.payload.map(
        (x) =>
          ({ ...x, metadata: x.metadata ? JSON.parse(x.metadata) : {} }) as StripePriceWithMetadata,
      );
    });
  },
});

export const { reset, updatePremium } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
