import {
  VenueGetCollabEstimateFilter,
  VenueGetCollabEstimateQuery,
  VenueGetCollabEstimateResponse,
} from '../../graphql/API';
import { venueGetCollabEstimate } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetCollabEstimateApi = async (
  filter: VenueGetCollabEstimateFilter,
): Promise<VenueGetCollabEstimateResponse> => {
  const result = await executeGql<VenueGetCollabEstimateQuery>(venueGetCollabEstimate, { filter });
  return processGqlResult('venueGetCollabEstimate', result);
};
