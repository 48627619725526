import { VenueListRatedUsersFilter, VenueRatedUser } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListRatedUsers = /* GraphQL */ `
  query VenueListRatedUsers($filter: VenueListRatedUsersFilter!) {
    venueListRatedUsers(filter: $filter) {
      items {
        averageRatings
        firstname
        id
        instagramFollowers
        instagramHandle
        lastname
        tags
        totalRatings
        updatedAt
        picture {
          large
          medium
          small
          thumbnail
        }
        __typename
      }
      __typename
    }
  }
`;

export const venueListRatedUserApi = async (
  filter: VenueListRatedUsersFilter,
): Promise<VenueRatedUser[]> => {
  const result = await executeGql<VenueRatedUser[]>(venueListRatedUsers, {
    filter,
  });
  return processGqlResult<VenueRatedUser[]>('venueListRatedUsers.items', result) ?? [];
};
