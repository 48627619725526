import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  checked?: boolean;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
};

export const RadioContentBuy: React.FC<Props> = ({
  label,
  checked = false,
  icon,
  checkedIcon,
  onClick,
}) => {
  const style = React.useMemo(
    () => (checked ? { borderWidth: '2px', borderColor: '#111111' } : undefined),
    [checked],
  );
  return (
    <Box
      sx={{
        borderRadius: '10px',
        border: '1px solid #ECECEC',
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        padding: '20px 18px',
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
    >
      {checked ? checkedIcon ?? icon : icon}
      <Typography
        fontWeight={checked ? 600 : 400}
        sx={{ fontSize: '15px', color: checked ? '#111' : '#707070' }}
      >
        {label}
      </Typography>
    </Box>
  );
};
