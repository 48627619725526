import * as React from 'react';

import { debounce } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import {
  DateCalendar as MuiDateCalendar,
  DateCalendarProps as MuiDateCalendarProps,
} from '@mui/x-date-pickers/DateCalendar';

import dayjs, { Dayjs } from 'dayjs';

const pickDate = (values: Dayjs[], value: Dayjs) => {
  const found = values.find((x) => x.isSame(value, 'date'));
  if (found) {
    const dates = values.filter((x) => !x.isSame(value, 'date'));
    return dates;
  }

  return [...values, value];
};

const DateCalendar: React.FC<MuiDateCalendarProps<Dayjs[]>> = ({ defaultValue, onChange }) => {
  const [selected, setSelected] = React.useState<Dayjs[]>(defaultValue ?? []);

  const debounceOnChange = React.useMemo(
    () =>
      debounce((dates: Dayjs[]) => {
        onChange && onChange(dates);
      }, 300),
    [onChange],
  );

  const handleChange = React.useCallback(
    (value: Dayjs | null) => {
      if (value) {
        setSelected((x) => {
          const dates = pickDate(x, value);

          debounceOnChange(dates);

          return dates;
        });
      }
    },
    [setSelected, debounceOnChange],
  );

  return (
    <MuiDateCalendar
      disableHighlightToday
      minDate={dayjs()}
      sx={{
        border: '1px solid #ededed',
      }}
      slots={{
        day: (props) => {
          const { day } = props;

          const found = Boolean(selected.find((x) => x.isSame(day, 'date')));

          return <PickersDay {...props} selected={found} />;
        },
      }}
      onChange={handleChange}
      maxDate={dayjs().add(1, 'month')}
    />
  );
};

export default DateCalendar;
