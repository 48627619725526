import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useUrlQuery from './useUrlQuery';

export const useFilter = <T extends Record<string, unknown>>(
  filterDefaultValues: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const query = useUrlQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = React.useState<T>(() =>
    Object.keys(filterDefaultValues).reduce<T>((acc, key) => {
      const queryItem = query.get(key);

      if (queryItem) {
        if (key === 'tags' || key === 'resources') {
          const newArrayVal = typeof queryItem === 'string' ? queryItem.split(',') : [];
          return { ...acc, [key]: newArrayVal };
        } else {
          return { ...acc, [key]: queryItem as string };
        }
      }

      return acc;
    }, filterDefaultValues),
  );

  React.useEffect(() => {
    const queryString = Object.entries(filterDefaultValues).reduce((acc, [key, defaultValue]) => {
      const filterValue = filter[key];

      if (filterValue !== undefined && filterValue !== defaultValue) {
        return ((acc && acc + '&') || '?') + key + '=' + filterValue;
      }

      return acc;
    }, '');

    navigate(`${location.pathname}${queryString}`, {
      replace: true,
    });
  }, [filter]);

  return [filter, setFilter];
};
