import * as React from 'react';

import BaseDialog, { BaseDialogWithChildrenProps } from '../../Base';
import { ConfirmDialogContentProps } from '../context/types';
import ConfirmDialogContent from './ConfirmDialogContent';

const ConfirmDialog: React.FC<BaseDialogWithChildrenProps<ConfirmDialogContentProps>> = ({
  childrenProps,
  ...restProps
}) => {
  return (
    <BaseDialog height='265px' width='450px' {...restProps}>
      <ConfirmDialogContent {...childrenProps} />
    </BaseDialog>
  );
};

export default ConfirmDialog;
