import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// Define the type for the state and the hook
type UseLocalStorageStateReturnType<T> = [T, Dispatch<SetStateAction<T>>];

// Create the hook
export const usePersistLocalStorageState = <T>(
  key: string,
  defaultValue: T,
): UseLocalStorageStateReturnType<T> => {
  // Retrieve the initial state from localStorage
  const getInitialValue = (): T => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  // Use state with a default value
  const [state, setState] = useState<T>(getInitialValue);

  // Update localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
