import { Reducer } from 'react';

import { random } from 'lodash';

import { User, VenueListUsersFilter } from '../../graphql/API';
import { usePersistedReducer } from '../../hooks/usePresistReducer';

type State = {
  selected: { values: string[]; users: User[] };
  filters: Partial<VenueListUsersFilter> | undefined;
};

type Action =
  | { type: 'SET_SELECTED_USERS'; payload: { values: string[]; users: User[] } }
  | { type: 'RESET_SELECTED_USERS' }
  | { type: 'SET_FILTERS'; payload: Partial<VenueListUsersFilter> | undefined }
  | { type: 'RESET_SELECTED_FILTERS' };

export const collabsDefaultFilters: Partial<VenueListUsersFilter> | undefined = {
  salt: random(10000, 999999),
  market: true,
  minFollowers: undefined,
  instagram: undefined,
  lifestyle: undefined,
  modelType: undefined,
};

const initialState: State = {
  selected: {
    values: [],
    users: [],
  },
  filters: collabsDefaultFilters,
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_USERS':
      return { ...state, selected: action.payload };
    case 'RESET_SELECTED_USERS':
      return {
        ...state,
        selected: initialState.selected,
      };
    case 'SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'RESET_SELECTED_FILTERS':
      return {
        ...state,
        filters: initialState.filters,
      };
    default:
      return state;
  }
};

const useCollabsReducer = () => usePersistedReducer(reducer, initialState, 'collabReducer');

export type CollabReducer = ReturnType<typeof useCollabsReducer>;

export default useCollabsReducer;
