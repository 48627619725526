import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, IconButton, SxProps, Theme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

export type BaseDialogProps = {
  titleComponent?: React.ReactNode;
  sideComponent?: React.ReactNode;
  hideHeader?: boolean;
  height?: string;
  width?: string;
  minHeight?: string;
  padding?: string;
  iconSx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  showCloseIcon?: boolean;
} & DialogProps;

export type BaseDialogWithChildrenProps<T = unknown> = {
  childrenProps: T;
} & BaseDialogProps;

const BaseDialog: React.FC<React.PropsWithChildren<BaseDialogProps>> = ({
  title,
  titleComponent,
  sideComponent,
  onClose,
  open,
  children,
  hideHeader,
  width,
  height,
  minHeight,
  sx,
  padding,
  iconSx,
  titleSx,
  showCloseIcon = true,
  ...restProps
}) => {
  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          onClose && onClose({}, 'escapeKeyDown');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onClose]);

  const handleClose = () => onClose && onClose({}, 'backdropClick');

  const renderChild = (sx?: SxProps<Theme>, titleSx?: SxProps<Theme>, showCloseIcon = true) => {
    return (
      <>
        {!hideHeader && (
          <DialogTitle sx={{ ...titleSx }}>
            {titleComponent || title}
            {showCloseIcon && (
              <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.primary.dark,
                  ...sx,
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </>
    );
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth='md'
      sx={{
        '.MuiDialog-paper': {
          height: height ?? '600px',
          width,
          maxWidth: width ? '1200px' : '900px',
          padding,
          minHeight,
        },
        ...sx,
      }}
      {...restProps}
    >
      {sideComponent ? (
        <Box display='flex' flexDirection='row'>
          <Box>{sideComponent}</Box>
          <Box>{renderChild(iconSx, titleSx, showCloseIcon)}</Box>
        </Box>
      ) : (
        renderChild(iconSx, titleSx, showCloseIcon)
      )}
    </Dialog>
  );
};

export default BaseDialog;
