import { NotificationsContext } from '.';
import { ReservationStatus } from '../../graphql/API';
import { useListInvitesV2Query } from '../../hooks/reactQuery/useListInvitesV2Query';
import useListReservationContentQuery from '../../hooks/reactQuery/useListReservationContentQuery';
import useReservationQuery from '../../hooks/reactQuery/useReservationQuery';
import { usePersistLocalStorageState } from '../../hooks/usePersistLocalStorageState';
import useVenueId from '../../hooks/useVenueId';

const NotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const venueID = useVenueId() || '';
  const [lastReservationCreatedAtTime, setLastReservationCreatedAtTime] =
    usePersistLocalStorageState('lastReservationCreatedAtTime', '');
  const [lastCollabUpdatedAtTime, setLastCollabUpdatedAtTime] = usePersistLocalStorageState(
    'lastCollabUpdatedAtTime',
    '',
  );
  const [lastContentUpdatedAtTime, setLastContentUpdatedAtTime] = usePersistLocalStorageState(
    'lastContentUpdatedAtTime',
    '',
  );

  const { data: reservations } = useReservationQuery(
    { status: [ReservationStatus.reserved] },
    venueID,
    1,
  );
  const { data: invites } = useListInvitesV2Query(
    venueID || '',
    {
      sortBy: 'invites.updatedAt',
      sortDirection: 'desc',
    },
    1,
  );

  const { data: contents } = useListReservationContentQuery({ byVenue: false }, 1);

  const handleUpdateInvite = (val: string) => {
    setLastCollabUpdatedAtTime(val);
  };

  const handleUpdateReservation = (val: string) => {
    setLastReservationCreatedAtTime(val);
  };

  const handleUpdateContent = (val: string) => {
    setLastContentUpdatedAtTime(val);
  };

  return (
    <NotificationsContext.Provider
      value={{
        lastCollabUpdatedAtTime,
        lastReservationCreatedAtTime,
        lastContentUpdatedAtTime,
        reservation: reservations.length ? reservations[0] : null,
        invite: invites.length ? invites[0] : null,
        content: contents.length ? contents[0] : null,
        handleUpdateInvite,
        handleUpdateReservation,
        handleUpdateContent,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
