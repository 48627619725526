import { ComplaintItemInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueCreateComplaintMutation = /* GraphQL */ `
  mutation venueCreateComplaint($input: VenueCreateComplaintInput!) {
    venueCreateComplaint(input: $input)
  }
`;

export const venueCreateComplaintApi = async (items: ComplaintItemInput[]): Promise<void> => {
  await executeGql(venueCreateComplaintMutation, {
    input: {
      items,
    },
  });
};
