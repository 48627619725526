import React from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  LinearProgress,
  styled as MuiStyled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  tableCellClasses,
} from '@mui/material';

import styled from '@emotion/styled';
import { InfiniteQueryObserverBaseResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useModal } from 'mui-modal-provider';

import { AccountTransaction, TransactionListFilter } from '../../../graphql/API';
import Icons from '../../../themes/icons';
import { centsToDollars, formatNumberWithCommas } from '../../../utils/formatter';
import { TransactionDetailsDialog } from '../../Dialogs/TransactionDetails/TransactionDetailsDialog';
import UsageRightsDialog from '../../Dialogs/UsageRights/UsageRightsDialog';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import StatusLabel from '../../StatusLabel/StatusLabel';

const StyledTableHeadCell = MuiStyled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8f8',
    color: '#848484',
    border: '1px solid #f8f8f8',
    fontSize: '15px',
    fontWeight: 500,
    padding: '11px 0',
    margin: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '15px',
    padding: '16px 0',
  },
}));

const StyledTableCell = MuiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#848484',
    fontSize: '15px',
    fontWeight: 400,
    padding: '0 0 4px 0',
    margin: 0,
    border: '1px solid #F8F8F8',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '15px',
    padding: '16px 0',
  },
}));

const StyledTableRow = MuiStyled(TableRow)(() => ({
  '&': {
    backgroundColor: 'transparent',
  },
  '& td, & th': {
    borderBottom: '1px solid #E0E0E0',
  },
}));

type Props = {
  filter: TransactionListFilter;
  setFilter: React.Dispatch<React.SetStateAction<TransactionListFilter>>;
  data: AccountTransaction[];
} & Pick<
  InfiniteQueryObserverBaseResult,
  'isFetching' | 'isLoading' | 'fetchNextPage' | 'hasNextPage'
>;

const headerCells = [
  {
    id: 'picture',
    label: '',
    sortable: false,
    style: { borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' },
    align: 'left',
  },
  {
    id: 'transactionLedger.title',
    label: 'Type',
    sortable: true,
    style: {},
    align: 'left',
  },
  { id: 'userFirstname', label: 'User', sortable: true, style: {}, align: 'left' },
  { id: 'transactionLedger.createdAt', label: 'Date', sortable: true, style: {}, align: 'left' },
  {
    id: 'transactionLedger.amount',
    label: 'Balance',
    sortable: true,
    style: {},
    align: 'right',
  },
  {
    id: 'transactionLedger.status',
    label: 'Status',
    sortable: true,
    style: { borderTopRightRadius: '8px', borderBottomRightRadius: '8px' },
    align: 'center',
  },
] as const;

export enum TransactionTiltes {
  marketplaceFee = 'marketplace fee',
  contentPurchased = 'content purchased',
  fee = 'fee',
  deposit = 'deposit',
  collab = 'collab',
}

export const BalanceHistory: React.FC<Props> = ({
  data,
  isFetching,
  isLoading,
  hasNextPage,
  fetchNextPage,
  filter,
  setFilter,
}) => {
  const { showModal } = useModal();

  const handleTransactionDetails = (transaction: AccountTransaction) => {
    showModal(TransactionDetailsDialog, {
      transaction,
      onLicense: () => {
        showModal(UsageRightsDialog);
      },
    });
  };

  return (
    <Box sx={{ marginTop: '34px', height: 'calc(100vh - 502px)' }}>
      <Heading>History</Heading>
      <TableContainer component={'div'} sx={{ marginTop: 1, height: '100%' }}>
        <Table stickyHeader sx={{ minWidth: '100%' }} aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              {headerCells.map((header) => (
                <StyledTableHeadCell style={{ ...header.style }} align={header.align}>
                  {header.sortable ? (
                    <TableSortLabel
                      active={header.id === filter?.sortBy}
                      direction={filter?.sortDirection as 'desc' | 'asc'}
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          sortDirection: filter?.sortDirection === 'asc' ? 'desc' : 'asc',
                          sortBy: header.id as string,
                        }));
                      }}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </StyledTableHeadCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <InfiniteScroll
              showLoading={false}
              isLoading={isLoading}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
            >
              {data.map((transaction, index) => (
                <StyledTableRow
                  key={`${index}-${transaction.createdAt}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleTransactionDetails(transaction);
                  }}
                >
                  <StyledTableCell style={{ maxWidth: 36 }}>
                    {transaction.tranType === 'debit' &&
                    (transaction.title?.toLowerCase() === TransactionTiltes.fee ||
                      transaction.title?.toLowerCase() === TransactionTiltes.marketplaceFee) ? (
                      <Icons.BeautyPassIcon style={{ width: '30px', height: '30px' }} />
                    ) : transaction.tranType === 'credit' ? (
                      <Icons.BalanceIcon style={{ width: '30px', height: '30px' }} />
                    ) : (
                      <Avatar
                        variant='rounded'
                        alt={transaction?.userFirstname || ''}
                        src={transaction.image?.thumbnail || ''}
                        sx={{ borderRadius: '50%' }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 96, textTransform: 'capitalize' }}>
                    {transaction.title}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 144 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {transaction.userFirstname && transaction.userLastname
                        ? `${transaction.userFirstname} ${transaction.userLastname}`
                        : '-'}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 44 }}>
                    {dayjs(transaction.createdAt).format('ll')}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 96 }} align='right'>
                    {transaction?.amount && transaction.amount < 0 ? (
                      <span
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        -$
                        {formatNumberWithCommas(
                          +centsToDollars(transaction.amount || 0)
                            .toString()
                            .replace('-', ''),
                        )}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: '#37BB90',
                          fontWeight: 500,
                        }}
                      >
                        +${formatNumberWithCommas(+centsToDollars(transaction.amount || 0))}
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 146 }} align='center'>
                    <StatusLabel
                      type='transactionStatus'
                      status={transaction.status}
                      emptyBackground
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </InfiniteScroll>
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && isFetching && <LinearProgress sx={{ my: 2 }} />}
    </Box>
  );
};

const Heading = styled.h1`
  color: #111;
  font-size: 18px;
  font-weight: 500;
`;
