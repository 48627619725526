import * as React from 'react';

import { Stack } from '@mui/material';
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiRadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';

import { CycleOptions as Options } from '../../../../../pages/SubscriptionPlans/common/helper';
import RadioContent from './RadioContent';

export type CycleOptionsProps = {
  options: Options[];
  cycle: string;
  onChange: (circle: string) => void;
};

const FormControlLabel: React.FC<FormControlLabelProps> = (props) => {
  const radioGroup = useRadioGroup();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (radioGroup) {
      setChecked(radioGroup.value === props.value);
    }
  }, [radioGroup, props.value]);

  return <MuiFormControlLabel checked={checked} {...props} sx={{ margin: 0, width: '100%' }} />;
};

const CycleOptions: React.FC<CycleOptionsProps> = ({ options, cycle, onChange }) => {
  const [value, setValue] = React.useState<string>(cycle);

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const c = e.currentTarget.value as string;
      setValue(c);

      onChange(c);
    },
    [setValue, onChange],
  );

  return (
    <MuiRadioGroup
      id='biling-periods'
      name='biling-periods'
      value={value}
      onChange={handleChange}
      sx={{
        flexWrap: 'unset',
      }}
    >
      <Stack spacing={2} direction='row' alignItems='stretch'>
        {options.map((x, i) => (
          <FormControlLabel
            key={i}
            value={x.value}
            control={<RadioContent label={x.label} discount={x.discount} />}
            label={null}
          />
        ))}
      </Stack>
    </MuiRadioGroup>
  );
};

export default CycleOptions;
