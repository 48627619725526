import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import ReportReservations, { ReportReservationsProps } from './components/ReportReservations';

type ReportReservationsDialogProps = BaseDialogProps & ReportReservationsProps;

const ReportReservationsDialog: React.FC<ReportReservationsDialogProps> = ({
  payload,
  modalRef,
  ...restProps
}) => {
  return (
    <BaseDialog width='350px' height='500px' {...restProps}>
      <ReportReservations
        payload={payload}
        modalRef={modalRef}
        refreshHandle={restProps.refreshHandle}
        offerId={restProps.offerId}
      />
    </BaseDialog>
  );
};

export default ReportReservationsDialog;
