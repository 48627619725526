import React from 'react';

import { Box, Typography } from '@mui/material';

import { Reservation } from '../../../graphql/API';
import { formatNumberWithCommas } from '../../../utils/formatter';

type Props = {
  reservation?: Reservation;
};

export const OfferPriceStatus: React.FC<Props> = ({ reservation }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '13px' }}>
      <Typography fontSize={'15px'} fontWeight={500}>
        ${formatNumberWithCommas(reservation?.offerPrice || 0)}
      </Typography>
    </Box>
  );
};
