import * as React from 'react';
import ReactPlayer from 'react-player';

import Box from '@mui/material/Box';

import PlayIcon from '../../../PlayIcon/PlayIcon';
import LazyImage from './LazyImage';

type MediaContentProps = {
  src: string;
  videoSrc?: string;
};

const PlayIconWithWaterMark: React.FC = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Box component='img' src='/png/logo-watermark.png' sx={{ width: '40%' }} />
    <PlayIcon />
  </Box>
);

const MediaContent: React.FC<MediaContentProps> = ({ src, videoSrc }) => {
  return videoSrc ? (
    <ReactPlayer
      width='100%'
      height='100%'
      playing={true}
      url={videoSrc}
      light={src ?? ''}
      controls
      playIcon={<PlayIconWithWaterMark />}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        overflow: 'hidden',
        borderRadius: '8px 0 0 8px',
      }}
    >
      <LazyImage
        sx={{
          width: '100%',
          objectFit: 'cover',
        }}
        src={src}
      />
    </Box>
  );
};

export default MediaContent;
