import * as React from 'react';
import { matchPath, useMatches } from 'react-router-dom';

const paths = [
  '/',
  '/collabs',
  '/reservations',
  '/offers',
  '/offers/:offerId',
  '/credits',
  '/profile',
];

type PathAttribute = {
  routeName: string;
} & Record<string, string>;

const getPathAttributes = (matches: ReturnType<typeof useMatches>): PathAttribute | undefined => {
  const matched = matches.pop();
  const pathName = matched?.pathname;

  if (!pathName) {
    return undefined;
  }

  const routeName = paths.find((x) => matchPath(x, pathName || ''));

  if (!routeName) {
    return undefined;
  }

  return { routeName, ...matched.params };
};

const usePathAttributes = (): PathAttribute | undefined => {
  const matches = useMatches();

  const attributes = React.useMemo(() => getPathAttributes(matches), [matches]);

  return attributes;
};

export default usePathAttributes;
