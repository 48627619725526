import * as React from 'react';
import { Link } from 'react-router-dom';

// import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Backdrop, Box, Container, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import styled from '@emotion/styled';
import { Formik, FormikConfig } from 'formik';

import Button from '../../components/Button/Button';
import AvailableSection from './components/AvailableSection';
import DateTimeSection from './components/DateTimeSection';
import DetailSection from './components/DetailSection';
import ManageAccess from './components/ManageAccess';
import { OfferTypes } from './components/OfferTypes/OfferTypes';
import RequirementsSection from './components/RequirementsSection';
import { FormValue, initialValues } from './components/schema';

const BackdropLoading: React.FC = () => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
    <CircularProgress color='inherit' />
  </Backdrop>
);

export type Method = 'insert' | 'update';

type OfferFormProps = {
  formikConfig: FormikConfig<FormValue>;
  method: Method;
  offerTypeIndex?: number;
};

const mapOfferForm: Record<Method, { title: string; buttonText: string }> = {
  insert: { title: 'Create a new', buttonText: 'Create Offer' },
  update: { title: 'Update offer', buttonText: 'Update Offer' },
};

const OfferForm: React.FC<OfferFormProps> = ({ formikConfig, method, offerTypeIndex }) => {
  const form = React.useMemo(() => mapOfferForm[method], [method]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: 4,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flex: 1,
        position: 'relative',
      }}
    >
      <Formik {...formikConfig}>
        {({ values, handleSubmit, isSubmitting, setValues }) => {
          return (
            <Container
              component='form'
              maxWidth='sm'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
              }}
              onSubmit={handleSubmit}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                component={Link}
                aria-label='back'
                color='primary'
                to='/offers'
              >
                <Typography sx={{ fontWeight: '600', fontSize: '24px', marginLeft: '30px' }}>
                  {form.title}
                </Typography>
              </Button>
              <Stack spacing={6} marginTop={`20px`} width={'100%'}>
                {method === 'insert' && (
                  <OfferTypes
                    resetForm={(type, category) => {
                      setValues({
                        ...initialValues,
                        type,
                        category,
                        ...(type === 'private' && { requirements: 'noRequirement' }),
                      });
                    }}
                    offerTypeIndex={offerTypeIndex}
                  />
                )}
                <Typography color={`#111111`} fontSize={`24px`} fontWeight={`600`}>
                  {values.type === 'private'
                    ? 'Collab details'
                    : values.category === 'event'
                      ? 'Event details'
                      : 'Offer details'}
                </Typography>
                <DetailSection initialValues={formikConfig.initialValues} method={method} />

                {values.type === 'public' && values.category !== 'event' && (
                  <AvailableSection method={method} initialValues={formikConfig.initialValues} />
                )}

                <DateTimeSection
                  offerType={values.type}
                  method={method}
                  initialValues={formikConfig.initialValues}
                />

                {values.type === 'public' && (
                  <RequirementsSection method={method} initialValues={formikConfig.initialValues} />
                )}

                {values.type === 'public' && (
                  <ManageAccess method={method} initialValues={formikConfig.initialValues} />
                )}
              </Stack>

              <Button variant='contained' type='submit' sx={{ mt: 10, px: '5rem', py: '1rem' }}>
                {form.buttonText}
              </Button>

              {isSubmitting && <BackdropLoading />}
            </Container>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OfferForm;

const AddOtherBtn = styled.button`
  border: none;
  background: transparent;
  color: #111111;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  max-width: fit-content;
`;
