import React from 'react';

import { AwsConfigV2 } from '../../config/types';

type AppConfigContextProps = {
  config: AwsConfigV2 | undefined;
}

export const AppConfigContext = React.createContext<AppConfigContextProps | undefined>(undefined);

export const useAppConfigContext = () => React.useContext(AppConfigContext);
