import { Action, State } from './types';

export const initialState = (): State => ({
  plan: 'basic',
  billingCycle: '1',
  logicPlan: '0:0',
});

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update-state': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
