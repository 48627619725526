import * as React from 'react';

import { useFormikContext } from 'formik';

import GooglePlaceAutoComplete, {
  GooglePlaceAutoCompleteProps,
} from '../../../GooglePlaceAutoComplete/GooglePlaceAutoComplete';

type GoogleOnChange = GooglePlaceAutoCompleteProps['onChange'];

const GooglePlaceContainer: React.FC = () => {
  const { setFieldValue } = useFormikContext();

  const handleChange = React.useCallback<GoogleOnChange>(
    (place) => {
      if (place) {
        setFieldValue('address1', place.address1 || place.formattedAddress);
        setFieldValue('address2', place.address2);
        setFieldValue('city', place.city);
        setFieldValue('formattedAddress', place.formattedAddress);
        setFieldValue('stateProvince', place.stateProvince);
        setFieldValue('country', place.country);
        setFieldValue('countryCode', place.countryCode);
        setFieldValue('postalCode', place.postalCode);
        setFieldValue('location.lat', place.location.lat);
        setFieldValue('location.lon', place.location.lon);
        setFieldValue('place_id', place.placeId);
      }
    },
    [setFieldValue],
  );

  return <GooglePlaceAutoComplete onChange={handleChange} />;
};

export default GooglePlaceContainer;
