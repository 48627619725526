import { Stripe, loadStripe } from '@stripe/stripe-js';

import config from '../../../config';

let stripePromise: Promise<Stripe | null>;
const getStripe = (stripeAccount?: string) => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripKey, { stripeAccount });
  }
  return stripePromise;
};

export default getStripe;
