import React from 'react';

import { Box, Typography } from '@mui/material';

import { useConversationsContext } from '../../../contexts/ConversationsContext';
import { ConversationItem } from '../../../graphql/API';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import TabPanelContainer from '../../TabPanelContainer/TabPanelContainer';
import { NoChatFound } from '../NoChatFound';
import ChatThreadSkeleton from './Skeleton';
import { Thread } from './Thread';

type Props = {
  selectedConversation: ConversationItem | null;
  onConversationSelectionHandle: (conversation: ConversationItem) => void;
};

const ConversationsList: React.FC<{
  activeTab: number;
  isFetching: boolean;
  isLoading: boolean;
  data: (ConversationItem | null | undefined)[];
  lastElementRef: (node: HTMLDivElement | null) => void;
  selectedConversation: ConversationItem | null;
  onConversationSelectionHandle: (conversation: ConversationItem) => void;
}> = ({
  isFetching,
  isLoading,
  data,
  activeTab,
  lastElementRef,
  selectedConversation,
  onConversationSelectionHandle,
}) => (
  <Box
    sx={{
      paddingY: '10px',
      overflow: 'auto',
      height: 'calc(-180px + 100vh)',
    }}
  >
    {!isFetching && data?.length === 0 && (
      <NoChatFound
        title={activeTab === 0 ? 'There are no active chats' : 'There are no closed chats'}
      />
    )}
    {data &&
      data.map(
        (conversation, index) =>
          conversation && (
            <div
              {...(data.length === index + 1 && {
                ref: lastElementRef,
              })}
            >
              <Thread
                active={conversation.conversationId === selectedConversation?.conversationId}
                {...conversation}
                onConversation={() => {
                  onConversationSelectionHandle(conversation);
                }}
              />
            </div>
          ),
      )}
    {isLoading || isFetching ? (
      <>
        <ChatThreadSkeleton />
        <ChatThreadSkeleton />
        <ChatThreadSkeleton />
        <ChatThreadSkeleton />
        <ChatThreadSkeleton />
      </>
    ) : null}
  </Box>
);

export const ChatThreads: React.FC<Props> = ({
  selectedConversation,
  onConversationSelectionHandle,
}) => {
  const {
    data,
    isLoading = false,
    isFetching = false,
    hasNextPage = false,
    fetchNextPage,
    revalidateConversations,
    setVenueConversationFilter,
  } = useConversationsContext() || {};

  const [activeTab, setActiveTab] = React.useState(0);

  const { lastElementRef } = useInfiniteScroll({
    hasMore: hasNextPage,
    isFetching: isFetching || isLoading,
    cb() {
      if (fetchNextPage) fetchNextPage();
    },
  });

  React.useEffect(() => {
    if (revalidateConversations) {
      revalidateConversations();
    }
  }, []);

  const handleTabChange = (val: number) => {
    setActiveTab(val);
    if (val === 0) {
      setVenueConversationFilter &&
        setVenueConversationFilter((prev) => ({ ...prev, closedChats: false }));
    } else if (val === 1) {
      setVenueConversationFilter &&
        setVenueConversationFilter((prev) => ({ ...prev, closedChats: true }));
    }
  };

  return (
    <>
      <Box sx={{ paddingX: '20px', paddingY: '22px' }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 500, textAlign: 'center' }}>
          Chat
        </Typography>
        <TabPanelContainer
          centered
          defaultValue={activeTab}
          handleTabChange={handleTabChange}
          tabSx={{
            m: 0,
            minWidth: '174px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center !important',
          }}
          panelSx={{ p: 0 }}
          panels={[
            {
              label: 'Active',
              children: (
                <ConversationsList
                  isFetching={isFetching}
                  isLoading={isLoading}
                  activeTab={activeTab}
                  data={data || []}
                  lastElementRef={lastElementRef}
                  selectedConversation={selectedConversation}
                  onConversationSelectionHandle={onConversationSelectionHandle}
                />
              ),
            },
            {
              label: 'Closed',
              children: (
                <ConversationsList
                  isFetching={isFetching}
                  isLoading={isLoading}
                  activeTab={activeTab}
                  data={data || []}
                  lastElementRef={lastElementRef}
                  selectedConversation={selectedConversation}
                  onConversationSelectionHandle={onConversationSelectionHandle}
                />
              ),
            },
          ]}
        />
      </Box>
    </>
  );
};
