import React from 'react';
import { useImageSize } from 'react-image-size';
import { Item as PhotoSwipeItem } from 'react-photoswipe-gallery';

import { CardMedia, CircularProgress } from '@mui/material';

import styled from '@emotion/styled';

import { environment } from '../../../config';
import icons from '../../../themes/icons';

const baseUrl = (() => {
  const envUrl = environment === 'production' ? '' : `-${environment}`;
  return `https://assets${envUrl}.beautypass.app`;
})();

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  type: string;
  rejected?: boolean;
  imageHeight?: string;
};

export const Image: React.FC<Props> = ({ src, type, rejected = false, imageHeight, ...rest }) => {
  const [loading, setLoading] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState('');
  const [demension] = useImageSize(imagePreview ?? '');

  const openOriginal = React.useCallback(() => {
    if (imagePreview) {
      window.open(imagePreview, '_blank');
    }
  }, [imagePreview]);

  React.useEffect(() => {
    if (src && src.startsWith('tmp/')) {
      setImagePreview(`${baseUrl}/${src}`);
      setLoading(false);
    } else if (src) {
      setImagePreview(src);
      setLoading(true);
    }
  }, [src]);

  return (
    <ImageBox loading={loading} rejected={rejected} imageHeight={imageHeight}>
      {imagePreview && (type === 'photo' || type === 'image') && (
        <PhotoSwipeItem
          original={imagePreview}
          thumbnail={imagePreview}
          width={demension?.width}
          height={demension?.height}
        >
          {({ ref, open }) => (
            <img
              {...rest}
              src={imagePreview}
              onClick={open}
              ref={ref}
              loading='lazy'
              style={{
                borderRadius: '10px',
              }}
            />
          )}
        </PhotoSwipeItem>
      )}
      {imagePreview && type === 'video' && (
        <CardMediaBlockWrapper onClick={openOriginal}>
          <CardMedia
            title='video-message'
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '10px',
              ...((rejected || loading) && { opacity: '0.5' }),
            }}
            component={'video'}
            src={imagePreview}
            autoPlay
            muted
          />
          {type === 'video' && (
            <LoaderContainer>
              <icons.PlayArrowIcon htmlColor='#fff' fontSize='large' />
            </LoaderContainer>
          )}
        </CardMediaBlockWrapper>
      )}

      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : null}
      {rejected && <RejectedBagde>Rejected</RejectedBagde>}
    </ImageBox>
  );
};

const ImageBox = styled.div<{ loading?: boolean; rejected?: boolean; imageHeight?: string }>`
  height: ${(props) => props.imageHeight || '210px'};
  min-width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
    opacity: ${(props) => (props.loading || props.rejected ? '0.5' : 1)};
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardMediaBlockWrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

const RejectedBagde = styled.div`
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 5px;
  right: 4px;
  font-weight: 500;
  color: #b73b2c;
  padding: 10px 14px;
  font-size: 13px;
`;
