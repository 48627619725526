import { BlockedUser, VenueListVenueBlockedUsersRequest } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListVenueBlockedUsers2 = /* GraphQL */ `
  query VenueListVenueBlockedUsers2($filter: VenueListVenueBlockedUsersRequest!) {
    venueListVenueBlockedUsers2(filter: $filter) {
      items {
        id
        email
        phone
        name
        country
        firstname
        lastname
        birthdate
        instagramHandle
        instagramFollowers
        instagramUpdatedAt
        failedInstaCheck
        profession
        gender
        agency
        motherAgency
        timezone
        currency
        homeCountry
        homeStateProvince
        homeCity
        city
        marketName
        marketID
        currentCity
        credits
        approvalDate
        lastSeen
        device
        totalCheckins
        totalCheckinLastMonth
        totalOfferValueRedeemed
        totalReservations
        totalWarnings
        totalSuspensions
        totalNoShow
        totalActiveNoShow
        totalCancellation
        totalNoPost
        totalPenalties
        totalActivePenalties
        totalPortfolioContents
        totalContentUploaded
        offerCategoriesRedeemed
        timeOnApp
        acceptedCodeOfConduct
        enabledPushNotifications
        enabledLocationAccess
        lastSynced
        createdAt
        updatedAt
        status
        reactivationDate
        instagramDuplicates
        firstnameDuplicates
        lastnameDuplicates
        birthdateDuplicates
        instagramVerificationRequested
        platform
        stripeId
        totalRequirementsSubmitted
        tags
        appVersion
        noshowCredits
        tncVersion
        hasPendingNoshowCredits
        agencyProfileUrl
        freelancer
        reservationRewardClaimed
        instagramVerified
        points
        premium
        savedByVenue
        inviteCredits
        newInvitesAvailable
        invitesAvailable
        invitesReceived
        webProfilePrivate
        rating
        totalRatings
        blockedDate
        tiktokHandle
        xHandle
        youtubeHandle
        picture {
          thumbnail
        }
        __typename
      }
      __typename
    }
  }
`;

export const venueListVenueBlockedUsers = async (
  filter: VenueListVenueBlockedUsersRequest,
): Promise<BlockedUser[]> => {
  const result = await executeGql(venueListVenueBlockedUsers2, { filter });
  return processGqlResult<BlockedUser[]>('venueListVenueBlockedUsers2.items', result);
};
