import * as React from 'react';

import Box from '@mui/material/Box';
import MuiRadio, { RadioProps } from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

export type RadioContentProps = {
  discount?: number;
  label: string;
} & RadioProps;

const RadioContent: React.FC<RadioContentProps> = ({ discount, label, ...restProps }) => {
  const { checked } = restProps;

  const style = React.useMemo(
    () => (checked ? { border: '2px solid #000000' } : undefined),
    [checked],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        border: '1px solid #EAEAEA',
        borderRadius: 1,
        p: 2,
        ...style,
      }}
    >
      <MuiRadio icon={<></>} checkedIcon={<></>} sx={{ display: 'none' }} {...restProps} />
      <Typography
        component='div'
        variant='subtitle1'
        fontWeight={checked ? 'bold' : undefined}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {label}
        {discount && <Box sx={{ color: '#F34D68', fontSize: '0.8rem' }}>~{discount}% off</Box>}
      </Typography>
    </Box>
  );
};

export default RadioContent;
