import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';

import { Offer, OfferCategory } from '../../../graphql/API';
import Stepper from './Stepper';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import SuccessBuy from './components/SuccessBuy';
import { useCreateInvite } from './context/CreateInvite.context';
import { CreateInviteProviderProps } from './context/CreateInvite.provider';
import { EventStep, Step } from './context/types';

type StepPanelProps = {
  step: number;
  offer?: Offer;
};

const StepPanel: React.FC<StepPanelProps> = ({ step, offer }) => {
  if (offer && offer?.category === OfferCategory.event) {
    switch (step) {
      case EventStep.OfferSelection:
        return <Step1 />;
      case EventStep.Summary:
        return <Step3 />;
    }
  } else {
    switch (step) {
      case Step.OfferSelection:
        return <Step1 />;
      case Step.DateSelection:
        return <Step2 />;
      case Step.Summary:
        return <Step3 />;
    }
  }
  return null;
};

type Props = {
  modalRef?: CreateInviteProviderProps['modalRef'];
  handleInviteStep?: (val: number, offerCategory?: OfferCategory) => void;
};

const CreateInvite: React.FC<Props> = ({ modalRef, handleInviteStep }) => {
  const { state, dispatch } = useCreateInvite();

  React.useEffect(() => {
    if (handleInviteStep) {
      handleInviteStep(state.step, state.offer?.category);
    }
  }, [handleInviteStep, state.offer?.category, state.step]);

  if (state.offer?.category === OfferCategory.event && state.step === 2) {
    return <SuccessBuy modalRef={modalRef} />;
  }

  if (state.step === Step.Success) {
    return <SuccessBuy modalRef={modalRef} />;
  }

  return (
    <Box>
      <Stepper activeStep={state.step} offer={state.offer} />
      {state.step > 0 && (
        <IconButton
          aria-label='delete'
          size='medium'
          color='primary'
          onClick={() => {
            dispatch({ type: 'prev' });
          }}
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(2.5),
          })}
        >
          <ArrowBackIcon fontSize='inherit' />
        </IconButton>
      )}

      <StepPanel step={state.step} offer={state.offer} />
    </Box>
  );
};

export default CreateInvite;
