import { createContext, useContext } from 'react';

import { Action, State } from './types';

type SubscriptionContextProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const SubscriptionContext = createContext<SubscriptionContextProps>(
  {} as SubscriptionContextProps,
);

export const useSubscription = () => useContext(SubscriptionContext);
