import React from 'react';
import { InView } from 'react-intersection-observer';

import { Box } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { ChatMessageItem } from '../../../graphql/API';
import { useOnAdminDeleteChatMessageListener } from '../../../hooks/listeners/useOnAdminDeleteChatMessageListener';
import { useOnAdminUpdateChatMessageListener } from '../../../hooks/listeners/useOnAdminUpdateChatMessageListener';
import { useRevalidateSupportChatMessages } from '../../../hooks/reactQuery/useChatListQuery';
import icons from '../../../themes/icons';
import { Image } from '../Image/Image';
import { Ticket } from '../Ticket/Ticket';

type Props = {
  userId: string;
  msg: ChatMessageItem;
  handleMessageUpdate: (message: ChatMessageItem) => void;
};

export const Message: React.FC<Props> = ({ userId, msg, handleMessageUpdate }) => {
  const [localMessage, setLocalMessage] = React.useState<ChatMessageItem>();

  const { revalidateChatList } = useRevalidateSupportChatMessages();
  const { message: messageEvent } = useOnAdminUpdateChatMessageListener(
    localMessage?.messageTime,
    localMessage?.conversationId,
  );

  const { message: deleteMessageEvent } = useOnAdminDeleteChatMessageListener(
    localMessage?.messageTime,
    localMessage?.conversationId,
  );

  React.useEffect(() => {
    if (msg) setLocalMessage(msg);
  }, [msg]);

  React.useEffect(() => {
    if (messageEvent) {
      setLocalMessage(messageEvent);
    }
  }, [messageEvent]);

  React.useEffect(() => {
    if (deleteMessageEvent) {
      revalidateChatList();
    }
  }, [deleteMessageEvent]);

  return (
    <>
      {userId === localMessage?.fromUserId ? null : (
        <>
          {localMessage?.messageType !== 'endchat' && (
            <Box height={40} width={40} minWidth={40}>
              <img
                src='/png/admin-support-chat-logo.png'
                alt='support-logo'
                className='rounded-image'
                width={'100%'}
                height={'100%'}
              />
            </Box>
          )}
        </>
      )}
      <InView
        as='div'
        onChange={(inView) => {
          if (inView && localMessage && localMessage.toUserId === userId && !localMessage.seen) {
            handleMessageUpdate(localMessage);
          }
        }}
      >
        <Box marginBottom='16px'>
          {localMessage?.messageType === 'photo' ||
          localMessage?.messageType === 'video' ||
          localMessage?.message === 'image' ? (
            <Image
              src={localMessage?.message || ''}
              alt={localMessage.messageTime}
              type={localMessage.messageType || ''}
              rejected={localMessage?.rejected || false}
            />
          ) : localMessage?.messageType === 'link' ? (
            <Ticket message={localMessage} />
          ) : localMessage?.messageType === 'endchat' ? (
            <Box>
              <ChatClosedMessage>{localMessage?.message}</ChatClosedMessage>
            </Box>
          ) : (
            <div
              className={
                userId === localMessage?.fromUserId ? 'message-sender' : 'message-receiver'
              }
            >
              <span className='message-text'>{localMessage?.message}</span>
            </div>
          )}
          {localMessage?.messageType !== 'endchat' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              marginTop={'8px'}
              justifyContent={userId === localMessage?.fromUserId ? 'flex-end' : 'flex-start'}
            >
              {userId === localMessage?.fromUserId ? null : (
                <MessageBottomStatus>Support</MessageBottomStatus>
              )}
              <span className='time-text'>
                {localMessage?.messageTime ? dayjs(localMessage.messageTime).format('LT') : null}
              </span>
              {localMessage?.fromUserId === userId && localMessage?.seen && (
                <Box component='span' sx={{ marginLeft: '5px' }}>
                  <icons.DoneAllIcon htmlColor='#3F51B5' fontSize='inherit' />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </InView>
    </>
  );
};

const MessageBottomStatus = styled.span`
  margin-right: 8px;
  font-size: 10px;
  color: #949ba5;
  font-weight: 600;
  text-transform: uppercase;
`;

const ChatClosedMessage = styled.span`
  font-size: 10px;
  color: #949ba5;
  font-weight: 600;
  text-transform: uppercase;
`;
