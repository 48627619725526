import * as React from 'react';

import { InputAdornment, Stack } from '@mui/material';

import styled from '@emotion/styled';
import { useField } from 'formik';
import { upperFirst } from 'lodash';

import { CurrencySign } from '../../../components/CurrencySign/CurrencySign';
import FormikNumberField from '../../../components/Formiks/NumberField/NumberField';
import SelectOption, { OptionItem } from '../../../components/Formiks/SelectOption/SelectOption';
import TextField from '../../../components/Formiks/TextField/TextField';
import GooglePlaceAutoComplete from '../../../components/GooglePlaceAutoComplete/GooglePlaceAutoComplete';
import { FoodCategories, OfferCategory } from '../../../graphql/API';
import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import { useAppSelector } from '../../../redux/hooks';
import { AttentionError } from './AttentionError';
import SideBySide from './SideBySide';
import OfferPhoto from './formiks/OfferPhoto/OfferPhoto';
import { followerOptions } from './options';
import {
  FormValue,
  OFFER_CATEGORIES,
  OFFER_FOOD_CATEGORIES,
  OfferType,
  waitTimeOptions,
} from './schema';

const offerCategoryOptions = OFFER_CATEGORIES.filter(
  (x) => x !== 'unknown' && x !== 'event',
).map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

const offerSubcategoryOptions = OFFER_FOOD_CATEGORIES.map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

type DetailSectionProps = {
  initialValues: FormValue;
  method: 'insert' | 'update';
};

const DetailSection: React.FC<DetailSectionProps> = ({ initialValues, method }) => {
  const subscriptionAccess = useSubscriptionAccess();
  const venueState = useAppSelector((state) => state.venue.value);

  const [field] = useField<OfferType>('type');
  const [categoryField] = useField<OfferCategory>('category');
  const [subCategoryField] = useField<FoodCategories>('subCategory');
  const [spotsField] = useField('spots');
  const [, , locationHepler] = useField('location');
  const [, addressMeta, addressHelper] = useField('address');
  const [minFollowersField] = useField('minFollowers');
  const [waitTimeField] = useField('waitTime');
  const fOptions = React.useMemo(() => followerOptions(subscriptionAccess), [subscriptionAccess]);

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <OfferPhoto />

      {field.value === 'public' && categoryField.value !== 'event' && (
        <>
          <CustomBox>
            <label htmlFor='' className='offer-form-label'>
              Offer category
            </label>
            <SelectOption
              sx={{ border: '1px solid transparent', padding: '5px 0' }}
              id='slt-offer-category'
              name='category'
              options={offerCategoryOptions}
            />
          </CustomBox>
          {method === 'update' && initialValues.category !== categoryField.value && (
            <AttentionError />
          )}
        </>
      )}
      {categoryField.value === 'food' && (
        <CustomBox>
          <label htmlFor='slt-offer-sub-category' className='offer-form-label'>
            Offer subcategory
          </label>
          <SelectOption
            sx={{ border: '1px solid transparent' }}
            id='slt-offer-sub-category'
            name='subCategory'
            options={offerSubcategoryOptions}
          />
          {method === 'update' &&
            categoryField.value &&
            initialValues.subCategory !== subCategoryField.value && <AttentionError />}
        </CustomBox>
      )}

      <CustomBox>
        <label htmlFor='' className='offer-form-label'>
          {categoryField.value === 'event' ? 'Event title' : 'Offer title'}
        </label>
        <TextField
          id='txt-title'
          name='title'
          placeholder={
            categoryField.value === 'event'
              ? 'Ex. Fashion week launch event'
              : 'Ex. dinner, haircut and brushing'
          }
          className='offer-input-field'
        />
      </CustomBox>
      <CustomBox>
        <label htmlFor='' className='offer-form-label'>
          Description
        </label>
        <TextField
          id='txt-desc'
          name='description'
          placeholder={
            categoryField.value === 'event'
              ? 'Ex. Join us at the exclusive Fashion Week launch event for our brand. There will be a DJ, open bar, and lots of surprises!'
              : 'Ex. 1 lunch item from the menu and drink of choice'
          }
          multiline
          rows={5}
          className='offer-input-field'
        />
      </CustomBox>

      {categoryField.value === 'event' && (
        <CustomBox>
          <GooglePlaceAutoComplete
            type='offer'
            onChange={(location) => {
              if (location) {
                locationHepler.setValue({
                  lat: location?.location.lat,
                  lon: location?.location.lon,
                });
                addressHelper.setValue(location?.formattedAddress);
              } else {
                locationHepler.setValue(null);
                addressHelper.setValue('');
              }
            }}
            error={addressMeta.touched && Boolean(addressMeta.error)}
            helperText={addressMeta.touched ? addressMeta.error : ''}
            placeAddress={venueState?.address1 || ''}
          />
        </CustomBox>
      )}

      {method === 'update' && categoryField.value === 'event' && (
        <CustomBox>
          <label htmlFor='txt-address' className='offer-form-label'>
            Address
          </label>
          <TextField
            id='txt-address'
            name='address'
            placeholder='Ex. address'
            disabled
            className='offer-input-field'
          />
        </CustomBox>
      )}

      {field.value === 'public' && (
        <>
          <SideBySide>
            <AvailableBox>
              <label htmlFor='' className='offer-form-label'>
                Available spots
              </label>
              <CustomBoxAvailble>
                <FormikNumberField
                  id='txt-spots'
                  name='spots'
                  type='number'
                  placeholder=''
                  className='offer-input-field'
                />
              </CustomBoxAvailble>
              {method === 'update' && initialValues.spots != spotsField.value && <AttentionError />}
            </AvailableBox>
            <CustomBox>
              <label htmlFor='' className='offer-form-label'>
                Minimum Followers
              </label>

              <SelectOption
                id='slt-min-followers'
                name='minFollowers'
                options={fOptions}
                fullWidth
                MenuProps={{
                  sx: {
                    '.MuiMenuItem-root.Mui-disabled': { opacity: 1 },
                  },
                }}
                sx={{ padding: '5px 0' }}
              />
              {method === 'update' && initialValues.minFollowers !== minFollowersField.value && (
                <AttentionError />
              )}
            </CustomBox>
          </SideBySide>
        </>
      )}

      <SideBySide shouldFlexEnd>
        <CustomBox>
          <label htmlFor='' className='offer-form-label'>
            Offer value
          </label>
          <FormikNumberField
            id='txt-offer-value'
            name='offerValue'
            type='number'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  {venueState?.currency && <CurrencySign currency={venueState?.currency} />}
                </InputAdornment>
              ),
            }}
            className='offer-input-field'
          />
        </CustomBox>
        {categoryField.value !== 'event' && field.value === 'public' && (
          <CustomBox>
            <label htmlFor='slt-waitTime' className='offer-form-label'>
              How many times can a Beautypass member book this offer?
            </label>
            <SelectOption
              id='slt-waitTime'
              name='waitTime'
              options={waitTimeOptions}
              fullWidth
              sx={{ padding: '5px 0' }}
            />

            {method === 'update' && initialValues.waitTime !== waitTimeField.value && (
              <AttentionError />
            )}
          </CustomBox>
        )}
      </SideBySide>
    </Stack>
  );
};

export default DetailSection;

const AvailableBox = styled.div`
  display: flex;
  flex-direction: column;

  // input::-webkit-outer-spin-button,
  // input::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }
  // input[type='number'] {
  //   padding-right: 102px;
  // }
  // input[type='number'] {
  //   -moz-appearance: textfield;
  // }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4%;
  button {
    background: #f6f6f6;
    border-radius: 6px;
    color: #111111;
    border: none;
    padding: 4px 12px;
    cursor: pointer;
  }
`;

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;

const CustomBoxAvailble = styled.div`
  position: relative;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;
