import * as React from 'react';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';

import { OfferCategory, OfferRequirements } from '../../../../graphql/API';
import { useVenueGetVerificationUrl } from '../../../../hooks/reactQuery/balance/useVenueGetVerificationUrl';
import useVenueId from '../../../../hooks/useVenueId';
import { RadioContentBuy } from '../../../../pages/CreateUpdateOffer/components/formiks/OfferRequirement/RadioContentBuy';
import {
  fetchAccountBalance,
  handleBalanceIsLoading,
} from '../../../../redux/features/balance/balanceSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Icons from '../../../../themes/icons';
import { delay } from '../../../../utils/delay';
import { centsToDollars, formatNumberWithCommas } from '../../../../utils/formatter';
import { CurrencySign } from '../../../CurrencySign/CurrencySign';
import SvgIcon, { IconName } from '../../../Icons';
import AddBalanceDialog from '../../AddBalance/AddBalanceDialog';
import { AddFundsStripeDialog } from '../../AddFundsStripe/AddFundsStripeDialog';
import StripeAccountVerifyDialog from '../../StripeAccountVerify/StripeAccountVerifyDialog';
import { useCreateInvite } from '../context/CreateInvite.context';
import { ExtendedOfferRequirements } from '../context/types';
import { inviteRequirementPrice } from '../context/util';
import { InvitedUsers } from './InvitedUsers';

const requirements: Record<string, string>[] = [
  {
    name: '1 Story',
    value: OfferRequirements.postOnInstagramStories,
    iconName: 'StoryBlack',
    checkedIconName: 'Story',
  },
  {
    name: '3 Stories',
    value: OfferRequirements.postOnInstagramStories3x,
    iconName: 'StoryBlack',
    checkedIconName: 'Story',
  },
  {
    name: 'Feed',
    value: OfferRequirements.postOnInstagramFeed,
    iconName: 'FeedBlack',
    checkedIconName: 'Feed',
  },
  {
    name: 'Reel',
    value: OfferRequirements.postOnInstagramReels,
    iconName: 'ReelBlack',
    checkedIconName: 'Reel',
  },
];

const Section: React.FC<React.PropsWithChildren<{ title: string }>> = ({ title, children }) => (
  <Box>
    <Typography fontWeight='bold' mb={1}>
      {title}
    </Typography>
    {children}
  </Box>
);

const Step3: React.FC = () => {
  const venueID = useVenueId();
  const appDispatch = useAppDispatch();
  const balance = useAppSelector((state) => state.balance.value);
  const { state, submitForm, dispatch } = useCreateInvite();
  const [offerPricesValid, setOfferPricesValid] = React.useState(false);

  const { showModal } = useModal();

  const [successAmount, setSuccessAmount] = React.useState(0);
  const [totalAmountWithFee, setTotalAmountWithFee] = React.useState(0);

  const { data: verficationData } = useVenueGetVerificationUrl(
    { venueID: venueID || '' },
    balance?.status === 'inactive',
  );

  const [stripeDialog, setStripeDialog] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [usersWithOfferPrice, setUsersWithOfferPrice] = React.useState<
    Record<number, number | undefined>
  >({});

  const handleUsersWithOfferPrice = (usersWithOfferPrice: {
    userID: number;
    offerPrice: number | undefined;
  }) => {
    setUsersWithOfferPrice((prev) => ({
      ...prev,
      [usersWithOfferPrice.userID]: usersWithOfferPrice.offerPrice,
    }));
  };

  const handleStripeDialog = () => {
    setStripeDialog((prev) => !prev);
  };

  const handleOfferPricesValidatity = (val: boolean) => {
    setOfferPricesValid(val);
  };

  const totalBalance = React.useMemo(
    () => centsToDollars(balance?.balance || 0),
    [balance?.balance],
  );
  const totalOfferPrice = React.useMemo(
    () => Object.values(usersWithOfferPrice).reduce<number>((acc, price) => acc + (price || 0), 0),
    [usersWithOfferPrice],
  );

  React.useEffect(() => {
    dispatch({
      type: 'update',
      payload: {
        usersWithOfferPrice: Object.keys(usersWithOfferPrice).map((userId) => ({
          userID: Number(userId),
          offerPrice: usersWithOfferPrice[Number(userId)] || 0,
        })),
        previousTotalBalance: totalBalance,
      },
    });
  }, [dispatch, totalBalance, usersWithOfferPrice]);

  React.useEffect(() => {
    if (state?.offer?.category === OfferCategory.event) {
      setUsersWithOfferPrice(
        state.users.reduce((acc, key) => {
          acc = {
            ...acc,
            [key.id]: 0,
          };
          return acc;
        }, {}),
      );
    }
  }, [state?.offer, state.users]);

  const handleSubmit = () => {
    if (state.offer?.category === OfferCategory.event) {
      dispatch({
        type: 'update',
        payload: {
          usersWithOfferPrice: Object.keys(usersWithOfferPrice).map((userId) => ({
            userID: Number(userId),
            offerPrice: usersWithOfferPrice[Number(userId)] || 0,
          })),
          previousTotalBalance: totalBalance,
        },
      });
      return submitForm();
    } else {
      if (totalOfferPrice > Number(totalBalance)) {
        return enqueueSnackbar('Your balance is insufficient', { variant: 'warning' });
      }

      const isValid = Object.keys(usersWithOfferPrice).length === state.users.length;
      const isPriceValid = Object.values(usersWithOfferPrice).every(
        (userWithPrice) => (userWithPrice || 0) > 0,
      );

      if (!isValid) {
        return enqueueSnackbar('Please give offer price to users', { variant: 'warning' });
      }

      if (!isPriceValid) {
        return enqueueSnackbar('Offer price should be greater than 0', { variant: 'warning' });
      }

      submitForm();
    }
  };

  const handleAddFundsDialog = () => {
    if (balance?.status === 'inactive') {
      return showModal(StripeAccountVerifyDialog, {
        onVerify: () => {
          return window.open(verficationData?.onBoardingURL, '_self');
        },
      });
    }

    showModal(AddBalanceDialog, {
      onSubmit: (totalAmountWithFee) => {
        setTotalAmountWithFee(totalAmountWithFee as number);
        handleStripeDialog();
      },
      getOriginalPriceHandle: (amount) => {
        setSuccessAmount(amount as number);
      },
    });
  };

  const isEnterPriceIsGreater = React.useMemo(
    () => totalOfferPrice > Number(totalBalance),
    [totalBalance, totalOfferPrice],
  );

  const inviteRequirements = React.useMemo(
    () => [
      ...requirements,
      ...(state.offer?.category === OfferCategory.event
        ? [
            {
              name: 'No Req.',
              value: ExtendedOfferRequirements.noRequirement,
              iconName: 'NoRequirement',
              checkedIconName: 'NoRequirement',
            },
          ]
        : []),
    ],
    [state.offer],
  );

  return (
    <>
      <Box
        sx={{
          marginBlock: 2,
          maxHeight: 'calc(600px - 50px)',
          overflowY: 'auto',
        }}
      >
        <Stack spacing={3}>
          <Section title='Select a requirement'>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              {inviteRequirements.map((requirement) => {
                return (
                  <Box sx={{ flex: 1 }}>
                    <RadioContentBuy
                      checked={state.requirements === requirement.value}
                      label={requirement.name}
                      icon={<SvgIcon name={requirement.iconName as IconName} />}
                      checkedIcon={<SvgIcon name={requirement.checkedIconName as IconName} />}
                      onClick={() => {
                        dispatch({
                          type: 'update',
                          payload: { requirements: requirement.value ?? undefined },
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Section>

          <InvitedUsers
            offer={state.offer}
            users={state.users}
            usersWithOfferPrice={usersWithOfferPrice}
            handleUsersWithOfferPrice={handleUsersWithOfferPrice}
            handleOfferPricesValidatity={handleOfferPricesValidatity}
            requirements={state.requirements}
          />
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            boxShadow: '0px 0px 20px #18232E26;',
            bottom: 0,
            right: 0,
            left: 0,
            padding: '26px',
          }}
        >
          <Grid container>
            <Grid item xs={4.5}>
              <Stack gap={1}>
                <Box display='flex' alignItems='flex-start' justifyContent={'space-between'}>
                  <Typography fontWeight='normal' fontSize={'15px'}>
                    Balance
                  </Typography>
                  <Typography textAlign='left' fontWeight='normal' fontSize={'15px'}>
                    <CurrencySign currency={'usd'} />
                    {formatNumberWithCommas(+totalBalance)}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='flex-start' justifyContent={'space-between'}>
                  <Typography fontWeight={500} fontSize={'15px'}>
                    Total cost
                  </Typography>
                  <Typography textAlign='left' fontWeight={500} fontSize={'15px'}>
                    <CurrencySign currency={'usd'} />
                    {formatNumberWithCommas(totalOfferPrice)}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={4.5}>
              {balance?.status === 'inactive' ||
              (Number(totalBalance) <= 0 && state.offer?.category !== OfferCategory.event) ? (
                <Button
                  id='btn-offer-submit'
                  variant='contained'
                  size='large'
                  onClick={handleAddFundsDialog}
                  fullWidth
                  sx={{
                    paddingY: '16px',
                    fontSize: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Icons.BalanceIcon /> <span>+Add Funds</span>
                </Button>
              ) : (
                <Button
                  id='btn-offer-submit'
                  variant='contained'
                  size='large'
                  disabled={
                    (Number(totalBalance) <= 0 && state.offer?.category !== OfferCategory.event) ||
                    !offerPricesValid ||
                    isEnterPriceIsGreater
                  }
                  onClick={handleSubmit}
                  fullWidth
                  sx={{
                    paddingY: '16px',
                    fontSize: '18px',
                    ':disabled': {
                      backgroundColor: '#FF5454',
                      color: '#fff',
                      border: '1px solid #FF5454',
                    },
                  }}
                >
                  Confirm and Invite
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <AddFundsStripeDialog
        open={stripeDialog}
        onClose={handleStripeDialog}
        amount={totalAmountWithFee}
        amountWithoutFee={successAmount}
        onSuccess={() => {
          appDispatch(handleBalanceIsLoading());
          delay(10000).then(() => {
            appDispatch(fetchAccountBalance({ venueID: venueID || '' }));
          });
        }}
      />
    </>
  );
};

export default Step3;
