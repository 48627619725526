import React from 'react';

import { Box, Button, TextField } from '@mui/material';

import styled from '@emotion/styled';
import { Formik } from 'formik';
import * as yup from 'yup';

import { formatNumberWithCommas } from '../../../utils/formatter';
import { CurrencySign } from '../../CurrencySign/CurrencySign';
import BaseDialog, { BaseDialogProps } from '../Base';

const schema = yup.object({
  balance: yup.number().min(1).required('Required'),
});

type Props = {
  title?: string;
  currency?: string;
  onSubmit: (totalAmountWithFee: number) => void;
  getOriginalPriceHandle?: (amount: number) => void;
} & BaseDialogProps;

const totalBalanceAfterMarketPlaceFee = (balance: number) => balance + balance * 0.05;

const AddBalanceDialog: React.FC<Props> = ({ onSubmit, getOriginalPriceHandle, ...rest }) => {
  return (
    <BaseDialog
      width='580px'
      height='400px'
      titleComponent={<ModalTitle>+Add funds</ModalTitle>}
      {...rest}
    >
      <Box sx={{ paddingX: '18px', paddingY: '12px' }}>
        <Heading>Enter the amount to add to your Beautypass Balance</Heading>
        <Formik
          validationSchema={schema}
          initialValues={{ balance: 0 }}
          onSubmit={(values) => {
            const totalAmount = values.balance + values.balance * 0.05;
            onSubmit(totalAmount);
            if (getOriginalPriceHandle) getOriginalPriceHandle(values.balance);
            if (rest?.onClose) rest?.onClose({}, 'backdropClick');
          }}
        >
          {({ values, handleSubmit, setValues }) => {
            const fee = values.balance * 0.05;
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  id='balance'
                  name='balance'
                  placeholder={'Amount (USD)'}
                  className='offer-input-field'
                  sx={{ marginY: '24px' }}
                  type='text'
                  value={values.balance}
                  onChange={(event) => {
                    const _value = event.target.value.trim();
                    if (_value === '' || (/^[0-9]+$/.test(_value) && Number(_value) > 0)) {
                      setValues({ balance: _value === '' ? 0 : Number(_value) });
                    }
                  }}
                />
                {values.balance > 0 && (
                  <>
                    <DiscountedText>
                      A 5% marketplace fee will be added to cover transaction costs.
                    </DiscountedText>
                    <DiscountedText>
                      For a balance addition of ${formatNumberWithCommas(values.balance)} the fee
                      will be ${formatNumberWithCommas(fee)}
                    </DiscountedText>
                  </>
                )}
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ paddingY: '15px', marginY: '10px' }}
                  type='submit'
                >
                  Continue with
                  {values.balance && values.balance > 0 ? (
                    <Box display={'flex'} alignItems={'center'} gap={'4px'} marginLeft={'4px'}>
                      <span>
                        <CurrencySign currency={'usd'} />
                        <span>
                          {formatNumberWithCommas(totalBalanceAfterMarketPlaceFee(values.balance))}
                        </span>
                      </span>
                    </Box>
                  ) : null}
                </Button>
              </form>
            );
          }}
        </Formik>
      </Box>
    </BaseDialog>
  );
};

export default AddBalanceDialog;

const Heading = styled.h1`
  margin: 0;
  padding: 0;

  color: #111;
  font-size: 24px;
  font-weight: 500;
`;

const ModalTitle = styled.span`
  color: #848484;
  font-size: 15px;
  font-weight: 500;
`;

export const DiscountedText = styled.span`
  color: #848484;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px;
  display: inline-block;
`;
