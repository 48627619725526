import * as React from 'react';

import { Box, Typography } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import Button from '../../../Button/Button';
import { useCreateInvite } from '../context/CreateInvite.context';
import DateCalendar from './DateCalendar';
import TimeRangePicker from './TimeRangePicker';

function isEndTimeValidHandle(startTime?: Dayjs, endTime?: Dayjs) {
  return endTime?.isAfter(startTime);
}

const Step2: React.FC = () => {
  const { state, dispatch } = useCreateInvite();

  const currentDate = dayjs();

  const maxEndTimeForCollabOffer = currentDate.add(2, 'hours');

  const isCurrentDateFound = state.dates
    ? state.dates.every((date) => currentDate.isSame(date, 'day'))
    : false;

  const isStartTimeMustLeastAfterOneHour = React.useMemo(
    () => isCurrentDateFound && currentDate.isAfter(state.startTime),
    [isCurrentDateFound, currentDate, state.startTime],
  );

  const isEndTimeMustLeastAfterTwoHour = React.useMemo(
    () => isCurrentDateFound && maxEndTimeForCollabOffer.isAfter(state.endTime),
    [isCurrentDateFound, maxEndTimeForCollabOffer, state.endTime],
  );

  const isEndTimeValid = React.useMemo(
    () => !isEndTimeValidHandle(state.startTime, state.endTime),
    [state.endTime, state.startTime],
  );

  return (
    <Box mt={4} sx={{ maxHeight: '100%' }}>
      <Box display='flex' flexDirection='column' alignItems='center' marginY={2}>
        <Typography fontWeight='medium'>Select dates for your invitation,</Typography>
        <Typography fontWeight='medium'>members will be able to reserve only one date</Typography>
      </Box>

      <DateCalendar
        defaultValue={state.dates}
        onChange={(value) => {
          const isCurrentDateFound = value
            ? value.some((date) => currentDate.isSame(date, 'day'))
            : false;
          if (isCurrentDateFound) {
            const currentTime = dayjs();
            const remainder = currentTime.minute() % 5;
            const minutesToAdd = remainder === 0 ? 5 : 5 - remainder;
            const startTime = currentTime.add(minutesToAdd, 'minute');

            dispatch({
              type: 'update',
              payload: { dates: value ?? undefined, startTime, endTime: startTime.add(2, 'hours') },
            });
          } else {
            dispatch({
              type: 'update',
              payload: { dates: value ?? undefined },
            });
          }
        }}
      />

      <Box display='flex' justifyContent='space-between' alignItems='flex-end' marginTop={2}>
        <Box sx={{ marginLeft: '30%' }}>
          <TimeRangePicker
            startTime={state.startTime}
            endTime={state.endTime}
            onChange={(payload) => {
              dispatch({ type: 'update', payload });
            }}
            shouldDisaledThePreviousTime={isCurrentDateFound}
            isStartTimeMustLeastAfterOneHour={isStartTimeMustLeastAfterOneHour}
            isEndTimeMustLeastAfterTwoHour={isEndTimeMustLeastAfterTwoHour}
            isEndTimeValid={isEndTimeValid}
          />
        </Box>

        <Button
          id='btn-offer-step2'
          sx={{ px: 7.5, py: 1.7 }}
          variant='contained'
          disabled={
            !state.dates?.length ||
            isStartTimeMustLeastAfterOneHour ||
            isEndTimeMustLeastAfterTwoHour ||
            isEndTimeValid
          }
          onClick={() => {
            dispatch({ type: 'next' });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Step2;
