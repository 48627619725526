import * as React from 'react';

import { OfferStatus } from '../../../graphql/API';
import { useListOfferQuery } from '../../../hooks/reactQuery/useOfferQuery';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import List from '../../List/Base/List';
import ListSkeleton from '../Base/ListSkeleton';
import { ListOfferEmptyPlaceholder } from './ListOfferEmptyPlaceholder';
import { mapToListItem } from './mapper';

type Props = {
  listHeight?: number;
};

const ListOffer: React.FC<Props> = ({ listHeight }) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useListOfferQuery({
    status: OfferStatus.active,
  });
  const offers = data.filter((offer) => offer?.totalCheckins && offer.totalCheckins > 0);

  if (isLoading) {
    return <ListSkeleton />;
  }

  return (
    <InfiniteScroll
      isLoading={isLoading}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    >
      {offers.length ? (
        <List items={mapToListItem(offers)} />
      ) : (
        <ListOfferEmptyPlaceholder listHeight={listHeight} />
      )}
    </InfiniteScroll>
  );
};

export default ListOffer;
