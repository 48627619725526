import React from 'react';

import { Alert, Box, Button, Collapse, IconButton } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

import Icons from '../../../../themes/icons';

type Props = {
  purchasedDate?: string;
  successMessage?: boolean;
  onUsageRights?: () => void;
  onDownload?: () => void;
};

export const ActionBuyDownload: React.FC<Props> = ({
  purchasedDate,
  successMessage = false,
  onUsageRights,
  onDownload,
}) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(successMessage);
  }, [successMessage]);

  return (
    <Box>
      <Collapse in={open}>
        <Alert
          sx={{ bgcolor: '#DDF4E8', mb: 2 }}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setOpen(false);
              }}
            >
              <Icons.CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          <DownloadTitle>Purchase completed</DownloadTitle>
        </Alert>
      </Collapse>
      <Button variant='contained' fullWidth sx={{ padding: '16px' }} onClick={onDownload}>
        Download
      </Button>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ marginY: '24px' }}
      >
        <PurchaseDate>Purchased on {dayjs(purchasedDate).format('LL')}</PurchaseDate>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Icons.UsageBadgeIcon />
          <UsageLink onClick={onUsageRights}>Usage Rights</UsageLink>
        </Box>
      </Box>
    </Box>
  );
};

const UsageLink = styled.button`
  color: #111;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  text-decoration-line: underline;

  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;

const DownloadTitle = styled.span`
  color: #111;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

const PurchaseDate = styled.span`
  display: inline-block;
  color: rgba(17, 17, 17, 0.5);
  font-size: 15px;
  font-weight: 400;
`;
