import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styled from '@emotion/styled';

type TitleWithDescriptionProps = {
  title: string;
};

const TitleWithDescription: React.FC<React.PropsWithChildren<TitleWithDescriptionProps>> = ({
  title,
  children,
}) => (
  <Box>
    <Title>{title}</Title>
    <Typography>{children}</Typography>
  </Box>
);

const ManageContentTips: React.FC = () => (
  <Stack spacing={5} paddingY='42px' paddingX='36px'>
    <TitleWithDescription title='What is content?'>
      Photos or videos that Beautypass members upload to our app in addition to what is required for
      your offer.
    </TitleWithDescription>

    <TitleWithDescription title='And the post?'>
      Content is uploaded to Beautypass while their main post is published on Instagram.
    </TitleWithDescription>

    <TitleWithDescription title='What can I do?'>
      You can purchase content to use for your Instagram advertising and marketing campaigns, as
      well as publish it on your business's social media channels.
    </TitleWithDescription>

    <TitleWithDescription title='License'>
      You have the right to use the purchased content for a period of 90 days from the date of
      purchase. The usage rights are global, allowing you to use the content without any regional
      restrictions. The creator retains ownership.
    </TitleWithDescription>
  </Stack>
);

export default ManageContentTips;

const Title = styled.h6`
  font-size: 20px;
  font-weight: 600;
  color: #111111;
  margin: 0 0 8px 0;
`;
