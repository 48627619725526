import { VenueListAccessRequetsFilter } from '../../graphql/API';
import { venueListAccessRequestsCount } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListAccessRequestsCountApi = async (
  filter: VenueListAccessRequetsFilter,
): Promise<Record<string, number>> => {
  const result = await executeGql<string>(venueListAccessRequestsCount, { filter });
  const json = JSON.parse(processGqlResult<string>('venueListAccessRequestsCount', result));
  return json;
};
