import { AccountTransaction, TransactionListFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListAccountTransactions = /* GraphQL */ `
  query VenueListAccountTransactions($filter: TransactionListFilter!) {
    venueListAccountTransactions(filter: $filter) {
      items {
        entityID
        entityType
        amount
        tranType
        subtitle
        status
        userID
        userFirstname
        userLastname
        createdAt
        updatedAt
        title
        metadata
        image {
          thumbnail
          medium
        }
        __typename
      }
      __typename
    }
  }
`;

export const venueListAccountTransactionsApi = async (
  filter: TransactionListFilter,
): Promise<AccountTransaction[]> => {
  const result = await executeGql(venueListAccountTransactions, { filter });
  return processGqlResult<AccountTransaction[]>('venueListAccountTransactions.items', result);
};
