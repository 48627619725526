import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import { Grid, Skeleton } from '@mui/material';

type PanelUserContentProps = {
  userId?: string;
};

const PanelSkeleton: React.FC<PanelUserContentProps> = () => {
  return (
    <Grid container spacing={1}>
      {[...Array(4)].map((_, i) => (
        <Grid key={i} item xs={5} sm={4} md={2.5} lg={2.2} xl={2}>
          <AspectRatio ratio='3/4'>
            <Skeleton variant='rectangular' sx={{ transform: 'unset' }} />
          </AspectRatio>
        </Grid>
      ))}
    </Grid>
  );
};

export default PanelSkeleton;
