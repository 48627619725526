import React from 'react';

import { Box, Typography } from '@mui/material';

import icons from '../../../themes/icons';

export const AttentionError = () => {
  return (
    <Box display={'flex'} alignItems={'center'} gap={2} marginY={1}>
      <icons.AttentionIcon />
      <Typography color={'#DC5564'} variant='body1'>
        This modification will result in the cancellation of all reservations
      </Typography>
    </Box>
  );
};
