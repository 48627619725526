import React from 'react';

import { Avatar, Box, InputAdornment, TextField } from '@mui/material';

import styled from '@emotion/styled';

import { Offer, OfferCategory, OfferRequirements, User } from '../../../../graphql/API';
import { useVenueListUserEstimatedPriceQuery } from '../../../../hooks/reactQuery/balance/useVenueListUserEstimatedPriceQuery';
import Icons from '../../../../themes/icons';
import { formatNumber } from '../../../../utils/formatter';
import { inviteRequirementPrice } from '../context/util';

type Props = {
  offer?: Offer;
  users: User[];
  usersWithOfferPrice: Record<number, number | undefined>;
  handleUsersWithOfferPrice: (usersWithOfferPrice: {
    userID: number;
    offerPrice: number | undefined;
  }) => void;
  handleOfferPricesValidatity: (val: boolean) => void;
  requirements?: string;
};

export const InvitedUsers: React.FC<Props> = ({
  offer,
  users,
  usersWithOfferPrice,
  handleUsersWithOfferPrice,
  handleOfferPricesValidatity,
  requirements,
}) => {
  const [errors, setErrors] = React.useState<Record<number, string>>({});
  const priceInputRef = React.useRef<HTMLInputElement | null>();

  const { data } = useVenueListUserEstimatedPriceQuery({
    userIDs: users.map((user) => Number(user.id || 0)),
  });

  React.useEffect(() => {
    if (offer?.category !== OfferCategory.event) {
      priceInputRef.current?.focus();
    }
  }, [offer?.category]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      handleOfferPricesValidatity(false);
    } else {
      handleOfferPricesValidatity(true);
    }
  }, [errors, handleOfferPricesValidatity]);

  const validateValue = (userId: number, value: string, esitmatedPrice: number) => {
    let error = '';
    const numericValue = Number(value);
    if (numericValue > 9999) {
      error = 'Value cannot exceed $9,999';
    } else if (numericValue < esitmatedPrice) {
      error = `The minimum cost for this collab $${esitmatedPrice}`;
    }
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (error) {
        newErrors[userId] = error;
      } else {
        delete newErrors[userId];
      }
      return newErrors;
    });
  };

  const handleChange = (value: string, user: User, esitmatedPrice: number) => {
    const _value = value.trim();
    if (_value === '' || (/^[0-9]*$/.test(_value) && Number(_value) > 0)) {
      handleUsersWithOfferPrice({
        userID: Number(user.id),
        offerPrice: _value === '' ? undefined : Number(_value),
      });
      validateValue(Number(user.id), _value, esitmatedPrice);
    }
  };

  React.useEffect(() => {
    const estimatedValue =
      offer?.category === OfferCategory.event ? 0 : inviteRequirementPrice[requirements || ''] || 0;
    users.forEach((user) => {
      handleChange(estimatedValue.toString(), user, estimatedValue);
    });
  }, [offer?.category, requirements, users]);

  const estimatedPrice = React.useMemo(
    () =>
      offer?.category === OfferCategory.event ? 0 : inviteRequirementPrice[requirements || ''] || 0,
    [offer?.category, requirements],
  );

  return (
    <Box>
      <Header>
        <HeaderCell>Collab summary</HeaderCell>
        <HeaderCell>Suggested cost</HeaderCell>
        <HeaderCell textAlign='right'>
          {offer?.category === OfferCategory.event ? 'Incentive' : 'Cost'}
        </HeaderCell>
      </Header>
      {users.map((user, index) => {
        const esitmatedMinPrice =
          data?.find((u) => String(u.userID) === user.id)?.estimatedPriceMin || 0;
        const esitmatedMaxPrice =
          data?.find((u) => String(u.userID) === user.id)?.estimatedPriceMax || 0;

        return (
          <RowContainer>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
              <Avatar
                variant='rounded'
                alt={String(user.firstname)}
                src={String(user.picture?.thumbnail)}
                sx={{ borderRadius: '50%' }}
              />
              <Box>
                <Name>
                  {user.firstname} {user.lastname}
                </Name>
                <NoOfFollowers>{formatNumber(user.instagramFollowers ?? 0)}</NoOfFollowers>
              </Box>
            </Box>
            <Price>
              {offer?.category === OfferCategory.event ||
              requirements === OfferRequirements.postOnInstagramStories
                ? '-'
                : requirements === OfferRequirements.postOnInstagramStories3x
                  ? `$${Math.round(esitmatedMinPrice)}`
                  : `$${Math.round(esitmatedMaxPrice)}`}
            </Price>
            <Box sx={{ textAlign: 'right' }}>
              <TextField
                {...(index === 0 ? { inputRef: priceInputRef } : {})}
                sx={{
                  width: '96px',
                  borderRadius: '4px',
                }}
                inputProps={{ style: { padding: '8px 10px' } }}
                InputProps={{
                  type: 'text',
                  startAdornment: (
                    <InputAdornment position='start' style={{ borderRight: '1px solid red' }}>
                      <Icons.DollarSignIcon />
                    </InputAdornment>
                  ),
                }}
                value={usersWithOfferPrice[Number(user.id)] ?? ''}
                onChange={(event) => {
                  handleChange(event.target.value as string, user, estimatedPrice);
                }}
                onBlur={(event) => {
                  handleChange(event.target.value as string, user, estimatedPrice);
                }}
                error={Boolean(errors[Number(user.id)])}
                helperText={errors[Number(user.id)] || ''}
              />
            </Box>
          </RowContainer>
        );
      })}
    </Box>
  );
};

const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
`;

const HeaderCell = styled.span<{ textAlign?: string }>`
  display: inline-block;
  color: #848484;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: ${(props) => props.textAlign || 'left'};
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 24px 0;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
`;

const Price = styled.span`
  display: inline-block;
  color: #111;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
`;

const Name = styled.span`
  display: block;
  color: #111;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
`;

const NoOfFollowers = styled.span`
  display: block;
  color: #848484;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;
