import React from 'react';

import styled from '@emotion/styled';

import { VenueListInvitesFilter } from '../../../graphql/API';
import { useAppSelector } from '../../../redux/hooks';
import BaseDialog, { BaseDialogProps } from '../Base';
import { CollabInvitesTable } from './CollabInvitesTable';

export type InviteSortFilter = Pick<VenueListInvitesFilter, 'sortBy' | 'sortDirection'>;

type Props = {
  handleUpdateLastInvite?: (val: string) => void;
} & BaseDialogProps;

const CollabsSentDialog: React.FC<Props> = ({ handleUpdateLastInvite, ...rest }) => {
  const venueID = useAppSelector((state) => state.venue.value)?.id || '';

  return (
    <BaseDialog
      width='1124px'
      height='624px'
      titleComponent={<ModalTitle>Collabs sent</ModalTitle>}
      padding='22px 24px'
      {...rest}
    >
      <CollabInvitesTable venueID={venueID} handleUpdateLastInvite={handleUpdateLastInvite} />
    </BaseDialog>
  );
};

export default CollabsSentDialog;

const ModalTitle = styled.h4`
  color: #111;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;

  margin: 0;
  padding: 0;
`;
