import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { AppConfigContext } from '.';
import { fetchConfig } from '../../config/fetchConfig';
import { AwsConfigV2 } from '../../config/types';

export const AppConfigProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [appConfig, setAppConfig] = useState<AwsConfigV2 | undefined>(undefined);

  const fetchAppConfig = React.useCallback(async () => {
    try {
      const config = await fetchConfig();
      setAppConfig(config as AwsConfigV2);
    } catch (error) {
      setAppConfig(undefined);
      console.error(error);
      enqueueSnackbar('Something went wrong while fetching the app config', { variant: 'error' });
    }
  }, [enqueueSnackbar, setAppConfig]);

  useEffect(() => {
    fetchAppConfig();
  }, [fetchAppConfig]);

  return (
    <AppConfigContext.Provider value={{ config: appConfig as AwsConfigV2 }}>
      {children}
    </AppConfigContext.Provider>
  );
};
