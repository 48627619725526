import { UserPortfolioItem, VenueListUserPortfolioContentFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListUserPortfolioContentQuery = /* GraphQL */ `
  query VenueListUserPortfolioContent($filter: VenueListUserPortfolioContentFilter!) {
    venueListUserPortfolioContent(filter: $filter) {
      items {
        id
        userID
        portfolio
        createdAt
        updatedAt
        asset {
          large
          medium
          small
          thumbnail
          video
        }
      }
    }
  }
`;

export const venueListUserPortfolioContentApi = async (
  filter: VenueListUserPortfolioContentFilter,
): Promise<UserPortfolioItem[]> => {
  const result = await executeGql<UserPortfolioItem[]>(venueListUserPortfolioContentQuery, {
    filter,
  });
  return processGqlResult<UserPortfolioItem[]>('venueListUserPortfolioContent.items', result);
};
