import * as React from 'react';

import Box from '@mui/material/Box';

import { Plan, SubscriptionConditionKind } from '../../../../pages/SubscriptionPlans/types';

type TitleProps = {
  plan: Plan;
  subscriptionKind: SubscriptionConditionKind;
};

const dialogTitles: Record<SubscriptionConditionKind, string> = {
  'cycle-change-decrease': 'Decrease billing period',
  'cycle-change-increase': 'Increase billing period',
  'plan-cancel': 'Cancel Subscription',
  'plan-downgrade': 'Downgrade to',
  'plan-upgrade': 'Upgrade to',
};

const titleColor: Record<Plan, { title: string; color?: string }> = {
  basic: { title: 'Free' },
  starter: { title: 'Starter', color: '#429C12' },
  plus: { title: 'PLUS', color: '#D86EEF' },
};

const Title: React.FC<TitleProps> = ({ plan, subscriptionKind }) => {
  const tc = React.useMemo(() => titleColor[plan], [plan]);

  if (['plan-downgrade', 'plan-upgrade'].includes(subscriptionKind)) {
    return (
      <Box display='flex'>
        {dialogTitles[subscriptionKind]}
        &nbsp;
        <Box sx={{ color: tc.color }}>{tc.title}</Box>
      </Box>
    );
  }

  return dialogTitles[subscriptionKind];
};

export default Title;
