import * as React from 'react';

import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

type SectionTitleProps = {
  title: string;
  count?: number;
};

type CardRadiusProps = {
  sectionTitle: string;
  listHeight: number;
  noPadding?: boolean;
  showBackground?: boolean;
  gradientType?: 'rated' | 'performance';
  sectionSubTitle?: string;
} & BoxProps;

const SectionTitle: React.FC<SectionTitleProps> = ({ title, count }) => (
  <Typography variant='subtitle1'>
    <Box sx={{ fontSize: '18px', fontWeight: '600' }}>{title}</Box>
    {count && (
      <Box component='span' sx={{ fontSize: '15px', pl: 1 }}>
        ({count})
      </Box>
    )}
  </Typography>
);

const GRADIENT_TYPE: Record<string, string> = {
  rated: 'linear-gradient(10deg, rgba(251,237,150,1) 0%, rgba(171,236,214,1) 100%)',
  performance: 'linear-gradient(10deg, rgba(214,255,252,1) 0%, rgba(178,186,255,1) 100%)',
};

const CardRadius: React.FC<React.PropsWithChildren<CardRadiusProps>> = ({
  children,
  sx,
  sectionTitle,
  listHeight,
  noPadding,
  showBackground,
  gradientType = '',
  sectionSubTitle = '',
}) => {
  const renderChild = React.useCallback(
    () => (
      <Box
        sx={{
          maxHeight: listHeight,
          minHeight: noPadding ? listHeight + 26 : listHeight,
          overflowY: 'auto',
          mt: 2,
        }}
      >
        {children}
      </Box>
    ),
    [listHeight, noPadding, children],
  );

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #f7f7f7',
        boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.04)',
        p: noPadding ? undefined : 2,
        borderRadius: 1,
        flex: 1,
        height: '100%',
        ...sx,
      }}
    >
      {showBackground ? (
        <Box
          display='flex'
          alignItems='center'
          gap='28px'
          p={2}
          sx={{
            background: GRADIENT_TYPE[gradientType] ?? '',
            borderRadius: '10px 10px 0 0',
          }}
        >
          <Box component='img' src='/png/trophy.png' height={30} width={30} />
          <SectionTitle title={sectionTitle} />
        </Box>
      ) : (
        <Box display={'flex'} alignItems={'center'} gap={'18px'}>
          <SectionTitle title={sectionTitle} />
          {sectionSubTitle && <Typography fontSize={'15px'}>{sectionSubTitle}</Typography>}
        </Box>
      )}

      {noPadding ? (
        <Box p={2} pt={0}>
          {renderChild()}
        </Box>
      ) : (
        renderChild()
      )}
    </Box>
  );
};

export default CardRadius;
