import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';

import Button from '../../../components/Button/Button';
import { Offer, OfferCategory } from '../../../graphql/API';
import Highlight from './Highlight';

type OfferMetricProps = {
  label: string;
  value: number;
};

type OfferCardProps = {
  data: Offer;
};

const OfferMetric: React.FC<OfferMetricProps> = ({ label, value }) => (
  <Box>
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography variant='body2' fontWeight='500'>
        {label}
      </Typography>
      <Typography variant='body2' fontWeight='500'>
        {value}
      </Typography>
    </Box>
    <Divider />
  </Box>
);

const OfferCard: React.FC<OfferCardProps> = ({ data }) => {
  const navigate = useNavigate();

  const onOfferNavigation = () => {
    navigate(`/offers/${data.id}`);
  };

  return (
    <Card
      sx={{ maxWidth: 345, position: 'relative', cursor: 'pointer' }}
      onClick={onOfferNavigation}
    >
      <Highlight
        privateOffer={data.private || false}
        eventOffer={data.category === OfferCategory.event}
      />
      <CardMedia component='img' height={200} src={String(data.picture?.small)} />
      <CardContent>
        <Typography variant='caption' component='div' color='text.secondary'>
          Name
        </Typography>
        <Typography>{data.title}</Typography>

        <Stack spacing={1} sx={{ mt: 2 }}>
          <OfferMetric label='Total checkins' value={data.totalCheckins || 0} />
          <OfferMetric label='Unique visitors' value={data.totalUniqueVisitors || 0} />
        </Stack>

        <Box marginTop={1}>
          <Typography variant='caption' color='text.secondary'>
            Created {dayjs(data.createdAt).fromNow()}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          to={`/offers/${data.id}/edit`}
          aria-label='Edit'
          id='btn-offer-edit'
          variant='contained'
          size='large'
          color='secondary'
          sx={{
            padding: '4px 10px',
            minWidth: 45,
            width: 45,
            height: 45,
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
          }}
        >
          <EditIcon fontSize='small' />
        </Button>

        {data.private ? (
          <Button
            component={Link}
            variant='outlined'
            size='large'
            fullWidth
            to={`/collabs?collabId=${data.id}`}
            sx={{ marginLeft: 1, borderWidth: 2, borderColor: 'black' }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
            }}
          >
            Invite
          </Button>
        ) : (
          <Button
            component={Link}
            variant='outlined'
            size='large'
            fullWidth
            to={`/offers/${data.id}`}
            sx={{ marginLeft: 1, borderWidth: 2, borderColor: 'black' }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
            }}
          >
            See offer info
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default OfferCard;
