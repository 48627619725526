import { Plan } from '../../../pages/SubscriptionPlans/types';

const ACCESS_LIST = [
  'FILTER_25K_FOLLOWERS',
  'FILTER_50K_FOLLOWERS',
  'COMPLETE_USER_PROFILES',
  'INVITE_1_CREDIT',
  'PRIVATE_OFFER',
  'PREMIUM_ACCESS_CONTROL',
] as const;

export type ACCESS = (typeof ACCESS_LIST)[number];

const starter: ACCESS[] = [
  'FILTER_25K_FOLLOWERS',
  'PRIVATE_OFFER',
  'COMPLETE_USER_PROFILES',
  'PREMIUM_ACCESS_CONTROL',
];

export const mapAccessWithPlan: Record<Plan, ACCESS[]> = {
  basic: [],
  starter,
  plus: starter.concat(['FILTER_50K_FOLLOWERS', 'INVITE_1_CREDIT']),
};
