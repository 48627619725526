import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

interface Props {
  loading?: boolean;
}

export const LoadingBackdrop: React.FC<Props> = ({ loading }) =>
  loading ? (
    <Backdrop open={loading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  ) : null;
