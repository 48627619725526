import { useQuery } from '@tanstack/react-query';

import { venueGetVerificationUrlApi } from '../../../api/balance/venueGetVerificationUrlApi';
import { VenueAccountBalanceRequest } from '../../../graphql/API';

export const useVenueGetVerificationUrl = ({ venueID }: VenueAccountBalanceRequest, enabled = false) => {
  const { data, isFetching, isError, isLoading } = useQuery({
    queryKey: ['venueGetVerificationUrl', { venueID }],
    queryFn: async ({ queryKey }) => {
      const [, filters] = queryKey as [string, VenueAccountBalanceRequest];

      return venueGetVerificationUrlApi(filters);
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(venueID) && enabled,
  });
  return { data, isFetching, isError, isLoading };
};
