import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

const LinkProfile: React.FC<{
  url: string;
  label: string;
  target?: string;
  fontSize?: string;
  fontWeight?: string;
  hoverTextDecoration?: string;
  color?: string;
}> = ({ url, label, target, fontSize = '19px', fontWeight, hoverTextDecoration, color }) => (
  <Box
    component={Link}
    to={url}
    fontSize={fontSize}
    fontWeight={fontWeight}
    sx={{
      textDecoration: 'none',
      ':hover': {
        textDecoration: hoverTextDecoration,
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: color || '#111',
    }}
    target={target}
  >
    {label}
  </Box>
);

export default LinkProfile;
