import * as React from 'react';

import { ShowFnOutput, useModal } from 'mui-modal-provider';

import ConfirmDialog from '../components/ConfirmDialog';
import { ConfirmDialogContext } from './ConfirmDialog.context';
import { ConfirmOptions } from './types';

type PromiseFn = (confirm: boolean) => void;

const ConfirmDialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showModal } = useModal();
  const promiseRef = React.useRef<PromiseFn[]>([]);
  const modelRef = React.useRef<ShowFnOutput<unknown>>();

  const handleClose = React.useCallback(() => {
    promiseRef.current = [];
    modelRef.current?.destroy();
  }, []);

  const handleCancel = React.useCallback(() => {
    const [resolve] = promiseRef.current;
    if (resolve) {
      resolve(false);
      handleClose();
    }
  }, [handleClose]);

  const handleConfirm = React.useCallback(() => {
    const [resolve] = promiseRef.current;

    if (resolve) {
      resolve(true);
      handleClose();
    }
  }, [handleClose]);

  const confirm = React.useCallback(
    ({ title, dialogProps, ...restProps }: ConfirmOptions) => {
      return new Promise<boolean>((resolve, reject) => {
        modelRef.current = showModal(ConfirmDialog, {
          title,
          ...dialogProps,
          childrenProps: {
            ...restProps,
            onCancel: handleCancel,
            onConfirm: handleConfirm,
          },
        });
        promiseRef.current = [resolve, reject];
      });
    },
    [showModal, handleCancel, handleConfirm],
  );

  return (
    <ConfirmDialogContext.Provider value={{ confirm }}>{children}</ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
