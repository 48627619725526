import {
  VennueAccessRequestItem,
  VenueAccessREnum,
  VenueActionAccessRequest,
} from '../../../graphql/API';

export const buttonLoadingState = (
  loadingState: Record<string, boolean>,
  item: VennueAccessRequestItem,
  action: VenueAccessREnum,
) => {
  const found = Object.entries(loadingState).filter(([x]) =>
    x.startsWith(`${item.userID}:${item.offerID}`),
  );

  if (found.length) {
    const exact = found.find(([k]) => k.includes(action)) ?? [];
    const disabled = found.some(([, clicked]) => clicked);
    const [key, loading] = exact;
    const act = key?.split(':').pop();
    return { loading: act === action && loading, disabled };
  }

  return { loading: false, disabled: false };
};

export const mapToActionRequest = (
  row: VennueAccessRequestItem | undefined,
  action: VenueAccessREnum,
): VenueActionAccessRequest | undefined =>
  row?.userID && row?.offerID ? { action, userID: row.userID, offerID: row.offerID } : undefined;
