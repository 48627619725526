import { User } from '../graphql/API';
import { OptionEntry } from './options';

export enum UserCategory {
  lifestyle = 'BP LIFESTYLE',
  select = 'BP SELECT',
}

export const mapUserCategory: Record<UserCategory, string> = {
  'BP LIFESTYLE': 'Lifestyle',
  'BP SELECT': 'Beautypass Select',
};

export const userCategoryOptions: OptionEntry[] = [
  { value: UserCategory.lifestyle, label: mapUserCategory[UserCategory.lifestyle] },
  { value: UserCategory.select, label: mapUserCategory[UserCategory.select] },
];

export const UserCategories = Object.values(UserCategory);

export const getUserCategory = (tags: (string | null)[]): UserCategory | undefined =>
  (tags?.find((x) => x && UserCategories.includes(x as UserCategory)) ?? undefined) as
    | UserCategory
    | undefined;

export const formatName = (user: User | undefined) => {
  if (user?.firstname && user?.lastname) {
    return `${user.firstname} ${user.lastname}`;
  }

  if (user?.firstname) {
    return user.firstname;
  }

  return user?.lastname;
};
