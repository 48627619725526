import { AdminListUserComplaintFilter, } from '../../graphql/API';
import { venueListVenueComplaintCount } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListVenueComplaintCountApi = async (
  filter: AdminListUserComplaintFilter,
): Promise<Record<string, number>> => {
  const result = await executeGql<string>(venueListVenueComplaintCount, { filter });
  return JSON.parse(processGqlResult<string>('venueListVenueComplaintCount', result));
};
