import * as React from 'react';

import { Box, ButtonProps } from '@mui/material';
import MuiBadge from '@mui/material/Badge';

import styled from '@emotion/styled';

import Button from '../../../components/Button/Button';
import SvgIcon from '../../../components/Icons';

type InviteSentButtonProps = {
  highlight?: boolean;
} & ButtonProps;

const COLOR = '#F4779F';

const Badge = styled(MuiBadge)({
  '.MuiBadge-dot': {
    backgroundColor: COLOR,
  },
});

const InviteSentButton: React.FC<InviteSentButtonProps> = ({ highlight, ...restProps }) => {
  return (
    <Button
      id='btn-list-invite'
      startIcon={
        <Badge color='primary' variant='dot' invisible={!highlight}>
          <SvgIcon name='InviteSent' sx={{ fontSize: '20px' }} />
        </Badge>
      }
      {...restProps}
    >
      <Box component='span' sx={{ color: highlight ? COLOR : undefined }}>
        Collabs sent
      </Box>
    </Button>
  );
};

export default InviteSentButton;
