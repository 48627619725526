import dayjs from 'dayjs';

import { PenaltyStatus, Reservation } from '../graphql/API';
import { getDateAndTimeInTimezoneInDayjs } from './date';

export const checkChatHoursIsNotPassed = (reservation: Reservation) => {
  const hours = reservation?.penaltyStatus === PenaltyStatus.active ? 168 : 48;
  const givenDateTimeUTC = getDateAndTimeInTimezoneInDayjs(
    reservation?.effectiveEndDateTime || '',
    reservation?.timeZoneId,
  )?.add(hours, 'hours');

  const currentDateTime = getDateAndTimeInTimezoneInDayjs(
    dayjs.utc().format(),
    reservation?.timeZoneId,
  );

  return currentDateTime?.isBefore(givenDateTimeUTC);
};

export const isAfter48HoursOfOfferEnd = ({ effectiveEndDateTime, timeZoneId }: Reservation) => {
  const givenDateTimeUTC = getDateAndTimeInTimezoneInDayjs(
    effectiveEndDateTime || '',
    timeZoneId,
  )?.add(48, 'hours');

  const currentDateTime = getDateAndTimeInTimezoneInDayjs(dayjs.utc().format(), timeZoneId);

  return currentDateTime?.isAfter(givenDateTimeUTC);
};

export const brandPromotionLimit: Record<string, number> = {
  basic: 5,
  starter: 25,
};
