import React from 'react';

import { Invite, Reservation, VenueReservationContentItemV2 } from '../../graphql/API';

export type NotificationsContextProps = {
  lastReservationCreatedAtTime: string;
  lastCollabUpdatedAtTime: string;
  lastContentUpdatedAtTime: string;
  invite: Invite | null;
  reservation: Reservation | null;
  content: VenueReservationContentItemV2 | null;
  handleUpdateReservation: (val: string) => void;
  handleUpdateInvite: (val: string) => void;
  handleUpdateContent: (val: string) => void;
};

export const NotificationsContext = React.createContext<NotificationsContextProps | undefined>(
  undefined,
);

export const useNotificationsContext = () => React.useContext(NotificationsContext);
