import React from 'react';

import styled from '@emotion/styled';

import icons from '../../themes/icons';
import './ChatSupport.css';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  unreadCount: number;
};

const ChatButton: React.FC<Props> = ({ unreadCount, ...rest }) => {
  return (
    <ButtonContainer {...rest}>
      <ButtonContent>
        <icons.ChatIcon />
        <ButtonText>Support</ButtonText>
      </ButtonContent>
      {unreadCount > 0 && <MessageCounter>{unreadCount}</MessageCounter>}
    </ButtonContainer>
  );
};

export default ChatButton;

const ButtonContainer = styled.button`
  position: relative;
  background-color: #3f51b5;
  color: #fff;
  height: 99px;
  width: 99px;
  border-radius: 100%;
  position: absolute;
  bottom: 55px;
  right: 3%;
  border: none;
  cursor: pointer;
  z-index: 9999;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: auto;
`;

const ButtonText = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
`;

const MessageCounter = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  box-shadow: 0px 3px 5px 0px #3f51b559;
  background-color: #f8f8f8;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #3f51b5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -8%;
`;
