import React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

import Icons from '../../../themes/icons';
import BaseDialog, { BaseDialogProps } from '../Base';
import { CollabItem } from './CollabItem';

const HowCollabsWorksDialog: React.FC<BaseDialogProps> = ({ ...rest }) => {
  return (
    <BaseDialog
      width='750px'
      minHeight='633px'
      titleComponent={<ModalTitle>How Collabs works</ModalTitle>}
      padding='32px 36px'
      {...rest}
    >
      <Box sx={{ marginY: '64px' }}>
        <CollabItem
          showLine
          icon={<Icons.CollabMessageIcon />}
          title='Send Collab'
          description='Your payment will be held for 72 hours. If the invited member does not accept your Collab, your money will be returned to your Beautypass balance.'
        />
      </Box>
      <Box sx={{ marginY: '64px' }}>
        <CollabItem
          showLine
          icon={<Icons.ReceiveUserIcon />}
          title='Receive Confirmation'
          description='You will be notified if your Collab is accepted. The member will arrive at your venue on the scheduled date and time.'
        />
      </Box>

      <CollabItem
        icon={<Icons.ReceivePostIcon />}
        title='Receive post'
        description='You will receive the post for review. Once the Collab is complete, payment will be issued to the member within 48 hours.'
      />
    </BaseDialog>
  );
};

export default HowCollabsWorksDialog;

const ModalTitle = styled.h1`
  color: #111;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;

  padding: 0;
  margin: 0;
`;
