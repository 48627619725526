import React from 'react';

import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';

import { ChatMessageItem } from '../../../graphql/API';
import { onVenueReceiveChatMessageFromUser } from '../../../graphql/subscriptions';
import { GqlError, getError, getErrorFromResult, processGqlResult } from '../../../utils/gql-utils';

export const useUserChatMessageListenerEvent = (fromUserId: string, toUserId: string) => {
  const [message, setMessage] = React.useState<Nullable<ChatMessageItem>>(null);
  const [error, setErorr] = React.useState<GqlError>();

  React.useEffect(() => {
    if (fromUserId && toUserId) {
      const createSub = API.graphql<GraphQLSubscription<ChatMessageItem>>({
        query: onVenueReceiveChatMessageFromUser,
        variables: { userType: 'venue/user', toUserId, fromUserId },
      }).subscribe({
        next: ({ value }) => {
          const _message = processGqlResult<ChatMessageItem>(
            'onVenueReceiveChatMessageFromUser',
            value,
          );
          setMessage(_message);
          const errorFromResponse = getErrorFromResult(value);

          if (errorFromResponse) {
            throw errorFromResponse;
          }
        },
        error: (ex) => {
          const raisedError = getError(ex);
          console.error(raisedError);
          setErorr(raisedError);
        },
      });

      return () => {
        createSub.unsubscribe();
      };
    }
  }, [fromUserId, setMessage, toUserId]);

  return {
    message,
    error,
  };
};
