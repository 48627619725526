import * as React from 'react';

import { OfferCategory, User } from '../../../graphql/API';
import BaseDialog, { BaseDialogProps } from '../Base';
import CreateInvite from './CreateInvite';
import CreateInviteProvider, { CreateInviteProviderProps } from './context/CreateInvite.provider';
import { Step } from './context/types';

export type InviteDialogProps = {
  users: User[];
  premiumCredits?: number;
  nonPremiumCredits?: number;
  onSuccess: CreateInviteProviderProps['onSuccess'];
  modalRef: CreateInviteProviderProps['modalRef'];
} & BaseDialogProps;

const InviteDialog: React.FC<InviteDialogProps> = ({
  users,
  premiumCredits,
  nonPremiumCredits,
  modalRef,
  onSuccess,
  ...restProps
}) => {
  const [inviteStep, setInviteStep] = React.useState(1);
  const [selectedOfferCategory, setSelectedOfferCategory] = React.useState<OfferCategory>();

  const handleInviteStep = (val: number, offerCategory?: OfferCategory) => {
    setInviteStep(val);
    setSelectedOfferCategory(offerCategory);
  };

  return (
    <BaseDialog
      height={
        selectedOfferCategory === OfferCategory.event && inviteStep === Step.Success - 1
          ? '674px'
          : inviteStep === Step.Summary ||
              (selectedOfferCategory === OfferCategory.event && inviteStep === Step.Summary - 1)
            ? '804px'
            : '694px'
      }
      width={
        inviteStep === Step.Success ||
        (selectedOfferCategory === OfferCategory.event && inviteStep === Step.Success - 1)
          ? '600px'
          : '900px'
      }
      padding='8px 10px'
      titleSx={{ mt: 3 }}
      {...restProps}
    >
      <CreateInviteProvider
        users={users}
        premiumCredits={premiumCredits}
        nonPremiumCredits={nonPremiumCredits}
        onSuccess={onSuccess}
        modalRef={modalRef}
      >
        <CreateInvite modalRef={modalRef} handleInviteStep={handleInviteStep} />
      </CreateInviteProvider>
    </BaseDialog>
  );
};

export default InviteDialog;
