import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ListSkeleton: React.FC = () => {
  return (
    <Stack spacing={2}>
      {[...Array(3)].map((_, i) => (
        <Box key={i} display='flex'>
          <Box component={AspectRatio} ratio='16/9' sx={{ width: 300 }}>
            <Skeleton variant='rounded' />
          </Box>

          <Stack>
            <Skeleton variant='text' width={300} sx={{ ml: 3 }} />
            <Skeleton variant='text' width={150} sx={{ ml: 3 }} />
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

export default ListSkeleton;
