import * as React from 'react';

import { Box, Button, Typography } from '@mui/material';

import {
  ReleasePaymentButton,
  StopPaymentButton,
} from '../../../pages/ReservationDetail/components/PaymentButtons';
import BaseDialog, { BaseDialogProps } from '../Base';

type Props = {
  title: string;
  subTitle?: string;
  isStopPayment?: boolean;
  onConfirm: () => void;
} & BaseDialogProps;

export const PaymentConfirmDialog: React.FC<Props> = ({
  title,
  subTitle,
  onConfirm,
  isStopPayment = false,
  ...restProps
}) => {
  return (
    <BaseDialog height='320px' width='450px' {...restProps}>
      <Typography fontSize='18px' fontWeight={500} mt={1}>
        {title}
      </Typography>
      {subTitle && (
        <Typography sx={{ mt: '16px', fontSize: '15px', fontWeight: 400 }}>{subTitle}</Typography>
      )}

      <Box sx={{ mt: '95px', display: 'flex', alignItems: 'stretch', gap: '12px' }}>
        <Button
          variant='outlined'
          color='primary'
          fullWidth
          sx={{ paddingY: '10px' }}
          onClick={() => {
            if (restProps?.onClose) restProps?.onClose({}, 'backdropClick');
          }}
        >
          Cancel
        </Button>
        {isStopPayment && (
          <StopPaymentButton
            sx={{ backgroundColor: '#DC5564', color: '#fff' }}
            variant='contained'
            fullWidth
            onClick={() => {
              onConfirm();
              if (restProps?.onClose) restProps?.onClose({}, 'backdropClick');
            }}
          >
            Stop payment
          </StopPaymentButton>
        )}
        {!isStopPayment && (
          <ReleasePaymentButton
            fullWidth
            variant='contained'
            onClick={() => {
              onConfirm();
              if (restProps?.onClose) restProps?.onClose({}, 'backdropClick');
            }}
          >
            Release payment
          </ReleasePaymentButton>
        )}
      </Box>
    </BaseDialog>
  );
};
