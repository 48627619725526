import * as React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

import { User } from '../../../graphql/API';
import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import ProfileCard from './ProfileCard';
import ProfileCardSkeleton from './ProfileCardSkeleton';
import { OnChange } from './types';

type ProfileCheckBoxGroupProps = {
  data: User[];
  isLoading: boolean;
  onChange: (values: string[]) => void;
  values: string[];
};

const commonGrid = (key: string): GridProps => ({ item: true, key, xs: 2.4, md: 3, lg: 3, xl: 2.4 });

const ProfileCheckBoxGroup: React.FC<ProfileCheckBoxGroupProps> = ({
  data,
  isLoading,
  onChange,
  values,
}) => {
  const subscriptionAccess = useSubscriptionAccess();

  const handleOnChanged = React.useCallback<OnChange>(
    (e, checked) => {
      const v = e.target.value;

      const vv = checked ? [...values, v] : values.filter((y) => y !== v);
      onChange(vv);
    },
    [onChange, values],
  );

  return (
    <Grid container spacing={3} rowSpacing={10}>
      {!isLoading
        ? data.map((x) => (
            <Grid {...commonGrid(x.id)}>
              <ProfileCard
                data={x}
                fullProfile={subscriptionAccess?.includes('COMPLETE_USER_PROFILES')}
                onChange={handleOnChanged}
                checked={values.includes(x.id)}
              />
            </Grid>
          ))
        : [...Array(6)].map((x, i) => (
            <Grid {...commonGrid(`grid-skeleton-${i}`)}>
              <ProfileCardSkeleton />
            </Grid>
          ))}
    </Grid>
  );
};

export default ProfileCheckBoxGroup;
