import React from 'react';

import { LinearProgress } from '@mui/material';

import styled from '@emotion/styled';
import { Elements } from '@stripe/react-stripe-js';

import { useBalancePaymentIntentMutation } from '../../../hooks/reactQuery/balance/useBalancePaymentIntentMutation';
import getStripe from '../../../pages/Credit/components/getStripe';
import { useAppSelector } from '../../../redux/hooks';
import BaseDialog, { BaseDialogProps } from '../Base';
import { CheckoutFunds } from './CheckoutFunds';

type Props = {
  amount: number;
  currency?: string;
  amountWithoutFee?: number;
  onSuccess?: () => void;
} & BaseDialogProps;

export const AddFundsStripeDialog: React.FC<Props> = ({
  currency = '',
  amount,
  amountWithoutFee = 0,
  onSuccess,
  ...rest
}) => {
  const balance = useAppSelector((state) => state.balance.value);

  const [clientSecret, setClientSecret] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);

  const balancePaymentIntent = useBalancePaymentIntentMutation({
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (response) => {
      setClientSecret(response?.paymentIntentClientSecret);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  React.useEffect(() => {
    if (amount) {
      balancePaymentIntent.mutate({ paymentAmount: amount });
    }
  }, [amount]);

  return (
    <BaseDialog
      width='550px'
      minHeight='610px'
      titleComponent={<ModalTitle>Add Funds</ModalTitle>}
      padding='10px 20px'
      {...rest}
    >
      {isLoading && <LinearProgress sx={{ mt: 2 }} />}
      {clientSecret && balance?.accountId && (
        <Elements
          stripe={getStripe()}
          options={{
            clientSecret,
          }}
        >
          <CheckoutFunds
          amountWithoutFee={amountWithoutFee}
            amount={amount}
            currency={currency}
            onSuccess={() => {
              if (onSuccess) {
                if (rest?.onClose) rest?.onClose({}, 'backdropClick');
                onSuccess();
              }
            }}
          />
        </Elements>
      )}
    </BaseDialog>
  );
};

const ModalTitle = styled.h3`
  color: #111;
  font-size: 24px;
  font-weight: 500;

  padding: 0;
  margin: 0;
`;
