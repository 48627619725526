import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { uploadFileToS3 } from '../../../api/files/uploadFileToS3';
import { venueUpdateVenueApi } from '../../../api/venue/venueUpdateVenueApi';
import { useAppSelector } from '../../../redux/hooks';

type MutationResult<T> = UseMutationResult<void, unknown, T, unknown>;
type QueryResult = {
  uploadMutation: MutationResult<File>;
};
const useProfileMutation = (): QueryResult => {
  const venueState = useAppSelector((state) => state.venue.value);

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const s3Key = await uploadFileToS3(file);

      await venueUpdateVenueApi({ id: String(venueState?.id), venueImageKey: s3Key });
    },
    onSuccess: () => {
      console.log('success');
    },
  });

  return { uploadMutation };
};

export default useProfileMutation;
