import React from 'react';

import { Box, Button } from '@mui/material';

import styled from '@emotion/styled';

import { formatNumberWithCommas } from '../../../utils/formatter';
import BaseDialog, { BaseDialogProps } from '../Base';

type Props = {
  cost: number;
  balance?: number;
  onConfirm?: () => void;
} & BaseDialogProps;

const BuyContentDialog: React.FC<Props> = ({ cost, balance, onConfirm, ...rest }) => {
  const { onClose } = rest;
  return (
    <BaseDialog
      width='470px'
      height='500px'
      padding='16px 20px'
      titleComponent={<Heading>Buy Content</Heading>}
      iconSx={{
        top: 24,
        right: 24,
      }}
      {...rest}
    >
      <BuyContentDescription>
        Confirm the purchase of this content. The purchase is non-refundable.
      </BuyContentDescription>
      <Box sx={{ marginTop: '52px' }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ marginY: '12px' }}
        >
          <BalanceText>Starting Balance:</BalanceText>
          <BalanceText>${formatNumberWithCommas(balance || 0)}</BalanceText>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ marginY: '12px' }}
        >
          <BalanceText>Total</BalanceText>
          <BalanceText>${formatNumberWithCommas(cost)}</BalanceText>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ marginY: '12px', borderTop: '1px solid #D9D9D9', paddingY: '8px' }}
        >
          <BalanceText>Ending Balance</BalanceText>
          <BalanceText>${balance && formatNumberWithCommas(balance - cost)}</BalanceText>
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} gap={2} marginTop={8}>
        <Button
          variant='outlined'
          fullWidth
          sx={{
            paddingY: '16px',
            fontSize: '15px',
            fontWeight: 500,
            borderRadius: '8px',
            border: '2px solid #111',
            ':hover': {
              border: '2px solid #111',
            },
          }}
          onClick={() => {
            if (onClose) {
              onClose({}, 'backdropClick');
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          fullWidth
          sx={{ paddingY: '16px', fontSize: '15px', fontWeight: 500, borderRadius: '8px' }}
          onClick={() => {
            if (onConfirm) onConfirm();
            if (onClose) {
              onClose({}, 'backdropClick');
            }
          }}
        >
          Confirm
        </Button>
      </Box>
    </BaseDialog>
  );
};

export default BuyContentDialog;

const Heading = styled.h4`
  margin: 0;
  padding: 0;

  color: #111;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

const BuyContentDescription = styled.p`
  margin: 0;
  padding: 0;

  color: #5f5f60;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
`;

const BalanceText = styled.span`
  color: #111;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
`;
