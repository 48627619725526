import * as React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListReservationContentApi } from '../../api/reservations/venueListReservationContentApi';
import {
  VenueListReservationsContentFilter,
  VenueReservationContentItemV2,
} from '../../graphql/API';
import { useAppSelector } from '../../redux/hooks';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

const useListReservationContentQuery = (
  filters: Partial<VenueListReservationsContentFilter> | undefined,
  limit = PAGE_LIMIT,
): QueryResult<VenueReservationContentItemV2[]> => {
  const venueState = useAppSelector((state) => state.venue.value);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useInfiniteQuery<
    VenueReservationContentItemV2[]
  >({
    initialPageParam: 0,
    queryKey: ['list-reservation-content', venueState?.id, filters],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID, queryFilters] = queryKey as [
        string,
        number,
        VenueListReservationsContentFilter,
      ];
      const offset = <number>pageParam * limit;

      return venueListReservationContentApi({
        ...queryFilters,
        limit,
        offset,
        venueID,
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups?.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueState?.id,
    refetchOnWindowFocus: false,
  });

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useListReservationContentQuery;
