import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

type GoBackProps = {
  to?: string;
};

const GoBack: React.FC<GoBackProps> = ({ to }) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const linkProps = React.useMemo<IconButtonProps<React.ElementType>>(() => {
    if (to) {
      const props: IconButtonProps<typeof Link> = {
        component: Link,
        to: String(to),
      };

      return props;
    }

    return {
      onClick: handleClick,
    } as IconButtonProps<'button'>;
  }, [to, handleClick]);

  return (
    <IconButton aria-label='back' color='primary' {...linkProps}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default GoBack;
