import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Icons from '../../../themes/icons';
import ChipOptions, { ChipOptionsProps } from '../../ChipOptions/ChipOptions';
import { HtmlTooltip } from '../../HtmlTooltip/HtmlTooltip';
import { MemberCategoryTooltipContent } from './MemberCategoryTooltipContent';

type ChipOptionContainerProps = {
  label: string;
  verifiedIcon?: boolean;
  eyeIconForMemberCategory?: boolean;
} & ChipOptionsProps;

const ChipOptionContainer: React.FC<ChipOptionContainerProps> = ({
  label,
  verifiedIcon = false,
  eyeIconForMemberCategory = false,
  ...restProps
}) => {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} sx={{ mb: 1 }} gap={'5px'}>
        <Typography component='div' variant='caption' fontWeight='bold'>
          {label}
        </Typography>
        {eyeIconForMemberCategory && (
          <HtmlTooltip
            placement='top'
            title={
              <MemberCategoryTooltipContent />
            }
          >
            <Icons.InfoIcon />
          </HtmlTooltip>
        )}
        {verifiedIcon && (
          <img src='/png/verified@2x.png' alt='verified-icon' height={16} width={16} />
        )}
      </Box>
      <ChipOptions {...restProps} />
    </Box>
  );
};

export default ChipOptionContainer;
