const colours = ['#37BB90', '#765BC6', '#C65BBB', '#C65B5B', '#FFDC51'];

const randomColour = () => '#' + (Math.random().toString(16) + '00000').slice(2, 8);

const offerColourGenerator = () => {
  const offerColours: string[] = [];

  const genNewColour = (i: number) => colours[i] || randomColour();

  return {
    getColor: (i: number) => {
      const colour = offerColours[i];

      if (!colour) {
        const newColour = genNewColour(i);
        offerColours.push(newColour);

        return newColour;
      }

      return colour;
    },
  };
};

export default offerColourGenerator();
