import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { useMutation } from '@tanstack/react-query';
import { ShowFnOutput } from 'mui-modal-provider';

import { venueUpdateSubscriptionApi } from '../../../../api/stripe/venueUpdateSubscriptionApi';
import { OfferCategory, VenueSubscriptionItem } from '../../../../graphql/API';
import { toCurrencyAmount } from '../../../../pages/Credit/components/PremiumSubscriptionSection/helper';
import {
  getBrandCycleOptions,
  getCycleOptions,
  getPlanPriceBySku,
  toDecimal,
} from '../../../../pages/SubscriptionPlans/common/helper';
import { extractSubscriptionPlan } from '../../../../pages/SubscriptionPlans/common/helper';
import PlanPrice from '../../../../pages/SubscriptionPlans/components/Plans/PlanPrice';
import { Plan, SubscriptionConditionKind } from '../../../../pages/SubscriptionPlans/types';
import { StripePriceWithMetadata } from '../../../../redux/features/subscription/types';
import { useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Button/Button';
import LoadingButton from '../../../Button/LoadingButton';
import { BaseDialogProps } from '../../Base';
import CycleOptions from './CycleOptions/CycleOptions';

export type CycleChangeProps = {
  price: StripePriceWithMetadata | undefined;
  subscription: VenueSubscriptionItem | undefined;
  subscriptionKind: SubscriptionConditionKind;
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
};

const mapLabel: Record<Plan, { title: string }> = {
  basic: { title: 'Free' },
  starter: { title: 'Starter' },
  plus: { title: 'PLUS' },
};

const mapSubscriptionKind: Partial<
  Record<
    SubscriptionConditionKind,
    { description: (p: number, pc?: string) => string; buttonLabel: (p: string) => string }
  >
> = {
  'plan-upgrade': {
    buttonLabel: (p) => `Upgrade to ${p}`,
    description: (p, pc) => {
      if (p > 0) {
        return `You can take advantage of the new plan benefits by paying the difference of ${pc} based on your current plan`;
      }
      return `You can take advantage of the new plan benefits. The difference in fare will be added to your credit balance and included in your next invoice`;
    },
  },
  'plan-downgrade': {
    buttonLabel: (p) => `Downgrade to ${p}`,
    description: () =>
      'You can take advantage of the new plan benefits. The difference in fare will be added to your credit balance and included in your next invoice',
  },
};

const calculateDiff = (newPrice: number, currency: string) =>
  toCurrencyAmount(toDecimal(String(newPrice)), currency);

const UpdatePlan: React.FC<CycleChangeProps> = ({
  price,
  subscription,
  subscriptionKind,
  modalRef,
}) => {
  const [newPrice, setNewPrice] = React.useState(price);
  const { pricing } = useAppSelector((state) => state.subscription.value);
  const { type: venueType } = useAppSelector((state) => state.venue.value) || {};

  const [plan, , cycle] = extractSubscriptionPlan(price?.metadata.ProductSku ?? '');

  const mutation = useMutation({
    mutationFn: venueUpdateSubscriptionApi,
    onSuccess: () => {
      window.location.href = `/plans?message_type=${subscriptionKind}`;
    },
  });

  const options = React.useMemo(
    () =>
      venueType === OfferCategory.brand
        ? getBrandCycleOptions(plan, OfferCategory.brand, pricing ?? [])
        : getCycleOptions(plan, pricing ?? []),
    [plan, pricing, venueType],
  );
  const ms = React.useMemo(() => mapSubscriptionKind[subscriptionKind], [subscriptionKind]);

  const priceDiff = React.useMemo(
    () => Number(newPrice?.unit_amount ?? 0) - Number((subscription?.price ?? 0) * 100),
    [newPrice?.unit_amount, subscription],
  );

  const priceDiffCurrency = React.useMemo(
    () => calculateDiff(priceDiff, subscription?.currency ?? ''),
    [priceDiff, subscription?.currency],
  );

  const optionsChangeHandler = React.useCallback(
    (c: string) => {
      const sku = `${plan}_venue_${c}`;

      const np = getPlanPriceBySku(sku, pricing ?? []);

      setNewPrice(np);
    },
    [plan, pricing],
  );

  return (
    <Box>
      <Box>
        <Typography sx={{ marginBottom: 2 }}>Select a billing period:</Typography>
        <CycleOptions options={options} cycle={cycle} onChange={optionsChangeHandler} />
      </Box>

      <Box sx={{ marginTop: 3, marginBottom: 5 }}>
        <Typography fontWeight='medium'>New Price:</Typography>
        <PlanPrice
          price={newPrice}
          highlightPrice='#37BB90'
          showBasePrice={false}
          boldBilledPrice={false}
        />
      </Box>

      <Typography sx={{ fontWeight: '700' }}>Difference</Typography>

      <Typography>{ms?.description(priceDiff, priceDiffCurrency)}</Typography>

      <Stack spacing={2} direction='row' sx={{ mt: 6, width: '100%' }}>
        <Button
          size='large'
          variant='outlined'
          sx={{ px: 10 }}
          onClick={() => modalRef.current?.destroy()}
        >
          Cancel
        </Button>
        <LoadingButton
          size='large'
          variant='contained'
          loading={mutation.isPending}
          fullWidth
          onClick={() => {
            mutation.mutate({
              newPriceId: newPrice?.id,
              subscriptionId: subscription?.subscriptionId,
            });
          }}
        >
          {ms?.buttonLabel(mapLabel[plan].title)}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default UpdatePlan;
