import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import AccessRequestDesc from './AccessRequestDesc';

const AccessRequestDescDialog: React.FC<BaseDialogProps> = (props) => {
  return (
    <BaseDialog {...props}>
      <AccessRequestDesc />
    </BaseDialog>
  );
};

export default AccessRequestDescDialog;
