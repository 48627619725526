import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Box, Typography } from '@mui/material';

import { ShowFnOutput, useModal } from 'mui-modal-provider';

import { ConversationItem, OfferCategory, Reservation, ReservationStatus } from '../../graphql/API';
import Icons from '../../themes/icons';
import { formatNumber } from '../../utils/formatter';
import Button from '../Button/Button';
import { BaseDialogProps } from '../Dialogs/Base';
import CancelReservationDialog from '../Dialogs/CancelReservation/CancelReservationDialog';
import { EditReservationDateAndTimeDialog } from '../Dialogs/EditReservationDateAndTime/EditReservationDateAndTimeDialog';

type Props = {
  conversation: ConversationItem;
  reservationDetailsRefetch?: () => void;
  reservationDetail?: Reservation;
};

export const ChatHeader: React.FC<Props> = ({
  conversation: { firstname, lastname, images, instagramHandle, instagramFollowers, toUserId },
  reservationDetail,
  reservationDetailsRefetch,
}) => {
  const { showModal } = useModal();
  const editDateAndTimeModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const cancelReservationModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();

  const onEditReservationDateAndTimeDialogHandle = () => {
    editDateAndTimeModalRef.current = showModal(EditReservationDateAndTimeDialog, {
      modalRef: editDateAndTimeModalRef,
      reservationDetail,
      refetchReservationDetails: reservationDetailsRefetch,
    });
  };

  const onCancelReservationDialogHandle = () => {
    cancelReservationModalRef.current = showModal(CancelReservationDialog, {
      modalRef: cancelReservationModalRef,
      reservationDetail,
      refetchReservationDetails: reservationDetailsRefetch,
    });
  };

  const offerCategory = reservationDetail?.offer?.category || reservationDetail?.offerCategory;

  const showReservationButtons =
    (offerCategory &&
      offerCategory === OfferCategory.beauty &&
      reservationDetail.offer?.confirmWithCall) ||
    false;

  const [, toUserID] = toUserId.split('-')

  return (
    <Box
      sx={{
        height: '72px',
        paddingX: '20px',
        bgcolor: '#F9F9F9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '18px' }}>
        <Avatar src={images?.thumbnail || ''} alt={firstname || 'user-picture'} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
          <Typography
            sx={{ fontSize: '15px', fontWeight: 500, color: '#1E55A1' }}
            component={Link}
            to={`/users/${toUserID}`}
          >
            {firstname} {lastname}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {instagramHandle && (
              <Typography
                sx={{ fontSize: '13px', fontWeight: 500, textDecoration: 'none', color: '#1E55A1' }}
                component={'a'}
                href={`https://instagram.com/${instagramHandle}`}
                target='_blank'
              >
                @{instagramHandle}
              </Typography>
            )}
            <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
              {formatNumber(+(instagramFollowers || 0))} followers
            </Typography>
          </Box>
        </Box>
      </Box>

      {showReservationButtons && (
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <Button
            startIcon={<Icons.CalendarIcon />}
            variant='outlined'
            sx={{ px: 2.5 }}
            onClick={onEditReservationDateAndTimeDialogHandle}
          >
            Edit reservation
          </Button>

          <Button
            startIcon={<Icons.DeleteIcon />}
            variant='outlined'
            color='error'
            sx={{ px: 2.5 }}
            onClick={onCancelReservationDialogHandle}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};
