import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';

import { User, VenueRatedUser } from '../../../graphql/API';
import { formatName } from '../../../utils/user';
import UserAvatarCategory from '../../Avatar/UserAvatarCategory/UserAvatarCategory';
import { ListItem } from '../Base/types';

const mapToItem = (data: VenueRatedUser): ListItem => ({
  avatar: <UserAvatarCategory src={data.picture?.thumbnail || ''} />,
  to: `/users/${data.id}`,
  primaryText: formatName({ firstname: data.firstname, lastname: data.lastname } as User) || '',
  secondaryText: <></>,
  actionSlot: (
    <Typography component='div' display='flex' alignItems='center'>
      <StarIcon sx={{ color: '#37BB90', mr: 1, fontSize: '1.3rem' }} />
      {data.averageRatings.toFixed(1)} Average rating ({data.totalRatings} ratings)
    </Typography>
  ),
});

export const mapToListItem = (data: VenueRatedUser[]) => data.map(mapToItem);
