import { OptionItem } from '../../../components/Formiks/SelectOption/SelectOption';
import { ACCESS } from '../../../redux/features/subscription/access';
import UpgradePlanTips from './UpgradePlanTips';

export const followerOptions: (subscriptionAccess: ACCESS[] | undefined) => OptionItem[] = (sa) => [
  { label: '0+', value: 0 },
  { label: '1k+', value: 1000 },
  { label: '5k+', value: 5000 },
  { label: '10k+', value: 10000 },
  {
    label: !sa?.includes('FILTER_25K_FOLLOWERS') ? (
      <UpgradePlanTips label='25k+' planType='starter' />
    ) : (
      '25k+'
    ),
    value: 25000,
    disabled: !sa?.includes('FILTER_25K_FOLLOWERS'),
  },
  {
    label: !sa?.includes('FILTER_50K_FOLLOWERS') ? (
      <UpgradePlanTips label='50k+' planType='plus' />
    ) : (
      '50k+'
    ),
    value: 50000,
    disabled: !sa?.includes('FILTER_50K_FOLLOWERS'),
  },
];
