import * as React from 'react';

import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';

import { URLSearchParams } from 'url';

import useUrlQuery from '../../../../hooks/useUrlQuery';
import { subscriptionConditionKinds } from '../../types';

const messageTypes = [...subscriptionConditionKinds, 'welcome'] as const;
type MessageType = (typeof messageTypes)[number];

const mapMessage: (
  q: URLSearchParams,
) => Record<MessageType, { title?: string; message: React.ReactNode }> = (q) => ({
  welcome: {
    message: <b>Welcome aboard! Start enjoying your premium benefits right away</b>,
  },
  'cycle-change-decrease': {
    message: <b>Billing period successfully changed!</b>,
  },
  'cycle-change-increase': {
    message: <b>Billing period successfully changed!</b>,
  },
  'plan-upgrade': {
    message: <b>Plan changed successfully! Enjoy the best of Beautypass with Beautypass PLUS!</b>,
  },
  'plan-downgrade': {
    message: <b>Plan changed successfully!</b>,
  },
  'plan-cancel': {
    title: 'Your Subscription was canceled',
    message: `Your plan remains active until ${q.get(
      'subscriptionEnd',
    )}. Thank you for using ${q.get('plan')}`,
  },
});

const AlertMessage: React.FC = () => {
  const q = useUrlQuery();

  const res = React.useMemo(() => {
    const mt = q.get('message_type') as MessageType | undefined;

    if (mt && messageTypes.includes(mt)) {
      const ddd = mapMessage(q)[mt];

      return ddd;
    }
  }, [q]);

  return (
    res && (
      <Alert icon={false} severity='success' sx={{ marginTop: 3, marginBottom: 2 }}>
        {res.title && <AlertTitle>{res.title}</AlertTitle>}

        {res.message}
      </Alert>
    )
  );
};

export default AlertMessage;
