import * as React from 'react';

import { Box, Typography } from '@mui/material';

import BaseBorder from './BaseBorder';

const EmptySaved: React.FC = () => {
  return (
    <BaseBorder>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', py: 5 }}>
        <Box component='img' src='/png/empty-content-saved-2x.png' sx={{ width: '5rem' }} />
        <Box
          sx={{
            p: 1,
            mt: 4,
            maxWidth: '12rem',
          }}
        >
          <Typography fontWeight='bold' textAlign='center'>
            No saved content...
          </Typography>
          <Typography variant='body2' textAlign='center' sx={{ color: '#707070' }}>
            The saved content will be displayed here
          </Typography>
        </Box>
      </Box>
    </BaseBorder>
  );
};

export default EmptySaved;
