import React from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  LinearProgress,
  styled as MuiStyled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  tableCellClasses,
} from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { useNotificationsContext } from '../../../contexts/NotificationsContext';
import { VenueListInvitesFilter } from '../../../graphql/API';
import { useListInvitesV2Query } from '../../../hooks/reactQuery/useListInvitesV2Query';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import { formatNumberWithCommas } from '../../../utils/formatter';
import SvgIcon, { IconName } from '../../Icons';
import { InviteStatus } from './InviteStatus';

const StyledTableCell = MuiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#848484',
    borderBottom: '1px solid #E0E0E0',
    fontSize: '15px',
    fontWeight: 400,
    padding: '0 0 4px 0',
    margin: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '15px',
    padding: '16px 0',
  },
}));

const StyledTableRow = MuiStyled(TableRow)(() => ({
  '&': {
    backgroundColor: 'transparent',
  },
  '& td, & th': {
    borderBottom: '1px solid #E0E0E0',
  },
}));

const REQUIREMENT_ICON_MAP: Record<string, string> = {
  postOnInstagramStories: 'Story',
  postOnInstagramStories3x: 'Story',
  postOnInstagramFeed: 'Feed',
  postOnInstagramReels: 'Reel',
};

const REQUIREMENT_MAP: Record<string, string> = {
  postOnInstagramStories: 'Story',
  postOnInstagramStories3x: '3 Stories',
  postOnInstagramFeed: 'Feed',
  postOnInstagramReels: 'Reel',
};

type Props = {
  venueID: string;
  handleUpdateLastInvite?: (val: string) => void;
};

export const CollabInvitesTable: React.FC<Props> = ({ venueID, handleUpdateLastInvite }) => {
  const [filter, setFilter] = React.useState<Partial<VenueListInvitesFilter>>({
    sortBy: 'invites.updatedAt',
    sortDirection: 'desc',
  });

  const {
    data: invites,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useListInvitesV2Query(venueID, { ...filter });

  const { lastElementRef } = useInfiniteScroll({
    hasMore: hasNextPage,
    isFetching: isFetching || isLoading,
    cb: fetchNextPage,
  });

  React.useEffect(() => {
    if (invites.length > 0 && handleUpdateLastInvite) {
      handleUpdateLastInvite(invites[0].updatedAt || '');
    }
  }, [invites]);

  const headerCells: Array<Record<string, string | boolean | number>> = [
    { id: 'picture', label: '', sortable: false, align: 'left' },
    { id: 'users.firstname', label: 'User', sortable: true, align: 'left' },
    { id: 'offers.title', label: 'Offer', sortable: true, align: 'left' },
    { id: 'invites.updatedAt', label: 'Invite date', sortable: true, align: 'left' },
    { id: 'invites.requirements', label: 'Requirement', sortable: true, align: 'left' },
    { id: 'invites.offerPrice', label: 'Cost', sortable: true, align: 'right' },
    { id: 'invites.status', label: 'Status', sortable: true, align: 'center' },
  ];

  return (
    <>
      {(isLoading || isFetching) && <LinearProgress sx={{ mt: 2 }} />}
      <TableContainer component={'div'} sx={{ marginTop: 1, height: '92%' }}>
        <Table stickyHeader sx={{ minWidth: '100%' }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              {headerCells.map((header) => (
                <StyledTableCell
                  align={header.align as TableCellProps['align']}
                  variant='head'
                  width={header.name === 'Requirement' ? 66 : undefined}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      active={header.id === filter?.sortBy}
                      direction={filter?.sortDirection as 'desc' | 'asc'}
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          sortDirection: filter?.sortDirection === 'asc' ? 'desc' : 'asc',
                          sortBy: header.id as string,
                        }));
                      }}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invites.map((invite, index) => (
              <StyledTableRow
                key={index}
                {...(invites.length === index + 1 && {
                  ref: lastElementRef,
                })}
              >
                <StyledTableCell>
                  <Avatar
                    variant='rounded'
                    alt={invite.user?.firstname}
                    src={invite.user?.picture?.thumbnail || ''}
                    sx={{ borderRadius: '50%' }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <MuiLink to={`/users/${invite.userID}`}>
                    {invite.user?.firstname} {invite.user?.lastname}
                  </MuiLink>
                </StyledTableCell>
                <StyledTableCell width={220}>
                  <MuiLink to={`/offers/${invite.offerID}`}>{invite.offer?.title}</MuiLink>
                </StyledTableCell>
                <StyledTableCell>{dayjs(invite.updatedAt).format('ll')}</StyledTableCell>
                <StyledTableCell width={96}>
                  <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 2 }}>
                    {REQUIREMENT_ICON_MAP[invite.requirements || ''] && (
                      <SvgIcon name={REQUIREMENT_ICON_MAP[invite.requirements || ''] as IconName} />
                    )}
                    <span>{REQUIREMENT_MAP[invite.requirements || ''] || '-'}</span>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align='right'>
                  ${formatNumberWithCommas(invite.offerPrice || 0)}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <InviteStatus status={invite.status || ''} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const MuiLink = styled(Link)`
  color: #111111;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
