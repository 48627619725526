import React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

import BaseDialog, { BaseDialogProps } from '../Base';

const UsageRightsDialog: React.FC<BaseDialogProps> = ({ ...rest }) => {
  return (
    <BaseDialog
      width='750px'
      minHeight='775px'
      titleComponent={<ModalTitle>Usage Rights</ModalTitle>}
      padding='24px 32px'
      iconSx={{ right: 24, top: 30 }}
      {...rest}
    >
      <UsageDescription>
        When you purchase content from our platform, you gain specific usage rights. Here's a clear
        breakdown:
      </UsageDescription>
      <RulesContainer>
        <Box>
          <RulesHeading>1. Content Ownership:</RulesHeading>
          <UsageDescription>
            The creator retains ownership of the content. However, you have the right to use the
            purchased content as outlined below.
          </UsageDescription>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <RulesHeading>2. Usage Rights:</RulesHeading>
          <UsageDescription>
            With your purchase, you are granted the following rights:
          </UsageDescription>
          <ul style={{ margin: 2, paddingLeft: '24px' }}>
            <li>
              <ListContainer>
                <RulesHeading>Commercial Use:</RulesHeading>
                <UsageDescription>
                  You can use the content for your Instagram advertising and marketing campaigns,
                  and on your business's social media channels.
                </UsageDescription>
              </ListContainer>
            </li>
            <li>
              <ListContainer>
                <RulesHeading>Duration and Territory:</RulesHeading>
                <UsageDescription>
                  You have the right to use the purchased content for a period of 90 days from the
                  date of purchase. The usage rights are global, allowing you to use the content
                  without any regional restrictions.
                </UsageDescription>
              </ListContainer>
            </li>
            <li>
              <ListContainer>
                <RulesHeading>Modification Rights:</RulesHeading>
                <UsageDescription>You cannot modify the content.</UsageDescription>
              </ListContainer>
            </li>
          </ul>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <RulesHeading>3. Creator’s Rights:</RulesHeading>
          <UsageDescription>
            The original creator still holds the right to use the content for their own personal and
            promotional purposes unless otherwise agreed upon.
          </UsageDescription>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <RulesHeading>4. Exclusive:</RulesHeading>
          <UsageDescription>
            Content is sold with exclusive rights, meaning only your brand can use it.
          </UsageDescription>
        </Box>
      </RulesContainer>
    </BaseDialog>
  );
};

export default UsageRightsDialog;

const ModalTitle = styled.h4`
  color: #111;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 0;
  margin: 0;
`;

const UsageDescription = styled.p`
  color: #111;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;

  margin: 0;
  padding: 0;
`;

const RulesContainer = styled.div`
  margin-top: 32px;
`;

const RulesHeading = styled.h6`
  padding: 0;
  margin: 0;

  color: #111;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
`;

const ListContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;
