import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { upperFirst } from 'lodash';

import SvgIcon from '../../../components/Icons';
import { Plan } from '../../SubscriptionPlans/types';

type UpgradePlan = Exclude<Plan, 'basic'>;

type UpgradePlanTipsProps = {
  planType: UpgradePlan;
  label: string;
};

const mapPlan: Record<UpgradePlan, { icon: React.ReactNode; color: string }> = {
  starter: {
    icon: <SvgIcon name='Plus' sx={{ fontSize: '1rem', ml: 1.5, fill: '#429C12' }} />,
    color: '#429C12',
  },
  plus: {
    icon: <SvgIcon name='Plus' sx={{ fontSize: '1rem', ml: 1.5, fill: '#D86EEF' }} />,
    color: '#D86EEF',
  },
};

const UpgradePlanTips: React.FC<UpgradePlanTipsProps> = ({ label, planType }) => {
  const mp = React.useMemo(() => mapPlan[planType], [planType]);

  return (
    <Box display='flex' width='100%'>
      <Box component='span' sx={{ mr: 2, opacity: 0.38 }}>
        {label}
      </Box>

      <Box display='flex' alignItems='center' sx={{ opacity: 1 }}>
        {mp.icon}
        <Box component={Link} to='/plans' sx={{ color: 'black', ml: 1, pointerEvents: 'fill' }}>
          Upgrade to{' '}
          <Box component='span' sx={{ fontWeight: 500, color: mp.color }}>
            {upperFirst(planType)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpgradePlanTips;
