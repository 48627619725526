import { ReactNode } from 'react';

import { Box } from '@mui/material';

import { BrandPlan, LogicCondition, Plan } from '../../types';

type Data = {
  titleText: string;
  title: ReactNode;
  highlight?: ReactNode;
  plan: string;
  benefitTitle: ReactNode;
  benefitColor?: string;
  benefits: { title: string | ReactNode; description?: string; tooltip?: string }[];
  tips?: string;
};

export const data: Record<Plan, Data> = {
  basic: {
    titleText: 'Free',
    title: 'Free',
    plan: 'basic',
    benefitTitle: <Box sx={{ fontWeight: 500, fontSize: '18px' }}>Basic usage of the platform</Box>,
    benefits: [
      {
        title: 'Limited traffic',
        tooltip: `Receive a limited number of promotions. Once you've reached the limit, your offers will only be visible to our premium members.`,
      },
      { title: 'Filter up to 25,000 followers' },
    ],
  },
  starter: {
    titleText: 'Starter',
    title: <Box sx={{ color: '#429C12' }}>Starter</Box>,
    highlight: (
      <Box
        sx={{
          background: '#429C12',
          color: 'white',
          borderRadius: 2,
          paddingX: 1.5,
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          fontSize: '1rem',
        }}
      >
        Popular
      </Box>
    ),
    plan: 'starter',
    benefitTitle: <span>Everything included in Free</span>,
    benefitColor: '#49B210',
    benefits: [
      {
        title: 'Moderated traffic',
        tooltip: 'Receive an increased number of promotions from our community',
      },
      { title: 'Data & analytics' },
      { title: 'Premium support' },
      { title: 'Filter over 25,000 followers' },
    ],
  },
  plus: {
    titleText: 'Beautypass PLUS',
    title: <Box sx={{ color: '#b084e7', fontWeight: 600 }}>PLUS</Box>,
    plan: 'plus',
    benefitTitle: (
      <>
        <span style={{ fontWeight: '500' }}>Everything included in</span>&nbsp;
        <Box sx={{ color: '#429C12', fontWeight: 'bold' }}>Starter</Box>
      </>
    ),
    benefitColor: '#B491D6',
    benefits: [
      {
        title: 'Unlimited traffic',
        tooltip: 'Enjoy unlimited promotions from our entire community.',
      },
      {
        title: 'Featured placement in app',
      },
      { title: 'Extra discovery tools' },
      { title: 'Unlimited filters' },
    ],
  },
};

export const brandData: Record<BrandPlan, Data> = {
  basic: {
    titleText: 'Basic',
    title: 'Basic',
    plan: 'basic',
    benefitTitle: <Box sx={{ fontWeight: 500, fontSize: '18px' }}>Basic usage of the platform</Box>,
    benefits: [
      {
        title: (
          <span>
            <b>Up to 5 promotions /month</b>
          </span>
        ),
      },
      { title: 'Accept & Reject reservations' },
    ],
  },
  starter: {
    titleText: 'Starter',
    title: <Box sx={{ color: '#429C12' }}>Starter</Box>,
    highlight: (
      <Box
        sx={{
          background: '#429C12',
          color: 'white',
          borderRadius: 2,
          paddingX: 1.5,
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          fontSize: '1rem',
        }}
      >
        Popular
      </Box>
    ),
    plan: 'starter',
    benefitTitle: <span>Everything included in Basic</span>,
    benefitColor: '#49B210',
    benefits: [
      {
        title: (
          <span>
            <b>Up to 25 promotions /month</b>
          </span>
        ),
      },
      { title: 'Data & Analytics' },
      { title: 'Filters when created offers' },
      { title: 'Filters when searching collabs' },
    ],
  },
  plus: {
    titleText: 'Beautypass PLUS',
    title: <Box sx={{ color: '#b084e7', fontWeight: 600 }}>Beautypass PLUS</Box>,
    plan: 'plus',
    benefitTitle: (
      <>
        <span style={{ fontWeight: '500' }}>Everything included in</span>&nbsp;
        <Box sx={{ color: '#429C12', fontWeight: 'bold' }}>Starter</Box>
      </>
    ),
    benefitColor: '#B491D6',
    benefits: [
      {
        title: (
          <span>
            <b>Unlimited promotions</b>
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Top visibility</b> in app
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Unlimited</b> filters when creating offers
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Unlimited</b> filters when searching collabs
          </span>
        ),
      },
      {
        title: (
          <span>
            <b>Dedicated</b> account manager
          </span>
        ),
      },
    ],
  },
};

export const mapCondition: Record<string, LogicCondition[]> = {
  '0:0': [
    { label: 'Current Plan', kind: 'plan-cancel', selected: true },
    {
      label: 'Upgrade to Starter',
      kind: 'plan-upgrade',
      selected: false,
    },
    {
      label: 'Upgrade to PLUS',
      kind: 'plan-upgrade',
      selected: false,
    },
  ],
  '0:1': [
    { label: 'Downgrade to Free', kind: 'plan-downgrade', selected: false },
    { label: 'Current Plan', kind: 'plan-cancel', selected: true },
    { label: 'Upgrade to PLUS', kind: 'plan-upgrade', selected: false },
  ],
  '0:2': [
    { label: 'Downgrade to Free', kind: 'plan-downgrade', selected: false },
    {
      label: 'Downgrade to Starter',
      kind: 'plan-downgrade',
      selected: false,
    },
    { label: 'Current Plan', kind: 'plan-cancel', selected: true },
  ],
  '1:1': [
    { label: 'Downgrade to Free', kind: 'plan-downgrade', selected: false },
    { label: 'Increase billing period', kind: 'cycle-change-increase', selected: true },
    { label: 'Upgrade to PLUS', kind: 'plan-upgrade', selected: false },
  ],
  '1:2': [
    { label: 'Downgrade to Free', kind: 'plan-downgrade', selected: false },
    { label: 'Downgrade to Starter', kind: 'plan-downgrade', selected: false },
    { label: 'Increase billing period', kind: 'cycle-change-increase', selected: true },
  ],
  '2:1': [
    { label: 'Downgrade to Free', kind: 'plan-downgrade', selected: false },
    { label: 'Decrease billing period', kind: 'cycle-change-decrease', selected: true },
    { label: 'Upgrade to PLUS', kind: 'plan-upgrade', selected: false },
  ],
  '2:2': [
    { label: 'Downgrade to Free', kind: 'plan-downgrade', selected: false },
    { label: 'Downgrade to Starter', kind: 'plan-downgrade', selected: false },
    { label: 'Decrease billing period', kind: 'cycle-change-decrease', selected: true },
  ],
};

export const brandMapCondition: Record<string, LogicCondition[]> = {
  '0:-1': [
    { label: 'Subscribe to Basic', kind: 'plan-upgrade', selected: false },
    {
      label: 'Subscribe to Starter',
      kind: 'plan-upgrade',
      selected: false,
    },
    {
      label: 'Subscribe to PLUS',
      kind: 'plan-upgrade',
      selected: false,
    },
  ],
  '0:0': [
    { label: 'Current Plan', kind: 'plan-cancel', selected: true },
    {
      label: 'Subscribe to Starter',
      kind: 'plan-upgrade',
      selected: false,
    },
    {
      label: 'Subscribe to PLUS',
      kind: 'plan-upgrade',
      selected: false,
    },
  ],
  '0:1': [
    { label: 'Downgrade to Basic', kind: 'plan-downgrade', selected: false },
    { label: 'Current Plan', kind: 'plan-cancel', selected: true },
    { label: 'Subscribe to PLUS', kind: 'plan-upgrade', selected: false },
  ],
  '0:2': [
    { label: 'Downgrade to Basic', kind: 'plan-downgrade', selected: false },
    {
      label: 'Downgrade to Starter',
      kind: 'plan-downgrade',
      selected: false,
    },
    { label: 'Current Plan', kind: 'plan-cancel', selected: true },
  ],
  '1:1': [
    { label: 'Downgrade to Basic', kind: 'plan-downgrade', selected: false },
    { label: 'Increase billing period', kind: 'cycle-change-increase', selected: true },
    { label: 'Subscribe to PLUS', kind: 'plan-upgrade', selected: false },
  ],
  '1:2': [
    { label: 'Downgrade to Basic', kind: 'plan-downgrade', selected: false },
    { label: 'Downgrade to Starter', kind: 'plan-downgrade', selected: false },
    { label: 'Increase billing period', kind: 'cycle-change-increase', selected: true },
  ],
  '2:1': [
    { label: 'Downgrade to Basic', kind: 'plan-downgrade', selected: false },
    { label: 'Decrease billing period', kind: 'cycle-change-decrease', selected: true },
    { label: 'Subscribe to PLUS', kind: 'plan-upgrade', selected: false },
  ],
  '2:2': [
    { label: 'Downgrade to Basic', kind: 'plan-downgrade', selected: false },
    { label: 'Downgrade to Starter', kind: 'plan-downgrade', selected: false },
    { label: 'Decrease billing period', kind: 'cycle-change-decrease', selected: true },
  ],
};
