import React from 'react';
import { InView } from 'react-intersection-observer';

import { Box } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { ChatMessageItem, ConversationItem } from '../../../graphql/API';
import icons from '../../../themes/icons';
import { Image } from '../Image/Image';
import { Ticket } from '../Ticket/Ticket';
import './MessageV2.css';

type Props = {
  index: number;
  userId: string;
  msg: ChatMessageItem;
  messages: Nullable<ChatMessageItem | undefined>[];
  conversation: ConversationItem;
  handleMessageUpdate?: (message: ChatMessageItem) => void;
  lastElementRef: (node: HTMLDivElement | null) => void;
};

export const MessageV2: React.FC<Props> = ({
  index,
  userId,
  msg,
  messages,
  conversation,
  handleMessageUpdate,
  lastElementRef,
}) => {
  return (
    <div
      {...(messages.length === index + 1 && {
        ref: lastElementRef,
      })}
      className={
        userId === msg?.fromUserId
          ? 'message-content-sender'
          : msg?.messageType === 'endchat'
            ? 'message-content-receiver-endchat'
            : 'message-content-receiver'
      }
      key={`${index}-${msg?.messageTime}-${conversation?.conversationId}`}
    >
      <InView
        as='div'
        onChange={(inView) => {
          if (inView && msg && msg.toUserId === userId && !msg.seen && handleMessageUpdate) {
            handleMessageUpdate(msg);
          }
        }}
      >
        <Box marginBottom='8px'>
          {msg?.messageType === 'photo' ||
          msg?.messageType === 'video' ||
          msg?.message === 'image' ? (
            <Image
              src={msg?.message || ''}
              alt={msg.messageTime}
              type={msg.messageType || ''}
              rejected={msg?.rejected || false}
              imageHeight='300px'
            />
          ) : msg?.messageType === 'link' ? (
            <Ticket message={msg} />
          ) : msg?.messageType === 'endchat' ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '32px',
                marginBottom: '35px',
              }}
            >
              <LineCloseChat />
              <ChatClosedMessage>{msg?.message}</ChatClosedMessage>
              <LineCloseChat />
            </Box>
          ) : (
            <div
              className={userId === msg?.fromUserId ? 'message-sender-v2' : 'message-receiver-v2'}
            >
              <span className='message-text'>{msg?.message}</span>
            </div>
          )}
          {msg?.messageType !== 'endchat' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              marginTop={'2px'}
              justifyContent={userId === msg?.fromUserId ? 'flex-end' : 'flex-start'}
            >
              <span className='time-text'>
                {msg?.messageTime ? dayjs(msg.messageTime).format('LT') : null}
              </span>
              {msg.toUserId !== userId && msg?.seen && (
                <Box component='span' sx={{ marginLeft: '5px' }}>
                  <icons.DoneAllIcon htmlColor='#3F51B5' fontSize='inherit' />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </InView>
    </div>
  );
};

const ChatClosedMessage = styled.span`
  font-size: 10px;
  color: #949ba5;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 32px;
  display: inline-block;
`;

const LineCloseChat = styled.div`
  height: 1px;
  background: #949ba5;
  width: 100%;
`;
