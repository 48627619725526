import * as React from 'react';

import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';

import { useField } from 'formik';

type OnChange = Exclude<SwitchProps['onChange'], undefined>;

const OfferCallToConfirm: React.FC = () => {
  const [field, , helper] = useField('confirmWithCall');

  const handleChange = React.useCallback<OnChange>(
    (v, c) => {
      helper.setValue(c);
    },
    [helper],
  );

  return (
    <FormControl component='fieldset' variant='standard' fullWidth sx={{ mb: 4 }}>
      <FormGroup>
        <FormControlLabel
          sx={{
            justifyContent: 'space-between',
            m: 0,
            '.MuiSwitch-switchBase': { color: 'grey' },
            '.Mui-checked': { color: 'currentcolor' },
          }}
          control={
            <Switch
              value='true'
              checked={Boolean(field.value)}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'call to confirm switch' }}
            />
          }
          label={<Typography sx={{fontWeight:"medium", fontSize:"15px"}}>Call to confirm</Typography>}
          labelPlacement='start'
        />
      </FormGroup>
      <FormHelperText sx={{ mb: 2,mt : -0.5, fontWeight:"regular", fontSize:"15px" }}>Ask users to call to confirm their reservation</FormHelperText>
      <Divider />
    </FormControl>
  );
};

export default OfferCallToConfirm;
