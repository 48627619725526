import { ChatMessageItem, NewVenueChatMessageToUser } from '../../graphql/API';
import { venueSendChatMessageToUser } from '../../graphql/mutations';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueSendChatMessageToUserApi = async (
  input: NewVenueChatMessageToUser,
): Promise<ChatMessageItem> => {
  const result = await executeGql(venueSendChatMessageToUser, { input });
  return processGqlResult<ChatMessageItem>('venueSendChatMessageToUser', result);
};
