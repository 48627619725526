import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { ChatMessageItem } from '../../../graphql/API';

interface TicketProps {
  message: ChatMessageItem;
}

export const Ticket: React.FC<TicketProps> = ({
  message: { url, title, thumbnail, messageTime, message },
}) => {
  return (
    <div>
      <List>
        <ListItem>
          <Box height='40px' width='40px'>
            <img
              src={thumbnail || ''}
              alt={`${thumbnail}-image`}
              height='100%'
              width='100%'
              style={{ borderRadius: '8px' }}
            />
          </Box>
          <Box display='flex' flexDirection='column' gap={'8px'}>
            <ListName>{title}</ListName>
            <ListDescription>
              <Typography
                className='ticketName'
                component={Link}
                to={`${url?.split(':')[0]}s/${url?.split(':')[1]}`}
              >
                {message}
              </Typography>
              <span>{dayjs(messageTime).format('LL')}</span>
            </ListDescription>
          </Box>
        </ListItem>
      </List>
    </div>
  );
};

const List = styled.ul`
  border: 1px solid #ebe8e8;
  border-radius: 8px;
  margin: 12px 0;
  list-style: none;
  padding: 0;
  max-width: fit-content;
`;

const ListItem = styled.li`
  padding: 14px 10px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ListName = styled.div`
  color: #949ba5;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
`;

const ListDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  .ticketName {
    text-decoration: underline;
    font-weight: 600;
    color: #111111;
    font-size: 13px;
  }
`;
