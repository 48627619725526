import React from 'react';

import { ConversationsContext } from '.';
import { VenueListConversationsFilter } from '../../graphql/API';
import { useVenueListConversationsQuery } from '../../hooks/reactQuery/chat/useVenueListConversationsQuery';
import useVenueId from '../../hooks/useVenueId';

const ConversationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const venueID = useVenueId();
  const [venueConversationFilter, setVenueConversationFilter] = React.useState<
    Partial<VenueListConversationsFilter>
  >({ venueID });

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, revalidateConversations } =
    useVenueListConversationsQuery({ ...venueConversationFilter });

  return (
    <ConversationsContext.Provider
      value={{
        data,
        isLoading,
        isFetching,
        hasNextPage,
        fetchNextPage,
        revalidateConversations,
        setVenueConversationFilter,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsProvider;
