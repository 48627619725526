import { ConversationItem, VenueGetConversationFilter } from '../../graphql/API';
import { venueGetConversation } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetConversationApi = async (
  filter: VenueGetConversationFilter,
): Promise<ConversationItem> => {
  const result = await executeGql<ConversationItem>(venueGetConversation, { filter });
  return processGqlResult<ConversationItem>('venueGetConversation', result);
};
