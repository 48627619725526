import React from 'react';

import { Alert, Collapse, IconButton } from '@mui/material';

import styled from '@emotion/styled';

import { CurrencySign } from '../../../components/CurrencySign/CurrencySign';
import Icons from '../../../themes/icons';
import { formatNumberWithCommas } from '../../../utils/formatter';

type Props = {
  amount: number;
  currency?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const SuccessAlert: React.FC<Props> = ({ amount, isOpen, onClose }) => {
  return (
    <Collapse in={isOpen}>
      <Alert
        sx={{ bgcolor: '#DDF4E8', mb: 2 }}
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              onClose();
            }}
          >
            <Icons.CloseIcon fontSize='inherit' />
          </IconButton>
        }
      >
        <AlertText>
          <BoldText>
            You have successfully deposited <CurrencySign currency={'usd'} />
            {formatNumberWithCommas(amount)}.
          </BoldText>{' '}
          Now you can use them to collaborate with our community
        </AlertText>
      </Alert>
    </Collapse>
  );
};

const AlertText = styled.p`
  margin: 0;
  padding: 0;
  color: #111;
  font-size: 12px;
  line-height: 20px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;
