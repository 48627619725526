import React from 'react';

import styled from '@emotion/styled';

import icons from '../../themes/icons';
import { centsToDollars, formatNumberWithCommas } from '../../utils/formatter';
import { CurrencySign } from '../CurrencySign/CurrencySign';

type Props = {
  balance?: number;
  currency?: string;
  status?: string;
};

export const Balance: React.FC<Props> = ({ balance, status }) => {
  return (
    <Container style={{ border: status === 'inactive' ? '1px solid #FF5454' : '' }}>
      {status === 'inactive' ? <icons.BalanceRedIcon /> : <icons.BalanceIcon />}
      <Text color={status === 'inactive' ? '#FF5454' : '#111'}>
        <CurrencySign currency={'usd'} /> {formatNumberWithCommas(+centsToDollars(balance || 0))}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 6px;
  background: #f8f8f8;
  padding: 8px 10px;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const Text = styled.span<{ color?: string }>`
  color: ${(props) => props.color || '#111'};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
`;
