import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import ReactPlayer from 'react-player';

import Grid from '@mui/material/Grid';

import LazyAvatar from '../../../components/Avatar/LazyAvatar/LazyAvatar';
import { Item } from '../../../components/Photoswipe/Photoswipe';
import EmptyContent, { ContentType } from './EmptyContent';
import PanelSkeleton from './PanelSkeleton';

export type MediaItem = {
  id: string;
  label: string;
  placeholderSrc: string;
  src: string;
  video?: string;
};

type MediaContainerProps = {
  items: MediaItem[];
  isLoading: boolean;
  contentType: ContentType;
};

const MediaContainer: React.FC<MediaContainerProps> = ({ isLoading, items, contentType }) => {
  if (isLoading) {
    return <PanelSkeleton />;
  }
  return items.length > 0 ? (
    <Grid container spacing={1}>
      {items.map((x) => (
        <Grid item xs={5} sm={4} md={2.5} lg={2.2} xl={2}>
          <AspectRatio key={x.id} ratio='3/4'>
            <Item
              itemProps={{
                original: x.src,
                content: x.video ? (
                  <ReactPlayer
                    width='100%'
                    height='100%'
                    playing={true}
                    url={x.video}
                    light={x.src}
                    controls
                  />
                ) : undefined,
              }}
            >
              <LazyAvatar
                variant='square'
                alt={x.label}
                src={x.src}
                placeholderSrc={x.placeholderSrc}
                isVideo={Boolean(x.video)}
              />
            </Item>
          </AspectRatio>
        </Grid>
      ))}
    </Grid>
  ) : (
    <EmptyContent contentType={contentType} />
  );
};

export default MediaContainer;
