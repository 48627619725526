import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import { venueResetPassword } from '../../api/forgotPassword/serviceResetPasswordApi';
import { getServiceSendForgotPassword } from '../../api/forgotPassword/serviceSendForgotPasswordApi';
import Header from '../../components/Authenticator/Header';
import LoadingButton from '../../components/Button/LoadingButton';
import FormikTextField from '../../components/Formiks/TextField/TextField';
import { useFrom } from '../../hooks/useFrom';
import { resetPasswordSchema } from './schema';
import './styles.css';

type FormFields = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const { id } = useParams() || {};
  const navigate = useNavigate();
  const from = useFrom();
  const { authStatus } = useAuthenticator();
  const [isLoading, setIsLoading] = useState(false);
  const [resendIsLoading, setResendIsLoading] = useState(false);

  useEffect(() => {
    if (authStatus === 'authenticated') navigate(from, { replace: true });
  }, [authStatus, from, navigate]);

  const handleResetCode = async () => {
    const lastUsedEmail = localStorage.getItem('lastUsedEmail') || '';
    try {
      if (lastUsedEmail) {
        setResendIsLoading(true);
        await getServiceSendForgotPassword(lastUsedEmail, true);
        enqueueSnackbar({ message: 'Email sent successfully', variant: 'success' });
        setResendIsLoading(false);
      }
    } catch (error) {
      const _err = error as Error;
      enqueueSnackbar({ message: _err.message, variant: 'error' });
      setResendIsLoading(false);
      console.error(error);
    }
  };

  const onSubmit = async ({ password }: FormFields) => {
    try {
      setIsLoading(true);
      await venueResetPassword({ token: id, pwd: password });
      navigate('/login', { replace: true });
      setIsLoading(false);
    } catch (error) {
      const _err = error as Error;
      enqueueSnackbar({ message: _err.message, variant: 'error' });
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className='auth-form'>
      <Header />
      <div className='auth-form-container'>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={resetPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleBlur }) => (
            <Form>
              <Box display='flex' flexDirection='column' gap='0.5rem' marginTop='1rem'>
                <label htmlFor='password' className='auth-input-label'>
                  New Password
                </label>
                <FormikTextField
                  className='auth-input-field auth-input-field-password'
                  id='password'
                  name='password'
                  type='password'
                  placeholder='New Password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box display='flex' flexDirection='column' gap='0.5rem' marginTop='1rem'>
                <label htmlFor='confirmPassword' className='auth-input-label'>
                  Confirm Password
                </label>
                <FormikTextField
                  className='auth-input-field auth-input-field-password'
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  placeholder='Confirm Password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <div className='auth-footer'>
                <LoadingButton
                  type='submit'
                  loading={isLoading}
                  color='primary'
                  fullWidth
                  variant='contained'
                  size='large'
                >
                  {isLoading ? ' Updating password' : ' Update password'}
                </LoadingButton>
                <div className='resend-code-container'>
                  <LoadingButton
                    type='button'
                    loading={resendIsLoading}
                    color='primary'
                    fullWidth
                    variant='contained'
                    size='large'
                    onClick={handleResetCode}
                  >
                    {resendIsLoading ? 'Resending code' : ' Resend code'}
                  </LoadingButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
