import { VenueSubscriptionItem, VenueSubscriptionsFilter } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListSubscriptionsQuery = /* GraphQL */ `
  query VenueListSubscriptions($filter: VenueSubscriptionsFilter!) {
    venueListSubscriptions(filter: $filter) {
      items {
        id
        subscriptionId
        venueID
        sku
        productGroup
        price
        convertedPrice
        currency
        convertedCurrency
        platform
        expirationDate
        status
        isSubscriptionRenewable
        createdAt
        updatedAt
      }
    }
  }
`;

export const venueListSubscriptionsApi = async (
  filter: VenueSubscriptionsFilter,
): Promise<VenueSubscriptionItem[]> => {
  const result = await executeGql<VenueSubscriptionItem[]>(venueListSubscriptionsQuery, { filter });
  return processGqlResult<VenueSubscriptionItem[]>('venueListSubscriptions.items', result);
};
