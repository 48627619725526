import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql/lib/types';

import { ResetPasswordInput } from '../../graphql/API';
import { serviceResetPassword } from '../../graphql/mutations';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueResetPassword = async (input: ResetPasswordInput): Promise<boolean> => {
  const result = await executeGql(
    serviceResetPassword,
    {
      input,
    },
    GRAPHQL_AUTH_MODE.AWS_IAM,
  );
  return processGqlResult('data.serviceResetPassword', result);
};
