import React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

type Props = {
  title: string;
  description?: React.ReactNode;
  showBorder?: boolean;
};

export const DetailItem: React.FC<Props> = ({ title, description, showBorder = true }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginY: '12px',
        paddingBottom: '15px',
        borderBottom: showBorder ? '1px solid #e1e1e1' : '',
      }}
    >
      <Title>{title}</Title>
      {description && <Box>{description}</Box>}
    </Box>
  );
};

const Title = styled.h6`
  padding: 0;
  margin: 0 0 4px 0;

  color: #707070;
  font-size: 15px;
  font-weight: 400;
`;
