import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import GoBack from '../../components/GoBack/GoBack';
import LabelDetail from '../../components/LabelDetail/LabelDetail';
import BorderBox from './components/BorderBox';

const OfferDetailSkeleton: React.FC = () => {
  return (
    <Box>
      <Box component={AspectRatio}>
        <Skeleton variant='rounded' />
      </Box>

      <Box display='flex' mt={2} justifyContent='space-between'>
        <GoBack />

        <Stack direction='row' width='75%' spacing={2}>
          <Skeleton variant='rounded' width='30%' height={40} />
          <Skeleton variant='rounded' width='70%' height={40} />
        </Stack>
      </Box>

      <Stack spacing={2} sx={{ mt: 4 }}>
        <BorderBox title='Offer details'>
          {[...Array(7)].map((_, i) => (
            <LabelDetail
              key={i}
              label={<Skeleton width='80%' />}
              description={<Skeleton width='100%' />}
            />
          ))}
        </BorderBox>
        <BorderBox title='Requirements'>
          <Stack divider={<Divider />} spacing={2} sx={{ mt: 3 }}>
            <Skeleton />
            <Skeleton />
          </Stack>
        </BorderBox>
      </Stack>
    </Box>
  );
};

export default OfferDetailSkeleton;
