import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueGetSubscriptionSessionQuery = /* GraphQL */ `
  query venueGetSubscriptionSession($priceId: String!) {
    venueGetSubscriptionSession(priceId: $priceId)
  }
`;

export const venueGetSubscriptionSessionApi = async (priceId: string): Promise<string> => {
  const result = await executeGql<string>(venueGetSubscriptionSessionQuery, { priceId });
  return processGqlResult<string>('venueGetSubscriptionSession', result);
};
