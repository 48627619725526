import * as React from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

type PageTitleProps = {
  title?: string;
  titleComponent?: React.ReactElement;
  sideComponent?: React.ReactElement;
  rightComponent?: React.ReactElement;
  sx?: SxProps<Theme>;
};

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  titleComponent,
  sideComponent,
  rightComponent,
  sx,
}) => {
  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
      <Box display='flex' alignItems='center'>
        {title && (
          <Typography variant='h5' fontWeight='bold' mr={3} sx={sx}>
            {title}
          </Typography>
        )}
        {titleComponent}

        {sideComponent}
      </Box>

      {rightComponent}
    </Box>
  );
};

export default PageTitle;
