import React, { useRef } from 'react';

import styled from '@emotion/styled';

import { getFileExtension } from '../../../api/files/uploadFileToS3';
import icons from '../../../themes/icons';

export type FileType = 'photo' | 'video' | 'other';

const getFileType = (file: File): FileType => {
  const fileExtension = getFileExtension(file.name)?.toLowerCase();
  if (
    fileExtension === 'png' ||
    fileExtension === 'jpg' ||
    fileExtension === 'jpeg' ||
    fileExtension === 'gif' ||
    fileExtension === 'tiff' ||
    fileExtension === 'svg'
  ) {
    return 'photo';
  } else if (
    fileExtension === 'mp4' ||
    fileExtension === 'avi' ||
    fileExtension === 'mov' ||
    fileExtension === 'mkv' ||
    fileExtension === 'webm'
  ) {
    return 'video';
  } else {
    return 'other';
  }
};

type Props = {
  handleFileUpload: (file: File, fileType: FileType) => void;
  disabled?: boolean;
};

export const AttachmentInput: React.FC<Props> = ({ handleFileUpload, disabled = false }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = () => {
    if (inputRef) inputRef.current?.click();
  };

  const onChangeFile = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const file = event.target.files ? event.target.files[0] : undefined;

      if (file) handleFileUpload(file, getFileType(file));
      event.currentTarget.value = '';
    },
    [handleFileUpload],
  );

  return (
    <PlusButton type='button' onClick={handleChange} disabled={disabled}>
      <input
        hidden
        ref={inputRef}
        accept='image/*,video/*'
        type='file'
        onChange={onChangeFile}
        multiple
      />
      <icons.AddIcon />
    </PlusButton>
  );
};

const PlusButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #030712;

  &:disabled {
    cursor: not-allowed;
  }
`;
