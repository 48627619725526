import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SvgIcon from '../../../components/Icons';

export type ContentType = 'portfolio' | 'content';

type EmptyContentProps = {
  contentType: ContentType;
};

const mapDesc: Record<ContentType, string> = {
  content: 'This member has not created any content.',
  portfolio: 'This member has not uploaded any pictures to their portfolio.',
};

const EmptyContent: React.FC<EmptyContentProps> = ({ contentType }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      height: '100%',
      maxWidth: '70%',
    }}
  >
    <SvgIcon name='Content' sx={{ fontSize: '6rem', mr: 3 }} />
    <Typography variant='subtitle1'>
      No pictures yet...
      <Typography color='text.secondary'>{mapDesc[contentType]}</Typography>
    </Typography>
  </Box>
);

export default EmptyContent;
