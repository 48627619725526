import * as React from 'react';

import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListAccessRequestsApi } from '../../api/accessRequest/venueListAccessRequestsApi';
import { VennueAccessRequestItem, VenueListAccessRequetsFilter } from '../../graphql/API';
import { useAppSelector } from '../../redux/hooks';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

const useAccessRequestQuery = (
  filters: Partial<VenueListAccessRequetsFilter> | undefined,
  disable?: boolean,
): QueryResult<VennueAccessRequestItem[]> => {
  const venueState = useAppSelector((state) => state.venue.value);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } = useInfiniteQuery<
    VennueAccessRequestItem[]
  >({
    initialPageParam: 0,
    queryKey: ['list-access-request', venueState?.id, filters],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID, queryFilters] = queryKey as [string, number, VenueListAccessRequetsFilter];
      const offset = <number>pageParam * PAGE_LIMIT;

      return venueListAccessRequestsApi({ ...queryFilters, limit: PAGE_LIMIT, offset, venueID });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !disable && !!venueState?.id,
    refetchOnWindowFocus: false,
  });

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export default useAccessRequestQuery;
