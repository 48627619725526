import React from 'react';

import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { ShowFnOutput, useModal } from 'mui-modal-provider';

import Button from '../../../components/Button/Button';
import ProfileUpdateDialog, {
  ProfileUpdateDialogProps,
} from '../../../components/Dialogs/ProfileUpdate/ProfileUpdateDialog';
import LabelDetail from '../../../components/LabelDetail/LabelDetail';
import { Item } from '../../../components/Photoswipe/Photoswipe';
import { Venue } from '../../../graphql/API';
import { useAppSelector } from '../../../redux/hooks';
import CoverPhoto from './CoverPhoto';

const getAddressString = (profile?: Venue) => {
  if (!profile) {
    return '';
  }
  return [
    profile.address1,
    profile.address2,
    profile.city,
    profile.postalCode + ' ' + profile.stateProvince,
    profile.country,
  ]
    .filter((i) => !!i)
    .join(', ');
};

const ProfilePanel: React.FC = () => {
  const profileUpdateModalRef = React.useRef<ShowFnOutput<ProfileUpdateDialogProps>>();

  const { showModal } = useModal();
  const venueState = useAppSelector((state) => state.venue.value);

  if (!venueState) {
    return <Skeleton variant='rounded' width='100%' height='70vh' />;
  }

  return (
    <Box>
      <Item
        itemProps={{ original: String(venueState.picture?.large), width: '1024', height: '768' }}
      >
        <Box>
          <CoverPhoto label={String(venueState.name)} src={String(venueState.picture?.medium)} />
        </Box>
      </Item>

      <Stack spacing={4} sx={{ mt: 4 }}>
        <Box>
          <Box display='flex' justifyContent='space-between' mb={1}>
            <Typography fontSize='24px' fontWeight='600'>
              {venueState.name}
            </Typography>

            <Button
              id='btn-edit'
              variant='outlined'
              size='small'
              color='primary'
              sx={{ px: 4 }}
              onClick={() =>
                (profileUpdateModalRef.current = showModal(ProfileUpdateDialog, {
                  title: 'Edit info',
                  modalRef: profileUpdateModalRef,
                }))
              }
            >
              Edit info
            </Button>
          </Box>

          <Stack spacing={0.5}>
            <LabelDetail label='Instagram' description={venueState.instagramHandle} />
            <LabelDetail label='Address' description={getAddressString(venueState)} />

            <LabelDetail label='Phone Number' description={venueState.phone} />
            <LabelDetail label='Email' description={venueState.email} />
            <LabelDetail label='Website' description={venueState.website} />
            <LabelDetail label='Description' description={venueState.description} />
          </Stack>
        </Box>

        <Box>
          <Typography fontWeight='bold' mb={1}>
            Contact
          </Typography>
          <Stack spacing={0.5}>
            <LabelDetail
              label='Contact name'
              description={`${venueState.contactFirstname} ${venueState.contactLastname}`}
            />
            <LabelDetail label='Position' description={venueState.contactPosition} />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfilePanel;
