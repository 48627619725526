import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, ModalProps, Paper, Stack } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';

import { useModal } from 'mui-modal-provider';

import { VenueReservationContentItemV2 } from '../../../../graphql/API';
import { useAppSelector } from '../../../../redux/hooks';
import { centsToDollars } from '../../../../utils/formatter';
import BuyContentDialog from '../../BuyContent/BuyContentDialog';
import { ConfirmAction } from '../../Confirm/context/types';
import UsageRightsDialog from '../../UsageRights/UsageRightsDialog';
import { ActionBuyDownload } from './ActionBuyDownload';
import { ActionBuyDownloadBeforeUpdate } from './ActionBuyDownloadBeforeUpdate';
import { BuyContent } from './BuyContent';
import MediaContent from './MediaContent';
import { NoPriceBuyContent } from './NoPriceBuyContent';
import UserHeader from './UserHeader';
import useAction from './useAction';

const IconButton = styled(MuiIconButton)({
  backgroundColor: 'white',
  ':hover': {
    backgroundColor: 'rgb(255,255,255,0.84)',
  },
});

export type ContentProps = {
  itemId: number;
  items: VenueReservationContentItemV2[];
  onClose?: ModalProps['onClose'];
  confirm: ConfirmAction;
};

export const BUY_CONTENT_COST = 5.0;

const Content: React.FC<ContentProps> = ({ onClose, ...restProps }) => {
  const { balance, status } = useAppSelector((state) => state.balance.value) || {};

  const availableBalance = Number(centsToDollars(balance || 0));

  const { showModal } = useModal();
  const {
    item,
    handleDownloadContent,
    handleInviteUser,
    handleBuyContent,
    handleSaveUser,
    handleNavigation,
    getLoading,
  } = useAction(restProps);

  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          onClose && onClose({}, 'escapeKeyDown');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', onKeyPress);

    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onClose]);

  const isSaveLoading = React.useMemo<boolean | undefined>(
    () => getLoading('saveContent', String(item?.id)),
    [getLoading, item?.id],
  );

  const handleUsageRightsDialog = () => {
    showModal(UsageRightsDialog, {});
  };

  const handleBuyContentDialog = () => {
    showModal(BuyContentDialog, {
      balance: availableBalance,
      onConfirm: handleBuyContent,
      cost: item?.price || 0,
    });
  };

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <IconButton color='primary' onClick={() => handleNavigation('prev')}>
        <ArrowBackIcon />
      </IconButton>
      <Paper sx={{ position: 'relative', width: '1293px', height: '834px' }}>
        <IconButton
          color='primary'
          sx={{ position: 'absolute', right: 10, top: 10 }}
          onClick={() => onClose && onClose({}, 'backdropClick')}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <Box sx={{ width: '50%', position: 'relative' }}>
              <MediaContent
                src={item?.images?.medium ?? ''}
                videoSrc={item?.images?.video ?? undefined}
              />
            </Box>
            <Box
              sx={{
                width: '50%',
                px: 4,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <UserHeader
                item={item}
                handleInviteUser={handleInviteUser}
                handleSaveUser={handleSaveUser}
                getLoading={getLoading}
              />

              {isSaveLoading === false || item?.byVenue ? (
                <>
                  {!item?.price ? (
                    <ActionBuyDownloadBeforeUpdate
                      purchasedDate={item?.downloadedAt || ''}
                      onDownload={handleDownloadContent}
                    />
                  ) : (
                    <ActionBuyDownload
                      purchasedDate={item?.downloadedAt || ''}
                      successMessage={isSaveLoading === false}
                      onUsageRights={handleUsageRightsDialog}
                      onDownload={handleDownloadContent}
                    />
                  )}
                </>
              ) : !item?.byVenue && item?.price ? (
                <BuyContent
                  cost={item?.price || 0}
                  loading={isSaveLoading}
                  balance={availableBalance}
                  accountStatus={status}
                  onBuyContent={handleBuyContentDialog}
                  onUsageRight={handleUsageRightsDialog}
                />
              ) : !item?.byVenue && !item?.price ? (
                <NoPriceBuyContent />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Paper>
      <IconButton color='primary' onClick={() => handleNavigation('next')}>
        <ArrowForwardIcon />
      </IconButton>
    </Stack>
  );
};

export default Content;
