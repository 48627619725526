import { VenueDownloadReservationContentInput } from '../../graphql/API';
import { venueDownloadReservationContent } from '../../graphql/mutations';
import { executeGql } from '../../utils/gql-utils';

export const venueDownloadReservationContentApi = async (
  input: VenueDownloadReservationContentInput,
): Promise<void> => {
  await executeGql(venueDownloadReservationContent, {
    input,
  });
};
