import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ShowFnOutput } from 'mui-modal-provider';

import { venueCreateInviteV2Api } from '../../../../api/invites/venueCreateInviteV2Api';
import { OfferRequirements, User, VenueCreateInviteInputV2 } from '../../../../graphql/API';
import useQueryParams from '../../../../hooks/useQueryParams';
import { dateToDateString } from '../../../../pages/CreateUpdateOffer/components/schema';
import {
  fetchAccountBalance,
  handleBalanceIsLoading,
} from '../../../../redux/features/balance/balanceSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  datetimeWithTimezone,
  getUTCTimeFromTimeInTimezone,
  timeToDayJs,
} from '../../../../utils/dateV2';
import { delay } from '../../../../utils/delay';
import { BaseDialogProps } from '../../Base';
import { CreateInviteContext } from './CreateInvite.context';
import { initialState, reducer } from './CreateInvite.reducer';
import { ExtendedOfferRequirements } from './types';

export type CreateInviteProviderProps = {
  users: User[];
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
  onSuccess: () => void;
  premiumCredits?: number;
  nonPremiumCredits?: number;
};

export const toUtcTime = (time: string, timezoneId?: string): string =>
  datetimeWithTimezone({
    datetime: timeToDayJs(time).format('YYYY-MM-DD HH:mm'),
    timezoneId,
  })
    .utc()
    .format('HH:mm') ?? '';

const CreateInviteProvider: React.FC<React.PropsWithChildren<CreateInviteProviderProps>> = ({
  children,
  users,
  modalRef,
  onSuccess,
  premiumCredits = 0,
  nonPremiumCredits = 0,
}) => {
  const navigate = useNavigate();
  const { getQueryParam } = useQueryParams();
  const collabId = getQueryParam('collabId');

  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState(users),
    premiumCredits,
    nonPremiumCredits,
  });

  const venueState = useAppSelector((state) => state.venue.value);
  const appDispatch = useAppDispatch();
  const mutation = useMutation({
    mutationFn: venueCreateInviteV2Api,
    onSuccess: () => {
      onSuccess();
    },
  });

  const submitForm = React.useCallback(async () => {
    const selectedDates =
      state.dates?.map((x) =>
        datetimeWithTimezone({
          datetime: dateToDateString(x.toDate(), state.startTime?.toDate() ?? new Date()),
          timezoneId: venueState?.timeZoneId,
        })
          .utc()
          .format('YYYY-MM-DD') || '',
      ) ?? [];

    const input: VenueCreateInviteInputV2 = {
      userIDsWithOfferPrice: state.usersWithOfferPrice || [],
      offerID: Number(state.offer?.id),
      availableDates: selectedDates,
      startTime: getUTCTimeFromTimeInTimezone(
        selectedDates[0],
        dayjs(state.startTime).format('HH:mm') ?? '',
        venueState?.timeZoneId ?? undefined,
      ),
      endTime: getUTCTimeFromTimeInTimezone(
        selectedDates[0],
        dayjs(state.endTime).format('HH:mm') ?? '',
        venueState?.timeZoneId ?? undefined,
      ),
      venueID: Number(venueState?.id),
      requirements:
        state.requirements === ExtendedOfferRequirements.noRequirement
          ? null
          : (state.requirements as OfferRequirements),
    };
    dispatch({ type: 'next' });

    modalRef.current?.update({ title: undefined });

    await mutation.mutateAsync(input);

    if (collabId) navigate('/collabs');

    appDispatch(handleBalanceIsLoading());
    delay(5000).then(() => {
      appDispatch(fetchAccountBalance({ venueID: venueState?.id || '' }));
    });
  }, [
    state.usersWithOfferPrice,
    state.offer?.id,
    state.dates,
    state.startTime,
    state.endTime,
    state.requirements,
    venueState?.timeZoneId,
    venueState?.id,
    modalRef,
    mutation,
    collabId,
    navigate,
    appDispatch,
  ]);

  return (
    <CreateInviteContext.Provider
      value={{
        state,
        dispatch,
        mutation,
        submitForm,
      }}
    >
      {children}
    </CreateInviteContext.Provider>
  );
};

export default CreateInviteProvider;
