import React from 'react';

import { styled as MUIStyled, Rating } from '@mui/material';

import styled from '@emotion/styled';

import LoadingButton from '../Button/LoadingButton';

const StyledRating = MUIStyled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#111111',
  },
  fontSize: '46px',
  gap: '16px',
});

type Props = {
  isLoading?: boolean;
  userName: string;
  value: number;
  onValueChange: (val: number) => void;
  onSubmit?: () => void;
};

export const RatingSection: React.FC<Props> = ({
  isLoading = false,
  userName,
  value,
  onValueChange,
  onSubmit,
}) => {
  return (
    <Container>
      <Heading>How did your collab go? Please rate {userName} post!</Heading>
      <RatingContainer>
        <StyledRating
          name='collab-rating-controlled'
          value={value}
          onChange={(_, newValue) => {
            if (newValue) onValueChange(newValue);
          }}
          size='large'
        />
      </RatingContainer>
      <LoadingButton
        loading={isLoading}
        variant='contained'
        fullWidth
        disabled={value < 1 || isLoading}
        onClick={onSubmit}
      >
        Submit
      </LoadingButton>
    </Container>
  );
};

const Container = styled.div`
  max-width: 350px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 24px;
`;

const RatingContainer = styled.div`
  margin: 23px 0 73px 0;
`;

const Heading = styled.p`
  color: #111;
  font-size: 15px;
  font-weight: 400;
`;
