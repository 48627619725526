import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

type Props = {
  currency: string;
};

export const CurrencySign: React.FC<Props> = ({ currency }) => {
  return <span>{getSymbolFromCurrency(currency)}</span>;
};
