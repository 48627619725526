import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import Button from '../../../Button/Button';
import { ConfirmDialogContentProps } from '../context/types';

const ConfirmDialogContent: React.FC<ConfirmDialogContentProps> = ({
  description,
  confirmationText,
  confirmButtonOnly,
  cancelationText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Box display='flex' justifyContent='space-between' flexDirection='column' height='100%'>
      <Typography component='div'>{description}</Typography>

      <Stack direction='row' spacing={2}>
        {!confirmButtonOnly && (
          <Button variant='outlined' fullWidth onClick={onCancel}>
            {cancelationText ?? 'Cancel'}
          </Button>
        )}
        <Button variant='contained' fullWidth onClick={onConfirm}>
          {confirmationText ?? 'OK'}
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfirmDialogContent;
