import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { venueSaveUserApi } from '../../api/venue/venueSaveUserApi';
import { venueUnsaveUserApi } from '../../api/venue/venueUnsaveUserApi';
import { useAppSelector } from '../../redux/hooks';

type MutationResult<T> = UseMutationResult<void, unknown, T, unknown>;

export type OnSuccess = (type: 'saved' | 'unsaved') => void;

type UseSaveUserMutationProps = {
  onSuccess?: OnSuccess;
};

type UseSaveUserMutationResult = {
  saveUserMutation: MutationResult<number>;
  unsaveUserMutation: MutationResult<number>;
};

const useMutationSaveUser = ({
  onSuccess,
}: UseSaveUserMutationProps): UseSaveUserMutationResult => {
  const venueId = useAppSelector((state) => state.venue.value?.id);

  const saveUserMutation = useMutation({
    mutationFn: async (userID: number) => {
      if (venueId) {
        await venueSaveUserApi({ venueID: Number(venueId), userID });
      }
    },
    onSuccess: () => onSuccess && onSuccess('saved'),
  });

  const unsaveUserMutation = useMutation({
    mutationFn: async (userID: number) => {
      if (venueId) {
        await venueUnsaveUserApi({ venueID: Number(venueId), userID });
      }
    },
    onSuccess: () => onSuccess && onSuccess('unsaved'),
  });

  return { saveUserMutation, unsaveUserMutation };
};

export default useMutationSaveUser;
