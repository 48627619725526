import React from 'react';

import { Box, Checkbox } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import { venueUpdateVenueApi } from '../../../api/venue/venueUpdateVenueApi';
import { TermsAndConditionsCompleteData } from '../../../hooks/useTermsAndConditions';
import { fetchVenue } from '../../../redux/features/venue/venueSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import LoadingButton from '../../Button/LoadingButton';
import BaseDialog, { BaseDialogProps } from '../Base';
import { PrivacyPolicyDialog } from '../PrivacyPolicy/PrivacyPolicyDialog';
import { TermsAndConditionDialog } from '../TermsAndCondition/TermsAndConditionDialog';

type Props = {
  onSuccess?: () => void;
  termsData: TermsAndConditionsCompleteData;
} & BaseDialogProps;

export const UpdateToOurTermsDialog: React.FC<Props> = ({ termsData, onSuccess, ...rest }) => {
  const venue = useAppSelector((state) => state.venue.value);
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = React.useState(false);

  const [showTermsDialog, setShowTermsDialog] = React.useState(false);
  const [showPolicyDialog, setShowPolicyDialog] = React.useState(false);

  const [isTermsAccepted, setIsTermsAccepted] = React.useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = React.useState(false);

  const handleShowTermsDialog = () => {
    setShowTermsDialog((prev) => !prev);
  };

  const handleShowPolicyDialog = () => {
    setShowPolicyDialog((prev) => !prev);
  };

  const onTermsAndPolicyContinue = async () => {
    try {
      if (venue?.id) {
        setIsLoading(true);
        await venueUpdateVenueApi({
          id: venue?.id,
          termsAccepted: true,
          tncVersion: termsData?.updateDate,
        });
        enqueueSnackbar('Terms of service accepted successfully', {
          variant: 'success',
        });
        if (onSuccess) onSuccess();
        dispatch(fetchVenue());
        setIsLoading(false);
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong while updating please try again later', {
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  const formattedUpdateDate = React.useMemo(() => {
    const parsedDate = dayjs(termsData?.updateDate);

    return parsedDate.isValid() ? ' after ' + parsedDate.format('MMMM DD, YYYY ') : ' ';
  }, [termsData?.updateDate]);

  return (
    <>
      <BaseDialog
        width='580px'
        height='560px'
        minHeight='500px'
        titleComponent={<ModalTitle>Updates to our Terms</ModalTitle>}
        padding='14px 18px'
        showCloseIcon={false}
        {...rest}
      >
        <Separator>
          <Description>
            We're reaching out to let you know about upcoming changes to our{' '}
            <b>Terms of Service.</b>
          </Description>
          <Separator></Separator>
          <Description>
            We recommend that you review the updated documents. By continuing to use Beautypass
            {formattedUpdateDate}you agree to our updated Terms and acknowledge that the updated
            policies will apply to you.
          </Description>
        </Separator>
        <Separator>
          <Description>Thank you for using Beautypass!</Description>
        </Separator>

        <Footer>
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Checkbox
              inputProps={{ 'aria-label': 'privacy checkbox' }}
              size='medium'
              checked={isTermsAccepted && isPolicyAccepted}
              onChange={() => {
                handleShowTermsDialog();
              }}
            />
            <TermsParagraph>
              I agree to the{' '}
              <Box component={'span'} sx={{ cursor: 'pointer' }} onClick={handleShowTermsDialog}>
                <b>
                  <u>Terms of Service</u>
                </b>{' '}
              </Box>
              and{' '}
              <Box component={'span'} sx={{ cursor: 'pointer' }} onClick={handleShowPolicyDialog}>
                <b>
                  <u>Privacy Policy</u>
                </b>
              </Box>
            </TermsParagraph>
          </Box>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            fullWidth
            sx={{ py: 1.5 }}
            disabled={!isTermsAccepted || !isPolicyAccepted}
            onClick={onTermsAndPolicyContinue}
          >
            Continue
          </LoadingButton>
        </Footer>
      </BaseDialog>
      <TermsAndConditionDialog
        termsData={termsData}
        open={showTermsDialog}
        onClose={handleShowTermsDialog}
        onAgreeTerms={(val) => {
          setIsTermsAccepted(val);
          handleShowTermsDialog();
          handleShowPolicyDialog();
        }}
      />
      <PrivacyPolicyDialog
        termsData={termsData}
        open={showPolicyDialog}
        onClose={handleShowPolicyDialog}
        onAgreePolicy={(val) => {
          setIsPolicyAccepted(val);
          handleShowPolicyDialog();
        }}
      />
    </>
  );
};

const ModalTitle = styled.h1`
  color: #000;
  font-size: 28px;
  font-weight: 500;
  margin: 0 0 10px 0;
  padding: 0;
`;

const Separator = styled.div`
  margin-bottom: 28px;
`;

const Description = styled.p`
  color: #111;
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  padding: 0;
`;

const Footer = styled.div`
  margin-top: 90px;
`;

const TermsParagraph = styled.p`
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
`;
