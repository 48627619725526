import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { uploadFileToS3 } from '../../../api/files/uploadFileToS3';
import { venueCreatePortfolioContentApi } from '../../../api/venue/venueCreatePortfolioContentApi';
import { venueDeletePortfolioContentApi } from '../../../api/venue/venueDeletePortfolioContent';
import { venueListPortfolioContentApi } from '../../../api/venue/venueListPortfolioContentApi';
import { VenuePortfolioItem } from '../../../graphql/API';
import { useAppSelector } from '../../../redux/hooks';
import { delay } from '../../../utils/delay';

type MutationResult<T> = UseMutationResult<void, unknown, T, unknown>;
type QueryResult = {
  data: VenuePortfolioItem[];
  uploadMutation: MutationResult<File>;
  deleteMutation: MutationResult<string>;
} & Pick<UseQueryResult<VenuePortfolioItem[]>, 'isFetching' | 'isLoading'>;

const usePortfolioQuery = (): QueryResult => {
  const queryClient = useQueryClient();

  const venueState = useAppSelector((state) => state.venue.value);

  const { data, isFetching, isLoading } = useQuery<VenuePortfolioItem[]>({
    queryKey: ['list-portfolio', venueState?.id],
    queryFn: async ({ queryKey }) => {
      const [, venueID] = queryKey as [string, string];

      return venueListPortfolioContentApi(venueID);
    },
    placeholderData: keepPreviousData,
    enabled: !!venueState?.id,
    refetchOnWindowFocus: false,
  });

  const deleteMutation = useMutation({
    mutationFn: venueDeletePortfolioContentApi,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['list-portfolio'] });
      queryClient.setQueryData<VenuePortfolioItem[]>(
        ['list-portfolio', venueState?.id],
        (oldData) => oldData?.filter((x) => x.id !== variables),
      );
    },
  });

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const s3Key = await uploadFileToS3(file);
      await venueCreatePortfolioContentApi(s3Key);
    },
    onSuccess: async (_, file) => {
      const objUrl = URL.createObjectURL(file);
      const newData = { asset: { thumbnail: objUrl } } as VenuePortfolioItem;

      const arr = data?.concat(newData);

      queryClient.setQueryData(['list-portfolio', venueState?.id], arr);

      await delay(5000);
      queryClient.invalidateQueries({ queryKey: ['list-portfolio'] });
    },
  });

  return { data: data ?? [], isFetching, isLoading, deleteMutation, uploadMutation };
};

export default usePortfolioQuery;
