import { ChatMessageItem, ChatMessageUpdateInput } from '../../graphql/API';
import { updateMessagesStatusBtwVenueAndUser } from '../../graphql/mutations';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const updateMessagesStatusBtwVenueAndUserApi = async (
  input: ChatMessageUpdateInput[],
): Promise<ChatMessageItem> => {
  const result = await executeGql(updateMessagesStatusBtwVenueAndUser, { input });
  return processGqlResult<ChatMessageItem>('updateMessagesStatusBtwVenueAndUser', result);
};
