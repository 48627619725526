import {
  VenueGetOfferStatisticsQuery,
  VenueGetOfferStatisticsRequest,
  VenueGetOfferStatisticsResponse,
} from '../../graphql/API';
import { venueGetOfferStatistics } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetOfferStatisticsApi = async (
  filter: VenueGetOfferStatisticsRequest,
): Promise<VenueGetOfferStatisticsResponse> => {
  const result = await executeGql<VenueGetOfferStatisticsQuery>(venueGetOfferStatistics, {
    filter,
  });
  const data = processGqlResult<VenueGetOfferStatisticsResponse>('venueGetOfferStatistics', result);
  return data;
};
