import React from 'react';

import { Box, styled as MUIStyled, Rating } from '@mui/material';

import styled from '@emotion/styled';

const StyledRating = MUIStyled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#111111',
  },
  '& .MuiRating-iconHover': {
    color: '#ccc',
  },
});

type Props = {
  rating: number;
  userName: string;
};

export const LeavedRatingSection: React.FC<Props> = ({ rating, userName }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Heading>Thank you for leaving a review for {userName}!</Heading>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
        <YourRating>Your Rating</YourRating>
        <StyledRating name='leaved-rating-collab' value={rating} readOnly size='medium' />
      </Box>
    </Box>
  );
};

const Heading = styled.p`
  color: #37bb90;
  font-size: 15px;
  font-weight: 400;
`;

const YourRating = styled.p`
  color: #949ba5;
  font-size: 12px;
  font-weight: 400;
`;
