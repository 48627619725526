import * as React from 'react';

import { styled } from '@mui/material';

import SvgIcon from '../Icons';
import LoadingButton, { LoadingButtonProps } from './LoadingButton';

const Button = styled(LoadingButton)(({ theme, size, loading }) => ({
  ...(!loading
    ? {
        '.MuiButton-startIcon': {
          marginRight: theme.spacing(0.5),
        },
        'svg.MuiSvgIcon-root': {
          fontSize: size === 'small' ? '1.3rem' : '1.5rem',
        },
      }
    : undefined),
}));

type SavedButtonProps = {
  active?: boolean;
} & LoadingButtonProps;

const SavedButton: React.FC<SavedButtonProps> = ({ children, active, ...restProps }) => {
  return (
    <Button
      {...restProps}
      id='btn-saved'
      startIcon={
        active ? (
          <SvgIcon name='Saved' sx={{ width: '34px', height: '34px' }} />
        ) : (
          <SvgIcon name='Save' sx={{ width: '34px', height: '34px' }} />
        )
      }
    >
      {children}
    </Button>
  );
};

export default SavedButton;
