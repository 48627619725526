import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const paths = [
  { matched: 'NA', label: 'Dashboard' },
  { matched: '/collabs', label: 'Collabs' },
  {
    matched: '/reservations',
    label: 'Reservations',
  },
  { matched: '/offers', label: 'Offers' },
  { matched: '/profile', label: 'Profile' },
];

const usePath = () => {
  const mainRef = useRef<HTMLElement>();

  const { pathname } = useLocation();

  const path = useMemo(() => {
    return pathname !== '/' ? paths.find((x) => pathname.startsWith(x.matched)) : paths[0];
  }, [pathname]);

  useEffect(() => {
    mainRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return { mainRef, path };
};

export default usePath;
