import { executeGql } from '../../utils/gql-utils';

const venueCancelSubscriptionQuery = /* GraphQL */ `
  query venueCancelSubscription($subscriptionId: String!) {
    venueCancelSubscription(subscriptionId: $subscriptionId)
  }
`;

export const venueCancelSubscriptionApi = async (subscriptionId: string): Promise<void> => {
  await executeGql<string>(venueCancelSubscriptionQuery, { subscriptionId });
};
