import * as React from 'react';

import { Box, SxProps, Typography, styled } from '@mui/material';

const DescriptionContainer = styled(Typography)<{ component: 'div' }>({
  display: 'flex',
  'div:first-of-type': {
    marginRight: 50,
  },
  'div:last-of-type': {
    marginRight: 10,
  },
  '& > *': {
    flex: '1',
  },
});

const titleStyle: SxProps = { fontSize: '18px', fontWeight: 'bold', fontStyle: 'italic' };

const ManageAccessTips: React.FC = () => (
  <Box>
    <Typography fontWeight='medium'>
      The Beautypass community consists of two user categories:
      <br />
      Beautypass Select and Lifestyle members
    </Typography>

    <DescriptionContainer component='div' sx={{ my: 8 }}>
      <Box>
        <Box sx={{ color: '#955CCC', ...titleStyle }}>Beautypass Select</Box>
        These members are represented by top model agencies or have a strong social presence. They
        have access to all your public offers
      </Box>
      <Box>
        <Box sx={{ color: '#37BB90', ...titleStyle }}>Lifestyle</Box>
        These members may be new to the world of fashion but they are eager to promote your
        business. They will have access only if you grant it to them
      </Box>
    </DescriptionContainer>
  </Box>
);

export default ManageAccessTips;
