import React from 'react';

import { useSnackbar } from 'notistack';

import { environment } from '../config';

export type Article = {
  description: string[];
  title: string;
};

export type TermsAndConditionsData = {
  terms: Article[];
  privacy: Article[];
  updateDate: string;
};

export type TermsAndConditionsCompleteData = {
  isLoading: boolean;
} & TermsAndConditionsData;

const fetchTermsAndConditions = async (): Promise<TermsAndConditionsData> => {
  const envUrl = environment === 'production' ? '' : `-${environment}`;
  const res = await fetch(
    `https://api${envUrl}.beautypass.app/app/adminconfig?type=termsAndConditions`,
    {
      headers: {
        'x-api-key': 'beautypass-app-apikey-00001',
      },
    },
  );

  const json = await res.json();
  return JSON.parse(json.data);
};

export const useTermsAndConditions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState<TermsAndConditionsData>();
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchTermsHandle = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const termsAndConditions = await fetchTermsAndConditions();
      setData(termsAndConditions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      enqueueSnackbar('something went wrong please try again later', { variant: 'error' });
    }
  }, [enqueueSnackbar]);
  
  React.useEffect(() => {
    fetchTermsHandle();
  }, [fetchTermsHandle]);

  return { ...data, isLoading } as TermsAndConditionsCompleteData;
};
