import React from 'react';

import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';

import { ChatMessageItem } from '../../graphql/API';
import { onMessagingBtwVenueAndUser } from '../../graphql/subscriptions';
import { GqlError, getError, getErrorFromResult, processGqlResult } from '../../utils/gql-utils';

export const useOnMessagingBtwVenueAndUserEventListener = (toUserId: string) => {
  const [message, setMessage] = React.useState<Nullable<ChatMessageItem>>(null);
  const [error, setErorr] = React.useState<GqlError>();

  React.useEffect(() => {
    if (toUserId) {
      const createSub = API.graphql<GraphQLSubscription<ChatMessageItem>>({
        query: onMessagingBtwVenueAndUser,
        variables: { userType: 'venue/user', toUserId },
      }).subscribe({
        next: ({ value }) => {
          const _message = processGqlResult<ChatMessageItem>('onMessagingBtwVenueAndUser', value);
          setMessage(_message);
          const errorFromResponse = getErrorFromResult(value);

          if (errorFromResponse) {
            throw errorFromResponse;
          }
        },
        error: (ex) => {
          const raisedError = getError(ex);
          console.error(raisedError);
          setErorr(raisedError);
        },
      });

      return () => {
        createSub.unsubscribe();
      };
    }
  }, [setMessage, toUserId]);

  return {
    message,
    error,
  };
};
