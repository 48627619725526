import React from 'react';

import { Box, Stack } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { ShowFnOutput } from 'mui-modal-provider';

import { Reservation } from '../../../../graphql/API';
import SideBySide from '../../../../pages/CreateUpdateOffer/components/SideBySide';
import { useUpdateReservationMutation } from '../../../../pages/ReservationDetail/components/useMutation';
import LoadingButton from '../../../Button/LoadingButton';
import TimePicker from '../../../Formiks/CustomTimePicker/CustomTimePicker';
import DatePicker, { SlotProps } from '../../../Formiks/DatePicker/DatePicker';
import { BaseDialogProps } from '../../Base';
import {
  EditReservationDateAndTimeFormValue,
  mapReservationFormValue,
  mapToRservationDateAndTimeForm,
} from './schema';

const slotProps: SlotProps = {
  textField: {
    size: 'small',
    fullWidth: true,
  },
};

export type EditReservationDateAndTimeProps = {
  modalRef: React.MutableRefObject<ShowFnOutput<BaseDialogProps> | undefined>;
  reservationDetail?: Reservation;
  refetchReservationDetails?: () => void;
};

export const EditReservationDateAndTime: React.FC<EditReservationDateAndTimeProps> = ({
  modalRef,
  reservationDetail,
  refetchReservationDetails,
}) => {
  const updateReservationMutation = useUpdateReservationMutation({
    onSuccess: () => {
      modalRef.current?.destroy();
      if (refetchReservationDetails) refetchReservationDetails();
    },
  });

  const initialValues = React.useMemo(
    () => (reservationDetail ? mapToRservationDateAndTimeForm(reservationDetail) : {}),
    [reservationDetail],
  );

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values) => {
        const body = mapReservationFormValue(
          values as EditReservationDateAndTimeFormValue,
          reservationDetail?.timeZoneId,
        );
        if (reservationDetail?.id) {
          updateReservationMutation.mutate({
            id: reservationDetail.id,
            ...body,
          });
        }
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box marginTop='24px'>
            <CustomBox>
              <label htmlFor='' className='offer-form-label'>
                Date
              </label>
              <DatePicker
                minDate={dayjs()}
                name='date'
                slotProps={slotProps as SlotProps}
                className='offer-input-field'
                maxDate={dayjs().add(30, 'day')}
              />
            </CustomBox>
          </Box>
          <Box marginTop='24px'>
            <Stack spacing={3} sx={{ width: '100%' }}>
              <SideBySide ratio={6}>
                <CustomBox>
                  <label htmlFor='' className='offer-form-label'>
                    Start time
                  </label>
                  <TimePicker name='effectiveStartDateTime' className='offer-time-input-field' />
                </CustomBox>
                <CustomBox>
                  <label htmlFor='' className='offer-form-label'>
                    End time
                  </label>
                  <TimePicker name='effectiveEndDateTime' className='offer-time-input-field' />
                </CustomBox>
              </SideBySide>
            </Stack>
          </Box>
          <LoadingButton
            loading={updateReservationMutation.isPending}
            fullWidth
            sx={{ display: 'block', width: '100%', paddingY: '12px', marginTop: '74px' }}
            variant={'contained'}
            type='submit'
            disabled={updateReservationMutation.isPending}
          >
            Save
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
};

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;
