import { pick } from 'lodash';
import * as yup from 'yup';

import { Venue, VenueUpdateVenueInput } from '../../../../graphql/API';

export const schema = yup.object({
  name: yup.string().required(),
  address1: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  country: yup.string().required(),
  description: yup.string().max(800),
  website: yup.string().url('Invalid website URL'),
});

export const makeFormValue = (venue: Venue | undefined): VenueUpdateVenueInput | undefined =>
  venue
    ? {
        id: venue.id,
        ...pick(venue, [
          'name',
          'phone',
          'address1',
          'address2',
          'city',
          'stateProvince',
          'postalCode',
          'country',
          'website',
          'description',
          'contactFirstname',
          'contactLastname',
          'contactPosition',
          'formattedAddress',
          'countryCode',
          'location.lat',
          'location.lon',
        ]),
      }
    : undefined;
