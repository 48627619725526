import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { venueAdminGetVenueApi } from '../../../api/venue/venueAdminGetVenue';
import { Venue } from '../../../graphql/API';

export const fetchVenue = createAsyncThunk('venues/fetchVenue', async () => {
  const data = await venueAdminGetVenueApi();
  return data;
});

export interface VenueState {
  value?: Venue;
  isLoading?: boolean;
}

const initialState: VenueState = {
  value: undefined,
  isLoading: true,
};

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    reset: () => initialState,
    updateCredit: (state, action: PayloadAction<number>) => {
      if (state.value?.availableDownloads) {
        state.value.availableDownloads += action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVenue.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
  },
});

export const { reset, updateCredit } = venueSlice.actions;

export default venueSlice.reducer;
