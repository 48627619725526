const formatter = Intl.NumberFormat('en', { notation: 'compact' });

export const formatNumber = (total: number) => formatter.format(total ?? 0);

export const centsToDollars = (cents: number): string => {
  if (isNaN(cents)) {
    throw new Error('Input must be a number');
  }

  const dollars = cents / 100;
  return dollars.toFixed(2);
};

export const formatNumberWithCommas = (number: number) => {
  return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
