import { AddressDetail } from './types';

export const getAddressFromPlaceDetail = (
  placeDetail: google.maps.places.PlaceResult | null,
): AddressDetail | null => {
  if (placeDetail && placeDetail.address_components && placeDetail?.geometry?.location) {
    const addressFromComponents = placeDetail.address_components.reduce(
      (acc, cur) => {
        switch (cur.types[0].replace(/_level_\d$/, '')) {
          case 'street_address':
          case 'route':
            return {
              ...acc,
              address1: acc.address1 ? cur.long_name + ' ' + acc.address1 : cur.long_name,
            };
          case 'street_number':
            return {
              ...acc,
              address1: acc.address1 ? acc.address1 + ' ' + cur.long_name : cur.long_name,
            };
          case 'subpremise':
            return {
              ...acc,
              address2: cur.long_name,
            };
          case 'postal_code':
            return {
              ...acc,
              postalCode: cur.long_name,
            };
          case 'administrative_area':
            return {
              ...acc,
              stateProvince: cur.long_name,
            };
          case 'country':
            return {
              ...acc,
              country: cur.long_name,
              countryCode: cur.short_name,
            };
          case 'locality':
            return {
              ...acc,
              city: cur.long_name,
            };
          case 'sublocality':
            return {
              ...acc,
              dependentLocality: cur.long_name,
            };
          default:
            return acc;
        }
      },
      {
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        stateProvince: '',
        country: '',
        countryCode: '',
        dependentLocality: '',
      },
    );

    return {
      location: {
        lat: placeDetail.geometry.location.lat(),
        lon: placeDetail.geometry.location.lng(),
      },
      placeId: placeDetail.place_id ?? '',
      formattedAddress: placeDetail.formatted_address,
      ...addressFromComponents,
    };
  }

  return null;
};
