import React from 'react';
import ReactCountryFlag from 'react-country-flag';

import styled from '@emotion/styled';

export const CountryItem: React.FC<{ name: string; followers: number; code: string }> = ({
  name,
  followers,
  code
}) => {
  return (
    <Container>
      <CountryNameContainer>
        <ReactCountryFlag countryCode={code || ''} svg />
        <CountryName>{name ?? code}</CountryName>
      </CountryNameContainer>
      <Followers>{followers?.toFixed(2)}%</Followers>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid #ebebeb;
  margin: 15px 0;
`;

const CountryNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CountryName = styled.span`
  font-size: 15px;
  font-weight: 400;
`;

const Followers = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #111;
`;
