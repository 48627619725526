import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

const OfferCardSkeleton: React.FC = () => {
  return (
    <Card sx={{ maxWidth: 345, position: 'relative' }}>
      <Skeleton variant='rounded' height={200} />
      <CardContent>
        <Skeleton />
        <Skeleton width='60%' />

        <Box sx={{ mt: 2 }}>
          <Skeleton width='100%' />
          <Skeleton width='100%' />
        </Box>

        <Box marginTop={1}>
          <Skeleton width='40%' />
        </Box>
      </CardContent>
      <CardActions sx={{ px: 2 }}>
        <Skeleton width='30%' variant='rounded' />
        <Skeleton width='70%' variant='rounded' />
      </CardActions>
    </Card>
  );
};

export default OfferCardSkeleton;
