import * as React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { venueActionAccessRequestApi } from '../../../api/accessRequest/venueActionAccessRequestApi';
import { VenueActionAccessRequest } from '../../../graphql/API';

export type OnAction = (input: VenueActionAccessRequest | undefined) => void;

export const useAction = () => {
  const [loadingState, setLoading] = React.useState<Record<string, boolean>>({});

  const queryClient = useQueryClient();

  const onAction = React.useCallback<OnAction>(
    async (input) => {
      if (input) {
        const key = `${input.userID}:${input.offerID}:${input.action}`;
        setLoading((x) => ({ ...x, [key]: true }));

        await venueActionAccessRequestApi(input);

        await queryClient.invalidateQueries({ queryKey: ['list-access-request'] });

        setLoading((x) => ({ ...x, [key]: false }));
      }
    },
    [queryClient],
  );

  return { loadingState, onAction };
};
