import * as React from 'react';

import { Box, CSSObject, styled } from '@mui/material';

import { UserCategory } from '../../../utils/user';
import LazyAvatar, { LazyAvatarProps } from '../LazyAvatar/LazyAvatar';

type UserAvatarCategoryProps = { userCategory?: UserCategory } & LazyAvatarProps;

const userCategoryStyle = (userCategory: UserCategory | undefined): CSSObject => {
  if (!userCategory) {
    return { padding: '3px' };
  }

  return userCategory === UserCategory.select
    ? { border: '2px solid #AB77EE' }
    : { border: '2px solid #77D3A2' };
};

const AvatarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'userCategory',
})<Pick<UserAvatarCategoryProps, 'userCategory'>>(({ userCategory }) => ({
  display: 'inline-flex',
  backgroundColor: 'white',
  padding: '1px',
  borderRadius: '50%',
  ...userCategoryStyle(userCategory),
}));

const UserAvatarCategory: React.FC<UserAvatarCategoryProps> = ({ userCategory, ...restProps }) => {
  return (
    <AvatarContainer userCategory={userCategory}>
      <LazyAvatar alt='avatar' {...restProps} />
    </AvatarContainer>
  );
};

export default UserAvatarCategory;
