import { useMemo } from 'react';

import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { venueGetUserApi } from '../..//api/users/venueGetUserApi';
import { venueListUserReservationContentApi } from '../..//api/users/venueListUserReservationContentApi';
import { venueListUserPortfolioContentApi } from '../../api/users/venueListUserPortfolioContentApi';
import {
  User,
  UserPortfolioItem,
  UserReservationContent,
  VenueListUserPortfolioContentFilter,
  VenueListUserReservationContentFilter,
} from '../../graphql/API';

const PAGE_LIMIT = 20;

export const useQueryUser = (venueId: string | undefined, userId: string | undefined) => {
  return useQuery<User>({
    queryKey: ['user-profile', venueId, userId],
    queryFn: async ({ queryKey }) => {
      const [, venueId, userId] = queryKey as [string, string, string];

      return venueGetUserApi({
        venueID: Number(venueId),
        userID: Number(userId),
        checkHasEventReservation: true,
      });
    },
    placeholderData: keepPreviousData,
    enabled: Boolean(venueId && userId),
  });
};

export const useQueryUserContent = (userId?: string) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<
    UserReservationContent[]
  >({
    initialPageParam: 0,
    queryKey: ['user-reservation-content', userId],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, userID] = queryKey as [string, number];
      const offset = <number>pageParam * PAGE_LIMIT;

      const filter: VenueListUserReservationContentFilter = { userID, offset, limit: PAGE_LIMIT };
      return venueListUserReservationContentApi(filter);
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  const flatData = useMemo(() => data?.pages.flatMap((x) => x) ?? [], [data]);

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export const useQueryUserPortfolio = (userId?: string) => {
  return useQuery<UserPortfolioItem[]>({
    queryKey: ['user-reservation-portfolio', userId],
    queryFn: async ({ queryKey }) => {
      const [, userID] = queryKey as [string, number];

      const filter: VenueListUserPortfolioContentFilter = {
        userID: Number(userID),
      };
      return venueListUserPortfolioContentApi(filter);
    },
    placeholderData: keepPreviousData,
  });
};
