import React, { PropsWithChildren, useState } from 'react';

export type ChatContextProps = {
  shouldChatOpen: boolean;
  offerId: string;
  setShouldChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOfferId: React.Dispatch<React.SetStateAction<string>>;
};

export const ChatContext = React.createContext<ChatContextProps | null>(null);

export const ChatProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [shouldChatOpen, setShouldChatOpen] = useState(false);
  const [offerId, setOfferId] = useState('');

  return (
    <ChatContext.Provider value={{ shouldChatOpen, setShouldChatOpen, offerId, setOfferId }}>
      {children}
    </ChatContext.Provider>
  );
};
