import { VenueActionAccessRequest, VenueBlockUserInput } from '../../../graphql/API';

export const isVenueActionAccessInput = (
  input: VenueActionAccessRequest | VenueBlockUserInput,
): input is VenueActionAccessRequest =>
  Boolean((<VenueActionAccessRequest>input).action && (<VenueActionAccessRequest>input).offerID);

export type OnAction = (input: VenueActionAccessRequest | VenueBlockUserInput | undefined) => void;

export type MapProps = {
  venueId: number;
  onAction: OnAction;
  loadingState: Record<string, boolean>;
};
