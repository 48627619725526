import * as React from 'react';

import { Button, Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { ShowFnOutput, useModal } from 'mui-modal-provider';

import SubscriptionDialog, {
  SubscriptionDialogProps,
} from '../../../../components/Dialogs/Subscriptions/SubscriptionDialog';
import { VenueSubscriptionItem } from '../../../../graphql/API';
import { StripePriceWithMetadata } from '../../../../redux/features/subscription/types';
import {
  extractSubscriptionPlan,
  getPlanPriceBySku,
  toCurrencyAmount,
  toDecimal,
} from '../../common/helper';
import { Plan } from '../../types';

type SubscriptionDetailProps = {
  subscription: VenueSubscriptionItem;
  pricing: StripePriceWithMetadata[];
};

const LabelValue: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
  <Typography
    component='div'
    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
  >
    <Box sx={{ color: '#848484' }}>{label}</Box>
    <Box>{value}</Box>
  </Typography>
);

const mapPlanLabel: Partial<Record<Plan, React.ReactNode>> = {
  starter: <Box sx={{ color: '#49B210' }}>Starter</Box>,
  plus: <Box sx={{ color: '#D86EEF' }}>PLUS</Box>,
};

const removeFinalKeyword = (sku: string): string => {
  return sku.replace('_final', '');
};

const SubscriptionDetail: React.FC<SubscriptionDetailProps> = ({ subscription, pricing }) => {
  const [plan, , cycle] = React.useMemo(
    () => extractSubscriptionPlan(subscription.sku),
    [subscription.sku],
  );

  const { showModal } = useModal();

  const modalRef = React.useRef<ShowFnOutput<SubscriptionDialogProps>>();

  const handleCancel = React.useCallback(() => {
    modalRef.current = showModal(SubscriptionDialog, {
      price: undefined,
      plan: 'basic',
      kind: 'plan-cancel',
      subscription,
      modalRef,
    });
  }, [subscription, showModal]);

  const price = getPlanPriceBySku(removeFinalKeyword(subscription.sku), pricing);

  const labelExpiration = React.useMemo(
    () => (subscription.isSubscriptionRenewable ? 'Next renewal' : 'End date'),
    [subscription.isSubscriptionRenewable],
  );

  const renderAction = React.useCallback(() => {
    if (subscription.platform == 'web') {
      return subscription.isSubscriptionRenewable ? (
        <Button sx={{ p: 0, textDecoration: 'underline' }} onClick={handleCancel}>
          Cancel Subscription
        </Button>
      ) : (
        <Typography>The plan will not be renewed</Typography>
      );
    }

    return null;
  }, [subscription.platform, subscription.isSubscriptionRenewable, handleCancel]);

  return (
    <Paper sx={{ p: 2, boxShadow: '0px 0px 30px #0000000D', maxWidth: 400 }}>
      <Stack spacing={1}>
        <LabelValue label='Your plan' value={mapPlanLabel[plan]} />
        <LabelValue label='Period' value={`${cycle} ${cycle === '1' ? 'month' : 'months'}`} />
        <LabelValue
          label='Price'
          value={
            <>
              {toCurrencyAmount(subscription.price ?? 0, subscription.currency ?? '')} &nbsp;(
              {toCurrencyAmount(toDecimal(price?.metadata.CostPerMonth), price?.currency ?? '')}
              /month)
            </>
          }
        />
        <LabelValue
          label={labelExpiration}
          value={dayjs(subscription.expirationDate).format('ll')}
        />
      </Stack>

      <Box sx={{ marginTop: 4 }}>{renderAction()}</Box>
    </Paper>
  );
};

export default SubscriptionDetail;
