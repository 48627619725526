import * as React from 'react';
import { useImageSize } from 'react-image-size';
import { Gallery, ItemProps, Item as PhotoSwipeItem } from 'react-photoswipe-gallery';

type PhotoswipeProps = {
  itemProps: Omit<ItemProps<HTMLElement>, 'children'>;
};

const Item: React.FC<React.PropsWithChildren<PhotoswipeProps>> = ({ children, itemProps }) => {
  const [dimensions] = useImageSize(itemProps.original ?? '');

  return (
    <PhotoSwipeItem {...itemProps} width={dimensions?.width} height={dimensions?.height}>
      {({ ref, open }) =>
        React.cloneElement(children as React.ReactElement, {
          ref,
          onClick: open,
        })
      }
    </PhotoSwipeItem>
  );
};

export { Gallery, Item };
