import MuiMenu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const Menu = styled((props: MenuProps) => (
  <MuiMenu
    sx={{ mt: '45px' }}
    keepMounted
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 200,
    border: `1px solid ${theme.custom.colors.brandLightGrey}`,
    boxShadow: theme.shadows[4],
  },
}));

export default Menu;
