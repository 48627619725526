import { AccountBalanceResponse, VenueAccountBalanceRequest } from '../../graphql/API';
import { venueGetAccountBalance } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueGetAccountBalanceApi = async (
  filter: VenueAccountBalanceRequest,
): Promise<AccountBalanceResponse> => {
  const result = await executeGql(venueGetAccountBalance, { filter });
  return processGqlResult<AccountBalanceResponse>('venueGetAccountBalance', result);
};
