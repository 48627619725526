import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import ManageContentTips from './ManageContentTips';

const ManageContentTipsDialog: React.FC<BaseDialogProps> = (props) => {
  return (
    <BaseDialog {...props} width='660px' height='unset'>
      <ManageContentTips />
    </BaseDialog>
  );
};

export default ManageContentTipsDialog;
