import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Lottie from 'lottie-react';

import offerCreatedSuccess from '../../../Icons/lottie/offerCreatedSuccess.json';

const CreateSuccess: React.FC = () => {
  return (
    <Box
      height='100%'
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      px={4}
      pb={4}
    >
      <Stack spacing={2} alignItems='center'>
        <Box
          component={Lottie}
          animationData={offerCreatedSuccess}
          loop={true}
          sx={{ width: 150 }}
        />

        <Box>
          <Typography variant='h5' textAlign='center' fontWeight='bold'>
            Offer created successfully!
          </Typography>
          <Typography
            component='div'
            variant='body2'
            textAlign='center'
            width='17rem'
            margin='auto'
          >
            We have taken your request, our team will review the offer as soon as possible{' '}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateSuccess;
