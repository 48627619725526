import React from 'react';

import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';

import { ChatMessageItem } from '../../graphql/API';
import { onAdminUpdateChatMessage } from '../../graphql/subscriptions';
import { GqlError, getError, getErrorFromResult, processGqlResult } from '../../utils/gql-utils';

export const useOnAdminUpdateChatMessageListener = (
  messageTime?: string,
  conversationId?: string,
) => {
  const [message, setMessage] = React.useState<Nullable<ChatMessageItem>>(null);
  const [error, setErorr] = React.useState<GqlError>();

  React.useEffect(() => {
    if (messageTime && conversationId) {
      const createSub = API.graphql<GraphQLSubscription<ChatMessageItem>>({
        query: onAdminUpdateChatMessage,
        variables: { messageTime, conversationId },
      }).subscribe({
        next: ({ value }) => {
          const _message = processGqlResult<ChatMessageItem>('onAdminUpdateChatMessage', value);
          setMessage(_message);
          const errorFromResponse = getErrorFromResult(value);

          if (errorFromResponse) {
            throw errorFromResponse;
          }
        },
        error: (ex) => {
          const raisedError = getError(ex);
          console.error(raisedError);
          setErorr(raisedError);
        },
      });

      return () => {
        createSub.unsubscribe();
      };
    }
  }, [setMessage, conversationId, messageTime]);

  return {
    message,
    error,
  };
};
