import * as React from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { Box, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import config from '../../config';
import icons from '../../themes/icons';
import GooglePlaceAutoCompleteOption from './GooglePlaceAutoCompleteOption';
import { getAddressFromPlaceDetail } from './getAddressFromPlaceDetail';
import {
  AddressDetail,
  AutocompleteIsOptionEqualToValue,
  AutocompleteOnChange,
  AutocompleteOnInputChange,
  AutocompleteRenderInput,
  PlaceType,
} from './types';

export type GooglePlaceAutoCompleteProps = {
  type?: 'offer' | 'normal';
  onChange: (e: AddressDetail | null) => void;
  error?: boolean;
  helperText?: string;
  placeAddress?: string;
};

const GooglePlaceAutoComplete: React.FC<GooglePlaceAutoCompleteProps> = ({
  type = 'normal',
  onChange,
  error = false,
  helperText = '',
  placeAddress,
}) => {
  const placeRef = React.useRef<string>('');

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({ apiKey: config.googleMapApiKey, language: 'en' });

  React.useEffect(() => {
    const json = JSON.stringify(placePredictions);

    if (placeRef.current !== json) {
      setOptions(placePredictions);
      placeRef.current = json;
    }
  }, [placePredictions]);

  React.useEffect(() => {
    if (placeAddress && type === 'offer') {
      getPlacePredictions({ input: placeAddress.substring(0, 40) });
    }
  }, [placeAddress, type]);

  const handleOnInputChange = React.useCallback<AutocompleteOnInputChange>(
    (_event, newInputValue) =>
      getPlacePredictions({
        input: !newInputValue ? placeAddress?.substring(0, 40) || '' : newInputValue,
      }),
    [getPlacePredictions, placeAddress],
  );

  const handleOnChange = React.useCallback<AutocompleteOnChange>(
    (_event, newValue) => {
      setValue(newValue);
      if (newValue?.place_id) {
        placesService?.getDetails({ placeId: newValue.place_id }, (placeDetails) =>
          onChange(getAddressFromPlaceDetail(placeDetails)),
        );
      } else {
        onChange(null);
      }
    },
    [placesService, onChange],
  );

  const renderOption = React.useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: PlaceType) => (
      <GooglePlaceAutoCompleteOption key={props.id} props={props} option={option} />
    ),
    [],
  );

  const renderInput = React.useCallback<AutocompleteRenderInput>(
    (props) =>
      type === 'normal' ? (
        <Box>
          <label htmlFor='google-map-autocomplete' className='update-profile-modal-label'>
            Search an address to fill the form
          </label>
          <TextField {...props} fullWidth />
        </Box>
      ) : (
        <Box>
          <label htmlFor='google-map-autocomplete' className='offer-form-label'>
            Location
          </label>
          <TextField
            {...props}
            placeholder='Location of the event'
            InputProps={{
              ...props.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <icons.PlaceRoundedIcon />
                </InputAdornment>
              ),
            }}
            error={error}
            helperText={helperText}
            fullWidth
          />
        </Box>
      ),
    [error, helperText, type],
  );

  const isOptionEqualToValue = React.useCallback<AutocompleteIsOptionEqualToValue>(
    (a, b) => a?.place_id === b?.place_id,
    [],
  );

  return (
    <Autocomplete
      id='google-map-autocomplete'
      size={type === 'offer' ? 'medium' : 'small'}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete={false}
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={isPlacePredictionsLoading}
      noOptionsText='No locations'
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  );
};

export default GooglePlaceAutoComplete;
