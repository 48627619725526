import { useQuery } from '@tanstack/react-query';

import { VenueListReservationsCountFilter } from '../../../graphql/API';
import { venueListReservationsCountApi } from '../../../api/reservations/venueListReservationsCountApi';

export const useVenueListReservationsCountQuery = (filter: VenueListReservationsCountFilter, enabled = true) => {
  const { data, isFetching, isError, isLoading } = useQuery({
    queryKey: ['useVenueListReservationsCountAPI', { ...filter }],
    queryFn: async ({ queryKey }) => {
      const [, filters] = queryKey as [string, VenueListReservationsCountFilter];

      return venueListReservationsCountApi(filters);
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(filter.venueID) && enabled,
  });
  return { data, isFetching, isError, isLoading };
};
