import useQueryParams from '../../hooks/useQueryParams';

export const Footer = () => {
  const { getQueryParam } = useQueryParams();
  const instaHandle = getQueryParam('referralInstaHandle');

  return (
    <div className='login-footer-account-container'>
      <span className='dont-have-account'>Don't have an account?</span>
      <a
        href={`https://www.beautypass.app/sign-up${
          instaHandle ? `?referralInstaHandle=${instaHandle}` : ''
        }`}
        className='login-footer-create-account'
        target='_blank'
      >
        Create a venue account
      </a>
    </div>
  );
};
