import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { venueListInvitesApi } from '../../../api/invites/venueListInvitesApi';
import { Invite, VenueListInvitesFilter } from '../../../graphql/API';

type Filter = {
  venueID: VenueListInvitesFilter['venueID'];
} & Partial<VenueListInvitesFilter>;

export const fetchInvites = createAsyncThunk(
  'invite/fetchInvites',
  async ({ venueID, limit = 99, offset = 0, ...restFilters }: Filter) => {
    const data = await venueListInvitesApi({ venueID, limit, offset, ...restFilters });
    return data;
  },
);

export interface InviteState {
  value?: Invite[];
  highlight?: boolean;
}

const initialState: InviteState = {
  value: undefined,
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (state, action: PayloadAction<Invite[]>) => {
      state.value = action.payload;
    },
    markHighlight: (state, action: PayloadAction<boolean>) => {
      state.highlight = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvites.fulfilled, (state, action) => {
      state.value = action.payload;
    });
  },
});

export const { reset, update, markHighlight } = inviteSlice.actions;

export default inviteSlice.reducer;
