import { environment } from '.';
import { AwsConfigV2 } from './types';

export const fetchConfig = async (): Promise<AwsConfigV2 | undefined> => {
  try {
    const response = await fetch(
      environment === 'production'
        ? 'https://api.beautypass.app/app/config'
        : 'https://api-dev.beautypass.app/app/config',
      {
        headers: {
          'x-api-key': 'beautypass-app-apikey-00001',
        },
      },
    );

    const envConfig = await response.json();

    return envConfig as unknown as AwsConfigV2;
  } catch (error) {
    console.error(error);
  }
};
