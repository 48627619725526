import { executeGql } from '../../utils/gql-utils';

const venueCreatePortfolioContentMutation = /* GraphQL */ `
  mutation venueCreatePortfolioContent($input: VenueCreatePortfolioInput!) {
    venueCreatePortfolioContent(input: $input)
  }
`;

export const venueCreatePortfolioContentApi = async (portfolio: string): Promise<void> => {
  await executeGql<void>(venueCreatePortfolioContentMutation, { input: { portfolio } });
};
