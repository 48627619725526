import * as React from 'react';

import Box from '@mui/material/Box';

const FloatTopLeft: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        top: theme.spacing(3),
        left: theme.spacing(3),
      })}
    >
      {children}
    </Box>
  );
};

export default FloatTopLeft;
