import { Dayjs } from 'dayjs';

import { Offer, User } from '../../../../graphql/API';
import { UserIDsWithOfferPrice } from './../../../../graphql/API';

type ActionKind = 'next' | 'prev' | 'update' | 'event-offer-next' | 'event-offer-prev';

export enum Step {
  OfferSelection,
  DateSelection,
  Summary,
  Success,
}

export enum EventStep {
  OfferSelection,
  Summary,
  Success,
}

export type Action = {
  type: ActionKind;
  payload?: Partial<State>;
};

export type State = {
  step: Step;
  users: User[];
  isEmptyOffer?: boolean;
  offer?: Offer;
  dates?: Dayjs[];
  startTime?: Dayjs;
  endTime?: Dayjs;
  premiumCredits?: number;
  nonPremiumCredits?: number;
  requirements?: string;
  usersWithOfferPrice?: UserIDsWithOfferPrice[];
  previousTotalBalance?: string;
};

export enum ExtendedOfferRequirements {
  noRequirement = 'noRequirement',
}
