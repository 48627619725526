import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Grid, Skeleton, Stack, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';

import { venueGetConversationWithUserApi } from '../../api/chat/venueGetConversationWithUserApi';
import { venueUpdateInvitePaymentToReleaseApi } from '../../api/invites/venueUpdateInvitePaymentToReleaseApi';
import { venueUpdateInvitePaymentToStopApi } from '../../api/invites/venueUpdateInvitePaymentToStopApi';
import LazyAvatar from '../../components/Avatar/LazyAvatar/LazyAvatar';
import Button from '../../components/Button/Button';
import SaveUserButton from '../../components/Button/SaveUserButton';
import { BaseDialogProps } from '../../components/Dialogs/Base';
import CancelReservationDialog from '../../components/Dialogs/CancelReservation/CancelReservationDialog';
import { CheckedInConfirmDialog } from '../../components/Dialogs/Confirm/CheckedInConfirmDialog';
import { PaymentConfirmDialog } from '../../components/Dialogs/Confirm/PaymentConfirmDialog';
import { EditReservationDateAndTimeDialog } from '../../components/Dialogs/EditReservationDateAndTime/EditReservationDateAndTimeDialog';
import ReportReservationsDialog from '../../components/Dialogs/ReportReservations/ReportReservationsDialog';
import FloatTopLeft from '../../components/FloatTopLeft/FloatTopLeft';
import GoBack from '../../components/GoBack/GoBack';
import { IOSSwitch } from '../../components/IOSSwitch';
import InstagramTag from '../../components/InstagramTag/InstagramTag';
import LabelDetail from '../../components/LabelDetail/LabelDetail';
import SkeletonWithChild from '../../components/SkeletonWithChild/SkeletonWithChild';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import { OfferCategory, ReservationStatus, UserUpdateStatus } from '../../graphql/API';
import { useQueryUser } from '../../hooks/reactQuery/useQueryUser';
import useVenueId from '../../hooks/useVenueId';
import { useAppSelector } from '../../redux/hooks';
import Icons from '../../themes/icons';
import {
  datetimeWithTimezone,
  getDateAndTimeInTimezoneInDayjs,
  getTimeInTimezoneInDayjs,
} from '../../utils/dateV2';
import { formatNumber } from '../../utils/formatter';
import { OfferCategoriesCallToConfirm } from '../../utils/offerAccess';
import { checkChatHoursIsNotPassed, isAfter48HoursOfOfferEnd } from '../../utils/reservations';
import { useRequirementOfReservation } from '../OfferDetail/components/useRequirment';
import DateTime from './components/DateTime';
import { OfferPriceStatus } from './components/OfferPriceStatus';
import { ReleasePaymentButton, StopPaymentButton } from './components/PaymentButtons';
import Requirements from './components/Requirements';
import { useUpdateReservationMutation } from './components/useMutation';
import { useQueryReservation } from './components/useQuery';

export enum ReservationPost {
  validated = 'validated',
  validationFailed = 'validationFailed',
  validationUpdated = 'validationUpdated',
}

export enum ReservationPaymentStatus {
  released = 'released',
  ready = 'ready',
  stop = 'stop',
  processed = 'processed',
}

const ReservationDetail: React.FC = () => {
  const navigate = useNavigate();
  const venueId = useVenueId();
  const { enqueueSnackbar } = useSnackbar();
  const venue = useAppSelector((state) => state.venue.value);
  const { data, isLoading, refetch } = useQueryReservation();
  const { data: userData } = useQueryUser(venueId, data?.userID);
  const updateReservationMutation = useUpdateReservationMutation({ onSuccess: refetch });
  const requirements = useRequirementOfReservation(data ?? undefined);
  const { showModal } = useModal();
  const complainModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const cancelReservationModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const editDateAndTimeModalRef = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const paymentReleaseDialog = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const paymentStopDialog = React.useRef<ShowFnOutput<BaseDialogProps>>();
  const checkedInDialog = React.useRef<ShowFnOutput<BaseDialogProps>>();

  const [isConversationCreationLoading, setIsConversationCreationLoading] = React.useState(false);

  const paymentReleaseMutation = useMutation({
    mutationFn: venueUpdateInvitePaymentToReleaseApi,
    onSuccess: () => {
      refetch();
      enqueueSnackbar('Payment release action submitted successfully', { variant: 'success' });
    },
  });
  const paymentStopMutation = useMutation({
    mutationFn: venueUpdateInvitePaymentToStopApi,
    onSuccess: () => {
      refetch();
      enqueueSnackbar('Payment stop action submitted successfully', { variant: 'success' });
    },
  });

  const { offerStartTime, offerEndTime, checkedInTime, reservedDate } = React.useMemo(() => {
    const effectiveStartTime = dayjs.utc(data?.effectiveStartDateTime).format('HH:mm');
    const effectiveEndTime = dayjs.utc(data?.effectiveEndDateTime).format('HH:mm');

    const timezoneId = data?.timeZoneId;
    const reservedDate = getDateAndTimeInTimezoneInDayjs(
      data?.effectiveStartDateTime || '',
      timezoneId,
    );
    const reservedDateInUtc = dayjs.utc(data?.effectiveStartDateTime).format('YYYY-MM-DD');
    return {
      offerStartTime: getTimeInTimezoneInDayjs(
        reservedDateInUtc || '',
        effectiveStartTime,
        timezoneId,
      ),
      offerEndTime: getTimeInTimezoneInDayjs(reservedDateInUtc || '', effectiveEndTime, timezoneId),
      checkedInTime: getDateAndTimeInTimezoneInDayjs(data?.checkInDateTime || '', timezoneId),
      reservedDate,
    };
  }, [data]);

  const checkedInValue = React.useMemo(() => {
    const reservationStatus = data?.status;
    if (reservationStatus === ReservationStatus.checkedin) {
      return <DateTime value={checkedInTime ?? dayjs()} />;
    }

    if (reservationStatus === ReservationStatus.noshow) {
      return 'User did not check in';
    }

    return 'Not checked in yet';
  }, [checkedInTime, data?.status]);

  const onCancelReservationDialogHandle = () => {
    cancelReservationModalRef.current = showModal(CancelReservationDialog, {
      modalRef: cancelReservationModalRef,
      reservationDetail: data,
      refetchReservationDetails: refetch,
    });
  };

  const onEditReservationDateAndTimeDialogHandle = () => {
    editDateAndTimeModalRef.current = showModal(EditReservationDateAndTimeDialog, {
      modalRef: editDateAndTimeModalRef,
      reservationDetail: data,
      refetchReservationDetails: refetch,
    });
  };

  const onCheckedInHandle = (checked: boolean) => {
    if (data?.id && checked) {
      updateReservationMutation.mutate({
        id: data?.id,
        status: UserUpdateStatus.checkedin,
      });
    }
  };

  const onCheckedInDialogHandle = () => {
    checkedInDialog.current = showModal(CheckedInConfirmDialog, {
      title: 'Are you sure you want check in?',
      subTitle:
        'The offer will become active again, and the user will be able to complete the reservation.',
      onConfirm: () => {
        onCheckedInHandle(true);
      },
    });
  };

  const onPaymentReleaseDialogHandle = () => {
    paymentReleaseDialog.current = showModal(PaymentConfirmDialog, {
      title: 'Are you sure you want to release the payment?',
      subTitle:
        'By releasing the payment, the user will receive the collab amount. This action cannot be undone.',
      onConfirm: () => {
        if (data?.inviteID) {
          paymentReleaseMutation.mutate({ inviteID: data?.inviteID });
        }
      },
    });
  };

  const onPaymentStopDialogHandle = () => {
    paymentStopDialog.current = showModal(PaymentConfirmDialog, {
      title: 'Are you sure you want to stop the payment?',
      subTitle: 'A member of our team will review your case and contact you shortly via chat.',
      onConfirm: () => {
        if (data?.inviteID) {
          paymentStopMutation.mutate({ inviteID: data?.inviteID });
        }
      },
      isStopPayment: true,
    });
  };

  const refreshHandle = React.useCallback(() => {
    refetch();
  }, [refetch]);

  const postLinkHandle = React.useCallback(() => {
    window.open(data?.postLink || '', '_blank');
  }, [data?.postLink]);

  const createConversationWithUser = async () => {
    try {
      setIsConversationCreationLoading(true);
      await venueGetConversationWithUserApi({
        venueID: venueId || '',
        userID: data?.userID || '',
        reservationID: data?.id || '',
        offerTitle: data?.offerTitle || '',
      });
      setIsConversationCreationLoading(false);
      navigate(
        `/chats?userID=${data?.userID}&reservationID=${data?.id}&offerTitle=${data?.offerTitle}`,
      );
    } catch (error) {
      console.error(error);
      setIsConversationCreationLoading(false);
      enqueueSnackbar('Something went wrong please try again later.', { variant: 'error' });
    }
  };

  const offerCategory = data?.offer?.category || data?.offerCategory;

  const isEditable =
    (offerCategory &&
      OfferCategoriesCallToConfirm.includes(offerCategory) &&
      data.status === ReservationStatus.reserved) ||
    false;

  const canReleasePayment = !!(
    data &&
    data.status === ReservationStatus.checkedin &&
    !data.requirementsSubmitted &&
    !data.paymentStatus &&
    isAfter48HoursOfOfferEnd(data)
  );

  const canStopPayment = !!(
    data &&
    data?.paymentStatus === ReservationPaymentStatus.ready &&
    data.status === ReservationStatus.checkedin &&
    data?.requirementsSubmitted
  );

  const isChatCanStart = React.useMemo(
    () =>
      (data && data?.offer?.private && data.paymentStatus === ReservationPaymentStatus.stop) ||
      (data &&
        checkChatHoursIsNotPassed(data) &&
        data &&
        data?.status !== ReservationStatus.cancelled &&
        (data?.offer?.private ||
          (offerCategory === OfferCategory.beauty && data?.offer?.confirmWithCall))) ||
      false,
    [offerCategory, data],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: 'white',
        flex: 1,
        py: 4,
        pl: 10,
        pr: 6,
      }}
    >
      <FloatTopLeft>
        <GoBack />
      </FloatTopLeft>

      <Grid container spacing={2}>
        <Grid item lg={8} md={7} xs={7}>
          <Box
            sx={{
              borderRadius: '8px',
              border: '1px solid #F1F1F1',
              backgroundColor: '#fff',
              padding: '22px 32px',
            }}
          >
            <Box
              sx={{
                borderBottom: '1px solid #F7F7F7',
                paddingBottom: '15px',
                display: 'flex',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <Typography fontSize={'20px'} fontWeight={600}>
                Reservation details
              </Typography>

              {data?.offer?.private && (
                <CollabBadge>
                  <Icons.CollabOfferIcon style={{ width: '20px', height: '20px' }} /> Collab offer
                </CollabBadge>
              )}
            </Box>
            <Box my={4}>
              {isLoading ? (
                <>
                  <Stack spacing={3}>
                    {[...Array(6)].map((_, i) => (
                      <LabelDetail
                        key={i}
                        label={<Skeleton width='80%' />}
                        description={<Skeleton width='100%' />}
                      />
                    ))}
                  </Stack>
                  <Stack spacing={3} mt={6}>
                    {[...Array(3)].map((_, i) => (
                      <LabelDetail
                        key={i}
                        label={<Skeleton width='80%' />}
                        description={<Skeleton width='100%' />}
                      />
                    ))}
                  </Stack>
                </>
              ) : (
                <>
                  <Stack spacing={3}>
                    <LabelDetail label='Offer' description={data?.offer?.title} />
                    <LabelDetail label='Description' description={data?.offer?.description} />
                    <LabelDetail
                      alignItems='center'
                      label='Offer time'
                      description={
                        offerStartTime && (
                          <DateTime
                            reservedDate={reservedDate}
                            value={offerStartTime ?? dayjs()}
                            endValue={offerEndTime}
                            showEditBtn={isEditable}
                            onEditHandle={onEditReservationDateAndTimeDialogHandle}
                          />
                        )
                      }
                    />
                    <LabelDetail
                      alignItems='center'
                      label='Requirements'
                      description={<Requirements values={requirements} />}
                    />
                    <LabelDetail
                      label='Reservation Status'
                      description={
                        <StatusLabel status={data?.status} type='reservation' emptyBackground />
                      }
                    />
                    <Box position={'relative'}>
                      <LabelDetail
                        label='Posting Status'
                        description={
                          data?.postLink &&
                          (data?.validation === ReservationPost.validated ||
                            data?.validation === ReservationPost.validationUpdated) ? (
                            <Button
                              size='medium'
                              variant='outlined'
                              disabled={isLoading}
                              sx={{ borderRadius: '7px', px: '18px' }}
                              onClick={postLinkHandle}
                            >
                              Go to post
                            </Button>
                          ) : (
                            '-'
                          )
                        }
                      />
                    </Box>
                  </Stack>
                  <Stack
                    spacing={3}
                    mt={6}
                    position={'relative'}
                    sx={{ borderTop: '1.5px solid #F7F7F7' }}
                    pt={3}
                  >
                    <LabelDetail label='Check in time' description={checkedInValue} />
                    <LabelDetail
                      label='Cost'
                      description={<OfferPriceStatus reservation={data} />}
                    />
                    {data?.offer?.private && (
                      <>
                        {data?.paymentStatus && (
                          <LabelDetail
                            label='Payment status'
                            description={
                              data?.paymentStatus !== ReservationPaymentStatus.ready &&
                              data?.paymentStatus !== ReservationPaymentStatus.stop ? (
                                <StatusLabel
                                  status={data?.paymentStatus}
                                  type='paymentStatus'
                                  emptyBackground
                                />
                              ) : (
                                <>
                                  {data?.paymentStatus === ReservationPaymentStatus.ready && (
                                    <Typography fontSize={'15px'} fontWeight={400}>
                                      The payment will be released within 48 hours
                                    </Typography>
                                  )}

                                  {data?.paymentStatus === ReservationPaymentStatus.stop && (
                                    <Typography
                                      fontSize={'15px'}
                                      fontWeight={500}
                                      color={'#37BB90'}
                                    >
                                      You stopped the payment. Our team is reviewing your request
                                    </Typography>
                                  )}
                                </>
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </Stack>
                  <Stack spacing={1} mt={10}>
                    <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                      {isChatCanStart && (
                        <Button
                          size='large'
                          variant='contained'
                          sx={{ borderRadius: '5px', px: '36px' }}
                          startIcon={
                            isConversationCreationLoading ? (
                              <CircularProgress size='1rem' sx={{ color: 'grey' }} />
                            ) : (
                              <Icons.ChatIcon style={{ height: '20px', width: '20px' }} />
                            )
                          }
                          disabled={isConversationCreationLoading}
                          onClick={createConversationWithUser}
                        >
                          Chat
                        </Button>
                      )}
                      {data?.status === ReservationStatus.reserved && (
                        <StopPaymentButton
                          variant='outlined'
                          size='large'
                          sx={{ borderRadius: '5px' }}
                          onClick={onCancelReservationDialogHandle}
                        >
                          Cancel reservation
                        </StopPaymentButton>
                      )}

                      {data?.privateOffer && (
                        <>
                          {canReleasePayment && (
                            <ReleasePaymentButton
                              variant='outlined'
                              size='large'
                              onClick={onPaymentReleaseDialogHandle}
                              {...(paymentReleaseMutation.isPending
                                ? {
                                    startIcon: (
                                      <CircularProgress size='1rem' sx={{ color: 'grey' }} />
                                    ),
                                    disabled: true,
                                  }
                                : {})}
                            >
                              Release payment
                            </ReleasePaymentButton>
                          )}

                          {canStopPayment && (
                            <StopPaymentButton
                              variant='outlined'
                              size='large'
                              onClick={onPaymentStopDialogHandle}
                              {...(paymentStopMutation.isPending
                                ? {
                                    startIcon: (
                                      <CircularProgress size='1rem' sx={{ color: 'grey' }} />
                                    ),
                                    disabled: true,
                                  }
                                : {})}
                            >
                              Stop payment
                            </StopPaymentButton>
                          )}
                        </>
                      )}

                      {(data?.status === ReservationStatus.noshow ||
                        data?.status === ReservationStatus.noshowprocessed ||
                        (offerCategory === OfferCategory.beauty &&
                          data?.status === ReservationStatus.reserved)) && (
                        <Button
                          size='large'
                          variant='outlined'
                          sx={{ borderRadius: '5px', px: '28px' }}
                          onClick={onCheckedInDialogHandle}
                        >
                          Check in
                        </Button>
                      )}

                      <Button
                        size='large'
                        variant='outlined'
                        disabled={isLoading}
                        sx={{ borderRadius: '5px', px: '28px' }}
                        onClick={() => {
                          if (!(venueId && data?.id && data?.user)) {
                            return;
                          }

                          complainModalRef.current = showModal(ReportReservationsDialog, {
                            title: 'Report a problem',
                            modalRef: complainModalRef,
                            payload: {
                              venueId: venueId,
                              reservationId: data.id,
                              userId: data.user.id,
                              name: `${data.user.firstname} ${data.user.lastname}`.trim(),
                              plan: venue?.premium ? 'PREMIUM' : 'BASIC',
                              venueName: venue?.name || '',
                              email: venue?.email || '',
                              venueLink: venueId,
                              type: 'Reservation report',
                            },
                            refreshHandle,
                            offerId: data?.offer?.id || '',
                          });
                        }}
                      >
                        Support
                      </Button>
                    </Box>
                  </Stack>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} md={5} xs={5}>
          <Box
            sx={{
              borderRadius: '8px',
              border: '1px solid #F1F1F1',
              backgroundColor: '#fff',
              padding: '22px 32px',
              height: '100%',
              position: 'relative',
            }}
          >
            <Box sx={{ borderBottom: '1px solid #F7F7F7', paddingBottom: '15px' }}>
              <Typography fontSize={'20px'} fontWeight={500} textAlign={'center'}>
                Guest detail
              </Typography>
            </Box>

            <Box
              my={5}
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <SkeletonWithChild variant='circular' loading={isLoading}>
                <LazyAvatar
                  variant='circular'
                  alt={String(data?.user?.name)}
                  placeholderSrc={String(data?.userPicture?.thumbnail)}
                  src={String(data?.userPicture?.medium)}
                  sx={{
                    width: '288px',
                    height: '288px',
                  }}
                />
              </SkeletonWithChild>

              <SkeletonWithChild loading={isLoading}>
                <Typography
                  fontWeight={500}
                  fontSize={'20px'}
                  mt={3}
                  mb={1}
                >{`${data?.user?.firstname} ${data?.user?.lastname}`}</Typography>
              </SkeletonWithChild>
              <SkeletonWithChild loading={isLoading}>
                <Typography component={Stack} direction='row' spacing={1}>
                  <InstagramTag username={data?.user?.instagramHandle} sx={{ ml: 1 }} />

                  <Box>·</Box>
                  <Box>
                    <Box component='span' fontWeight={400} fontSize={'15px'}>
                      {formatNumber(data?.user?.instagramFollowers ?? 0)}
                    </Box>
                    &nbsp;followers
                  </Box>
                </Typography>
              </SkeletonWithChild>
            </Box>

            <Box textAlign={'center'} sx={{ position: 'absolute', bottom: 50, left: 0, right: 0 }}>
              <SaveUserButton
                saved={Boolean(userData?.savedByVenue)}
                userId={Number(data?.userID)}
                sx={{ fontSize: '15px' }}
              >
                Save profile
              </SaveUserButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReservationDetail;

const CollabBadge = styled.span`
  display: inline-block;
  background-color: #f6f6f6;
  color: #7b7bff;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;

  gap: 12px;
`;
