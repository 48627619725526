import React, { ChangeEventHandler, MouseEventHandler, useCallback } from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  ButtonProps,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';

import LazyAvatar from '../../../components/Avatar/LazyAvatar/LazyAvatar';
import { useConfirm } from '../../../components/Dialogs/Confirm/context/ConfirmDialog.context';
import { Item } from '../../../components/Photoswipe/Photoswipe';
import UploadButton from './UploadButton';
import usePortfolioQuery from './usePortfolioQuery';

type DeleteButtonProps = {
  isLoading?: boolean;
} & ButtonProps;

const DeleteButton: React.FC<DeleteButtonProps> = ({ isLoading, ...restProps }) => (
  <IconButton
    aria-label='delete'
    sx={(theme) => ({
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      padding: theme.spacing(0.5),
      '.MuiCircularProgress-root': {
        color: 'white',
      },
      '.MuiSvgIcon-root': {
        color: 'white',
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
      ':hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
    })}
    disabled={isLoading}
    {...restProps}
  >
    {isLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
  </IconButton>
);

const gridItemProps = { xs: 5, sm: 4, md: 4, lg: 3, xl: 2.4 };

const PortfolioPanel: React.FC = () => {
  const { data, isLoading, deleteMutation, uploadMutation } = usePortfolioQuery();
  const { confirm } = useConfirm();

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (e) => {
      e.stopPropagation();
      const id = e.currentTarget.value;

      const isConfirm = await confirm({
        title: 'Delete photo',
        confirmationText: 'Delete photo',
        description: <>Are you sure you want to delete the photo?</>,
      });

      if (isConfirm) {
        deleteMutation.mutate(id);
      }
    },
    [confirm, deleteMutation],
  );

  const handleFileUpload = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const file = event.target.files ? event.target.files[0] : undefined;

      if (file) {
        uploadMutation.mutate(file);
      }
    },
    [uploadMutation],
  );

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography fontWeight='bold'>
          Your photos
          <Box component='span' ml={2}>
            {data.length ?? 0}/10
          </Box>
        </Typography>

        <UploadButton
          id='btn-add'
          variant='contained'
          sx={{ px: 3 }}
          disabled={isLoading || data.length >= 10}
          loading={uploadMutation.isPending}
          onFileUpload={handleFileUpload}
        >
          Add photos
        </UploadButton>
      </Box>

      <Grid container spacing={2}>
        {isLoading
          ? [...Array(3)].map((_, i) => (
              <Grid key={i} item {...gridItemProps}>
                <AspectRatio>
                  <Skeleton variant='rectangular' />
                </AspectRatio>
              </Grid>
            ))
          : data.map((x, i) => (
              <Item key={x.id ?? i} itemProps={{ original: String(x.asset?.large) }}>
                <Grid item {...gridItemProps}>
                  <AspectRatio>
                    <LazyAvatar
                      variant='square'
                      alt={String(x.id)}
                      placeholderSrc={String(x.asset?.thumbnail)}
                      src={String(x.asset?.medium)}
                      sx={{ ...(!x.id ? { opacity: '50%' } : undefined) }}
                    />
                    {x.id ? (
                      <Box
                        sx={(theme) => ({
                          position: 'absolute',
                          bottom: theme.spacing(1.5),
                          right: theme.spacing(1.5),
                        })}
                      >
                        <DeleteButton
                          value={String(x.id)}
                          onClick={handleClick}
                          isLoading={deleteMutation.variables === x.id && deleteMutation.isPending}
                        />
                      </Box>
                    ) : null}
                  </AspectRatio>
                </Grid>
              </Item>
            ))}
      </Grid>
    </Box>
  );
};

export default PortfolioPanel;
