import React from 'react';

import { Grid, GridProps } from '@mui/material';

import { VennueAccessRequestItem } from '../../../graphql/API';
import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import ProfileCardSkeleton from '../../../pages/Collabs/components/ProfileCardSkeleton';
import { useAction } from '../../Tables/AccessRequest/useAction';
import { AccessRequestUserProfileCard } from '../components/AccessRequestUserProfileCard';
import { NoPendingAccessRequest } from '../components/NoPendingAccessRequest';

type Props = { data: VennueAccessRequestItem[]; isLoading: boolean };

const commonGrid = (key: string): GridProps => ({
  item: true,
  key,
  xs: 2.4,
  md: 3,
  lg: 3,
  xl: 2.4,
});

export const AccessRequestGrid: React.FC<Props> = ({ data, isLoading }) => {
  const subscriptionAccess = useSubscriptionAccess();
  const actionProps = useAction();

  return (
    <Grid container spacing={3} rowSpacing={10}>
      {!isLoading
        ? data.map((x) => (
            <Grid {...commonGrid(`${x.offerID}`)}>
              <AccessRequestUserProfileCard
                accessRequest={x}
                fullProfile={subscriptionAccess?.includes('COMPLETE_USER_PROFILES') || false}
                {...actionProps}
              />
            </Grid>
          ))
        : [...Array(6)].map((x, i) => (
            <Grid {...commonGrid(`grid-skeleton-${i}`)}>
              <ProfileCardSkeleton />
            </Grid>
          ))}
      <Grid item xs={12}>
        {!isLoading && data.length === 0 && <NoPendingAccessRequest />}
      </Grid>
    </Grid>
  );
};
