import React from 'react';

import BeenhereIcon from '@mui/icons-material/Beenhere';
import { SxProps } from '@mui/material';

const TrustedIcon: React.FC<{ sx?: SxProps }> = ({ sx }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id='linearColors' x1={1} y1={0} x2={1} y2={1}>
        <stop offset='0%' stopColor='#ffd47b' />
        <stop offset='45.67%' stopColor='#ff778e' />
        <stop offset='100%' stopColor='#b97aff' />
      </linearGradient>
    </svg>
    <BeenhereIcon
      sx={{
        fill: 'url(#linearColors)',
        fontSize: 16,
        ...sx,
      }}
    />
  </>
);

export default TrustedIcon;
