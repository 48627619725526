import * as React from 'react';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

import { useAuthenticator } from '@aws-amplify/ui-react';

import { Venue } from '../../graphql/API';
import { useAppReset } from '../../redux/hooks';
import { useConfirm } from '../Dialogs/Confirm/context/ConfirmDialog.context';

type LoadingProps = {
  venue?: Venue;
};

export const PageLoading: React.FC = () => (
  <Box display='flex' justifyContent='center' alignItems='center' flex={1}>
    <CircularProgress />
  </Box>
);

const LoadingAccount: React.FC<LoadingProps> = ({ venue }) => {
  const { confirm } = useConfirm();
  const { signOut } = useAuthenticator();
  const reset = useAppReset();

  React.useEffect(() => {
    if (venue) {
      confirm({
        title: 'Account is in Pending',
        confirmButtonOnly: true,
        description: (
          <>
            You account is in pending state at the moment. We will let you know when you are
            approved
          </>
        ),
      }).then(() => {
        signOut();
        reset();
      });
    }
  }, [venue, confirm, signOut, reset]);

  return <PageLoading />;
};

export default LoadingAccount;
