import * as React from 'react';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Box, Stack, Typography } from '@mui/material';

import Icons from '../../../themes/icons';
import SvgIcon from '../../Icons';

const SemiBold: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box component='span' fontWeight='medium'>
    {children}
  </Box>
);

const TitleWithDescription: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => (
  <Box>
    <Typography sx={{ fontSize: '18px', fontWeight: '500' }} marginBottom={1}>
      {title}
    </Typography>
    <Typography sx={{ fontSize: '15px', fontWeight: 'normal' }}>{children}</Typography>
  </Box>
);

const DescriptionBox: React.FC<React.PropsWithChildren<{ icon: React.ReactNode }>> = ({
  children,
  icon,
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FAFAFA',
      borderRadius: 1,
      px: 3,
      py: 2,
    }}
  >
    {icon}
    <Typography>{children}</Typography>
  </Box>
);

const AccessRequestDesc: React.FC = () => (
  <Stack spacing={4} padding={1}>
    <TitleWithDescription title='What is access request?'>
      <Typography fontSize={'15px'} mt={1} mb={2}>
        When creating a recurring offer, you can choose how users access your offer by selecting one
        of these options:
      </Typography>
      <Box display={'flex'} alignItems={'center'} gap={'8px'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
          sx={{ padding: '8px 24px', bgcolor: '#FAFAFA', borderRadius: '24px' }}
        >
          <SvgIcon name='Tick' sx={{ fontSize: '16px' }} />
          <Typography component={'span'} fontSize={'15px'}>
            Accept All
          </Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
          sx={{ padding: '8px 24px', bgcolor: '#FAFAFA', borderRadius: '24px' }}
        >
          <SvgIcon name='Key' sx={{ fontSize: '16px' }} />
          <Typography component={'span'} fontSize={'15px'}>
            Permission Required
          </Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
          sx={{ padding: '8px 24px', bgcolor: '#FAFAFA', borderRadius: '24px' }}
        >
          <SvgIcon name='Cross' sx={{ fontSize: '14px' }} />
          <Typography component={'span'} fontSize={'15px'}>
            Reject all
          </Typography>
        </Box>
      </Box>

      <Box sx={{ border: '1px solid #FAFAFA', mt: 6 }} />
    </TitleWithDescription>

    <TitleWithDescription title='Permission required'>
      If you choose "Permission Required,"{' '}
      <Typography sx={{ fontSize: '15px', fontWeight: '600', display: 'inline' }}>
        users must request access to reserve your offer.
      </Typography>{' '}
      You can then review and either approve or reject each request.
    </TitleWithDescription>

    <Stack spacing={2}>
      <Box
        sx={{
          borderRadius: '8px',
          padding: '12px 24px',
          bgcolor: '#fafafa',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <SvgIcon name='Tick' sx={{ fontSize: '16px' }} />
        <Box display={'flex'} flexDirection={'column'}>
          <Typography fontSize={'15px'} fontWeight={500}>
            Accept
          </Typography>
          <Typography fontSize={'15px'}>
            The user is granted permission and can reserve the offer
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: '8px',
          padding: '12px 24px',
          bgcolor: '#fafafa',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <SvgIcon name='Cross' sx={{ fontSize: '14px' }} />
        <Box display={'flex'} flexDirection={'column'}>
          <Typography fontSize={'15px'} fontWeight={500}>
            Reject
          </Typography>
          <Typography fontSize={'15px'}>The user will no longer see this offer</Typography>
        </Box>
      </Box>
    </Stack>
  </Stack>
);

export default AccessRequestDesc;
