import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Offer, OfferStatus } from '../../../graphql/API';

export interface OfferState {
  value?: Offer[];
}

const initialState: OfferState = {
  value: undefined,
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (state, action: PayloadAction<Offer[]>) => {
      state.value = action.payload.filter((x) => x.status === OfferStatus.active);
    },
  },
});

export const { reset, update } = offerSlice.actions;

export default offerSlice.reducer;
