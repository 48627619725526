import React from 'react';

import { keepPreviousData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import { venueListConversationsApi } from '../../../api/chat/venueListConversationsApi';
import { ConversationList, VenueListConversationsFilter } from '../../../graphql/API';

const PAGE_LIMIT = 20;

export const useVenueListConversationsQuery = (
  filter: Partial<VenueListConversationsFilter>,
  limit = PAGE_LIMIT,
) => {
  const queryClient = useQueryClient();
  const qkey = 'venue-list-conversations-api';
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery<ConversationList>({
      initialPageParam: null,
      queryKey: [qkey, filter],
      queryFn: async ({ queryKey, pageParam }) => {
        const [, filter] = queryKey as [string, VenueListConversationsFilter];

        return venueListConversationsApi({
          ...filter,
          sortbyRecent: false,
          limit,
          startKey: pageParam as string,
        });
      },
      getNextPageParam: (lastGroup) => lastGroup.nextPageKey || null,
      placeholderData: keepPreviousData,
      enabled: !!filter.venueID,
      refetchOnWindowFocus: false,
    });

  const revalidateConversations = () => {
    queryClient.invalidateQueries({ queryKey: [qkey] });
  };

  const flatData = React.useMemo(() => data?.pages.flatMap((x) => x.items) ?? [], [data]);

  return {
    data: flatData,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    revalidateConversations,
  };
};
