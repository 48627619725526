import React from 'react';

import { Box } from '@mui/material';

import icons from '../../../../themes/icons';
import { IGCard } from './IGCard';
import { FigureDescription, FigureHeading } from './StyledComponents';

type IconType = 'heart' | 'comment' | 'video';

type Props = {
  figure: React.ReactNode;
  desc: string;
  type?: IconType;
  descMaxWidth?: string;
};

const CARD_ICONS: Record<IconType, React.ReactNode> = {
  heart: <icons.HeartIcon />,
  comment: <icons.MessageIcon />,
  video: <icons.VideoIcon />,
};

export const FigureCard: React.FC<Props> = ({ figure, desc, type = 'heart', descMaxWidth }) => {
  return (
    <IGCard>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        gap={3}
        minHeight={'194px'}
      >
        {CARD_ICONS[type]}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <FigureHeading>{figure}</FigureHeading>
          <FigureDescription textAlign='center' maxWidth={descMaxWidth}>
            {desc}
          </FigureDescription>
        </Box>
      </Box>
    </IGCard>
  );
};
