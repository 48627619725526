import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import { Box, Typography, styled } from '@mui/material';

import LazyAvatar, { LazyAvatarProps } from '../../../components/Avatar/LazyAvatar/LazyAvatar';
import PlayIcon from '../../../components/PlayIcon/PlayIcon';

export const AvatarContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: 10,
  left: 10,
});

const AvatarBody: React.FC<LazyAvatarProps> = (props) => (
  <Box
    sx={{
      borderRadius: '50%',
      border: '1px solid #FFFFFF',
      mr: 1,
    }}
  >
    <LazyAvatar {...props} sx={{ width: '2rem', height: '2rem' }} />
  </Box>
);

type ContentProps = {
  onClick: React.MouseEventHandler;
  src: string;
  video?: string;
  userAvatar: string;
  name: string;
};

const Content: React.FC<ContentProps> = ({ onClick, src, userAvatar, name, video }) => {
  return (
    <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={onClick}>
      <AspectRatio ratio='1'>
        <Box component='img' src={src} />

        {video && <PlayIcon />}
      </AspectRatio>
      <AvatarContainer>
        <AvatarBody variant='circular' src={userAvatar} />
        <Typography color='white' fontWeight='medium' variant='body2'>
          {name}
        </Typography>
      </AvatarContainer>
    </Box>
  );
};

export default Content;
