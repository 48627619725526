import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box, SvgIconProps, Typography, styled } from '@mui/material';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { Plan } from '../../../pages/SubscriptionPlans/types';
import SvgIcon from '../../Icons';

export const PlusIcon: React.FC<SvgIconProps> = ({ sx }) => (
  <SvgIcon name='Plus' sx={{ fontSize: '1rem', ml: 1.5, fill: 'url(#linear-gradient)', ...sx }} />
);

export const StarterIcon: React.FC<SvgIconProps> = ({ sx }) => (
  <SvgIcon name='Plus' sx={{ fontSize: '1rem', ml: 1.5, fill: '#429C12', ...sx }} />
);

const mapLabelColor: Partial<
  Record<Plan, { label: string; color: string; icon: React.ReactNode }>
> = {
  starter: { label: 'Starter', color: '#429C12', icon: <StarterIcon sx={{ ml: 0, mr: 1 }} /> },
  plus: { label: 'PLUS', color: '#D86EEF', icon: <PlusIcon sx={{ ml: 0, mr: 1 }} /> },
};

export const TooltipContent: React.FC<{ plan: Plan }> = ({ plan }) => {
  const labelColor = React.useMemo(() => mapLabelColor[plan], [plan]);

  return (
    <Typography component='div' sx={{ display: 'flex' }}>
      <Box sx={{ backgroundColor: labelColor?.color, borderRadius: 1 }}>&nbsp;&nbsp;</Box>
      <Box sx={{ ml: 1, px: 0.5, py: 1 }}>
        To unlock this feature, upgrade your plan to
        <Box
          component={Link}
          to='/plans'
          sx={{ display: 'inline-flex', ml: 1, fontWeight: '500', color: labelColor?.color }}
        >
          {labelColor?.icon}
          {labelColor?.label}
        </Box>
      </Box>
    </Typography>
  );
};

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    padding: 0,
    boxShadow: '0px 0px 30px #0000000D',
  },
}));

export default Tooltip;
