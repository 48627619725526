import React from 'react';

import { Box, Button } from '@mui/material';

import styled from '@emotion/styled';

import icons from '../../../../themes/icons';

type Props = {
  onCreateOffer: () => void;
};

export const OfferNotFound: React.FC<Props> = ({ onCreateOffer }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      sx={{
        height: 'calc(600px - 170px)',
      }}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <img src='/png/collab-offer-black.png' alt='create-offer' width={'100%'} />
      </Box>
      <TitleNoFound>You don't have any collab offers or special events</TitleNoFound>
      <DescriptionNotFound>
        To invite a Beautypass member with collabs, you need to have at least one collab offer
      </DescriptionNotFound>
      <Button
        id='btn-create-offer'
        variant='contained'
        sx={{
          width: 320,
          marginTop: '48px',
          padding: '12px 0',
          fontSize: '15px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
        onClick={onCreateOffer}
      >
        {<icons.CollabOfferIcon style={{ width: '26px', height: '26px' }} />} <span>Create a collab offer</span>
      </Button>
    </Box>
  );
};

const TitleNoFound = styled.p`
  margin-top: 84px;
  font-size: 18px;
  color: #111111;
  font-weight: 600;
`;

const DescriptionNotFound = styled.p`
  margin-top: 5px;
  font-size: 15px;
  color: #111111;
  width: 50%;
  text-align: center;
`;
