import * as React from 'react';

import BaseDialog, { BaseDialogProps } from '../Base';
import CreateSuccess from './components/CreateSuccess';

const OfferSuccessDialog: React.FC<BaseDialogProps> = ({ ...restProps }) => {
  return (
    <BaseDialog width='unset' height='unset' {...restProps}>
      <CreateSuccess />
    </BaseDialog>
  );
};

export default OfferSuccessDialog;
