import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import styled from '@emotion/styled';

import Button from '../Button/Button';
import { PlusIcon } from '../Dialogs/CollabsFilters/ToolTip';

type Props = {
  totalReservations: number;
  outOfReservations: number;
};

export const BrandPromotionLimit: React.FC<Props> = ({ totalReservations, outOfReservations }) => {
  return (
    <Container>
      <Box display={'flex'} alignItems={'center'} gap={4.5}>
        <Limit>
          {totalReservations}/{outOfReservations}
        </Limit>
        <Box>
          <Typography sx={{ fontSize: '18px', color: '#232832', fontWeight: 600 }}>
            You’ve hit your monthly promotion limit
          </Typography>
          <Typography sx={{ color: '#707070', fontSize: '15px' }}>
            Update your plan to unlock more promotions now
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        variant='contained'
        color='primary'
        sx={{ fontSize: '18px', fontWeight: 500, px: 3, py: 1.3 }}
        startIcon={<PlusIcon sx={{ height: '16px', width: '16px' }} />}
        to='/plans'
      >
        Upgrade plan
      </Button>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  background-color: #fafafa;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 22px 28px;
`;

const Limit = styled.h1`
  color: #232832;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;
