import React from 'react';

import { Box, Button } from '@mui/material';

import styled from '@emotion/styled';
import dayjs from 'dayjs';

type Props = {
  purchasedDate?: string;
  onDownload?: () => void;
};

export const ActionBuyDownloadBeforeUpdate: React.FC<Props> = ({ purchasedDate, onDownload }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'15px'} paddingBlock={2}>
      <Container>
        <UsageDescription>
          <b>You purchased this content without a commercial license.</b>
          <br /> You can repost it on your Instagram, but it cannot be used for ads or commercial
          purposes
        </UsageDescription>
      </Container>
      <Button variant='contained' fullWidth sx={{ padding: '16px' }} onClick={onDownload}>
        Download
      </Button>
      <PurchaseDate>Saved on {dayjs(purchasedDate).format('LL')}</PurchaseDate>
    </Box>
  );
};

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #ebe8e8;
  background-color: #f7f7f7;

  padding: 22px 30px;

  display: flex;
  flex-direction: column;

  gap: 68px;
`;

const UsageDescription = styled.p`
  margin: 0;
  padding: 0;

  color: #5f5f60;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 6px;
`;

const PurchaseDate = styled.span`
  display: inline-block;
  color: rgba(17, 17, 17, 0.5);
  font-size: 15px;
  font-weight: 400;
`;
