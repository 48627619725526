import * as React from 'react';

import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';

import { useField } from 'formik';

export type OptionItem = {
  label: React.ReactNode;
  value: string | number;
  disabled?: boolean;
};

type SelectOptionProps = {
  options: OptionItem[];
} & SelectProps;

const SelectOption: React.FC<SelectOptionProps> = ({
  name,
  id,
  label,
  options,
  fullWidth,
  ...restProps
}) => {
  const [field, meta] = useField(name || '');

  return (
    <FormControl size='small' fullWidth={fullWidth} error={meta.touched && Boolean(meta.error)}>
      <InputLabel id={`formik-${id}`}>{label}</InputLabel>
      <Select
        labelId={`formik-${id}`}
        label={label}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        error={meta.touched && Boolean(meta.error)}
        {...restProps}
      >
        {options.map((x, i) => (
          <MenuItem key={i} value={x.value} disabled={x.disabled}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
      {(meta.touched || meta.error) && (
        <FormHelperText sx={{ color: '#d32f2f' }}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectOption;
