import * as React from 'react';

import { Grid, Stack } from '@mui/material';

import { useMutation } from '@tanstack/react-query';
import { ShowFnOutput, useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';

import { venueGetSubscriptionSessionApi } from '../../../../api/stripe/venueGetSubscriptionSessionApi';
import SubscriptionDialog, {
  SubscriptionDialogProps,
} from '../../../../components/Dialogs/Subscriptions/SubscriptionDialog';
import { OfferCategory } from '../../../../graphql/API';
import { useAppSelector } from '../../../../redux/hooks';
import {
  PlanPrice,
  getBrandPlanPriceByCycle,
  getPlanPriceByCycle,
  getPlanPriceBySku,
} from '../../common/helper';
import { useSubscription } from '../../context/Subscription.context';
import { Plan, SubscriptionConditionKind, brandPlans, plans } from '../../types';
import PlanContent, { PlanContentProps } from './PlanContent';

const Plans: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { type: venueType } = useAppSelector((state) => state.venue.value) || {};
  const { pricing, subscription } = useAppSelector((state) => state.subscription.value);

  const { state } = useSubscription();
  const { showModal } = useModal();

  const modalRef = React.useRef<ShowFnOutput<SubscriptionDialogProps>>();

  const planPrices = React.useMemo<PlanPrice | null>(() => {
    if (!pricing) {
      return null;
    }

    return venueType === OfferCategory.brand
      ? getBrandPlanPriceByCycle('brand', pricing)
      : getPlanPriceByCycle(state.billingCycle, pricing);
  }, [state.billingCycle, pricing, venueType]);

  const mutation = useMutation({
    mutationFn: venueGetSubscriptionSessionApi,
    onSuccess: (sessionUrl) => {
      window.location.href = sessionUrl;
    },
    onError: () => {
      enqueueSnackbar('Something went wrong. Please try again later', { variant: 'error' });
      window.location.reload();
    },
  });

  const handleSubscriptionClick = React.useCallback<PlanContentProps['onSubscriptionClick']>(
    (v) => {
      const sku =
        venueType === OfferCategory.brand
          ? `${v.value}_brand_${state.billingCycle}_`
          : `${v.value}_venue_${state.billingCycle}_`;
      const price = getPlanPriceBySku(sku, pricing ?? []);

      if (!state.currentSubscription) {
        if (price) {
          mutation.mutate(price.id);
          return true;
        }
        return;
      }

      const kind: SubscriptionConditionKind =
        v.value === 'basic' && v.logicCondition.kind === 'plan-downgrade'
          ? 'plan-cancel'
          : v.logicCondition.kind;

      modalRef.current = showModal(SubscriptionDialog, {
        price,
        plan: v.value,
        kind,
        subscription,
        modalRef,
      });
    },
    [
      showModal,
      subscription,
      state.billingCycle,
      state.currentSubscription,
      pricing,
      mutation,
      venueType,
    ],
  );

  const isHighlight = React.useCallback(
    (p: Plan) => {
      if (state.currentSubscription) {
        return state.plan === p;
      }
      return p === 'starter';
    },
    [state.currentSubscription, state.plan],
  );

  const selectedPlans = venueType === OfferCategory.brand ? brandPlans : plans;

  return (
    <Grid spacing={3} container>
      {selectedPlans.map((x, i) => (
        <Grid item xs={4}>
          <PlanContent
            key={i}
            subscriptionState={state}
            value={x}
            highlight={isHighlight(x)}
            disabled={Boolean(state.currentSubscription)}
            disableButton={mutation.isPending}
            price={planPrices?.[x]}
            onSubscriptionClick={handleSubscriptionClick}
            isBrand={venueType === 'brand'}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Plans;
