import React from 'react';

import { Box, Stack } from '@mui/material';

export const IGTitle = () => {
  return (
    <Stack direction='row' alignItems='center' justifyContent={'center'} spacing={1}>
      <Box component='span' fontWeight={600} fontSize={18}>
        IG Statistics
      </Box>
    </Stack>
  );
};
