import * as React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { VenueReservationContentItemV2 } from '../../../../graphql/API';
import SvgIcon from '../../../Icons';

type SaveConfirmationProps = { item?: VenueReservationContentItemV2; venueCredit: number };
type PriceLineProps = { icon?: React.ReactNode; label: string; credit: number };

const PriceLine: React.FC<PriceLineProps> = ({ icon, label, credit }) => (
  <Typography
    component='div'
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    <Box sx={{ display: 'flex', ml: icon ? undefined : 4 }}>
      {icon && icon}
      {label}
    </Box>

    <Box>
      <Box component='span' sx={{ mr: 1, color: credit < 0 ? '#D04040' : undefined }}>
        {credit}
      </Box>
      {Math.abs(credit) > 1 ? 'Credits' : 'Credit'}
    </Box>
  </Typography>
);

const SaveConfirmation: React.FC<SaveConfirmationProps> = ({ item, venueCredit }) => (
  <Box>
    <Typography variant='h6'>Save content</Typography>
    <Typography variant='body2' sx={{ color: '#5F5F60' }}>
      Please confirm your selection.
      <br />
      You will be charged one credit after the purchase
    </Typography>

    <Stack spacing={0.5} sx={{ mt: 3, mb: 10 }}>
      <PriceLine
        icon={<SvgIcon name='Wallet' sx={{ mr: 1 }} />}
        label='Your Balance'
        credit={venueCredit}
      />
      <PriceLine label='Cost' credit={-(item?.downloadCredits ?? 0)} />
      <Divider sx={{ ml: 3 }} />
      <PriceLine label='New Balance' credit={venueCredit - (item?.downloadCredits ?? 0)} />
    </Stack>
  </Box>
);

export default SaveConfirmation;
