import React from 'react';

import dayjs from 'dayjs';

import { OfferCategory } from '../../../graphql/API';

export const isBeforeNow = (dateTime?: dayjs.Dayjs) => dateTime?.utc().isBefore(dayjs().utc());

export const getOfferEarlierCheckInInMinutes = (category?: OfferCategory) =>
  category === OfferCategory.beauty || category === OfferCategory.fitness ? 15 : 0;

export const useIsAfterDateTime = (
  dateTime?: dayjs.Dayjs,
  forceIsAfterDateTime?: boolean,
): boolean => {
  const [isAfterDateTime, setIsAfterDateTime] = React.useState(
    forceIsAfterDateTime || (dateTime && isBeforeNow(dateTime)),
  );

  React.useEffect(() => {
    setIsAfterDateTime(forceIsAfterDateTime || (dateTime && isBeforeNow(dateTime)));
  }, [dateTime, forceIsAfterDateTime]);

  return Boolean(isAfterDateTime);
};

const getDstOffset = (dateTime: dayjs.Dayjs, timeZoneId: string) =>
  Math.abs(
    dateTime.utcOffset() -
      Math.min(
        dayjs.tz('2022-01-01', timeZoneId).utcOffset(),
        dayjs.tz('2022-06-01', timeZoneId).utcOffset(),
      ),
  );

const convertUTCToLocal = (dateTime: string, timeZoneId: string, useStandardTime: boolean) => {
  const localDateTime = dayjs.utc(dateTime).tz(timeZoneId);
  const dstOffset = getDstOffset(localDateTime, timeZoneId);

  return useStandardTime && dstOffset ? localDateTime.subtract(dstOffset, 'minute') : localDateTime;
};

export const getDateTimeInTimezone = (
  timestamp?: string,
  timeZoneId?: string,
  useStandardTime: boolean = true,
): dayjs.Dayjs | undefined =>
  timestamp && timeZoneId && dayjs(timestamp).isValid()
    ? convertUTCToLocal(timestamp, timeZoneId, useStandardTime)
    : undefined;

export const useDateTimeInTimezone = (
  timestamp?: string,
  timeZoneId?: string,
  useStandardTime: boolean = true,
): dayjs.Dayjs | undefined =>
  React.useMemo(
    () => getDateTimeInTimezone(timestamp, timeZoneId, useStandardTime),
    [timestamp, timeZoneId, useStandardTime],
  );

export const convertLocalToUTC = (localDateTime: dayjs.Dayjs, timeZoneId: string) => {
  const dstOffset = getDstOffset(localDateTime, timeZoneId);

  return dstOffset ? localDateTime.add(dstOffset, 'minute').utc() : localDateTime.utc();
};

export const getUTCDateTimeFromDateAndTimeInTimezone = (
  date?: string,
  time?: string,
  timeZoneId?: string,
): dayjs.Dayjs | undefined =>
  date && time && timeZoneId && dayjs(date + ' ' + time.slice(0, 5)).isValid()
    ? convertLocalToUTC(dayjs.tz(date + ' ' + time.slice(0, 5), timeZoneId), timeZoneId)
    : undefined;
