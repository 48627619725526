import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const ProfileCardSkeleton: React.FC = () => {
  return (
    <Box display='flex' flexDirection='column'>
      <AspectRatio>
        <Skeleton variant='rounded' />
      </AspectRatio>

      <Skeleton width='100%' />

      <Skeleton width='40%' />
    </Box>
  );
};

export default ProfileCardSkeleton;
