import * as React from 'react';

import { Analytics } from 'aws-amplify';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

import { AnalyticsContext } from '.';
import { useAppSelector } from '../../redux/hooks';
import { AnalyticsContextProps } from './types';

const ANALYTIC_KEY = 'app-analytics-attributes';

Analytics.autoTrack('event', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, events you want to track, by default is 'click'
  events: ['click'],
  // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
  // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
  // always put 'data' as the first prefix
  selectorPrefix: 'data-amplify-analytics-',
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: 'AWSPinpoint',

  // when using function
  attributes: () => {
    const item = localStorage.getItem(ANALYTIC_KEY);
    const attributes = item ? JSON.parse(item) : undefined;

    return attributes;
  },
});

const AnalyticsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const venueState = useAppSelector((state) => state.venue.value);

  const attributes = React.useMemo<Record<string, string> | undefined>(
    () =>
      venueState
        ? {
            entity: 'venue',
            venueId: venueState?.id,
            appVersion: 'local',
            locale: dayjs().locale(),
            platform: 'Web',
            model: 'model',
            modelVersion: 'modelVersion',
            platformVersion: 'platformVersion',
            timezone: dayjs.tz.guess(),
            latitude: String(venueState.location?.lat),
            longitude: String(venueState.location?.lon),
            marketID: venueState.marketID,
          }
        : undefined,
    [venueState],
  );

  React.useEffect(() => {
    if (attributes) {
      localStorage.setItem(ANALYTIC_KEY, JSON.stringify(attributes));
    }
  }, [attributes]);

  const recordDebounce = React.useMemo(() => debounce(Analytics.record, 500), []);

  const record = React.useCallback<AnalyticsContextProps['record']>(
    (name, additionalAttributes) => {
      if (attributes) {
        const event = {
          name,
          attributes: {
            ...attributes,
            ...additionalAttributes,
          },
        };

        recordDebounce(event);
      }
    },
    [attributes, recordDebounce],
  );

  return <AnalyticsContext.Provider value={{ record }}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
