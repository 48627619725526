import React, { PropsWithChildren, useState } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

type BackdropContextProps = {
  toggleBackdrop: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BackdropContext = React.createContext<BackdropContextProps>({
  toggleBackdrop: () => {},
});

export const BackdropLoading: React.FC<{ open: boolean }> = ({ open }) => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <CircularProgress color='inherit' />
  </Backdrop>
);

const BackdropProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [open, toggleBackdrop] = useState(false);

  return (
    <BackdropContext.Provider value={{ toggleBackdrop }}>
      {children}
      <BackdropLoading open={open} />
    </BackdropContext.Provider>
  );
};

export default BackdropProvider;
