import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import Header from '../../components/Authenticator/Header';
import LoadingButton from '../../components/Button/LoadingButton';
import FormikTextField from '../../components/Formiks/TextField/TextField';
import { useFrom } from '../../hooks/useFrom';
import { Footer } from './Footer';
import { loginSchema } from './schema';
import './styles.css';

type FormFields = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const from = useFrom();
  const { authStatus } = useAuthenticator();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authStatus === 'authenticated') navigate(from, { replace: true });
  }, [authStatus, from, navigate]);

  const onSubmit = async ({ email, password }: FormFields) => {
    try {
      setIsLoading(true);
      await Auth.signIn(email, password);
      navigate(from, { replace: true });
      setIsLoading(false);
    } catch (error) {
      const _err = error as Error;
      enqueueSnackbar({ message: _err.message, variant: 'error' });
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className='auth-form'>
      <Header />
      <div className='auth-form-container'>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={loginSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Box display='flex' flexDirection='column' gap='0.5rem'>
                <label htmlFor='email' className='auth-input-label'>
                  Email
                </label>
                <FormikTextField
                  className='auth-input-field'
                  id='email'
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box display='flex' flexDirection='column' gap='0.5rem' marginTop='1rem'>
                <label htmlFor='password' className='auth-input-label'>
                  Password
                </label>
                <FormikTextField
                  className='auth-input-field auth-input-field-password'
                  id='password'
                  name='password'
                  placeholder='Password'
                  type={'password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box display='flex' justifyContent='start' gap='0.5rem' marginTop='1rem'>
                <Link to='/forgot-password' className='forgot-password-link'>
                  Forgot password?
                </Link>
              </Box>
              <div className='auth-footer'>
                <LoadingButton
                  type='submit'
                  loading={isLoading}
                  color='primary'
                  fullWidth
                  variant='contained'
                  size='large'
                >
                  {isLoading ? 'Signing in' : 'Sign in'}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
