import { ChatMessageItem, ChatMessagesList, VenueListChatMessagesFilter } from '../../graphql/API';
import { venueListChatMessages } from '../../graphql/queries';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListChatMessagesApi = async (
  filter: VenueListChatMessagesFilter,
): Promise<ChatMessagesList> => {
  const result = await executeGql<ChatMessageItem[]>(venueListChatMessages, { filter });
  return processGqlResult<ChatMessagesList>('venueListChatMessages', result);
};

