import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom';

import { debounce, styled } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { useAuthenticator } from '@aws-amplify/ui-react';
import ModalProvider from 'mui-modal-provider';

import ConfirmDialogProvider from '../../components/Dialogs/Confirm/context/ConfirmDialog.provider';
import { useAnalyticsContext } from '../../contexts/AnalyticsContext';
import usePathAttributes from '../../contexts/AnalyticsContext/usePathAttributes';
import ConversationsProvider from '../../contexts/ConversationsContext/provider';
import { VenueStatus } from '../../graphql/API';
import { fetchAccountBalance } from '../../redux/features/balance/balanceSlice';
import { fetchReservation } from '../../redux/features/reservation/reservationSlice';
import {
  fetchStripePricing,
  fetchSubscription,
  updatePremium,
} from '../../redux/features/subscription/subscriptionSlice';
import { fetchVenue } from '../../redux/features/venue/venueSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Chat } from '../ChatSupport/Chat';
import Header from './Header/Header';
import LoadingAccount, { PageLoading } from './LoadingAccount';
import SideBar from './SideMenu/SideMenu';
import usePath from './usePath';
import NotificationsProvider from '../../contexts/NotificationsContext/provider';

const Parent = styled(Box)({ display: 'flex', flexDirection: 'column', height: '100vh' });
const Content = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto',
  position: 'relative',
});
const Main = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  overflow: 'auto',
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
}));

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const analytic = useAnalyticsContext();
  const venue = useAppSelector((state) => state.venue.value);
  const pathAttributes = usePathAttributes();

  const dispatch = useAppDispatch();
  const { authStatus } = useAuthenticator();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const { mainRef, path } = usePath();

  const debounceFetchVenue = React.useMemo(
    () =>
      debounce(() => {
        dispatch(fetchVenue());
      }, 300),
    [dispatch],
  );

  const debounceFetchOthers = React.useMemo(
    () =>
      debounce((venueID: string, premium: boolean) => {
        dispatch(fetchReservation({ venueID }));
        dispatch(fetchStripePricing());
        dispatch(updatePremium(premium));
        dispatch(fetchSubscription({ venueID: Number(venueID), status: 'active' }));
        dispatch(fetchAccountBalance({ venueID }));
      }, 300),
    [dispatch],
  );

  const isAbleLogin = React.useMemo(
    () => Boolean(venue && [VenueStatus.active, VenueStatus.inactive].includes(venue.status)),
    [venue],
  );

  React.useEffect(() => {
    if (authStatus === 'authenticated') {
      if (!venue) {
        debounceFetchVenue();
      }

      if (venue && isAbleLogin) {
        const venueID = venue.id;
        debounceFetchOthers(venueID, Boolean(venue.premium));
      }
    }

    if (authStatus === 'unauthenticated') {
      navigate('/login', { replace: true });
    }
  }, [venue, isAbleLogin, authStatus, debounceFetchVenue, debounceFetchOthers, navigate]);

  React.useEffect(() => {
    if (pathAttributes) {
      analytic.record('SCREEN_VIEW', pathAttributes);
    }
  }, [analytic, pathAttributes]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ModalProvider>
      <ConfirmDialogProvider>
        <CssBaseline />

        {venue && isAbleLogin ? (
          <Parent>
            <Helmet>
              <title>{path?.label ? `Beautypass - ${path.label}` : 'Beautypass'} </title>
            </Helmet>

            <Box sx={{ display: 'flex' }}>
              <Header />
            </Box>

            <ConversationsProvider>
              <NotificationsProvider>
                <Content>
                  <SideBar drawerOpen={drawerOpen} onDrawerClick={toggleDrawer} />

                  <Main component='main' ref={mainRef}>
                    <React.Suspense fallback={<PageLoading />}>
                      <Outlet />
                    </React.Suspense>
                  </Main>
                  <Chat />
                </Content>
              </NotificationsProvider>
            </ConversationsProvider>
          </Parent>
        ) : (
          <LoadingAccount venue={venue} />
        )}
      </ConfirmDialogProvider>
    </ModalProvider>
  );
};

export default Layout;
