import * as React from 'react';

import Avatar, { AvatarProps } from '@mui/material/Avatar';

import PlayIcon from '../../PlayIcon/PlayIcon';

export type LazyAvatarProps = {
  placeholderSrc?: string;
  isVideo?: boolean;
} & AvatarProps;

const LazyAvatar: React.FC<LazyAvatarProps> = React.forwardRef(
  ({ placeholderSrc, src, alt, isVideo, sx, ...restProps }, ref) => {
    const [isLoading, setIsLoading] = React.useState(true);

    return (
      <>
        {isLoading && (
          <Avatar
            alt={alt}
            src={placeholderSrc ?? src}
            sx={{ transition: 'visibility 0ms ease 400ms', ...sx }}
            {...restProps}
          />
        )}
        <Avatar
          alt={alt}
          src={src}
          sx={{
            transition: 'opacity 400ms ease 0ms',
            ...(isLoading ? { display: 'none' } : undefined),
            ...sx,
          }}
          imgProps={{
            onLoad: () => setIsLoading(false),
          }}
          ref={ref}
          {...restProps}
        />
        {isVideo && <PlayIcon />}
      </>
    );
  },
);

export default LazyAvatar;
