import * as React from 'react';

import useListRatedUserQuery from '../../../hooks/reactQuery/useListRatedUserQuery';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import List from '../../List/Base/List';
import ListSkeleton from '../Base/ListSkeleton';
import { ListRatedUserEmptyPlaceholder } from './ListRatedUserEmptyPlaceholder';
import { mapToListItem } from './mapper';

type Props = {
  listHeight?: number;
};

const ListRatedUser: React.FC<Props> = ({ listHeight }) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useListRatedUserQuery({});

  if (isLoading) {
    return <ListSkeleton />;
  }
  return (
    <InfiniteScroll
      isLoading={isLoading}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    >
      {data.length ? (
        <List items={mapToListItem(data)} />
      ) : (
        <ListRatedUserEmptyPlaceholder listHeight={listHeight} />
      )}
    </InfiniteScroll>
  );
};

export default ListRatedUser;
