import { VenueSaveUserInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueUnsaveUserMutation = /* GraphQL */ `
  mutation VenueUnsaveUser($input: VenueSaveUserInput!) {
    venueUnsaveUser(input: $input)
  }
`;

export const venueUnsaveUserApi = async (input: VenueSaveUserInput): Promise<void> => {
  await executeGql<void>(venueUnsaveUserMutation, { input });
};
