import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { venueListReservationsApi } from '../../../api/reservations/venueListReservationsApi';
import { Reservation, VenueListReservationsFilter } from '../../../graphql/API';

type Filter = {
  venueID: VenueListReservationsFilter['venueID'];
} & Partial<VenueListReservationsFilter>;

export const fetchReservation = createAsyncThunk(
  'reservations/fetchReservation',
  async ({ venueID, limit = 99, ...restFilters }: Filter) => {
    const data = await venueListReservationsApi({
      ...restFilters,
      venueID,
      limit,
    });
    return data;
  },
);

export interface ReservationState {
  value?: Reservation[];
}

const initialState: ReservationState = {
  value: undefined,
};

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReservation.fulfilled, (state, action) => {
      state.value = action.payload;
    });
  },
});

export const { reset } = reservationSlice.actions;

export default reservationSlice.reducer;
