import * as React from 'react';

import { Box, Stack } from '@mui/material';

type TitleWithIconProps = { title: string; icon: React.ReactElement };

const TitleWithIcon: React.FC<TitleWithIconProps> = ({ title, icon }) => (
  <Stack direction='row' alignItems='center' spacing={1}>
    {icon}
    <Box component='span'>{title}</Box>
  </Stack>
);

export default TitleWithIcon;
