import { Button, styled } from '@mui/material';

export const StopPaymentButton = styled(Button)(() => ({
  color: '#DC5564',
  backgroundColor: 'transparent',
  border: '1.5px solid #DC5564',
  borderRadius: '6px',
  ':hover': {
    color: '#fff',
    backgroundColor: '#DC5564',
    border: '1.5px solid #DC5564',
  }
}));

export const ReleasePaymentButton = styled(Button)(() => ({
  color: '#37BB90',
  backgroundColor: 'transparent',
  border: '1.5px solid #37BB90',
  borderRadius: '6px',
  ':hover': {
    color: '#fff',
    backgroundColor: '#37BB90',
    border: '1.5px solid #37BB90',
  }
}));
