import * as React from 'react';

import Box from '@mui/material/Box';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

type ListSkeletonProps = {
  avatarVariant?: SkeletonProps['variant'];
};

const ListSkeleton: React.FC<ListSkeletonProps> = ({ avatarVariant }) => {
  return (
    <Stack spacing={2} sx={{ flex: '1' }}>
      {[...Array(3)].map((_, i) => (
        <Box key={i} display='flex'>
          <Skeleton variant={avatarVariant ?? 'rounded'} width={40} height={40} />

          <Stack>
            <Skeleton variant='text' width={300} sx={{ ml: 3, fontSize: '0.8rem' }} />
            <Skeleton variant='text' width={150} sx={{ ml: 3, fontSize: '0.6rem' }} />
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

export default ListSkeleton;
