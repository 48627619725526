import * as React from 'react';

import { SxProps, Theme, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

import TabPanel from './TabPanel';

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export type Panel = {
  label: string;
  children: React.ReactNode;
};

const Tabs = styled(MuiTabs)({
  '.MuiButtonBase-root': {
    paddingLeft: 0,
    alignItems: 'flex-start',
  },
});

type TabPanelContainerProps = {
  panels: Panel[];
  defaultValue?: number;
  tabSx?: SxProps<Theme>;
  panelSx?: SxProps<Theme>;
  centered?: boolean;
  handleTabChange?: (val: number) => void;
};

const TabPanelContainer: React.FC<TabPanelContainerProps> = ({
  panels,
  tabSx,
  panelSx,
  centered = false,
  defaultValue = 0,
  handleTabChange,
}) => {
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (handleTabChange) handleTabChange(newValue);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          centered={centered}
        >
          {panels.map((x, i) => (
            <Tab
              key={`tab-${i}`}
              label={x.label}
              {...a11yProps(i)}
              sx={{ marginRight: '32px', minWidth: '64px', padding: 0, ...tabSx }}
            />
          ))}
        </Tabs>
      </Box>

      {panels.map((x, i) => (
        <TabPanel key={`tab-panel-${i}`} value={value} index={i} sx={panelSx}>
          {x.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabPanelContainer;
