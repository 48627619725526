import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { RequirementItem } from '../../OfferDetail/components/useRequirment';

type RequirementsProps = {
  values: RequirementItem[];
};

type RequirementItemProps = {
  value: RequirementItem;
};

const Requirement: React.FC<RequirementItemProps> = ({ value }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 0.5,
      }}
    >
      <value.Icon sx={{ fontSize: '1.5rem', mr: 1 }} />
      <Typography fontSize={'15px'}>{value.label}</Typography>
    </Box>
  );
};

const Requirements: React.FC<RequirementsProps> = ({ values }) => {
  return (
    <Stack direction='row' spacing={1}>
      {values.map((x, i) => (
        <Typography key={`lbl-req-${i}`} variant='caption' noWrap>
          <Requirement value={x} />
        </Typography>
      ))}
    </Stack>
  );
};

export default Requirements;
