import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { VenueReservationContentItemV2 } from '../../../../graphql/API';
import LinkProfile from '../../../../pages/Collabs/components/LinkProfile';
import { formatNumber } from '../../../../utils/formatter';
import UserAvatarCategory from '../../../Avatar/UserAvatarCategory/UserAvatarCategory';
import LoadingButton from '../../../Button/LoadingButton';
import SaveUserButton from '../../../Button/SaveUserButton';
import InstagramTag from '../../../InstagramTag/InstagramTag';
import { UseActionResponse } from './useAction';

export type UserHeaderProps = {
  item: VenueReservationContentItemV2 | undefined;
} & Pick<UseActionResponse, 'getLoading' | 'handleSaveUser' | 'handleInviteUser'>;

const UserHeader: React.FC<UserHeaderProps> = ({
  item,
  getLoading,
  handleSaveUser,
  handleInviteUser,
}) => {
  return (
    <Stack spacing={2} direction='row'>
      <UserAvatarCategory src={item?.user?.picture?.thumbnail ?? ''} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkProfile
          url={`/users/${item?.user?.id}`}
          label={`${item?.user?.firstname} ${item?.user?.lastname}`}
          fontSize='22px'
          fontWeight='600'
          hoverTextDecoration='underline'
        />
        <Stack spacing={1} direction='row'>
          <InstagramTag username={item?.user?.instagramHandle} />
          <Typography color='#5F5F60'>
            {`${formatNumber(item?.user?.instagramFollowers ?? 0)} Followers`}
          </Typography>
        </Stack>

        <Stack spacing={2} direction='row' sx={{ mt: 2 }}>
          <SaveUserButton
            size='small'
            userId={Number(item?.user?.id)}
            saved={Boolean(item?.user?.savedByVenue)}
            onSuccess={handleSaveUser}
          >
            Save
          </SaveUserButton>

          <LoadingButton
            variant='outlined'
            size='small'
            sx={{ px: 4 }}
            loading={getLoading('invite', String(item?.id))}
            onClick={handleInviteUser}
          >
            Invite
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default UserHeader;
