import { Flex, Image } from '@aws-amplify/ui-react';
import "./styles.css"

const Header = () => (
  <Flex justifyContent='center' alignItems='center' padding={50}>
    <Image
      alt='logo'
      src='https://scouting-dev.beautypass.app/assets/web-icon.svg'
    />
    <p className='bypass-logo'>Beautypass</p>
  </Flex>
);

export default Header;
