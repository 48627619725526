/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql/lib/types';
import { API } from 'aws-amplify';

import { getProperty } from './get-property';

export const getError = (payload: any) => {
  const errors = getProperty(payload, 'errors');
  const error = new GqlError('Unknown Error', errors);

  if (Array.isArray(errors)) {
    const message =
      errors
        .map((e) => e.message)
        .filter((e) => !!e)
        .join('\n') || 'Unknown Error';
    error.message = message;
  }
  return error;
};

export const getErrorFromResult = (result: any) => {
  if (result && result.data) {
    const values = Object.values(result.data);

    if (values[0] && typeof values[0] === 'string') {
      try {
        const jsonResponse = JSON.parse(values[0]);

        if (
          typeof jsonResponse === 'object' &&
          'success' in jsonResponse &&
          'message' in jsonResponse &&
          !jsonResponse.success
        ) {
          return { errors: [{ message: jsonResponse.message }] };
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  }
};

export const executeGql = async <T = Record<string, unknown>>(
  query: string,
  variables?: Record<string, unknown>,
  authMode: GRAPHQL_AUTH_MODE = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
): Promise<GraphQLResult<T>> => {
  try {
    const result = await API.graphql({
      query,
      variables,
      authMode,
    });

    const errorFromResponse = getErrorFromResult(result);

    if (errorFromResponse) {
      throw errorFromResponse;
    }

    return result as GraphQLResult<T>;
  } catch (ex) {
    console.log({ ex });
    const raisedError = getError(ex);
    console.error(raisedError);
    throw raisedError;
  }
};

export const processGqlResult = <T>(key: string, payload: any) => {
  const json = getProperty(payload, `data.${key}`);
  return json as T;
};

export class GqlError extends Error {
  private _payload: any;

  constructor(message: string, payload: any) {
    super(message);
    Object.setPrototypeOf(this, GqlError.prototype);
    this._payload = payload;
  }

  get payload() {
    return this._payload;
  }
}
