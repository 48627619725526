import { AdminUpdatePaymentInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueUpdateInvitePaymentToRelease = /* GraphQL */ `mutation VenueUpdateInvitePaymentToRelease($input: VenueUpdatePaymentInput!) {
  venueUpdateInvitePaymentToRelease(input: $input) 
}
`;

export const venueUpdateInvitePaymentToReleaseApi = async (
  input: AdminUpdatePaymentInput,
): Promise<void> => {
  await executeGql<void>(venueUpdateInvitePaymentToRelease, { input });
};
