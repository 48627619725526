import { OfferRequirements, User } from '../../../../graphql/API';
import { ExtendedOfferRequirements } from './types';

export const calculateInviteCost = (users: User[]) =>
  users.reduce((prev, crr) => prev + (crr.inviteCredits ?? 0), 0);

export const collabRequirementPrices = {
  story: 10,
  stories3x: 25,
  feed: 50,
  reel: 100,
};

export const inviteRequirementPrice: Record<string, number> = {
  [OfferRequirements.postOnInstagramStories]: collabRequirementPrices.story,
  [OfferRequirements.postOnInstagramStories3x]: collabRequirementPrices.stories3x,
  [OfferRequirements.postOnInstagramFeed]: collabRequirementPrices.feed,
  [OfferRequirements.postOnInstagramReels]: collabRequirementPrices.reel,
  [ExtendedOfferRequirements.noRequirement]: 0,
};
