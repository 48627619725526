import React from 'react';

import { Box } from '@mui/material';

import styled from '@emotion/styled';

import Icons from '../../../../themes/icons';
import { AddFundsButton } from '../../../AddFundsButton';
import LoadingButton from '../../../Button/LoadingButton';

type Props = {
  cost: number;
  balance: number;
  loading?: boolean;
  disabled?: boolean;
  accountStatus?: string;
  onUsageRight?: () => void;
  onBuyContent?: () => void;
};

export const BuyContent: React.FC<Props> = ({
  cost,
  balance,
  loading = false,
  accountStatus,
  onUsageRight,
  onBuyContent,
}) => {
  return (
    <Container>
      <Box>
        <Box display={'flex'} alignItems='center' justifyContent={'space-between'}>
          <BuyContentHeading>Buy content</BuyContentHeading>
          <UsageRightLink onClick={onUsageRight}>Usage Rights</UsageRightLink>
        </Box>
        <UsageDescription>
          By purchasing, you get commercial usage rights for <b>90 days</b> on Instagram. Creator
          retains ownership
        </UsageDescription>
      </Box>
      <Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ marginBottom: '16px' }}
        >
          <Price fontWeight={400}>Cost</Price>
          <Price fontWeight={400}>${cost.toFixed(2)}</Price>
        </Box>
        {accountStatus === 'inactive' || balance <= 0 ? (
          <AddFundsButton />
        ) : (
          <LoadingButton
            variant='contained'
            fullWidth
            sx={{ paddingY: '16px' }}
            onClick={onBuyContent}
            loading={loading}
            disabled={accountStatus === 'inactive' || balance <= 0}
            startIcon={<Icons.LockIcon />}
          >
            Buy content
          </LoadingButton>
        )}
      </Box>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #ebe8e8;
  background-color: #f7f7f7;

  padding: 22px 30px;

  display: flex;
  flex-direction: column;

  gap: 68px;
`;

const BuyContentHeading = styled.h6`
  padding: 0;
  margin: 0;

  color: #111;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`;

const UsageRightLink = styled.button`
  color: #1e55a1;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
`;

const UsageDescription = styled.p`
  margin: 0;
  padding: 0;

  color: #5f5f60;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 6px;
`;

const Price = styled.span<{ fontWeight?: number }>`
  color: #111;
  font-size: 15px;
  font-weight: ${(props) => props.fontWeight || 500};
  line-height: 23px;
`;
