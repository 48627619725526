import { useEffect, useReducer, Dispatch } from 'react';

import deepEqual from 'fast-deep-equal/es6';

import { usePrevious } from './usePrevious';

type PersistedReducer<State, Action> = [State, Dispatch<Action>];

export function usePersistedReducer<State, Action>(
  reducer: (state: State, action: Action) => State,
  initialState: State,
  storageKey: string,
): PersistedReducer<State, Action> {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const prevState = usePrevious(state);

  function init(): State {
    const stringState = localStorage.getItem(storageKey);
    if (stringState) {
      try {
        return JSON.parse(stringState);
      } catch (error) {
        return initialState;
      }
    } else {
      return initialState;
    }
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Clear the localStorage key when the page is about to be reloaded
      localStorage.removeItem(storageKey);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const stateEqual = deepEqual(prevState, state);
    if (!stateEqual) {
      const stringifiedState = JSON.stringify(state);
      localStorage.setItem(storageKey, stringifiedState);
    }
  }, [prevState, state, storageKey]);

  return [state, dispatch];
}
