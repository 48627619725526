import * as React from 'react';
import { EventProps } from 'react-big-calendar';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { toEventResource } from './mapper';

const MonthEvent: React.FC<EventProps> = ({ title, event }) => {
  const resource = toEventResource(event);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#F6F6F6',
        color: 'text.primary',
        borderRadius: 1,
      }}
    >
      <Box sx={{ backgroundColor: resource.colour, borderRadius: 1 }}>&nbsp;&nbsp;</Box>

      <Typography noWrap variant='caption' sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
  );
};

export default MonthEvent;
