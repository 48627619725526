import dayjs from 'dayjs';
import { upperFirst } from 'lodash';

import { Reservation } from '../../../graphql/API';
import { formatName } from '../../../utils/user';

const mapToCsv = (d: Reservation) => ({
  Name: formatName(d.user ?? undefined),
  Offer: d.offerTitle,
  'Offer Date': dayjs(d.createdAt).format('LL'),
  Status: upperFirst(d.status),
});

export default mapToCsv;
