import React from 'react';

import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';

import { ChatMessageItem } from '../../graphql/API';
import { onMessageUpdated } from '../../graphql/subscriptions';
import { GqlError, getError, getErrorFromResult, processGqlResult } from '../../utils/gql-utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMessageSeenListenerQuery = ({ conversationId }: { conversationId: string }) => {
  const [updatedMessage, setUpdatedMessage] = React.useState<Nullable<ChatMessageItem>>(null);
  const [error, setErorr] = React.useState<GqlError>();

  React.useEffect(() => {
    const createSub = API.graphql<GraphQLSubscription<ChatMessageItem>>({
      query: onMessageUpdated,
      variables: { conversationId },
    }).subscribe({
      next: ({ value }) => {
        const _message = processGqlResult<ChatMessageItem>('onMessageUpdated', value);
        setUpdatedMessage(_message);

        const errorFromResponse = getErrorFromResult(value);
        if (errorFromResponse) {
          throw errorFromResponse;
        }
      },
      error: (ex) => {
        const raisedError = getError(ex);
        console.error(raisedError);
        setErorr(raisedError);
      },
    });

    return () => {
      createSub.unsubscribe();
    };
  }, [conversationId, setUpdatedMessage]);

  return {
    updatedMessage,
    error,
  };
};
