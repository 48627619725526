import React from 'react';

import { Box, Divider, Typography } from '@mui/material';

import styled from '@emotion/styled';

interface TabsProps {
  active: boolean;
  icon: React.ReactNode;
  title: string;
  description: string;
  specialText?: string;
  handleChange: () => void;
}

export const OfferSelectionButton: React.FC<TabsProps> = ({
  icon,
  title,
  description,
  specialText,
  active,
  handleChange,
}) => {
  return (
    <Box
      onClick={handleChange}
      border={`${active ? '2px solid #000000' : '1px solid #E6E6E6'}`}
      padding={`20px 24px`}
      borderRadius={`10px`}
      display={`flex`}
      alignItems={`center`}
      gap={`30px`}
      width={`100%`}
      sx={{ cursor: 'pointer' }}
    >
      <Box minWidth={'40px'}>{icon}</Box>
      <Divider orientation='vertical' flexItem />
      <Box>
        <Typography
          color={`#111111`}
          fontSize={`18px`}
          fontWeight={'600'}
          display={`flex`}
          alignItems={`center`}
          gap={`12px`}
          textAlign={'left'}
        >
          <span>{title}</span> {specialText && <SpecialText>{specialText}</SpecialText>}
        </Typography>
        <Typography color={`#5F5F60`} fontSize={`15px`}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const SpecialText = styled.span`
  color: #FFCE50;
`;
