import { StripePricing } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueListPricingQuery = /* GraphQL */ `
  query venueListPricing {
    venueListPricing {
      items {
        id
        currency
        nickname
        product
        metadata
        recurring {
          interval
          interval_count
        }
        unit_amount
      }
    }
  }
`;

export const venueListPricingApi = async (): Promise<StripePricing[]> => {
  const result = await executeGql<StripePricing[]>(venueListPricingQuery);
  return processGqlResult<StripePricing[]>('venueListPricing.items', result);
};
