import { useMemo } from 'react';

export const useQueryParams = () => {
  const queryString = useMemo(() => window.location.search, []); // Memoize the query string

  const params = useMemo(() => new URLSearchParams(queryString), [queryString]); // Memoize the URLSearchParams

  const getQueryParam = (key: string): string | null => params.get(key);

  const getAllQueryParams = (): Record<string, string> => {
    const queryParamsObject: Record<string, string> = {};
    params.forEach((value, key) => {
      queryParamsObject[key] = value;
    });
    return queryParamsObject;
  };

  return { getQueryParam, getAllQueryParams };
};

export default useQueryParams;
