import React from 'react';

import { Box, Typography } from '@mui/material';

import styled from '@emotion/styled';

import { FigureDescription } from './StyledComponents';

type Props = {
  male: number;
  female: number;
};

export const FollowersList: React.FC<Props> = ({ male, female }) => {
  return (
    <Box>
      <Typography fontWeight={500} fontSize={'15px'} marginBottom={'16px'}>
        Followers gender
      </Typography>
      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        <Box display={'flex'} alignItems={'center'} gap={'12px'}>
          <Dot color='#4a98ff' />
          <FigureDescription fontWeight={500}>Male {male.toFixed(2)}%</FigureDescription>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'12px'}>
          <Dot color='#FF4791' />
          <FigureDescription fontWeight={500}>Female {female.toFixed(2)}%</FigureDescription>
        </Box>
      </Box>
    </Box>
  );
};

const Dot = styled.span<{ color?: string }>`
  height: 17px;
  width: 17px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
`;
