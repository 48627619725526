import * as React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { Offer } from '../../../graphql/API';
import useRequirement from './useRequirment';

type RequirementProps = {
  data: Offer;
};

const Requirement: React.FC<RequirementProps> = ({ data }) => {
  const requirements = useRequirement(data);

  return (
    <Stack divider={<Divider />} spacing={2} sx={{ mt: 3 }}>
      {requirements.map(({ Icon, InstagramTag, label }, i) => (
        <Box key={`requirement-${i}`} display='flex' alignItems='center'>
          <Icon sx={{ mr: 2 }} />
          <Typography variant='body2'>
            {label}
            {InstagramTag && <InstagramTag username={data.instagramTag} sx={{ ml: 1 }} />}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default Requirement;
