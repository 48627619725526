import * as React from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

type SideBySideProps = {
  ratio?: number;
  shouldFlexEnd?: boolean;
};

const SideBySide: React.FC<React.PropsWithChildren<SideBySideProps>> = ({
  children,
  ratio = 6,
  shouldFlexEnd = false,
}) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const childs = React.Children.toArray(children);

  const ratios = React.useMemo(() => {
    if (isMobileScreen) {
      return [12, 12];
    }

    return [ratio, 12 - ratio];
  }, [isMobileScreen, ratio]);

  return (
    <Box>
      <Grid container spacing={2}>
        {React.Children.map(childs, (child, i) => (
          <Grid key={i} item xs={ratios[i]} {...(shouldFlexEnd && { alignSelf: 'flex-end' })}>
            {child}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SideBySide;
