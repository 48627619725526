import React from 'react';

import { useMutation } from '@tanstack/react-query';

import { venueGetPaymentIntentApi } from '../../../api/stripe/venueGetPaymentIntentApi';
import { PaymentIntent, VenuePaymentIntentInput } from '../../../graphql/API';

type Props = {
  onMutate?: () => void;
  onSuccess?: (intent: PaymentIntent) => void;
  onSettled?: () => void;
};

export const useBalancePaymentIntentMutation = ({ onMutate, onSuccess, onSettled }: Props) => {
  const useCreditMutation = useMutation({
    mutationFn: async (input: VenuePaymentIntentInput) => {
      const paymentIntent = await venueGetPaymentIntentApi(input);

      return paymentIntent;
    },
    onMutate: () => {
      if (onMutate) {
        onMutate();
      }
    },
    onSuccess: (pi) => {
      if (onSuccess) {
        onSuccess(pi);
      }
    },
    onSettled: () => {
      if (onSettled) {
        onSettled();
      }
    },
  });

  return useCreditMutation;
};
