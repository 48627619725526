import { VenueUpdateVenueInput } from '../../graphql/API';
import { executeGql } from '../../utils/gql-utils';

const venueUpdateVenueMutation = /* GraphQL */ `
  mutation venueUpdateVenue($input: VenueUpdateVenueInput!) {
    venueUpdateVenue(input: $input)
  }
`;

export const venueUpdateVenueApi = async (input: VenueUpdateVenueInput): Promise<void> => {
  await executeGql<void>(venueUpdateVenueMutation, { input });
};
