import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { venueListInvitesApi } from '../../api/invites/venueListInvitesApi';
import { Invite, VenueListInvitesFilter } from '../../graphql/API';
import { QueryResult } from './types';

const PAGE_LIMIT = 50;

type Filter = Partial<VenueListInvitesFilter>;

export const useListInvitesV2Query = (venueID: string, filter?: Filter, limit = PAGE_LIMIT): QueryResult<Invite[]> => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<Invite[]>({
    initialPageParam: 0,
    queryKey: ['list-invites-v2-api', venueID, filter],
    queryFn: async ({ queryKey, pageParam }) => {
      const [, venueID, filter] = queryKey as [string, string, Partial<Filter>];
      const offset = <number>pageParam * limit;

      return venueListInvitesApi({
        limit,
        offset,
        includeExpired: true,
        venueID: Number(venueID),
        ...filter,
      });
    },
    getNextPageParam: (lastGroup, groups) =>
      lastGroup.length === PAGE_LIMIT ? groups.length : undefined,
    placeholderData: keepPreviousData,
    enabled: !!venueID,
    refetchOnWindowFocus: false,
  });

  const flatData = data?.pages.flatMap((x) => x) ?? [];

  return { data: flatData, isFetching, isLoading, fetchNextPage, hasNextPage };
};
