import React from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Grid, Typography } from '@mui/material';

import parse from 'autosuggest-highlight/parse';

import { RenderOptionParams } from './types';

interface GooglePlaceAutoCompleteOptionProps {
  props: RenderOptionParams[0];
  option: RenderOptionParams[1];
}

const GooglePlaceAutoCompleteOption: React.FC<GooglePlaceAutoCompleteOptionProps> = ({
  props,
  option,
}) => {
  const matches = option?.structured_formatting.main_text_matched_substrings ?? [];

  const parts = parse(
    option?.structured_formatting.main_text ?? '',
    matches.map((match) => [match.offset, match.offset + match.length]),
  );

  return (
    <li {...props}>
      <Grid container alignItems='center'>
        <Grid item sx={{ display: 'flex', width: 44 }}>
          <LocationOnIcon sx={{ color: 'text.secondary' }} />
        </Grid>
        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
          {parts.map((part, index) => (
            <Box
              key={index}
              component='span'
              sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
            >
              {part.text}
            </Box>
          ))}
          <Typography variant='body2'>{option?.structured_formatting.secondary_text}</Typography>
        </Grid>
      </Grid>
    </li>
  );
};

export default GooglePlaceAutoCompleteOption;
