import React, { useRef, useState } from 'react';
import { Gallery } from 'react-photoswipe-gallery';
import TextareaAutosize from 'react-textarea-autosize';

import { Box, CircularProgress } from '@mui/material';

import styled from '@emotion/styled';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import { updateMessagesStatusBtwVenueAndUserApi } from '../../../api/chat/updateMessagesStatusBtwVenueAndUserApi';
import { venueSendChatMessageToUserApi } from '../../../api/chat/venueSendChatMessageToUserApi';
import { uploadFileToS3 } from '../../../api/files/uploadFileToS3';
import { ChatMessageItem, ConversationItem } from '../../../graphql/API';
import { useUserChatMessageListenerEvent } from '../../../hooks/reactQuery/chat/useUserChatMessageListenerEvent';
import { useVenueListConversationsQuery } from '../../../hooks/reactQuery/chat/useVenueListConversationsQuery';
import { useVenueRateUserMutation } from '../../../hooks/reactQuery/reservations/useVenueRateUserMutation';
import { useChatListQuery } from '../../../hooks/reactQuery/useChatListQuery';
import { useMessageSeenListenerQuery } from '../../../hooks/reactQuery/useMessageSeenListenerQuery';
import { useQueryReservationById } from '../../../hooks/reactQuery/useQueryReservationById';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import useVenueId from '../../../hooks/useVenueId';
import Icons from '../../../themes/icons';
import { AttachmentInput, FileType } from '../../ChatSupport/AttachmentInput/AttachmentInput';
import { MessageV2 } from '../../ChatSupport/MessageV2/MessageV2';
import { ChatHeader } from '../ChatHeader';
import { LeavedRatingSection } from '../LeavedRatingSection';
import { OfferRequirement } from '../OfferRequirement';
import { RatingSection } from '../RatingSection';

// import { LeavedRatingSection } from '../LeavedRatingSection';

type Props = {
  conversation: ConversationItem;
};

export const Chat: React.FC<Props> = ({ conversation }) => {
  const { enqueueSnackbar } = useSnackbar();
  const venueID = useVenueId();
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const scrollingView = useRef<HTMLDivElement | null>(null);

  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = React.useState<Nullable<ChatMessageItem | undefined>[]>([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isLocalMessageStart, setIsLocalMessageStart] = useState(false);
  const [userRating, setUserRating] = useState(0);

  const { data: reservationDetail, refetch: reservationDetailsRefetch } = useQueryReservationById(
    conversation.reservationID || '',
  );

  const { revalidateConversations } = useVenueListConversationsQuery({ venueID });

  const [, toUserId] = conversation.toUserId.split('-');
  const { message: newMessageListener } = useUserChatMessageListenerEvent(
    toUserId || '',
    venueID || '',
  );

  const { updatedMessage } = useMessageSeenListenerQuery({
    conversationId: conversation?.conversationId || '',
  });

  const {
    isFetching: isFetchingMessages,
    isLoading: isLoadingMessages,
    data,
    hasNextPage,
    fetchNextPage,
    revalidateChatList,
  } = useChatListQuery({ conversationId: conversation?.conversationId || '' });

  const postMessageMutation = useMutation({
    mutationFn: venueSendChatMessageToUserApi,
    onSuccess: () => {
      console.log('sended');
    },
    onSettled: () => {
      setIsLocalMessageStart(true);
    },
    onError: () => {
      enqueueSnackbar('Something went wrong while sending the message', { variant: 'error' });
    },
  });

  const postVenueRateUser = useVenueRateUserMutation({
    onSuccess: () => {
      reservationDetailsRefetch();
      setUserRating(0);
    },
  });

  const updateMessagesStatusMutation = useMutation({
    mutationFn: updateMessagesStatusBtwVenueAndUserApi,
    onSettled: () => {},
    onError: () => {
      enqueueSnackbar('Something went wrong while updating the message status.', {
        variant: 'error',
      });
    },
  });

  const { lastElementRef } = useInfiniteScroll({
    isFetching: isFetchingMessages || isLoadingMessages,
    hasMore: hasNextPage,
    cb: () => {
      fetchNextPage();
      setIsLocalMessageStart(false);
    },
  });

  const focusInput = React.useCallback(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);

  const handleMessageUpdate = async (messages: Nullable<ChatMessageItem | undefined>[]) => {
    const _unseenMessages = messages.filter(
      (message) => message && message.toUserId === venueID && !message.seen,
    );
    if (_unseenMessages.length > 0) {
      await updateMessagesStatusMutation.mutateAsync(
        _unseenMessages.map((message) => ({
          messageTime: message?.messageTime || '',
          conversationId: message?.conversationId || '',
          seen: true,
          received: true,
        })),
      );
    }
  };

  React.useEffect(() => {
    if (messages.length > 0) {
      handleMessageUpdate(messages);
    }
  }, [conversation.conversationId, messages]);

  React.useEffect(() => {
    if (newMessageListener) {
      setMessages((pre) => [newMessageListener, ...pre]);
    }
  }, [newMessageListener]);

  React.useEffect(() => {
    if (newMessageListener) {
      revalidateConversations();
    }
  }, [newMessageListener]);

  React.useEffect(() => {
    focusInput();
  }, [focusInput]);

  React.useEffect(() => {
    setMessages(data);
  }, [data]);

  React.useEffect(() => {
    if (updatedMessage) {
      revalidateChatList();
    }
  }, [updatedMessage]);

  React.useEffect(() => {
    setUserRating(0);
  }, [conversation]);

  const onRatingChange = (val: number) => {
    setUserRating(val);
  };

  const handleFileUpload = React.useCallback(
    async (file: File, fileType: FileType) => {
      const messageTime = dayjs.utc().format();
      const dataUrl = URL.createObjectURL(file);

      if (file && conversation) {
        const newMessages = [
          {
            __typename: 'ChatMessageItem',
            fromUserId: venueID,
            toUserId: conversation.toUserId, // adminID
            conversationId: conversation.conversationId,
            userType: 'venue/user',
            messageTime: messageTime,
            message: dataUrl,
            messageType: fileType,
          },
          ...messages,
        ];
        setMessages(newMessages as ChatMessageItem[]);

        const url = await uploadFileToS3(file);
        setIsFileUploading(false);
        const [, toUserId] = conversation.toUserId.split('-');
        if (venueID && conversation) {
          await postMessageMutation.mutateAsync({
            conversationId: conversation.conversationId,
            message: url,
            fromUserChatId: venueID,
            toUserChatId: toUserId,
            messageType: fileType,
            reservationID: reservationDetail?.id || '',
          });
        }
      }
    },
    [conversation, messages, postMessageMutation, reservationDetail?.id, venueID],
  );

  const messageSubmitHandler = async () => {
    if (newMessage.trim() !== '') {
      const _newMessage = {
        __typename: 'ChatMessageItem',
        fromUserId: conversation.fromUserId,
        toUserId: conversation.toUserId,
        conversationId: conversation.conversationId,
        userType: 'venue/user',
        messageTime: dayjs.utc().format(),
        message: newMessage,
      };

      setMessages((prev) => [_newMessage, ...prev] as ChatMessageItem[]);
      setNewMessage('');
      scrollingView.current?.scrollIntoView({ block: 'center', behavior: 'auto' });
      const [, toUserId] = conversation.toUserId.split('-');
      await postMessageMutation.mutateAsync({
        conversationId: conversation.conversationId,
        message: newMessage,
        fromUserChatId: venueID || '',
        toUserChatId: toUserId,
        reservationID: reservationDetail?.id || '',
      });
    }
  };

  const onVenueRateUserSubmit = () => {
    if (reservationDetail?.id) {
      postVenueRateUser.mutate({
        reservationID: Number(reservationDetail?.id),
        userID: reservationDetail.userID,
        rating: userRating,
      });
    }
  };

  const isChatEnd = !!conversation.isChatClosed;

  return (
    <>
      <ChatHeader
        conversation={conversation}
        reservationDetail={reservationDetail}
        reservationDetailsRefetch={() => {
          reservationDetailsRefetch();
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          height: 'calc(100vh - 140px)',
          overflow: 'auto',
          bgcolor: '#fff',
        }}
      >
        <Box
          sx={{
            textAlign: 'left',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0px',
            justifyContent: 'end',
          }}
        >
          {!isLocalMessageStart && (isFetchingMessages || isLoadingMessages) && (
            <Box textAlign={'center'} marginY={1}>
              <CircularProgress style={{ width: 20, height: 20 }} />
              <div>
                <em>Loading messages...</em>
              </div>
            </Box>
          )}
          <Gallery>
            <Box
              sx={{
                flex: '0 1 85%',
                height: '100%',
                overflowY: 'auto',
                maxHeight: '100%',
                padding: '32px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column-reverse',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#a8a8a8',
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#111',
                },
              }}
            >
              {isChatEnd && (
                <Box
                  sx={{
                    px: 1,
                    my: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {!reservationDetail?.rating ? (
                    <RatingSection
                      userName={`${conversation.firstname} ${conversation.lastname}`}
                      isLoading={postVenueRateUser.isPending}
                      value={userRating}
                      onValueChange={onRatingChange}
                      onSubmit={onVenueRateUserSubmit}
                    />
                  ) : (
                    <LeavedRatingSection
                      rating={reservationDetail?.rating || 0}
                      userName={`${conversation.firstname} ${conversation.lastname}`}
                    />
                  )}
                </Box>
              )}
              {isChatEnd && (
                <Box sx={{ px: 1, my: 2, display: 'flex', alignItems: 'center' }}>
                  <LineCloseChat />
                  <ChatClosedMessage>Chat closed</ChatClosedMessage>
                  <LineCloseChat />
                </Box>
              )}
              <div ref={scrollingView} />
              {messages.map(
                (message, index) =>
                  message && (
                    <MessageV2
                      userId={venueID || ''}
                      key={message.messageTime}
                      conversation={conversation}
                      index={index}
                      lastElementRef={lastElementRef}
                      messages={messages}
                      msg={message}
                    />
                  ),
              )}
              <div className='message-content-receiver'>
                <OfferRequirement reservationDetail={reservationDetail} />
              </div>
            </Box>
          </Gallery>
          <Box
            sx={{
              backgroundColor: '#f8f8f9',
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              gap: '14px',
            }}
          >
            <AttachmentInput
              handleFileUpload={handleFileUpload}
              disabled={isFileUploading || isChatEnd}
            />
            <StyledInput
              placeholder='Type your message'
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              ref={messageInputRef}
              onKeyDown={(e) => {
                if (e.keyCode == 13 && e.shiftKey == false) {
                  e.preventDefault();
                  messageSubmitHandler();
                }
              }}
              disabled={isChatEnd}
            />
            <ButtonContainer type='submit' disabled={isChatEnd}>
              <Icons.SendIcon />
            </ButtonContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const StyledInput = styled(TextareaAutosize)`
  background-color: #fff;
  width: 100%;
  font-size: 15px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #f3f4f6;
  padding: 12px 42px 12px 15px;
  resize: none;

  &:focus {
    outline: none;
  }
  @media (max-width: 485px) {
    padding: 10px 12px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ButtonContainer = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #949ba5;

  &:disabled {
    cursor: not-allowed;
  }
`;

const ChatClosedMessage = styled.span`
  font-size: 10px;
  color: #949ba5;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 70px;
  display: block;
  margin: 0 24px;
`;

const LineCloseChat = styled.div`
  height: 1px;
  background: #949ba5;
  width: 100%;
`;
