import * as React from 'react';

import Typography from '@mui/material/Typography';

type SectionTitleProps = {
  title: string | React.ReactNode;
  description?: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ title, description }) => {
  return (
    <Typography variant='h6' mb={3}>
      <Typography sx={{fontSize:"22px", fontWeight:"600"}}>{title}</Typography>
      {description && <Typography>{description}</Typography>}
    </Typography>
  );
};

export default SectionTitle;
