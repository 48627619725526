import * as React from 'react';

import { VenueSubscriptionItem } from '../../../graphql/API';
import { extractSubscriptionPlan } from '../../../pages/SubscriptionPlans/common/helper';
import { Plan, SubscriptionConditionKind } from '../../../pages/SubscriptionPlans/types';
import { StripePriceWithMetadata } from '../../../redux/features/subscription/types';
import BaseDialog, { BaseDialogProps } from '../Base';
import CancelSubscription, { CancelSubscriptionProps } from './components/CancelSubscription';
import CycleChange from './components/CycleChange';
import DowngradeBenefit from './components/DowngradeBenefit/DowngradeBenefit';
import Title from './components/Title';
import UpdatePlan from './components/UpdatePlan';

export type SubscriptionDialogProps = {
  subscription: VenueSubscriptionItem | undefined;
  price: StripePriceWithMetadata | undefined;
  kind: SubscriptionConditionKind;
  plan: Plan;
  modalRef: CancelSubscriptionProps['modalRef'];
} & BaseDialogProps;

const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  modalRef,
  kind,
  plan,
  price,
  subscription,
  ...restProps
}) => {
  const renderContent = React.useCallback(
    (k: SubscriptionConditionKind) => {
      const props = {
        subscriptionKind: k,
        modalRef,
        price,
        subscription,
      };

      switch (k) {
        case 'cycle-change-decrease':
        case 'cycle-change-increase':
          return <CycleChange {...props} />;
        case 'plan-cancel':
          return <CancelSubscription {...props} />;
        case 'plan-upgrade':
        case 'plan-downgrade':
          return <UpdatePlan {...props} />;
      }

      return null;
    },
    [modalRef, price, subscription],
  );

  const extraProps = React.useMemo<Partial<SubscriptionDialogProps> | undefined>(() => {
    if (kind === 'plan-downgrade') {
      const [newPlan] = extractSubscriptionPlan(subscription?.sku ?? '');

      return {
        sideComponent: <DowngradeBenefit plan={newPlan} />,
        width: '750px',
      };
    }
    return undefined;
  }, [kind, subscription]);

  return (
    <BaseDialog
      {...restProps}
      titleComponent={<Title plan={plan} subscriptionKind={kind} />}
      height='unset'
      width='550px'
      {...extraProps}
    >
      {renderContent(kind)}
    </BaseDialog>
  );
};

export default SubscriptionDialog;
