import {
  VenueListReservationsContentFilter,
  VenueReservationContentItemV2,
} from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

export const venueListReservationContent = /* GraphQL */ `
  query VenueListReservationContentV2($filter: VenueListReservationsContentFilter!) {
    venueListReservationContentV2(filter: $filter) {
      items {
        byVenue
        content
        createdAt
        downloadCredits
        downloaded
        id
        seen
        updatedAt
        user {
          id
          firstname
          lastname
          updatedAt
          picture {
            thumbnail
            small
            medium
            large
          }
          instagramHandle
          instagramFollowers
          savedByVenue
        }
        images {
          thumbnail
          small
          medium
          large
          video
        }
        cost
        price
        downloadedAt
        __typename
      }
      __typename
    }
  }
`;

export const venueListReservationContentApi = async (
  filter: Partial<VenueListReservationsContentFilter>,
): Promise<VenueReservationContentItemV2[]> => {
  const result = await executeGql<VenueReservationContentItemV2[]>(venueListReservationContent, {
    filter,
  });
  return processGqlResult<VenueReservationContentItemV2[]>(
    'venueListReservationContentV2.items',
    result,
  );
};
