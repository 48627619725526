import { useParams } from 'react-router-dom';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { venueGetReservationApi } from '../../../api/reservations/venueGetReservationApi';
import { Reservation } from '../../../graphql/API';

export const useQueryReservation = () => {
  const { reservationId } = useParams<{ reservationId: string }>();

  return useQuery<Reservation>({
    queryKey: ['reservation-detail', reservationId],
    queryFn: async ({ queryKey }) => {
      const [, reservationID] = queryKey as [string, string];

      return venueGetReservationApi(reservationID);
    },
    placeholderData: keepPreviousData,
  });
};
